<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                temporary
                absolute
                app
        >
            <v-divider></v-divider>
            <div class="pa-4">
                <v-select
                        v-model="filters.account"
                        :items="filter_choices.seller"
                        item-text="name"
                        item-value="id"
                        label="ЛК"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                ></v-select>
            </div>
			<div class="pa-4">
				<v-autocomplete
					v-model="filters.manager" :items="filter_choices.product_manager_choices"
					label="Менеджер" outlined hide-details dense clearable multiple item-text="name" item-value="id"
				></v-autocomplete>
            </div>
            <div class="pa-4">
                <v-select
                        v-model="filters.profile"
                        :items="filter_choices.product_profile_choices"
                        item-text="name"
                        item-value="value"
                        label="Профильность"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                ></v-select>
            </div>
            <div class="pa-4">
                <v-text-field
                        v-model="filters.tag"
                        clearable
                        outlined
                        dense
                        label="Тег"
                        hide-details="hide-details">
                </v-text-field>
            </div>
			<div class="pa-4">
              <v-switch
                      v-model="filters.is_bookmark"
                      inset
                      class="mt-0 mb-2"
                      hide-details
                      :label="`Избранные`"
              ></v-switch>
            </div>
            <div class="pa-5">
                <v-btn
                        @click="fetchProductsWithFilters()"
                        depressed
                        color="purple darken-2"
                        class="mb-3"
                        dark
                >
                    Применить
                </v-btn>
                <v-btn
                        @click="clearFilters()"
                        depressed
                        class="mb-10"
                >
                    Сбросить
                </v-btn>
            </div>
        </v-navigation-drawer>
        <v-sheet ref="product_table_filters">
			<v-btn icon @click="drawer = !drawer">
                <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            <v-container fluid>
                <v-row class="align-center">
                    <v-col>
                        <v-select
                                v-model="filters.brand"
                                :items="filter_choices.product_brand_choices"
                                item-text="name"
                                item-value="name"
                                label="Бренд"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="filters.group"
                                :items="filter_choices.product_group_choices"
                                item-text="name"
                                item-value="value"
                                label="Группа"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="filters.status"
                                :items="filter_choices.product_status_choices"
                                item-text="name"
                                item-value="value"
                                label="Статус"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                        ></v-select>
                    </v-col>
					<v-col>
						<v-switch
                            v-model="filters.is_empty_expenses" hide-details 
                            label="Без расходов"
                        ></v-switch>
                    </v-col>
					<v-col>
                        <v-radio-group
							v-model="filters.stock"
							hide-details
							row
							class="mt-1"
						>
							<v-radio
								label="Все"
								:value=0
							></v-radio>
							<v-radio
								label="Есть остатки"
								:value=1
							></v-radio>
							<v-radio
								label="Нет остатков"
								:value=-1
							></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
						<v-radio-group
							v-model="filters.price"
							hide-details
							row
							class="mt-1"
						>
							<v-radio
								label="Все"
								:value=0
							></v-radio>
							<v-radio
								label="Цена больше 0"
								:value=1
							></v-radio>
							<v-radio
								label="Цена 0"
								:value=-1
							></v-radio>
						</v-radio-group>
                    </v-col>
					<v-col>
						<v-radio-group
							v-model="filters.rentab"
							hide-details
							row
							class="mt-1"
						>
							<v-radio
								label="Все"
								:value=0
							></v-radio>
							<v-radio
								label="Марж-ть больше 0"
								:value=1
							></v-radio>
							<v-radio
								label="Марж-ть меньше 0"
								:value=-1
							></v-radio>
						</v-radio-group>
                    </v-col>
                    <v-col>
                        <v-radio-group
							v-model="filters.cost_price"
							hide-details
							row
							class="mt-1"
                        >
							<v-radio
								label="Все"
								:value=0
							></v-radio>
							<v-radio
								label="Себестоимость больше 0"
								:value=1
							></v-radio>
							<v-radio
								label="Себестоимость 0"
								:value=-1
							></v-radio>
                        </v-radio-group>
                    </v-col>
					<v-col>
						<v-radio-group
							v-model="filters.stock_day"
							hide-details
							row
							class="mt-1"
						>
							<v-radio
								label="Все"
								:value=0
							></v-radio>
							<v-radio
								label="Остаток дней больше 60"
								:value=1
							></v-radio>
							<v-radio
								label="Остаток дней c 1c больше 60"
								:value=2
							></v-radio>
						</v-radio-group>
                    </v-col>
					<v-col>
                        <v-text-field
							v-model="filters.name"
							clearable
							outlined
							dense
							label="Название товара"
							hide-details="hide-details">
                        </v-text-field>
                    </v-col>
					<v-col>
                        <v-text-field
							v-model="filters.code_1c"
							clearable
							outlined
							dense
							label="Артикул 1С"
							hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
							v-model="filters.mid"
							clearable
							outlined
							dense
							@keyup.enter="fetchProductsWithFilters()"
							append-icon="mdi-magnify"
							label="Артикул"
							hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">
						<v-btn
							@click="fetchProductsWithFilters()"
							depressed
							color="purple darken-2"
							class="mb-3"
							dark
                        >Применить</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <v-data-table
                v-else
                :headers="dataTable.headers"
                :pageCount="pagination.numberOfPages"
                :items="items"
                item-key="mid"
                :loading="tableLoading"
                :options.sync="options"
				:items-per-page="25"
                :server-items-length="pagination.totalItems"
				:expanded.sync="expanded"
				show-expand
				single-expand
                class="elevation-1 small-font"
				:footer-props="{
					'items-per-page-options': [5, 15, 25, 50, 100]
				}"
        >
			<template v-slot:header.rentab_value="{ header }">
				{{ header.text }}
				<v-tooltip bottom max-width=1200>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" style="cursor: pointer">
							<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
						</span>
					</template>
					<span>
						<p style="white-space: nowrap;" v-for="i in calcHelpText('rentab_value')" :key="i">{{ i }}</p>
					</span>
				</v-tooltip>
			</template>
			<template v-slot:header.rentab_mp="{ header }">
				{{ header.text }}
				<v-tooltip bottom max-width=1200>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" style="cursor: pointer">
							<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
						</span>
					</template>
					<span>
						<p style="white-space: nowrap;" v-for="i in calcHelpText('rentab_mp')" :key="i">{{ i }}</p>
					</span>
				</v-tooltip>
			</template>
			<template v-slot:item.pic="{ item }">
                <div>
					<img style="max-height:100px" :src="item.primary_image" alt="">
				</div>
            </template>
			<template v-slot:item.plan_execution_vp_mp="{ item }">
                {{ item.catalog_item.code1C_name }}
				<div class="small-text" :class="{'green_rentab': item.dynamic_fields.plan_execution_vp_mp >= 100 && item.dynamic_fields.plan_vp_mp != 0, 'orange_rentab': item.dynamic_fields.plan_execution_vp_mp >= 90 && item.dynamic_fields.plan_execution_vp_mp < 100 && item.dynamic_fields.plan_vp_mp != 0, 'red_rentab2': item.dynamic_fields.plan_execution_vp_mp < 90 && item.dynamic_fields.plan_vp_mp != 0}">
					<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap;">План: {{ item.dynamic_fields.plan_vp_mp | toRUBWithoutKopecks }}</p>
					<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap;">Прогноз: {{ item.dynamic_fields.progress_vp_mp | toRUBWithoutKopecks }}</p>
					<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap; font-weight: bold">Выполнение: {{ item.dynamic_fields.plan_execution_vp_mp.toFixed(0) }}%</p>
				</div>
            </template>
            <template v-slot:item.mid="{ item }">
				<div>
					<div><span class="grey--text">OZON ID: </span>{{ item.mid }}</div>

					<a 
						:href="`https://www.ozon.ru/context/detail/id/${item.fbo_sku}/`"
						target="_blank"
						style="display: block;"
					><span class="grey--text">FBO SKU: </span>{{ item.fbo_sku }}</a>

					<a 
						:href="`https://www.ozon.ru/context/detail/id/${item.fbs_sku}/`"
						target="_blank"
						style="display: block;"
					><span class="grey--text">FBS SKU: </span>{{ item.fbs_sku }}</a>
					<div><span class="grey--text">Code 1c: </span>{{ item.catalog_item.code1C_code }}</div>
					<v-icon small class="ml-1 mb-2" title="Добавление новых ключей" @click="editItemMonitoring(item)">
						mdi-plus
					</v-icon>
					<v-icon small class="ml-1 mb-2" title="Добавление в избранное" @click="toggleBookmarks(item)" v-if="productInBookmarks(item)">
						mdi-star-plus-outline
					</v-icon>
					<v-icon small class="ml-1 mb-2" title="Добавление в избранное" @click="toggleBookmarks(item)" v-else>
						mdi-star-plus
					</v-icon>
					<div><router-link target="_blank" class="link text-decoration-underline" :to="{ name: 'ozon_product_card', params: { id: item.mid }}">Карточка товара</router-link></div>
				</div>
            </template>
			<template v-slot:item.brand="{ item }">
                <div><span class="grey--text">Бренд: </span>{{ item.catalog_item.brand ? item.catalog_item.brand : "-" }}</div>
                <div><span class="grey--text">Группа: </span>{{ item.catalog_item.code1C_tag_group_name ? item.catalog_item.code1C_tag_group_name : "-"}}</div>
            </template>
			<template v-slot:item.product_status="{ item }">
                <div style="font-size: .7rem;" class="pt-2">{{ item.catalog_item.status_name ? item.catalog_item.status_name : "-" }} |</div>
                <div style="font-size: .7rem;" class="pt-2">{{ item.catalog_item.account_name ? item.catalog_item.account_name : "-" }}</div>
				<div 
					class="mt-2 small-text orange_rentab" 
					style="white-space: nowrap; font-size: .7rem;"
					v-if="item.dynamic_fields.days_from_first_stocks <= 91 || item.catalog_item.status_name == 'Новинка' "
				>{{daysLabel(item.dynamic_fields.days_from_first_stocks)}}</div>
            </template>
			<template v-slot:item.price="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					{{item.dynamic_fields.marketing_seller_price ? item.dynamic_fields.marketing_seller_price : 0 | toRUB }}
					({{item.dynamic_fields.marketing_price ? item.dynamic_fields.marketing_price : 0 | toRUB }})
					<span v-if="!item.on_sale">⛔️</span>
				</div>
				<div class="small-text" v-else>-</div>
				<v-tooltip bottom max-width=350 v-if="item.general_index_type">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on" :class="{
							'green-index2': item.general_index_type == 'PROFIT',
							'yellow-index2': item.general_index_type == 'AVG_PROFIT',
							'red-index2': item.general_index_type == 'NON_PROFIT',
							'without-index2': item.general_index_type == 'WITHOUT_INDEX',
						}">
							<div class="dashed small-text">{{ item.general_index_type }}</div>
						</div>
					</template>
					<span>
						<div class="small-text">external_index_minimum_price: {{item.external_index_minimum_price ? item.external_index_minimum_price : 0 | toRUB}}</div>
						<div class="small-text">external_index_value: {{item.external_index_value ? item.external_index_value : 0}}</div>
						<div class="small-text">ozon_index_minimum_price: {{item.ozon_index_minimum_price ? item.ozon_index_minimum_price : 0 | toRUB}}</div>
						<div class="small-text">ozon_index_value: {{item.ozon_index_value ? item.ozon_index_value : 0}}</div>
						<div class="small-text">other_index_minimum_price: {{item.other_index_minimum_price ? item.other_index_minimum_price : 0 | toRUB}}</div>
						<div class="small-text">other_index_value: {{item.other_index_value ? item.other_index_value : 0}}</div>
					</span>
				</v-tooltip>
				<div class="small-text">WB: {{ item.dynamic_fields.analog_price | toRUB }}</div>
				<div class="small-text" style="white-space: nowrap;">Цена из заказов: {{ item.dynamic_fields.price_from_orders | toRUB }}</div>
				<div class="small-text">C/C: {{ item.dynamic_fields.cost_price ? item.dynamic_fields.cost_price : 0 | toRUB }}</div>
				<div 
					style="max-width: 150px; width: 150px" 
					v-if="item.dynamic_fields && (item.dynamic_fields.orders_array || item.dynamic_fields.price_array)"
				>
					<OrdersChartProductList 
						:series="getOrdersGraph(item, 1, 15)"
						:series_price="getPriceGraph(item, 1, 15)"
					/>
				</div>
            </template>
			<template v-slot:item.drr="{ item }">
				<div class="small-text" style="display:flex; flex-direction: column; align-items: center;" v-if="item.dynamic_fields">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="white-space: nowrap;">
								ДРР: {{ item.dynamic_fields.drr_14 ? item.dynamic_fields.drr_14 : 0 }}% / {{ item.dynamic_fields.drr_7 ? item.dynamic_fields.drr_7 : 0 }}%
								<br>
								Расход: {{ item.dynamic_fields.drr_expenses_14 ? item.dynamic_fields.drr_expenses_14 : 0 | toRUB}} / {{ item.dynamic_fields.drr_expenses_7 ? item.dynamic_fields.drr_expenses_7 : 0 | toRUB}}
							</div>
						</template>
						<span>
							<div class="small-text">ДРР за 7 дней: {{ item.dynamic_fields.drr_14 ? item.dynamic_fields.drr_7 : 0 }}%</div>
							<div class="small-text">ДРР за 14 дней: {{ item.dynamic_fields.drr_7 ? item.dynamic_fields.drr_14 : 0 }}%</div>
							<div class="small-text">Расход за 7 дней: {{ item.dynamic_fields.drr_expenses_7 ? item.dynamic_fields.drr_expenses_7 : 0 | toRUB}}</div>
							<div class="small-text">Расход за 14 дней: {{ item.dynamic_fields.drr_expenses_14 ? item.dynamic_fields.drr_expenses_14 : 0 | toRUB}}</div>
							<div class="small-text">Расход включает в себя внутреннюю рекламу, раздачи, блогеров. ДРР рассчитывается из суммы заказов АПИ</div>
						</span>
					</v-tooltip>
					<div style="max-width: 150px; width: 150px" v-if="item.dynamic_fields.drr_expenses_14 > 0">
						<OrdersChartProductList 
							:series="getDrrExpensesGraph(item, 1, 15)"
							:series_price="getDrrGraph(item, 1, 15)"
							:is_drr="true"
						/>
					</div>
				</div>
            </template>
			<template v-slot:item.last_stock_api="{ item }">
				<div v-if="item.dynamic_fields" style="white-space: nowrap;"><span class="grey--text">Ожид. поставка: </span>{{ item.dynamic_fields.stock_coming || '-' }}</div>
				<div v-if="item.dynamic_fields" style="white-space: nowrap;"><span class="grey--text">На складе: </span>{{ item.dynamic_fields.stock_present || '-' }}</div>
				<div v-if="item.dynamic_fields" style="white-space: nowrap;"><span class="grey--text">Зарезервировано: </span>{{ item.dynamic_fields.stock_reserved || '-' }}</div>
				<div v-if="item.dynamic_fields" style="white-space: nowrap;"><span class="grey--text">Индекс локализации ЛК: </span>{{ item.dynamic_fields.localization_index ? item.dynamic_fields.localization_index : 0 }}</div>
				<div :class="{
					'green-index2': item.dynamic_fields.index_by_product >= 75,
					'yellow-index2': item.dynamic_fields.index_by_product < 75 && item.dynamic_fields.index_by_product >= 65,
					'red-index2': item.dynamic_fields.index_by_product < 65,
				}">
					<div class="small-text">Индекс по товару: {{ item.dynamic_fields.index_by_product }}</div>
				</div>
			</template>
			<template v-slot:item.last_stock_1c="{ item }">
				<div class="small-text">
					{{ item.dynamic_fields && item.dynamic_fields.last_stock_1c ? item.dynamic_fields.last_stock_1c: 0 }}
				</div>
            </template>
			<template v-slot:item.orders_30days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_30days ? item.dynamic_fields.orders_30days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_30days ? item.dynamic_fields.orders_count_30days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_count_prev_days.days_30, item.dynamic_fields.orders_count_30days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.orders_7days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_7days ? item.dynamic_fields.orders_7days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_7days ? item.dynamic_fields.orders_count_7days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_count_prev_days.days_7, item.dynamic_fields.orders_count_7days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.sales_30days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.sales_30days ? item.dynamic_fields.sales_30days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.sales_count_30days ? item.dynamic_fields.sales_count_30days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.sales_count_prev_days.days_30, item.dynamic_fields.sales_count_30days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.speed_orders="{ item }">
				<div class="small-text" v-if="item.dynamic_fields && (item.dynamic_fields.speed_orders || item.dynamic_fields.speed_orders_7)">
					{{ item.dynamic_fields.speed_orders ? item.dynamic_fields.speed_orders.toFixed(2) : 0 }}
					({{ item.dynamic_fields.speed_orders_7 ? item.dynamic_fields.speed_orders_7.toFixed(2): 0 }})
				</div>
				<div class="small-text" v-else>-</div>
            </template>
			<template v-slot:item.day_stock="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					{{ item.dynamic_fields.day_stock ? item.dynamic_fields.day_stock.toFixed(2) : 0 }}
					({{ item.dynamic_fields.day_stock_1c ? item.dynamic_fields.day_stock_1c.toFixed(2) : 0 }})
				</div>
				<div class="small-text" v-else>-</div>
            </template>
			<template v-slot:item.index_day="{ item }">
				<div class="small-text" v-if="item.dynamic_fields && item.dynamic_fields.index_day">
					{{ item.dynamic_fields.index_day }}
				</div>
				<div class="small-text" v-else>-</div>
            </template>
			<template v-slot:item.rentab_mp="{ item }">
				<div 
					style="white-space: nowrap;"
					:class="{
						'green_rentab': item.dynamic_fields.retab_mp > 18, 
						'orange_rentab': item.dynamic_fields.retab_mp > 8 && item.dynamic_fields.retab_mp <= 18, 
						'red_rentab': item.dynamic_fields.retab_mp <= 8
					}"
				>
					{{ item.dynamic_fields.retab_mp ? item.dynamic_fields.retab_mp : 0 }}% ({{ item.dynamic_fields.vp_mp ? item.dynamic_fields.vp_mp : 0 | toRUB }})
				</div>
				<div style="max-width: 150px; width: 150px" v-if="item.dynamic_fields.vp_mp_array || item.dynamic_fields.rentab_mp_array">
					<OrdersChartProductList 
						:series="getVpMpGraph(item, 1, 15)"
						:series_price="getRentabMpGraph(item, 1, 15)"
						:is_vp="true"
					/>
				</div>
            </template> 
			<template v-slot:item.delta_rentab="{ item }">
				<div class="d-flex">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" >
								<div class="small-text" style="white-space: nowrap;">
									Прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_ls : 0 | toRUB }})
								</div>
								<hr>
								<div class="small-text" style="white-space: nowrap;">
									Факт: {{ item.dynamic_fields ? item.dynamic_fields.rentab_sales_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_sales_ls : 0 | toRUB }})
								</div>
								<div class="small-text" style="white-space: nowrap;" :class="{'red_rentab': item.delta_rentab > 5}">
									delta: {{ item.delta_rentab ? item.delta_rentab : 0 }}%
								</div>
							</div>
						</template>
						<span>
							<div class="small-text">ВП_бс_н прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_plan : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_unit_plan : 0 | toRUB }})</div>
							<div class="small-text">ВП_ЛС прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_ls : 0 | toRUB }})</div>
							<div class="small-text">ВП_М прогноз: {{ item.dynamic_fields ? item.dynamic_fields.retab_m : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_m : 0 | toRUB }})</div>
							<div class="small-text">ВП_МП прогноз: {{ item.dynamic_fields ? item.dynamic_fields.retab_mp : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_mp : 0 | toRUB }})</div>
						</span>
					</v-tooltip>
					<v-tooltip bottom max-width=350 v-if="item.dynamic_fields.vp_unit_plan == item.dynamic_fields.vp_ls">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-icon color="red">mdi-exclamation</v-icon>
							</div>
						</template>
						<span>Расходы не загружены</span>
					</v-tooltip>    
				</div>
            </template> 
			<template v-slot:top>
				<div class="d-flex w-100 px-7 pt-5">
					<v-btn
						color="primary" dark class="mb-2" @click="exportToExcel" :loading="loading_export"
					>Экспорт в excel</v-btn>	
					<v-btn
						color="primary" dark class="mb-2 ml-5" @click="exportWithoutPlan" :loading="loading_export_without_plan"
					>Экспорт товаров без планов</v-btn>	
					<router-link target="_blank" class="mb-2 ml-5" :to="{ name: 'ozon_product_indices'}">
						<v-btn color="primary" dark class="mb-2 ml-5">Индекц локализации и цен</v-btn>		
					</router-link>	
					<v-btn
						color="primary" dark class="mb-5 ml-5" @click="dialog_warehousing_cost=true"
					>Загрузка стоимости размещения</v-btn>	
					<router-link target="_blank" :to="{ name: 'ozon_product_promotions'}">
						<v-btn color="primary" dark class="mb-5 ml-5">Акции</v-btn>		
					</router-link>
				</div>
            </template>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length">
					<div class="pb-5">
						<OzonKeywordMonitoring 
							:sku="item.fbo_sku" 
							:mid="item.mid" 
							favorite="true"
							:key="item.mid"
							:is_short_version="true"
							:is_competitors="false"
						/>
					</div>
				</td>
            </template>
        </v-data-table>
		<v-dialog v-model="dialog_monitoring" max-width="500px" v-if="dialog_monitoring">
			<v-card>
				<v-card-title>Добавление ключей</v-card-title>
				<v-card-text class="mt-5">
					<v-textarea
					solo
					auto-grow
					rows="3"
					v-model="key_list_monitoring"
					label="Введите название ключей (каждый ключ с новой строки)"
					></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeWindowMonitoring">Закрыть</v-btn>
					<v-btn color="blue darken-1" text @click="saveKeyword">Добавить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_warehousing_cost" max-width="600px" v-if="dialog_warehousing_cost">
			<v-card>
				<v-card-title>Загрузка стоимости размещения товаров</v-card-title>
				<v-card-text class="mt-5">
					<v-file-input
						label="Загрузите файлы" outlined dense id="warehousing_cost_files"
						class="pt-5" hide-details multiple
					></v-file-input>
				</v-card-text>
				<v-card-actions>
					<v-btn 
						color="blue darken-1" text :loading="loading_warehousing_cost"
						@click="import_warehousing_cost"
					>Импортировать</v-btn>
					<div class="d-flex align-center ml-5" v-if="status_warehousing_cost != ''">
						<h3>Статус: </h3>
						<v-chip 
							class="ml-5" variant="flat" color="primary" v-if="status_warehousing_cost == 'Выполняется'"
						>{{ status_warehousing_cost }}</v-chip>
						<v-chip 
							class="ml-5" variant="flat" color="green" v-else-if="status_warehousing_cost == 'Успешно'"
						>{{ status_warehousing_cost }}</v-chip>
						<v-chip 
							class="ml-5" variant="flat" color="red" v-else-if="status_warehousing_cost == 'Ошибка'"
						>{{ status_warehousing_cost }}</v-chip>
						<div v-else></div>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
	import { Products } from '@/api/products'
	import { General } from '@/api/general'
	import { Catalog } from '@/api/catalog'
	import { Stats } from '@/api/stats'
	import {KeywordMonitoring} from '@/api/keyword_monitoring';
	import OrdersChartProductList from '@/components/Product/OrdersChartProductList'
	import OzonKeywordMonitoring from '@/components/Products/OzonKeywordMonitoring'

	export default {
		name: 'OzonProductsList',
		components: {
			OrdersChartProductList,
			OzonKeywordMonitoring
		},
		data () {
			return {
				loading_export_without_plan: false,
				dialog_warehousing_cost: false,
				loading_warehousing_cost: false,
				status_warehousing_cost: '',
				datepicker_date: null,
				list_bookmarks: [],
				loading_export: false,
				first_init: false,
				dialog_monitoring: false,
				expanded: [],
				key_list_monitoring:null,
				current_item_monitoring: null,
				initLoading: false,
				tableLoading: false,
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				options: {
					sortBy: ['orders_30days'],
					sortDesc: [true]
				},
				drawer: false,
				filters: {
					code_1c: null,
					profile: 0,
					stock: 0,
					price: 0,
					brand: null,
					group: null,
					date_before: null,
					date_after: null,
					mid: null,
					advertising: null,
					status: ['0001', '0008'],
					account: null,
					cost_price: 0,
					manager: null,
					tag: null,
					name: null,
					stock_day: 0,
					rentab: 0,
					is_bookmark: null,
					is_empty_expenses: null
				},
				filter_range: {
					index_day_from: null,
					index_day_to: null,
					index_week_from: null,
					index_week_to: null
				},
				filter_choices: {
					seller:[],
					product_status_choices: [],
					product_manager_choices: [],
					product_brand_choices: [],
					product_group_choices: [],
					product_profile_choices: [],
				},
				datepicker_menu_from: null,
				datepicker_menu_to: null,
				items: [],
				dataTable: {
					headers: [
						{ text: '', value: 'data-table-expand', width: '1%' },
						{ text: 'Картинка', align: 'start', sortable: false, value: 'pic', width: '1%'},
						{ text: 'id', align: 'start', sortable: true, value: 'mid', width: '3%' },
						{ text: 'Название', align: 'start', sortable: true, value: 'plan_execution_vp_mp', width: '1%' },
						{ text: 'Бренд / Группа', align: 'start', sortable: false, value: 'brand', width: '1%' },
						{ text: 'Статус / ЛК', value: 'product_status', width: '1%', sortable: false },
						{ text: 'Цена продажи / себестоимость', value: 'price', width: '1%', sortable: true },
						{ text: 'ВП_ЛС прогноз', value: 'delta_rentab', width: '2%', sortable: true },
						{ text: 'ВП_МП прогноз', value: 'rentab_mp', width: '2%', sortable: false },
						{ text: 'ДРР / расход(14 / 7 дней)', value: 'drr', width: '1%', sortable: true },
						{ text: 'Остаток Ozon', value: 'last_stock_api', width: '1%', sortable: true },
						{ text: 'Остаток 1C', value: 'last_stock_1c', width: '1%', sortable: true},
						{ text: 'Заказы за 7 дней', value: 'orders_7days', width: '1%', sortable: true },
						{ text: 'Заказы за 30 дней', value: 'orders_30days', width: '1%', sortable: true },
						{ text: 'Продажи за 30 дней', value: 'sales_30days', width: '1%', sortable: true},
						{ text: 'Скорость заказов, 14 дней(7 дней)', value: 'speed_orders', width: '1%', sortable: true},
						{ text: 'Дней остатка(с 1С)', align: 'start', sortable: true, value: 'day_stock', width: '1%'},	
						{ text: 'Индекс за день', align: 'start', sortable: true, value: 'index_day', width: '1%'},	
					],
				},
				tableItemLoader: []
			}
		},
		watch: {
			'options': async function (newVal, oldVal) {
				if (oldVal && this.first_init) {
					await this.fetchProducts()
				}
			},
		},
		computed: {
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
			dateFromFormatted () {
				return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
			},
			dateToFormatted () {
				return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
			}
		},
		methods: {
			calcHelpText(label){
				if (label == 'rentab_value')
					return [
						'Прогноз = прогнозное значение на сегодня',
						'Факт = фактическое значение вп_лс по данным из последнего фин. отчета',
						'ВП прогноз = цена продажи - с/с - логистика - комиссия - налог - эквайринг - последняя миля',
						'ВП_ЛС прогноз = ВП прогноз - плановые расходы склада и логистики до МП',
						'ВП_М прогноз = ВП_ЛС прогноз - средние расходы на рекламу за 14 дней',
						'ВП_МП прогноз = ВП_М прогноз - плановые затраты МП',
						'ВП_ЛС факт = ВП факт - плановые расходы склада и логистики до МП'
					]
                if (label == 'rentab_mp')
					return [
						'ВП_МП прогноз = ВП_М прогноз - плановые затраты МП',
					]
				return null
			},
			formattedNumber(val) {
				if(val && Number.isInteger(val))
					return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
				return val
			},
			copyArt(art) {
				const el = document.createElement('textarea')
				el.value = art
				el.setAttribute('readonly', '')
				el.style.position = 'absolute'
				el.style.left = '-99999px'
				document.body.appendChild(el)
				el.select()
				document.execCommand('copy')
				document.body.removeChild(el)
				this.$toasted.show(`Скопирован в буфер`, {
					theme: "bubble",
					type: 'success',
					position: "top-center",
					duration : 500
				})
			},
			addUniqueToArray (arr, value) {
				if (arr.indexOf(value) === -1) {
					arr.push(value)
				} else {
					arr.splice(arr.indexOf(value), 1)
				}
			},
			async clearFilters () {
				let dirtyFilters = false
				for (let item in this.filters) {
					if (this.filters[item]) {
						dirtyFilters = true
					}
					this.filters[item] = Array.isArray(this.filters[item]) ? [] : null
				}
				if (dirtyFilters) {
					await this.fetchProducts()
				}
			},
			async fetchSellers () {
				const response = await Catalog.getSellers()
				for (let account in response.data) {
					this.filter_choices.seller.push(response.data[account])
				}
			},
			async fetchProducts () {
				this.tableLoading = true
				this.$vuetify.goTo(this.$refs.product_table_filters, {
					duration: 500,
					offset: 150,
					easing: 'linear',
				})
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				try {
					const response = await Products.fetchOzonProducts(
						{
							...this.transfromFiltersForQuery,
							...{
								'offset': itemsPerPage*pageNumber,
								'limit': itemsPerPage,
								'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
							}
						}
					)
					this.items = response.data.results.objects
					this.filter_choices.product_brand_choices = response.data.results.filters.brand
					this.filter_choices.product_status_choices = response.data.results.filters.status
					this.filter_choices.product_group_choices = response.data.results.filters.group
					this.pagination.totalItems = response.data.count
					this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.tableLoading = false
			},
			async fetchProductsWithFilters () {
				this.drawer = false
				this.options.page = 1
				await this.fetchProducts()
			},
			async fetchProductGroup() {
				try {
					const response = await General.fetchProductGroup()
					this.filter_choices.product_group_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductStatus() {
				try {
					const response = await General.fetchProductStatus()
					this.filter_choices.product_status_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductManager() {
				try {
					const response = await General.fetchProductManager()
					this.filter_choices.product_manager_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductBrand() {
				try {
					const response = await General.fetchProductBrand()
					this.filter_choices.product_brand_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductProfile() {
				try {
					const response = await General.fetchProductProfile()
					this.filter_choices.product_profile_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			getOrdersGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.orders_array ? item.dynamic_fields.orders_array : []
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getPriceGraph(item, day_start, day_end){
				let price_dict = item.dynamic_fields.price_array ? item.dynamic_fields.price_array : []
				return price_dict.slice(day_start, day_end).reverse()
			},
			getDrrExpensesGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.drr_expenses_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getVpMpGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.vp_mp_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getRentabMpGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.rentab_mp_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getDrrGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.drr_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			calculateDelta(old_val, new_val){
				if (!old_val || old_val == 0 || !new_val || new_val == 0)
					return 0
				return ((new_val - old_val) / old_val * 10000 / 100).toFixed(2)
			},
			async exportToExcel(){
				this.loading_export = true
				try {
					const { page, itemsPerPage, sortBy, sortDesc } = this.options
					const response = await Products.exportOzonProducts({
						...this.transfromFiltersForQuery,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'ozon_products_list.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export = false
			},
			closeWindowMonitoring () {
				this.dialog_monitoring = false
				this.key_list_monitoring = null
			},
			editItemMonitoring (item) {
				this.current_item_monitoring = item
				this.dialog_monitoring = true
			},
			async saveKeyword() {
				try {
					const response = await KeywordMonitoring.updateOzonKeywords(
						this.current_item_monitoring.mid, this.key_list_monitoring
					)
					if (response.status == 200) {
						this.$toasted.show(`Ключевые запросы для товара ${this.current_item_monitoring.mid} изменены"`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
					}
				} catch (err) {
					console.log(err);
				}
				this.key_list_monitoring = null
				this.dialog_monitoring = false
			},
			productInBookmarks(item){
				if (this.list_bookmarks.includes(item.mid)){
					return false
				}
				return true
			},
			async toggleBookmarks(item){
				try{
					await Products.toggleOzonBookmarks(item.mid)
					await this.fetchBookmarks()
					this.$toasted.show(`Товар добавлен в избранное`, {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration : 3000
					})
				} catch(err){
					console.log(err)
					this.$toasted.show(`Ошибка`, {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration : 3000
					})
				}
			},
			daysLabel(number) {
				let titles = ['день', 'дня', 'дней']
				const cases = [2, 0, 1, 1, 1, 2];
				return `Новинка ${number} ${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
			},
			async fetchBookmarks(){
				try{
					const response = await Products.fetchOzonBookmarks()
					this.list_bookmarks = response.data
				} catch(err){
					console.log(err)
				}
			},
			async import_warehousing_cost(){
				this.loading_warehousing_cost = true
				this.status_warehousing_cost = 'Выполняется'
				let files = document.getElementById('warehousing_cost_files').files
				let formData = new FormData()
				for (var x = 0; x < files.length; x++) {
					formData.append("fileToUpload[]", files[x]);
				}
				try {
					await Stats.importOzonWarehousingCost(formData)
					this.$toasted.show(`Данные успешно импортированы`, {
						theme: "bubble", type: 'success', position: "top-center", duration : 500
					})
					this.status_warehousing_cost = 'Успешно'
				}
				catch (err){
					console.log(err)
					this.$toasted.show(`Ошибка в импорте данных`, {
						theme: "bubble", type: 'error', position: "top-center", duration : 500
					})
					this.status_warehousing_cost = 'Ошибка'
				}
				this.loading_warehousing_cost = false
				//this.dialog_warehousing_cost = false
			},
			async exportWithoutPlan(){
				this.loading_export_without_plan = true
				try {
					const response = await Products.exportOzonWithoutPlans({
						...this.transfromFiltersForQuery
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'ozon_products_list.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export_without_plan = false
			},
		},
		async mounted () {
			await this.fetchBookmarks()
			await this.fetchSellers()
			this.fetchProductManager()
			this.fetchProductProfile()
			this.initLoading = true
			await this.fetchProducts()
			this.first_init = true
			this.initLoading = false
		}
	}
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .rating-chip{
        display: inline-block;
        padding: 3px 8px;
        color: #ffffff;
        border-radius: 5px;
        font-size: .9rem;
    }
    .text-danger{
        color: red;
    }
	.green_rentab{
		background-color: #c0f1b4;
	}
	.orange_rentab{
		background-color: #f1f1d2;
	}
	.red_rentab{
		background-color: #f1b7bd;
	}
	.theme--dark .red_rentab{
		background-color: #a0535b;
	}
	.theme--dark .orange_rentab{
		background-color: #7b7b4f;
	}
	.theme--dark .green_rentab{
		background-color: #4f7844;
	}
	

</style>
<style lang="scss">

    .bee-table-wrapper{
        max-width: calc(100vw - 47px);
    }

	.green-index2 {
        background-color: #d9ead3 !important;
		display: block;
    }
	.yellow-index2 {
        background-color: #fff2cc !important;
		display: block;
    }
	.red-index2 {
        background-color: #f1b7bd !important;
		display: block;
    }
	.without-index2 {
        background-color: #e7e5e5 !important;
		display: block;
    }

	.theme--dark .red-index2{
		background-color: #a0535b !important;
	}
	.theme--dark .green-index2{
		background-color: #4f7844 !important;
	}
	.theme--dark .yellow-index2{
		background-color: #6d5c26 !important;
	}
	.theme--dark .without-index2{
		background-color: #5c5c5c !important;
	}
</style>

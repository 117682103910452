import { HTTP } from './http'

export const Redemption = {
    async fetchRedemptionFilteredProduct(query=null) {
        return HTTP.get('/redemption/list-product-redemption/', {
            params: query
        })
    },
    async fetchRedemptionManager (query=null) {
        return HTTP.get('/redemption/list-user-redemption/', {
			params: query
        })
    },
	async fetchRedemptionFeedback(query=null) {
		return HTTP.get(`/redemption/list-feedback-redemption/`, {
			params: query
		})
	},
	async createRedemption (data) {
		return HTTP.post('/redemption/list-redemption/', data )
	},
	async fetchRedemption (query=null) {
		return HTTP.get('/redemption/list-redemption/', {
			params: query
		} )
	},
	async updateRedemption (redemption_id, data) {
		return HTTP.put(`/redemption/list-redemption/${redemption_id}/`, data )
	},
	async fetchReportRedemption (query = null) {
		return HTTP.get(`/redemption/list-report-redemption/`, {
			params: query
		} )
	},
	async updateSourceFeedback (feedback_uid, source) {
		return HTTP.put(`/redemption/update-source-feedback/${feedback_uid}/`, source )
	},
	async deleteRedemption (item_id) {
		return HTTP.delete(`/redemption/list-redemption/${item_id}/`)
	},
	async setReportRedemption (query=null) {
		return HTTP.post('/debug_endpoint/setReportRedemptionCelery')
	},
	async uploadRedemptionFromExel (query=null) {
		return HTTP.post('/redemption/upload_redemption_from_exel/', query)
	},
	async exportRedemptionToExel (query) {
		return HTTP.get('/redemption/export_redemtion_excel/', {
			responseType:"blob", 
			params: query
		})
	},
	async downloadTemplateRedemption () {
		return HTTP.get('/redemption/download_template_import_redemption/', {responseType:"blob"})
	},
	async oneAutoCompleteReport (query=null) {
		return HTTP.get('/redemption/set_single_report_redemption/', {
			params: query
		})
	},
	async fetchBaseSourceFeedback() {
		return HTTP.get('/redemption/list-base-source-feedback/')
	},
	async redemptionTypeStats (query) {
		return HTTP.get(`/redemption/list-redemption/stat/`, {
			params: query
		})
	},
	async PutRedemption (redemption_id, type) {
		return HTTP.put('/redemption/list-redemption/', {"redemption_id": redemption_id, "type": type})
	},
	async fetchOzonRedemption (query=null) {
		return HTTP.get('/redemption/ozon/ozon-redemptions/', {
			params: query
		} )
	},
	async fetchOzonFilteredProductRedemption(query=null) {
        return HTTP.get('/redemption/ozon/ozon-filter-products-redemption/', {
            params: query
        })
    },
	async fetchBaseTypeRedemption() {
		return HTTP.get('/redemption/ozon/list-base-type-redemption-redemption/')
	},
	async fetchBaseProductStatusRedemption() {
		return HTTP.get('/redemption/ozon/list-base-product-status-redemption/')
	},
	async fetchBaseServiceRedemption() {
		return HTTP.get('/redemption/ozon/list-base-service-redemption/')
	},
	async createOzonRedemption (data) {
		return HTTP.post('/redemption/ozon/ozon-redemptions/', data )
	},
	async updateOzonRedemption (redemption_id, data) {
		return HTTP.patch(`/redemption/ozon/ozon-redemptions/${redemption_id}/`, data )
	},
	async deleteOzonRedemption (redemption_id) {
		return HTTP.delete(`/redemption/ozon/ozon-redemptions/${redemption_id}/`)
	},
	async exportOzonRedemption (query) {
		return HTTP.get('/redemption/ozon/export_ozon_redemption/', {
			responseType:"blob", 
			params: query
		})
	},
	async downloadOzonTemplateRedemption () {
		return HTTP.get('/redemption/ozon/template_import_ozon_redemption/', {
			responseType:"blob"
		})
	},
	async importOzonRedemption (query=null) {
		return HTTP.post('/redemption/ozon/import_ozon_redemption/', query)
	},
	async OzonredemptionTypeStats (query) {
		return HTTP.get(`/redemption/ozon/ozon-redemptions/stats/`, {
			params: query
		})
	},
	async fetchRedemptionReception (query) {
		return HTTP.get(`/redemption/redemption_reception/`, { params: query })
	},
	async fetchOzonRedemptionReception (query) {
		return HTTP.get(`/redemption/ozon/redemption_reception/`, { params: query })
	},
	async exportRedemptionReception (query) {
		return HTTP.get('/redemption/redemption_reception/export/', {
			responseType:"blob", 
			params: query
		})
	},
	async exportOzonRedemptionReception (query) {
		return HTTP.get('/redemption/ozon/redemption_reception/export/', {
			responseType:"blob", 
			params: query
		})
	},
	async loadRedemptionReception(){
		return HTTP.post('/redemption/load_redemption_reception/')
	},
	async selectionOfAccount(json_data){
		return HTTP.get('/redemption/selection_of_account_redemption/', {params: json_data})
	},
	async changeLastDateSelection(phone){
		return HTTP.post('/redemption/change_date_last_selection/', {"phone": phone})
	},
	async startFetchRedemptionDistribution(){
		return HTTP.post('/redemption/start_fetch_redemption_distribution/')
	}
}
<template>
    <ProductCharacteristics :product_id="product_id"/>
</template>

<script>

	import ProductCharacteristics from '@/components/Product/ProductCharacteristics.vue';

	export default {
		name: 'ProductCharPage',
		components: {
			ProductCharacteristics,
		},
		computed: {
			product_id() {
				return this.$route.params.id;
			},
		},
		mounted() {
			this.$store.commit('setPageTitle', `Страница характеристик продукта`);
		},
	};
</script>

<style scoped>

</style>
<template>
    <v-container fluid>
		<v-expansion-panels v-model="panels.panels_filters" class="mt-5">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Фильтры</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementTaskHistoryFilterComponent @applyFilters="apply_filters"/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		
		<v-expansion-panels v-model="panels.panels_data" class="mt-5">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Список задач</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementTaskHistoryTableComponent :filters="filters"/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
import AdvertisementTaskHistoryFilterComponent from '@/components/Advertisement/AdvertisementTaskHistory/AdvertisementTaskHistoryFilterComponent'
import AdvertisementTaskHistoryTableComponent from '@/components/Advertisement/AdvertisementTaskHistory/AdvertisementTaskHistoryTableComponent'

export default {
    name: 'AdvertisementStatsView',
	components: {AdvertisementTaskHistoryFilterComponent, AdvertisementTaskHistoryTableComponent},
	data(){
		return {
			filters: {},
			panels: {
				panels_data: 0,
				panels_filters: 0,
			}
		}
	},
	methods: {
		apply_filters(query){
			this.filters = query
		}
	},
	async mounted(){
		document.title = `Плановые рекламные задачи | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Плановые рекламные задачи`)
	}
}
</script>

<style lang="scss" scoped>
	.expand_title{
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: 0.0125em;
		line-height: 2rem;
		word-break: break-all;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
</style>
<template>
    <div>
        <TemplateResponsesFeedback/>
    </div>
</template>

<script>
  import TemplateResponsesFeedback from '@/components/Feedback/TemplateResponsesFeedback'

  export default {
    name: 'FeedbacksTemplateResponseView',
    components: { TemplateResponsesFeedback },
    mounted () {
      this.$store.commit('setPageTitle', `Шаблоны ответов (отзывы)`)
    }
  }
</script>

<style scoped lang="scss">

</style>

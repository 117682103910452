<template>
   <v-container>
        <v-dialog v-model="create_data.dialog" max-width="1600px" v-if="create_data.dialog">
            <v-card>
                <v-card-title>Создание записи</v-card-title>
                <v-card-text>
                    <v-menu 
						v-model="datepicker_date" :close-on-content-click="false" transition="scale-transition" 
						offset-y="offset-y" nudge-right="40" min-width="290px" ref="datepicker_date"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable @click:clear="create_data.date = null" v-model="dateTransform"
								label="Дата" readonly="readonly" class="mt-3" v-bind="attrs" outlined dense v-on="on"
                                hide-details
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="create_data.date" @input="datepicker_date = false" :first-day-of-week="1"
						></v-date-picker>
					</v-menu>
                    <v-text-field
                        v-model="create_data.redemption_percent" clearable outlined dense type='number' 
                        hide-details class="mt-3" label="% выкупа"
                    ></v-text-field>
                    <v-btn dense color="primary" dark class="mt-5" @click="create_item" :loading="create_data.loading">Добавить запись</v-btn>
                </v-card-text>
            </v-card>    
        </v-dialog>
        <v-dialog v-model="dialog_detail" max-width="1600px" v-if="dialog_detail">
            <v-card>
                <v-card-title>
                    Детализация: {{ selected_item.group_name }}
                    <v-btn dense color="primary" dark x-small outlined class="ml-5" @click="create_data.dialog=true">Добавить запись</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="detail_table.headers"
                        :items="detail_table.data"
                        :loading="detail_table.loading"
                        class="elevation-0 mt-0 vertical-border small-font"
                        hide-default-footer
                        disable-pagination
                    >
                        <template v-slot:item.action="{ item }">
                            <v-btn @click="delete_item(item)" dense color="error" dark x-small outlined>Удалить</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>    
        </v-dialog>
        <v-card>
            <v-card-title>Процент выкупа для товаров ОЗОН</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="table.headers"
                    :items="table.data"
                    :loading="table.loading"
                    class="elevation-0 mt-0 vertical-border small-font"
                    hide-default-footer
                    disable-pagination
                >
                    <template v-slot:item.action="{ item }">
                        <v-btn @click="open_detail(item)" dense color="primary" dark x-small outlined>Смотреть детализацию</v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
   </v-container>
</template>

<script>
    import {Catalog} from '@/api/catalog'
    
    export default {
        name: 'CatalogRedemptionPercent',
        data() {
            return{
                table:{
                    loading: false,
                    headers: [
                        { text: "Категория", value: "group_name", sortable: true, width: "1%", align: 'center' },
                        { text: "% выкупа", value: "redemption_percent", sortable: true, width: "1%", align: 'center' },
                        { text: "Дата", value: "date", sortable: true, width: "1%", align: 'center' },
                        { text: "Действия", value: "action", sortable: false, width: "1%", align: 'center' },
                    ],
                    data: []
                },
                create_data:{
                    redemption_percent: null,
                    date: null,
                    loading: false,
                    dialog: false
                },
                detail_table: {
                    loading: false,
                    headers: [
                            { text: "ID", value: "id", sortable: true, width: "1%", align: 'center' },
                            { text: "% выкупа", value: "redemption_percent", sortable: true, width: "1%", align: 'center' },
                            { text: "Дата", value: "date", sortable: true, width: "1%", align: 'center' },
                            { text: "Действия", value: "action", sortable: false, width: "1%", align: 'center' },
                        ],
                    data: []
                },
                selected_item: null,
                dialog_detail: false,
                datepicker_date: null,
            }
        },
        computed: {
            dateTransform () {
				return this.create_data.date ? this.$options.filters.formatDateToLocaleNoTime(this.create_data.date) : null
			},
        },
        methods:{
            async open_detail(item){
                this.selected_item = item
                this.dialog_detail = true
                await this.fetch_detail_group_data()
            },
            async fetch_all_group_data(){
                this.table.loading = true
                try{
                    const response = await Catalog.fetchAllGroupRedemption()
                    this.table.data = response.data
                } catch(err){
                    console.log(err)
                }
                this.table.loading = false
            },
            async fetch_detail_group_data(){
                this.detail_table.loading = true
                try{
                    const response = await Catalog.fetchDetailGroupRedemption(this.selected_item.group_id)
                    this.detail_table.data = response.data
                } catch(err){
                    console.log(err)
                }
                this.detail_table.loading = false
            },
            async delete_item(item){
                try{
                    await Catalog.deleteGroupRedemption(item.id)
                    this.$toasted.show(`Запись удалена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.fetch_detail_group_data()
                    await this.fetch_all_group_data()
                } catch(err){
                    this.$toasted.show(`Ошибка удаления`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                }
            },
            async create_item(item){
                this.create_data.loading = true
                try{
                    let json_data = {
                        "date": this.create_data.date, 
                        'redemption_percent': this.create_data.redemption_percent, 
                        'product_group': this.selected_item.real_group_id
                    }
                    await Catalog.createGroupRedemption(json_data)
                    this.$toasted.show(`Запись добавлена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.create_data.dialog = false
                    await this.fetch_detail_group_data()
                    await this.fetch_all_group_data()
                } catch(err){
                    this.$toasted.show(`Ошибка удаления`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                }
                this.create_data.loading = false
            }
        },
        async mounted() {
            this.$store.commit('setPageTitle', `Установка % выкупа`);
            document.title = `Установка % выкупа | mp.lomex-group.ru`;
            await this.fetch_all_group_data()
        },
    };
</script>

<style scoped>

</style>
import { HTTP } from './http'


function handleChargeError (err) {
  const error = err
  const data = error.response.data
  console.error(error.response)
  // if (data.msg === 'payment auth required') {
  //   throw new PaymentAuthRequiredException(
  //
  //   )
  // } else if (data.msg === 'payment declined') {
  //   throw new PaymentDeclinedException(data.meta.payment_error_code, data.meta.payment_error_message)
  // }
  throw new Error('unknown error', data)
}



export const Suppl = {
  async sendCode (phone) {
    try {
      const res = await HTTP.post('/suppl/actions/send_code/', {phone} )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      throw new Error('Error. Try later')
    }
  },
  async confirmCode (confirmCode, sendCodeToken) {
    const res = await HTTP.post('/suppl/actions/confirm_code/', {'confirm_code': confirmCode, 'send_code_token': sendCodeToken} )
    if (res.status === 200) {
      return res.data
    }
  },
  async getSuppliers () {
    const res = await HTTP.get('/suppl/actions/get_suppliers/')
    if (res.status === 200) {
      return res.data
    }
  },
  async getTotalSellerData (supplierID) {
    const res = await HTTP.post('/suppl/actions/get_total_seller_data/', {'supplier_id': supplierID})
    if (res.status === 200) {
      return res.data
    }
  },
  async fetchSupplyTasks () {
    const res = await HTTP.get('/suppl/actions/supply_tasks/')
    if (res.status === 200) {
      return res.data
    }
  },
  async handleSuppleTask (task_id, action) {
    const res = await HTTP.post('/suppl/actions/task_action/', {task_id, action})
    if (res.status === 200) {
      return res.data
    }
  },
  async supplyPlan (
      supplyData,
      dates,
      supplierID,
      supplierName,
      monoCount = 0,
      shop_requisites_id='',
      maxCoef = 0
  ) {
    const res = await HTTP.post('/suppl/actions/supply_plan/', {
      'supply_data': supplyData,
      'dates': dates,
      'supplier_id': supplierID,
      'supplier_name': supplierName,
      'mono_count': monoCount,
      'shop_requisites_id': shop_requisites_id,
      'max_coef': maxCoef
    })
    if (res.status === 200) {
      return res.data
    }
  },
  async createSupplyPlanning (data) {
    return HTTP.post('/suppl/supply_planning/', data)
  },
  async listSupplyPlanning (data) {
    return HTTP.get('/suppl/supply_planning/', {params: data})
  },
  async deleteSupplyPlanning (id) {
    return HTTP.delete(`/suppl/supply_planning/${id}/`)
  },
  async checkProgressSupplyPlanning (data) {
    return HTTP.post(`/suppl/supply_planning/check_progress/`, data)
  },
  async detailSupplyPlanning (id, query) {
    return HTTP.get(`/suppl/supply_planning/${id}/`, {params: query})
  },
  async fetchWarehouseListSuppl (id) {
    return HTTP.get(`/suppl/supply_planning/${id}/list_warehouses/`)
  },
  async ozonFetchWarehouseListSuppl (id) {
    return HTTP.get(`/suppl/ozon/supply_planning/${id}/list_warehouses/`)
  },
  async exportGeneralSupplyPlanning(id, warehouse_name) {
    return HTTP.get(
      `/suppl/supply_planning/${id}/general_export/`, 
      {"responseType": "blob", params: {"warehouse": warehouse_name}}
    )
  },
  async exportABCSupplyPlanning(id, warehouse_name) {
    return HTTP.get(
      `/suppl/supply_planning/${id}/abc_export/`, 
      {"responseType": "blob", params: {"warehouse": warehouse_name}}
    )
  },
  async listProductsByAccount(data) {
    return HTTP.get(`/suppl/supply_planning/list_products/`, { params: data })
  },
  async exportListProductsByAccount(data) {
    return HTTP.get(
      `/suppl/supply_planning/list_products_xlsx/`, {"responseType": "blob", params: data}
    )
  },
  async saveInactiveProduct(data) {
    return HTTP.post('/suppl/supply_planning/save_inactive_product/', data)
  },
  async ozonCreateSupplyPlanning (data) {
    return HTTP.post('/suppl/ozon/supply_planning/', data)
  },
  async ozonListSupplyPlanning (data) {
    return HTTP.get('/suppl/ozon/supply_planning/', {params: data})
  },
  async ozonDeleteSupplyPlanning (id) {
    return HTTP.delete(`/suppl/ozon/supply_planning/${id}/`)
  },
  async ozonCheckProgressSupplyPlanning (data) {
    return HTTP.post(`/suppl/ozon/supply_planning/check_progress/`, data)
  },
  async ozonDetailSupplyPlanning (id, query) {
    return HTTP.get(`/suppl/ozon/supply_planning/${id}/`, {params: query})
  },
  async ozonExportGeneralSupplyPlanning(id, warehouse_name) {
    return HTTP.get(
      `/suppl/ozon/supply_planning/${id}/general_export/`, 
      {"responseType": "blob",params: {"warehouse": warehouse_name}}
    )
  },
  async ozonListProductsByAccount (data) {
    return HTTP.get(`/suppl/ozon/supply_planning/list_products/`, { params: data })
  },
  async ozonExportListProductsByAccount(data) {
    return HTTP.get(
      `/suppl/ozon/supply_planning/list_products_xlsx/`, {"responseType": "blob", params: data}
    )
  },
  async ozonSaveInactiveProduct(data) {
    return HTTP.post('/suppl/ozon/supply_planning/save_inactive_product/', data)
  },
  async saveLimitTurnover(data){
    return HTTP.post('/suppl/supply_planning/save_limit_turnover/', data)
  },
  async checkFreeLimits(period){
    return HTTP.get('/suppl/free_limits/', {params: {'period': period}})
  },
  async getAlertConfig(){
    return HTTP.get('/suppl/suppl_alert_config/')
  },
  async updateAlertConfig(id, payload){
    return HTTP.patch(`/suppl/suppl_alert_config/${id}/`, payload)
  }
}

<template>
    <section>
        <v-dialog
                transition="dialog-bottom-transition"
                max-width="800"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        small
                        dark
                        color="purple darken-2"
                        :disabled="!product_id"
                        v-bind="attrs"
                        v-on="on"
                >Дни искуственного спроса</v-btn>
            </template>
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar
                            color="purple"
                            class="mb-5"
                            dark
                    >Календарь дней ИС</v-toolbar>
                    <v-card-text>
                        <div class="d-flex">
                            <v-date-picker
                                    v-model="datesComponent"
                                    multiple
                                    no-title
                                    scrollable
                            >
                            </v-date-picker>
                            <div>
                                <div class="mb-3">
                                    Всего дней: <strong>{{ dates_count }}</strong>
                                </div>
                                <div class="mb-3">
                                    Дни:
                                    <div class="grey lighten-3 pa-2 caption">
                                        <span v-for="date in formatted_dates" :key="date">
                                            {{ date }},
                                        </span>
                                    </div>
                                </div>
                                <v-btn small dark :loading="loading" color="purple darken-2" @click="updateProduct()">Сохранить</v-btn>
                                <div class="mt-3">
                                    <div class="mt-3" v-if="formSent && saveSuccess">
                                        <v-alert
                                                dense
                                                type="success"
                                        >Данные сохранены</v-alert>
                                    </div>
                                    <div v-else-if="formSent && !saveSuccess">
                                        <v-alert
                                                dense
                                                type="error"
                                        >Ошибка сохранения</v-alert>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                                text
                                @click="dialog.value = false"
                        >Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </section>
</template>

<script>
	import {Products} from '@/api/products'

	export default {
		name: 'ArtificialSales',
		props: {
			product_id: {
				type: Number,
				required: true
			},
			dates: {
				type: Array
            },
            marketplace: {
				type: String,
                required: true
            }
        },
		data() {
			return {
				datesComponent: this.dates,
				menu: false,
                loading: false,
                formSent: false,
				saveSuccess: false,
                dialog: false
            };
		},
		computed: {
			dates_count () {
				return this.datesComponent.length
            },
            formatted_dates () {
				let dates = this.datesComponent.map(dateString => new Date(dateString).toLocaleDateString())
                return dates
            },
            query () {
				return {
					product_id: this.product_id,
					dates: this.datesComponent,
					marketplace: this.marketplace,
                }
            }
        },
		methods: {
			async updateProduct () {
				this.loading = true
				this.formSent = false
				this.saveSuccess = false
				try {
					const response = await Products.updateProductArtificialDays(
						this.query
					)
                    this.formSent = true
                    this.saveSuccess = true
					this.$emit('update:dates', this.datesComponent)
				} catch (err) {
					console.error(err)
					this.formSent = true
					this.saveSuccess = false
				}
				this.loading = false
			}
        },
		async mounted() {

		},

	};
</script>

<style scoped>

</style>
import { HTTP } from './http'

export const Administration = {
  async setNewCatalogFields (query=null) {
    return HTTP.post('/products/set_catalog_fields/', {
      params: query
    } )
  },
  async debug (endpoint_name) {
    return HTTP.post(`/debug_endpoint/${endpoint_name}`)
  },
  async exportListUser (query) {
	return HTTP.get('/products/export_list_users/', {
		responseType:"blob", 
		params: query
	})
  },
  async uploadProductsPlans (file) {
	return HTTP.post('/products/upload_products_plans/', file)
  },
  async uploadCommissionWB (file) {
	return HTTP.post('/products/upload_wb_commission/', file)
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"icon":"","title":"Сводка по типам выкупа","color":"grey","dark":""},on:{"click":function($event){return _vm.getRedemptionTypeStats()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-poll ")])],1)]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 darken-2"},[_vm._v(" Сводка по типам выкупа ")]),_c('div',{staticStyle:{"position":"relative"}},[(_vm.redemption_type_stats_loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1 pb-5 mt-3",class:{'loading': _vm.redemption_type_stats_loading},attrs:{"dense":"","headers":_vm.redemptionTypeStatsHeader,"items":_vm.redemption_type_stats,"hide-default-footer":""}})],1)],1)],1),(_vm.dialog_delete)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1):_vm._e(),_c('OzonRedemptionFilters',{on:{"filtersData":_vm.filtersData}}),(_vm.init_loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-0 mt-0 vertical-border small-font",attrs:{"headers":_vm.data_table.headers,"items":_vm.data_table.data,"loading":_vm.table_loading,"pageCount":_vm.pagination.numberOfPages,"options":_vm.options,"items-per-page":20,"footer-props":{
                'items-per-page-options': [5, 10, 20, 50]
            },"server-items-length":_vm.pagination.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product.mid",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ozon_product_card', params: { id: item.product.mid }},"target":"_blank"}},[_vm._v(" Ozon id: "+_vm._s(item.product.mid)+" ")]),_c('hr'),_vm._v(" fbo sku: "+_vm._s(item.product.fbo_sku)+" "),_c('hr'),_vm._v(" fbs sku: "+_vm._s(item.product.fbs_sku)+" "),_c('hr'),_vm._v(" code_1c: "+_vm._s(item.product.code_1c)+" ")]}},{key:"item.sum_order",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.sum_order))+" ")]}},{key:"item.cashback",fn:function(ref){
            var item = ref.item;
return [(item.cashback)?_c('div',[_vm._v(" "+_vm._s(item.cashback)+"% ("+_vm._s(_vm._f("toRUB")(item.cashback_in_money ? item.cashback_in_money : 0))+") ")]):_c('div',[_vm._v("-")])]}},{key:"item.order_number",fn:function(ref){
            var item = ref.item;
return [(item.order_number)?_c('div',{staticStyle:{"padding":"10px 5px"}},_vm._l((_vm.convertStrToArrayOrderNumber(item.order_number)),function(obj,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(obj)+" "),_c('hr')])}),0):_c('div',[_vm._v("-")])]}},{key:"item.real_price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.quantity_product ? item.sum_order / item.quantity_product : 0))+" ")]}},{key:"item.product.art",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"art_product"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.product.art)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product.art))])])]}},{key:"item.feedback_text",fn:function(ref){
            var item = ref.item;
return [(item.feedback_text)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"art_product"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.feedback_text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.feedback_text))])])],1):_vm._e()]}},{key:"item.is_report",fn:function(ref){
            var item = ref.item;
return [(item.is_report && item.is_report.length != 0)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.is_report.length == item.quantity_product)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-marked-circle ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pause-circle ")])]}}],null,true)},[_c('div',_vm._l((item.is_report),function(obj,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(obj.posting_number)+" ")])}),0)]):_vm._e()]}},{key:"item.phone",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.phoneMask(item.phone))+" ")]}},{key:"item.expenses",fn:function(ref){
            var item = ref.item;
return [(item.expenses)?_c('p',[_vm._v(_vm._s(_vm._f("toRUB")(item.expenses)))]):_c('p',[_vm._v("-")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-row',{staticClass:"justify-space-between",staticStyle:{"padding":"0 24px 24px 24px"}},[(_vm.isRedemptionUser || _vm.isRedemptionManager)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","loading":_vm.loading_import}},'v-btn',attrs,false),on),[_vm._v(" Импорт из Excel ")])]}}],null,false,1462828841),model:{value:(_vm.dialog_import),callback:function ($$v) {_vm.dialog_import=$$v},expression:"dialog_import"}},[_c('v-card',[_c('v-card-text',{staticClass:"pb-0"},[_c('v-file-input',{staticClass:"pt-5",attrs:{"label":"File input","outlined":"","dense":"","id":"upload_input","hide-details":""}}),_c('p',{staticClass:"text-download mt-3",on:{"click":_vm.downloadTempateExport}},[_vm._v("Скачать шаблон")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_import = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.uploadFromExel}},[_vm._v(" Импортировать ")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.loading_export},on:{"click":_vm.exportExcel}},[_vm._v(" Экспорт в excel ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.loading_export_for_warehouse},on:{"click":_vm.exportExcelWarehouse}},[_vm._v(" Экспорт для склада ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.loading_bundle_cashback},on:{"click":_vm.startBundleCashback}},[_vm._v(" Загрузка кешбэков ")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить выкуп ")])]}}]),model:{value:(_vm.dialog_forms),callback:function ($$v) {_vm.dialog_forms=$$v},expression:"dialog_forms"}},[(_vm.type_forms === -1)?_c('OzonRedemptionFormCreate',{on:{"close":_vm.close}}):_c('OzonRedemptionFormUpdate',{attrs:{"item":_vm.editable_item},on:{"close":_vm.close}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.isRedemptionManager || _vm.isRedemptionUser)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteRedemption(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('div',{staticClass:"stat px-5 pt-5 pb-5"},[_c('div',{staticClass:"body-1"},[_vm._v("Общая сумма заказа - "+_vm._s(_vm.stats.sum_order))]),_c('div',{staticClass:"body-1"},[_vm._v("Общее количество товаров - "+_vm._s(_vm.stats.sum_quantity))]),_c('div',{staticClass:"body-1"},[_vm._v("Общее количество выкупов - "+_vm._s(_vm.stats.count_redemption))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <v-dialog v-model="download_dialog" max-width="500px" v-if="download_dialog">
			<v-card>
				<v-card-title>
					<span class="text-h5">Документы для поставки {{ selected_income.incomeid }}</span>
				</v-card-title>
				<v-card-text>
					<v-alert density="compact" type="warning" v-if="!files_data.id || download_loading">
                        {{ download_loading ? "Подождите, идет загрузка" : "Файлы с документами еще не получены" }}
                    </v-alert>
                    <div v-else>
                        <v-btn color="blue darken-1" text>
                            <a :href="files_data.acceptance_certificate" download>Скачать акт приемки</a>
                        </v-btn>
                    <v-btn color="blue darken-1" text>
                        <a :href="files_data.reconciliation_report" download>Скачать отчет сверки</a>
					</v-btn>
                    </div>
				</v-card-text>
			</v-card>    
		</v-dialog>
        <v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <section v-else>
            <v-sheet>
                <v-container fluid>
                    <v-row class="end">
						<v-col>
							<v-menu 
                                v-model="datepicker_menu_from" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datepicker_menu_from"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateFromFormatted"
                                        label="Дата, от"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_after" 
                                    @input="datepicker_menu_from = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
						</v-col>
						<v-col>
							<v-menu 
                                v-model="datepicker_menu_to" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datepicker_menu_to"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateToFormatted"
                                        label="Дата, до"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_before" 
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
						</v-col>
                        <v-col>
                            <v-select
                                    v-model="filters.status"
                                    :items="filter_choices.statuses"
                                    label="Статус"
                                    outlined
                                    hide-details
                                    dense
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                                    v-model="filters.account"
                                    :items="filter_choices.wbaccount"
                                    item-text="name"
                                    item-value="id"
                                    label="ЛК"
                                    outlined
                                    hide-details
                                    dense
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="filters.nmid"
                                    clearable
                                    outlined
                                    dense
                                    @keyup.enter="fetchDataWithFilters()"
                                    append-icon="mdi-magnify"
                                    label="Артикул"
                                    hide-details="hide-details">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="filters.supplier_article"
                                    clearable
                                    outlined
                                    dense
                                    @keyup.enter="fetchDataWithFilters()"
                                    append-icon="mdi-magnify"
                                    label="1c артикул"
                                    hide-details="hide-details">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn
                                    @click="fetchDataWithFilters()"
                                    depressed
                                    color="purple darken-2"
                                    class="mb-3"
                                    dark
                            >
                                Применить
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!--                <v-row>-->
                    <!--                    <v-col md="4" class="text-right py-0">-->
                    <!--                        <v-chip-->
                    <!--                                v-for="(f, i) in filters"-->
                    <!--                                :key="i"-->
                    <!--                                class="ma-2"-->
                    <!--                                close-->
                    <!--                                @click:close="chip1 = false"-->
                    <!--                        >-->
                    <!--                            {{ f }}-->
                    <!--                        </v-chip>-->
                    <!--                    </v-col>-->
                    <!--                </v-row>-->
                </v-container>
            </v-sheet>
            <v-data-table
                    :headers="dataTable.headers"
                    :pageCount="pagination.numberOfPages"
                    :items="items"
                    item-key="id"
                    @update:page="pageChanged"
                    @update:sort-by="sortChanged"
                    @update:sort-desc="sortChanged"
                    :loading="tableLoading"
                    :options.sync="options"
                    :items-per-page="50"
                    :item-class="row_classes"
                    :server-items-length="pagination.totalItems"
                    class="elevation-1 small-font"
                    dense
            >
                <template v-slot:item.date="{ item }">
                    <div class="d-flex"><span>{{ item.date | formatDateToLocaleNoTime }}</span></div>
                </template>
                <template v-slot:item.date_close="{ item }">
                    <div class="d-flex">
                        <span v-if="item.date_close">{{ item.date_close | formatDateToLocaleNoTime }}</span>
                        <span v-else>-</span>
                    </div>
                </template>
                <template v-slot:item.files="{ item }">
                    <v-btn 
                        v-if="item.status == 'Принято'" depressed
                        x-small @click="open_download_dialog(item)"
                    >Скачать документы</v-btn>
                </template>
            </v-data-table>
        </section>
    </div>
</template>

<script>
	import {General} from '@/api/general';
	import {Stats} from '@/api/stats';

	export default {
		name: 'IncomesList',
		data() {
			return {
                selected_income: null,
                download_dialog: false,
                download_loading: false,
                files_data: {},
				initLoading: true,
				tableLoading: false,
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0,
				},
				options: {},
				drawer: false,
				filters: {},
				filter_choices: {
					wbaccount: [],
					statuses: [
						'Приемка',
						'Принято',
					],
				},
				filterChoices: [],
				datepicker_menu_from: null,
				datepicker_menu_to: null,
				
				items: [],
				dataTable: {
					headers: [
						{text: 'Дата', align: 'start', sortable: false, value: 'date', width: '1%'},
						{text: 'code1C', align: 'start', sortable: false, value: 'supplier_article', width: '1%'},
						{text: 'sku', align: 'start', sortable: false, value: 'nmid', width: '1%'},
						{text: 'Кол-во', value: 'quantity', width: '1%', sortable: false},
						{text: 'Склад', value: 'warehouse_name', width: '1%', sortable: false},
						{text: 'Статус', value: 'status', width: '1%', sortable: false},
						{text: 'Дата принятия', value: 'date_close', width: '1%', sortable: false},
						{text: '№ поставки', align: 'start', sortable: false, value: 'incomeid', width: '1%'},
						{text: 'Номер УПД', align: 'start', sortable: false, value: 'number', width: '1%'},
						{text: 'Цена из УПД', align: 'start', sortable: false, value: 'total_price', width: '1%'},
						{text: 'Штрих-код', value: 'barcode', width: '1%'},
						{text: 'Акк', align: 'start', sortable: false, value: 'account', width: '1%'},
                        {text: 'Документы', align: 'start', sortable: false, value: 'files', width: '1%'},
					
                    ],
				},
				tableItemLoader: [],
			};
		},
		computed: {
			transfromFiltersForQuery() {
				let query = {};
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',');
					} else {
						query[f] = this.filters[f];
					}
					if (this.filters[f] == false) {
						query[f] = null;
					}
				}
				return query;
			},
			dateFromFormatted() {
				return this.filters.date_after ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) :
					null;
			},
			dateToFormatted() {
				return this.filters.date_before ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) :
					null;
			},
		},
		async mounted() {
			await this.fetchWbAccounts();
			await this.fetchData();
			this.initLoading = false;
		},
		methods: {
            async open_download_dialog(item){
                this.download_dialog = true
                this.selected_income = item
                await this.fetch_download_link()
            },
            async fetch_download_link(){
                this.download_loading = true
                try{
                    const response = await Stats.fetchIncomeFiles(this.selected_income.incomeid)
                    this.files_data = response.data
                } catch (err){
                    console.log(err)
                }
                this.download_loading = false
            },
			row_classes(item) {
				if (item.status === 'Принято') {
					return this.$vuetify.theme.dark ? 'green darken-4' : 'green lighten-5';
				}
				if (item.status === 'Приемка') {
                    return this.$vuetify.theme.dark ? 'blue darken-4' : 'blue lighten-5'
				}
			},
			choiceFilter(filterName, filterValue) {
				this.$set(this.filters, filterName, filterValue);
			},
			async sortChanged() {
				await this.fetchData();
			},
			async pageChanged() {
				await this.fetchData();
			},
			async clearFilters() {
				let dirtyFilters = false;
				for (let item in this.filters) {
					if (this.filters[item]) {
						dirtyFilters = true;
					}
					this.filters[item] = Array.isArray(this.filters[item]) ? [] : null;
				}
				if (dirtyFilters) {
					await this.fetchData();
				}
			},
			async fetchWbAccounts() {
				const response = await General.getWbAccounts();
				for (let account in response.data) {
					this.filter_choices.wbaccount.push(response.data[account]);
				}
			},
			async fetchDataWithFilters() {
				this.drawer = false;
				this.options.page = 1;
				await this.fetchData();
			},
			async fetchData() {
				this.tableLoading = true;
				const {page, itemsPerPage, sortBy, sortDesc} = this.options;
				let pageNumber = page - 1;
				// try {
				const response = await Stats.fetchWBIncomes(
					{
						...this.transfromFiltersForQuery,
						...{
							'offset': itemsPerPage * pageNumber,
							'limit': itemsPerPage,
							// 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
						},
					},
				);
				this.items = response.data.results;
				this.pagination.totalItems = response.data.count;
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage);

				// } catch (err) {
				// 	console.log(err.data);
				// }
				this.tableLoading = false;
			},
			async fetchCatalogFilterChoices() {
				try {
					const response = await Stats.fetchWBIncomes();
					this.filterChoices = response.data;
				} catch (err) {
					console.log(err.response);
				}
			},
		},
	};
</script>

<style scoped>

</style>
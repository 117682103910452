<template>
	<v-row class="mt-3">
		<v-col>
			<v-select
				v-model="filters.account" :items="choices.account" label="Аккаунт"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.name" :items="choices.task_name" label="Задача"
				outlined multiple hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-btn
				@click="apply_filters" depressed class="mb-3" 
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			>Применить</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import {Advertisement} from '@/api/advertisement'
import {General} from '@/api/general'

export default {
    name: 'AdvertisementStatsFilterComponent',
    data() {
        return {
			filters: {
				account: null,
				name: null,
			},
			choices: {
				account: [],
				task_name: [],
			},
		}		
    },
    computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
    },
    methods: {
		async fetch_advertisement_filters(){
			try {
                const responce = await Advertisement.fetchAdvertisementChoices()
				this.choices.account = responce.data.accounts
            } catch (err) {
                console.log(err)
            }
		},
        async fetch_advertisement_tasks_filters(){
			try {
                const responce = await Advertisement.fetchAdvertisementTaskFilters()
				this.choices.task_name = responce.data
            } catch (err) {
                console.log(err)
            }
		},
		apply_filters(){
			this.$emit('applyFilters', this.transfromFiltersForQuery)
		}
    },
    async mounted(){
        await this.fetch_advertisement_filters()
		await this.fetch_advertisement_tasks_filters()
		this.apply_filters()
    }
}
</script>

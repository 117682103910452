<template>
    <v-container fluid grey lighten-4 class="pa-5">
		<v-dialog 
			v-model="dialog_change_product_bid" 
			max-width="500px"
			v-if="dialog_change_product_bid"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Изменение общей ставки {{edit_product_bid.sku}}</span>
				</v-card-title>
				<v-card-text>
					<div style="display: flex">
						<v-text-field
							v-model="new_product_bid"
							label="Ставка"
							outlined
							type="number"
							dense
							clearable
						></v-text-field>
						<v-btn
							@click="changeProductBid"
							depressed
							color="purple darken-2"
							dark
							class="ml-5"
							:loading="loading_change_product_bid"
						>Изменить</v-btn>
					</div>
					<div>
						<div class="title">Рекомендация: 
							<span :class="{
								'green_stats': recommendation_product_bid <= new_product_bid,
								'red_stats': recommendation_product_bid > new_product_bid
							}">
								{{recommendation_product_bid ? recommendation_product_bid : "..."}}
							</span>
							<div v-if="recommendation_product_bid && new_product_bid">
								<p 
									v-if="recommendation_product_bid <= new_product_bid"
									style="font-size: 12px"
								>
									Ваша ставка соответствует текущему уровню конкуренции
								</p>
								<p 
									v-else-if="recommendation_product_bid > new_product_bid"
									style="font-size: 12px"
								>
									Ваша ставка ниже текущего уровня конкуренции
								</p>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-dialog  v-model="dialog_delete_competitors" max-width="1900px" v-if="dialog_delete_competitors">
			<v-card>
				<v-card-title>Удаление конкурентов</v-card-title>
				<v-card-text>
					<div v-for="item, index in competitors_list" :key="index" class="mt-3">
						<h3 class="mt-3">
							{{ item }} 
							<v-icon small @click="deleteCompetitors(item)">mdi-delete</v-icon>
						</h3>
					</div>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-dialog 
			v-model="dialog_change_keyword_bid" 
			max-width="1500px"
			v-if="dialog_change_keyword_bid"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Ставки по ключевым словам</span>
				</v-card-title>
				<v-card-text>
					<AdvertisementKeywordDialog 
						:advertisement_id="edit_keyword_bid.adver"
						:product_id="edit_keyword_bid.id"
						
					/>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-dialog
			v-model="content_rating_dialog"
			v-if="content_rating_dialog"
			max-width="600"
			scrollable
		>
			<v-card>
				<v-card-text class="mt-5" v-if="product.content_rating">
					<div class="d-flex">
						<h2>Общий рейтинг: </h2>
						<v-rating
							:value="product.content_rating.rating / 10"
							readonly
							color="amber"
							dense
							half-increments
							size="18"
							class="ml-3"
							length="10"
						></v-rating>
						<span class="ml-2">{{product.content_rating.rating}} из 100</span>
					</div>
					<h2 class="mt-5">Группы:</h2>
					<div v-for="(group, index) in product.content_rating.groups" :key="index">
						<h4 class="mt-3">Название группы: {{group.name}}</h4>
						<div class="d-flex">
							<h4>Рейтинг: </h4>
							<v-rating
								:value="group.rating / 10"
								readonly
								color="amber"
								dense
								half-increments
								size="18"
								class="ml-3"
								length="10"
							></v-rating>
							<span class="ml-2">{{group.rating}} из 100</span>
						</div>
						<h4>Вес группы: {{group.weight}}</h4>
						<div class="d-flex">
							<v-tooltip 
								bottom max-width="300" 
								v-if="group.conditions && group.conditions.length > 0"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										color="green"
										v-bind="attrs"
										v-on="on"
										title="Список условий, увеличивающих контент-рейтинг товара"
									>mdi-checkbox-marked-circle</v-icon>
								</template>
								<span>
									<p v-for="(el, index2) in group.conditions" :key="index2">
										{{el.description}}
									</p>
								</span>
							</v-tooltip>
							<v-tooltip 
								bottom max-width="300" 
								v-if="group.improve_attributes && group.improve_attributes.length > 0"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										color="red"
										v-bind="attrs"
										class="ml-2"
										v-on="on"
										title="Cписок атрибутов, заполнение которых может увеличить контент-рейтинг товара."
									>mdi-alert-circle</v-icon>
								</template>
								<span>
									<p v-for="(el, index2) in group.improve_attributes" :key="index2">
										{{el.name}}
									</p>
								</span>
							</v-tooltip>
						</div>
						<hr class="mt-3">
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-skeleton-loader
                v-if="productBaseDataLoading"
                type="article, card-heading"
        ></v-skeleton-loader>
        <v-card
                v-else
                class="mx-auto"
                flat
        >
            <v-card-text class="">
                <div class="d-flex">
                    <div>
                        <img :src="product.primary_image" width="80" alt=""/>
                    </div>
                    <div class="mx-4">
                        <p class="text-body-1 text--primary">
                            {{ product.name }}
                        </p>
						<div class="d-flex" style="justify-content:space-between; padding: 0px 15px;">
							<div>
								<div style="display: flex;">
									<div>
										<div><span class="grey--text">OZON ID: </span>{{ product.mid }}</div>

										<a 
											:href="`https://www.ozon.ru/context/detail/id/${product.fbo_sku}/`"
											target="_blank"
											style="display: block;"
										><span class="grey--text">FBO SKU: </span>{{ product.fbo_sku }}</a>

										<a 
											:href="`https://www.ozon.ru/context/detail/id/${product.fbs_sku}/`"
											target="_blank"
											style="display: block;"
										><span class="grey--text">FBS SKU: </span>{{ product.fbs_sku }}</a>

										<div><span class="grey--text">Code 1c: </span>{{ product.catalog_item.code1C_code }}</div>
										<a
											:href="`https://mpstats.io/ozon/item/${product.fbo_sku}`"
											target="_blank"
										>Открыть в mpstats</a>
										/ <a
											:href="`https://mpstats.io/ozon/item/${product.fbs_sku}`"
											target="_blank"
										>FBS</a>

									</div>
								</div>
								<div class="mt-3">
									<artificial-sales
											:dates.sync="product.artificial_sales_dates"
											marketplace="ozon"
											:product_id="product.mid">
									</artificial-sales>
								</div>
								<div class="mt-3">
									<v-btn
										elevation="1" 
										x-small 
										class="ml-5" 
										@click="flag_old_table = !flag_old_table"
									>Переключить вид таблицы</v-btn>
								</div>
							</div>
							<div class="ml-5">
								<div><span class="grey--text">Дата создания: </span>{{ product.created_at | formatDateToLocaleNoTime }}</div>
								<div><span class="grey--text">Видимость на МП: </span>{{ product.visible ? "Да" : "Нет" }}</div>
								<div><span class="grey--text">Цвет: </span>{{ product.color_image ? product.color_image : '-' }}</div>
								<div><span class="grey--text">Цена: </span>{{ product.marketing_price | toRUB }}</div>
								<div><span class="grey--text">Ценовой индекс: </span>{{ product.price_index }}</div>
								<div class="d-flex align-center">
									расчет ВП по fbs:
									<v-switch
										color="primary" class="mt-0 ml-3 pt-0" hide-details x-small
										v-model="product.is_fbs" @change="changeIsFbs(product.mid)"
									></v-switch>
					</div>
							</div>
							<div class="ml-5">
								<div><span class="grey--text">Категория товара: </span>{{product.categories_name ? product.categories_name : '-'}}</div>
								<div><span class="grey--text">Габаритный товар: </span>{{ product.is_kgt ? "Да" : "Нет" }}</div>
								<div><span class="grey--text">Объемный вес товара: </span>{{ product.volume_weight ? product.volume_weight.toFixed(2) : 0}}</div>
								<div v-if="product.content_rating && product.content_rating.rating">
									<a class="grey--text" @click="content_rating_dialog=true">
										Контент-рейтинг:  
										<v-rating
											:value="product.content_rating.rating / 10"
											readonly
                                            color="amber"
                                            dense
                                            half-increments
                                            size="18"
											length="10"
										></v-rating>
									</a>
								</div>
							</div>
							<div class="ml-5">
								<div><span class="grey--text">Бренд: </span>{{ product.catalog_item.brand ? product.catalog_item.brand : '-' }}</div>
								<div><span class="grey--text">Группа: </span>{{ product.catalog_item.code1C_tag_group_name ? product.catalog_item.code1C_tag_group_name : "-"}}</div>
								<div><span class="grey--text">Аккаунт: </span>{{product.catalog_item.account_name ? product.catalog_item.account_name : '-'}}</div>
								<div><span class="grey--text">Тег: </span>{{ product.catalog_item.code1C_tag_name ? product.catalog_item.code1C_tag_name : "-"}}</div>
								<div><span class="grey--text">Статус: </span>{{product.catalog_item.status_name ? product.catalog_item.status_name : '-'}}</div>
								<div class="orange_rentab" v-if="product.dynamic_fields.days_from_first_stocks <= 91 || product.catalog_item.status_name == 'Новинка'">
									{{daysLabel(product.dynamic_fields.days_from_first_stocks)}}
								</div>
								<div><span class="grey--text">Менеджер: </span>{{product.catalog_item ? product.catalog_item.our_manager : '-'}}</div>
								<div>
									<span class="grey--text">Рецепт: </span>
									<div v-for="c in product.kit_components" :key="c.id" class="px-5" style="font-size: 12px;">
										<router-link 
											:class="['mb-0', 'kit_text', {'font-weight-bold': c.main_kit, 'black--text': c.main_kit}]" 
											target="_blank" 
											:to="{ name: 'kits_component_card', params: { id: c.id }}"
										>{{ c.name }} ({{ c.code }}) - {{ c.count }}</router-link>
										<hr>
									</div>
								</div>

							</div>
						</div>
						</div>
					<PlansBlockComponent :nmid="product.mid" :is_ozon="true"/>
                </div>
            </v-card-text>
        </v-card>
        <v-card
                class="mx-auto my-2 elevation-0"
                flat
        >
			<v-card-title>Динамика показателей</v-card-title>
            <OzonProductDataDays 
				v-if="flag_old_table"
				:product_id="$route.params.id"
				:catalog_item_id="product.catalog_item.code1C_code"
				:artificial_sales_dates="product.artificial_sales_dates"
            />
			<OzonProductDataDaysNew
				v-else
				:product_id="$route.params.id"
				:catalog_item_id="product.catalog_item.code1C_code"
				:artificial_sales_dates="product.artificial_sales_dates"
				ref="product_stock_balances"
            />
        </v-card>
		<v-card class="mx-auto my-2 elevation-0" flat>
			<v-card-title>Мониторинг позиций Ozon</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<v-col cols="6">
						<v-row class="align-center">
							<v-col cols="8" class="d-flex flex-row">
								<v-textarea
									class="elevation-0" outlined clearable auto-grow
									rows="3" col="2" v-model="key_list_monitoring" hide-details
									label="Введите название ключей (каждый ключ с новой строки)"
								></v-textarea>
							</v-col>
							<v-btn
								@click="saveKeywords" depressed color="purple darken-2" dark
							>Добавить</v-btn>
						</v-row>
					</v-col>
					<v-col cols="5">
						<v-row class="align-center justify-end">
							<v-col cols="8">
								<v-text-field
									v-model="copy_nmid" clearable outlined dense
									label="Артикул" hide-details
								></v-text-field>
							</v-col>
							<v-btn
								@click="copyKeyword" depressed color="purple darken-2" dark
							>Скопировать
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
				<OzonKeywordMonitoring 
					class="mt-5" :sku="product.fbo_sku" :mid="product.mid" 
					ref="child_table_keyword" :is_short_version="false" :is_competitors="false"
				/>		
			</v-card-text>	
        </v-card>
		<v-card flat class="mx-auto my-2 elevation-0">
			<v-card-title>
				Блок конкурентов 
				<v-icon small class="ml-5" @click="dialog_delete_competitors = true">mdi-pencil</v-icon>
			</v-card-title>
				<v-card-text>
					<v-row class="align-center mt-5">
						<v-col cols="6">
							<v-row class="align-center">
								<v-col cols="8" class="d-flex flex-row">
									<v-textarea
										class="elevation-0" outlined clearable auto-grow hide-details
										rows="3" col="2" v-model="competitors_list_monitoring" 
										label="Введите конкурентов (каждый конкурент с новой строки)"
									></v-textarea>
								</v-col>
								<v-btn
									@click="saveCompetitors" depressed color="purple darken-2" dark
								>Добавить</v-btn>
							</v-row>
						</v-col>
					</v-row>
					<OzonCompetitorsComponent :fbo_sku="product.fbo_sku" ref="ozon_competitors_components" v-if="product.fbo_sku"/>
					<!--<div v-for="item, index in competitors_list" :key="index" class="mt-3">
						<h3 class="mt-3">
							{{ item }} 
							<v-icon small @click="deleteCompetitors(item)">mdi-delete</v-icon>
						</h3>
						<OzonKeywordMonitoring 
							:sku="item" :mid="product.mid" :ref="`child_table_keyword_${item}`"
							:is_short_version="false" :is_competitors="true"
						/>	
					</div>-->
				</v-card-text>
			</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Продвижение в поиске</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="product_table.headers"
					:items="product_table.data"
					:loading="product_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto"
					disable-pagination
				>
					<template v-slot:item.adver="{ item }">
						<router-link 
							target="_blank" 
							class="link" 
							:to="{ name: 'ozon_advertisement_detail_new', params: { campaign_id: item.adver }}"
						>{{ item.adver }}</router-link>
					</template>
					<template v-slot:item.bid="{ item }">
						<div v-if="item.bid">
							{{ convertKopecksToRub(item.bid) | toRUB }}
						</div>
						<div v-else>-</div>
					</template>
					<template v-slot:item.price="{ item }">
						<div v-if="item.price">
							{{ item.price | toRUB }}
						</div>
						<div v-else>-</div>
					</template>
					<template v-slot:item.visibility_index="{ item }">
						<div style="font-size: 12px;">{{ item.visibility_index }}</div>
						<div 
							v-if="item.prev_visibility_index && item.visibility_index && item.visibility_index - item.prev_visibility_index != 0"
							style="font-size: 12px;"
							:class="{
								'red_stats': item.visibility_index - item.prev_visibility_index > 0,
								'green_stats': item.visibility_index - item.prev_visibility_index < 0
							}"
						>
							{{ item.visibility_index - item.prev_visibility_index }}
						</div>
					</template>
					<template v-slot:item.views="{ item }">
						<div style="font-size: 12px;">{{ item.views }}</div>
						<div 
							v-if="calculateDelta(item.prev_views, item.views)"
							style="font-size: 12px;"
							:class="{
								'red_stats': calculateDelta(item.prev_views, item.views) < 0,
								'green_stats': calculateDelta(item.prev_views, item.views) > 0
							}"
						>
							{{ calculateDelta(item.prev_views, item.views) }}%
						</div>
					</template>
					<template v-slot:item.bid_percent="{ item }">
						<a v-if="item.bid_percent" @click="openDialogChangeProductBid(item)">
							{{item.bid_percent}}% ({{item.bid_price | toRUB}})
						</a>
						<a v-else @click="openDialogChangeProductBid(item)">
							Установить ставку
						</a>
					</template>
					<template v-slot:item.count_special_bid="{ item }">
						<a @click="openDialogChangeKeywordBid(item)">
							{{item.count_special_bid ? item.count_special_bid : "Установить"}}
						</a>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Статистика по рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="adver_stats_table.headers"
					:items="adver_stats_table.data"
					:loading="adver_stats_table.loading"
					fixed-header
					class="elevation-0 mt-0 vertical-border small-font"
					:items-per-page="14"
					:footer-props="{
						'items-per-page-options': [5, 14, 25, 50]
					}"
				>
					<template v-slot:item.bid="{ item }">
						{{ convertKopecksToRub(item.bid) | toRUB }}
					</template>
					<template v-slot:item.campaign_id="{ item }">
						<router-link 
							target="_blank" 
							class="link" 
							:to="{ name: 'ozon_advertisement_detail_new', params: { campaign_id: item.campaign_id }}"
						>{{ item.campaign_id }}</router-link>
					</template>
					<template v-slot:body.append>
						<tr class="sticky-table-footer" v-if="summary_stats_adver">
							<td style="font-weight:bold;" v-text="'Всего'" />
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_views" />
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_clicks" />
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_ctr" />
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_expenses" />
							<td></td>
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_order_count" />
							<td style="font-weight:bold;" v-text="summary_stats_adver.all_quantity" />
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</template>
					<template v-slot:item.placement_type_display="{ item }">
						{{ item.adver_type == 'Продвижение в поиске' ? item.adver_type : item.placement_type_display }}
					</template>
					
				</v-data-table>
				</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5">
			<v-card-title>История изменения статусов и менеджеров</v-card-title>
			<v-card-text>
				<CatalogMpHistory :mid="$route.params.id" :nmid="null"/>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5">
			<v-card-text>
				<v-row>
					<v-col>
						<v-tabs
								v-model="tab"
								align-with-title
						>
							<v-tabs-slider color="purple"></v-tabs-slider>

							<v-tab key="1">
								Заказы
							</v-tab>
							<v-tab key="2">
								Продажи
							</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item>
								<OzonProductOrdersList v-if="product && tab == 0" :product_id="product_id"/>
							</v-tab-item>
							<v-tab-item>
								<OzonProductTransactionsList v-if="product && tab == 1" :product_id="product_id"/>
							</v-tab-item>
						</v-tabs-items>

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
        
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';
	import {KeywordMonitoring} from '@/api/keyword_monitoring';
	import OzonProductDataDays from '../../components/Products/OzonProductDataDays';
	import OzonProductDataDaysNew from '../../components/Products/OzonProductDataDaysNew';
	import OzonProductTransactionsList from '../../components/Products/OzonProductTransactionsList';
	import OzonProductOrdersList from '../../components/Products/OzonProductOrdersList';
	import ArtificialSales from '../../components/Products/ArtificialSales';
	import OzonKeywordMonitoring from '../../components/Products/OzonKeywordMonitoring'
	import { OzonAdvertisement } from '@/api/ozon_advertisement'
	import AdvertisementKeywordDialog from '@/components/OzonAdvertisement/AdvertisementKeywordDialog.vue'
	import OzonCompetitorsComponent from '@/components/Products/OzonCompetitorsComponent'
	import PlansBlockComponent from '@/components/Product/PlansBlockComponent'
	import CatalogMpHistory from '@/components/Product/CatalogMpHistory.vue';

	export default {
		name: 'OzonProductCard',
		components: {
			ArtificialSales, 
			OzonProductOrdersList, 
			OzonProductTransactionsList, 
			OzonProductDataDays,
			OzonProductDataDaysNew,
			OzonKeywordMonitoring,
			AdvertisementKeywordDialog,
			OzonCompetitorsComponent,
			PlansBlockComponent,
			CatalogMpHistory
		},
		data() {
			return {
				dialog_delete_competitors:false,
				competitors_list: [],
				recommendation_product_bid: null,
				edit_keyword_bid: null,
				dialog_change_keyword_bid: false,
				edit_product_bid: null,
				loading_change_product_bid: false,
				new_product_bid: null,
				dialog_change_product_bid: false,
				summary_stats_adver: {},
				product_table: {
					loading: false,
					headers: [
						{ text: 'id РК', value: 'adver', sortable: false, width: '1%' },
						{ text: 'Статус', value: 'adver_status', sortable: false, width: '1%' },
						{ text: 'Индекс видимости', value: 'visibility_index', sortable: false, width: '1%' },
						{ text: 'Показы', value: 'views', sortable: false, width: '1%' },
						{ text: 'Текущая цена', value: 'price', sortable: false, width: '1%' },
						{ text: 'Общая ставка', value: 'bid_percent', sortable: false, width: '1%' },
						{ text: 'Специальных ставок', value: 'count_special_bid', sortable: false, width: '1%' },
					],
					data: []
				},
				content_rating_dialog: false,
				flag_old_table: false,
				productBaseDataLoading: true,
				product: {},
                tab: null,
				product_plans: {},
				key_list_monitoring: null,
				competitors_list_monitoring: null,
				copy_nmid: null,
				adver_stats_table: {
					loading: false,
					headers: [
						{ text: 'sku', value: 'sku', sortable: false, width: '1%' },
						{ text: 'РК', value: 'campaign_id', sortable: false, width: '1%' },
						{ text: 'Статус', value: 'adver_status', sortable: false, width: '1%' },
						{ text: 'Размещение', value: 'placement_type_display', sortable: false, width: '1%' },
						{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
						{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
						{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
						{ text: 'CTR(%)', value: 'ctr', sortable: false, width: '1%' },
						{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
						{ text: 'Средняя ставка', value: 'avg_bid', sortable: false, width: '1%' },
						{ text: 'Заказы шт', value: 'calc_orders', sortable: false, width: '1%' },
						{ text: 'Кол-во', value: 'search_quantity', sortable: false, width: '1%' },
						{ text: 'Цена продажи', value: 'price', sortable: false, width: '1%' },
						{ text: 'Ставка %', value: 'search_avg_bid_percent', sortable: false, width: '1%' },
						{ text: 'Ставка руб', value: 'search_avg_bid', sortable: false, width: '1%' },
					],
					data: []
				},
			};
		},
		computed: {
			product_id() {
				return this.$route.params.id;
			},
		},
		async mounted() {
			document.title = `Товары OZON | ${this.product_id} | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Список товаров OZON`);
			await this.fetchProductData();
			await this.fetchListCompetitors()
			await this.getAdverStatsByProduct()
			await this.getProductAndAdverts()
		},
		methods: {
			async fetchProductData() {
				this.productBaseDataLoading = true;
				const response = await Products.fetchOzonProductData(this.product_id);
				this.product = response.data;
				this.productBaseDataLoading = false;
			},
			calculate_percent_per_month(new_value, old_value){
				if (!old_value || old_value == 0 || !new_value)
					return 0
				try {
					let result = new_value / old_value * 100
					return result.toFixed(2)
				} catch(e){
					return 0
				}
			},
			calculate_revenue_per_month(){
				if (this.$refs.product_stock_balances){
					let redemption_sum = this.$refs.product_stock_balances.findField('redemption_sum')
					let report_sale_sum = this.$refs.product_stock_balances.findField('sales_sum')
					let result = 0
					if (this.product_plans && this.product_plans.actual_date_list && this.product_plans.actual_date_list.length > 0){
						this.product_plans.actual_date_list.forEach(date => {
							let tmp_report_sale_sum = report_sale_sum[date] ? report_sale_sum[date] : 0
							let tmp_redemption_sum = redemption_sum[date] ? redemption_sum[date]: 0
							try{
								result += (tmp_report_sale_sum - tmp_redemption_sum)
							} catch (e){
								console.log(e)
							}					
						})
					}
					return result
				}
				return 0
			},
			calculate_vp_per_month(){
				if (this.$refs.product_stock_balances){
					let report_sale_sum = this.$refs.product_stock_balances.findField('sales_sum')
					let report_sale_count = this.$refs.product_stock_balances.findField('sales_count')
					let cost_price = this.$refs.product_stock_balances.findField('cost_price')
					let comission_sum = this.$refs.product_stock_balances.findField('comission_sum')
					let delivery_sum = this.$refs.product_stock_balances.findField('delivery_sum')
					let redemption_sum = this.$refs.product_stock_balances.findField('redemption_sum')
					let result = 0
					if (this.product_plans && this.product_plans.actual_date_list && this.product_plans.actual_date_list.length > 0){
						this.product_plans.actual_date_list.forEach(date => {
							let tmp_report_sale_sum = report_sale_sum[date] ? report_sale_sum[date] : 0
							let tmp_report_sale_count = report_sale_count[date] ? report_sale_count[date] : 0
							let tmp_cost_price = cost_price[date] ? cost_price[date] : 0
							let tmp_comission_sum = comission_sum[date] ? comission_sum[date] : 0
							let tmp_delivery_sum = delivery_sum[date] ? delivery_sum[date] : 0
							let tmp_redemption_sum = redemption_sum[date] ? redemption_sum[date]: 0
							try{
								result += (
									tmp_report_sale_sum - (tmp_cost_price * tmp_report_sale_count) - tmp_delivery_sum - 
									tmp_comission_sum - tmp_redemption_sum
								)
							} catch (e){
								console.log(e)
							}
							
						})
					}
					return result
				}
				return 0
			},
			async saveCompetitors(){
				try {
					await KeywordMonitoring.saveCompetitors(this.product.mid, this.competitors_list_monitoring)
					this.$toasted.show(`Конкуренты изменены"`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000
					});
					this.competitors_list_monitoring = null;				
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
					});
				}
			},
			async deleteCompetitors(sku){
				try {
					await KeywordMonitoring.deleteOzonCompetitors(this.product.mid, sku)
					this.$toasted.show(`Конкурент удален"`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000
					});
					await this.fetchListCompetitors()				
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
					});
				}
				
			},
			async saveKeywords() {
				try {
					await KeywordMonitoring.updateOzonKeywords(this.product.mid, this.key_list_monitoring)
					this.$toasted.show(`Ключевые запросы изменены"`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000
					});
					this.key_list_monitoring = null;
					if (this.$refs.child_table_keyword)
						this.$refs.child_table_keyword.fetchOzonKeywordMonitoring();					
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
					});
				}
			},
			async copyKeyword() {
				try {
					await KeywordMonitoring.copyOzonKeyword(this.copy_nmid, this.product.mid);
					this.$toasted.show(`Ключевые запросы скопированы`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
					});
					this.copy_nmid = null;
					if (this.$refs.child_table_keyword)
						this.$refs.child_table_keyword.fetchOzonKeywordMonitoring();
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
					});
				}
			},
			async fetchListCompetitors(){
				try{
					const response = await KeywordMonitoring.fetchListCompetitors(this.product.mid)
					this.competitors_list = response.data
				} catch(e){
					console.log(e)
				}
			},
			async getAdverStatsByProduct() {
				this.adver_stats_table.loading = true
				try {
					let response = await OzonAdvertisement.getOzonAdvertisementProductStats(
						{ "mid": this.product.mid }
					);
					this.adver_stats_table.data = response.data;
					this.calculateSummaryAdverStats(response.data)
				} catch (err) {
					console.error(err.response);
				}
				this.adver_stats_table.loading = false
			},
			calculateSummaryAdverStats(data){
				let all_views = 0
				let all_clicks = 0
				let all_expenses = 0
				let all_order_count = 0
				let all_quantity = 0
				let all_ctr = null
				data.forEach(item => {
					all_views += item.views
					all_clicks += item.clicks
					all_expenses += item.expenses
					all_order_count += item.orders_count
					all_quantity += item.quantity
				})
				if (all_clicks && all_views && all_views != 0 && all_clicks !=0)
					all_ctr = (all_clicks / all_views * 100).toFixed(2)
				this.summary_stats_adver = {
					"all_views": all_views,
					"all_clicks": all_clicks,
					"all_expenses": all_expenses ? all_expenses.toFixed(2) : 0,
					"all_order_count": all_order_count,
					"all_quantity": all_quantity,
					"all_ctr": all_ctr,
				}
			},
			convertKopecksToRub(money){
				return money ? money / 1000000: 0
			},
			async getProductAndAdverts() {
				this.product_table.loading = true
				try {
					let response = await OzonAdvertisement.fetchAdvertsByProduct(this.product.mid);
					this.product_table.data = response.data;
				} catch (err) {
					console.error(err.response);
				}
				this.product_table.loading = false
			},
			calculateDelta(v1, v2){
				if (!v1 || !v2 || v1==0 || v2==0)
					return null
				return ((v2 - v1) / v1 * 10000 / 100).toFixed(2)
			},
			async openDialogChangeProductBid(item){
				this.recommendation_product_bid = null
				this.dialog_change_product_bid = true
				this.edit_product_bid = item
				this.new_product_bid = item.bid_percent
				await this.getRecommendationProductBid()
			},
			openDialogChangeKeywordBid(item){
				this.dialog_change_keyword_bid = true
				this.edit_keyword_bid = item
			},
			async changeProductBid(){
				this.loading_change_product_bid = true
				try {
					let response = await OzonAdvertisement.changeGeneralBid(
						this.edit_product_bid.id, this.new_product_bid
					);
					this.$toasted.show(`Ставка обновлена`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
					this.dialog_change_product_bid = false
					this.edit_product_bid = null
					await this.getAdverProducts()
				} catch (err) {
					console.error(err.response);
				}
				this.loading_change_product_bid = false
			},
			async getRecommendationProductBid() {
				try {
					let response = await OzonAdvertisement.getRecommendationProductBid(
						{ "product_id": this.edit_product_bid.id }
					);
					this.recommendation_product_bid = response.data.recommendation;
				} catch (err) {
					console.error(err.response);
				}
			},
			daysLabel(number) {
				let titles = ['день', 'дня', 'дней']
				const cases = [2, 0, 1, 1, 1, 2];
				return `Новинка ${number} ${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
			},
			async changeIsFbs(id){
				try {
					await Products.changeOzonIsFbs(id)
					this.$toasted.show("Успешно", {
						theme: "bubble", type: 'success', position: "top-center", duration: 3000
					})
				}
				catch (err){
					this.$toasted.show("Ошибка", {
						theme: "bubble", type: 'error', position: "top-center", duration: 3000
					})
					console.log(err)
				}
			},
		},
	};
</script>

<style scoped>
	.red_text{
		color: red !important;
	}
	.red_stats{
		color: #ea6a69;
	}
	.green_stats{
		color: #89cc41;
	}
	.kit_text{
		color: rgba(0, 0, 0, 0.6);
	}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.dialog_detail)?_c('v-dialog',{model:{value:(_vm.dialog_detail),callback:function ($$v) {_vm.dialog_detail=$$v},expression:"dialog_detail"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Исторические данные по "+_vm._s(_vm.dialog_detail_item.sku)+" ("+_vm._s(_vm.dialog_detail_item.keyword)+") ")]),_c('div',[_c('OzonCompetitorsDetailDialog',{attrs:{"sku":_vm.dialog_detail_item.sku,"keyword":_vm.dialog_detail_item.keyword}})],1)],1)],1):_vm._e(),_c('v-data-table',{ref:"test",staticClass:"elevation-0 mt-1 vertical-border",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"group-by":"keyword","show-group-by":"","hide-default-footer":"","disable-pagination":"","item-class":_vm.calculate_color_row},scopedSlots:_vm._u([{key:"group.header",fn:function(item){return [_c('td',{attrs:{"colspan":_vm.table.headers.length}},[_c('v-btn',{attrs:{"icon":""},on:{"click":item.toggle}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(item.isOpen ? 'mdi-minus' : 'mdi-plus'))])],1),_vm._v(" Поисковой запрос: "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.group))])],1)]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"padding":"0px"},attrs:{"small":""},on:{"click":function($event){return _vm.open_dialog_detail(item)}}},[_vm._v("mdi-history ")]),_vm._v(" "+_vm._s(item.position ? item.position : 0)+" ")],1)]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-1"},[_c('div',{staticStyle:{"border-radius":"10px","overflow":"hidden","width":"48px","height":"48px"}},[(!item.is_empty_product)?_c('v-img',{attrs:{"src":item.image,"contain":"","height":"48","width":"48"}}):_vm._e()],1),_c('div',{staticClass:"ml-2",staticStyle:{"width":"250px"}},[_c('p',{staticClass:"mb-0",staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(!item.is_empty_product ? item.name : '-')+" ")]),_c('p',{staticClass:"mt-1 mb-0 grey--text",staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.sku)+" | "+_vm._s(!item.is_empty_product ? item.seller_name : '-')+" ")])])])]}},{key:"item.summary_score",fn:function(ref){
var item = ref.item;
return [(item.is_empty_product)?_c('div'):(item.is_traforetto)?_c('div',{staticStyle:{"text-align":"center","color":"#005bff"}},[_vm._v("Трафарет")]):_c('div',{staticStyle:{"text-align":"center"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.final_result.toFixed(3)))]),(item.search_promotion_enabled)?_c('v-chip',{staticClass:"deep-purple--text",attrs:{"small":""}},[_vm._v(" Поднятие на "+_vm._s((item.search_promotion_boost * 100).toFixed(3))+"% ")]):_vm._e()],1)]}},{key:"item.query_fit_score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.query_fit_score ? item.query_fit_score : 0)+" балла ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('p',{staticClass:"mb-0",staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" "+_vm._s(item.rating.toFixed(1))+" ("+_vm._s(item.rates_count)+" шт.) ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.price_score)+" б.")]),_c('p',{staticClass:"mb-0 grey--text"},[_vm._v(_vm._s(_vm._f("toRUB")(item.price_rub)))])]):_vm._e()]}},{key:"item.promotion",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('p',{staticClass:"mb-0 grey--text",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.is_in_promo ? 'Учавствует' : 'Нет')+" ")]):_vm._e()]}},{key:"item.delivery_time",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center","white-space":"nowrap"}},[_vm._v(" от "+_vm._s(item.delivery_speed_boost_slot_from)+" до "+_vm._s(item.delivery_speed_boost_slot_to)+" дней ")]):_vm._e()]}},{key:"item.delivery_speed",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.convert_delivery_speed(item.delivery))+" ")]):_vm._e()]}},{key:"item.price_index",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.price_defect_rate_boost > 0 ? (item.price_defect_rate_boost * 100).toFixed(1) + '%' : 'Нет')+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
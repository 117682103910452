var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"deep-purple-accent-4"}}):_vm._l((_vm.data),function(value,key,index){return _c('div',{key:index,staticClass:"mt-2"},[_c('p',{staticClass:"title px-3 text--secondary"},[_vm._v("Артикул: "+_vm._s(key))]),_c('v-data-table',{staticClass:"elevation-0 mt-1 vertical-border small-font advertisement_stats",attrs:{"headers":_vm.headers,"items":value.detail,"items-per-page":7,"footer-props":{'items-per-page-options': [7, 14, 30, 60]},"fixed-header":"","height":"450px"},scopedSlots:_vm._u([{key:"item.profit_plan_simple",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
					'green_stats': item.profit_plan_simple >= 0, 
					'red_stats': item.profit_plan_simple < 0
				}},[_vm._v(_vm._s(item.profit_plan_simple))])]}},{key:"item.auto_avg_position",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.auto_avg_position)+" "),(_vm.calc_position_delta(item, value.detail)> 0)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-arrow-up-thin ")]):(_vm.calc_position_delta(item, value.detail) < 0)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-arrow-down-thin ")]):_vm._e()],1)]}},{key:"item.profit_plan_delta",fn:function(ref){
				var item = ref.item;
return [_c('div',{class:{
					'green_stats': item.profit_plan_delta >= 0, 
					'red_stats': item.profit_plan_delta < 0
				}},[_vm._v(_vm._s(item.profit_plan_delta))])]}},{key:"body.prepend",fn:function(){return [(value.detail.length != 0)?_c('tr',{staticStyle:{"font-weight":"bold"}},_vm._l((_vm.headers),function(col,index){return _c('td',{key:index},[(index == 0)?_c('div',[_vm._v("Всего: ")]):(!col.summary)?_c('div'):_c('div',[_vm._v(_vm._s(value.summary[col.value] ? value.summary[col.value].toFixed(1) : 0))])])}),0):_vm._e()]},proxy:true}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div>
		<div style="display: flex">
			<v-menu 
				v-model="datePicker.datepicker_date" 
				:close-on-content-click="false" 
				transition="scale-transition" 
				offset-y="offset-y"
				nudge-right="40" 
				min-width="290px" 
				ref="datePicker.datepicker_date"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						clearable
						@click:clear="date = null"
						v-model="dateTransform"
						label="Дата"
						readonly="readonly"
						v-bind="attrs"
						outlined
						dense
						v-on="on"
					>  
					</v-text-field>
				</template>
				<v-date-picker 
					v-model="date" 
					@input="datePicker.datepicker_date = false"
					:first-day-of-week="1"
				></v-date-picker>
			</v-menu>
			<v-btn class="ml-10" @click="fetchProductCharsByDate" :loading="loading"> 
				Показать
			</v-btn>
		</div>
		<div v-if="chars_data.length == 0" class="mt-5">Нет данных</div>
		<div v-else class="mt-5">
			<div v-if="chars_data && chars_data[0] && chars_data[0].characteristics">
				<p style="font-size: 18px">Характеристики продукта на {{chars_data[0].date}}:</p>
				<p v-for="(item, index) in chars_data[0].characteristics" :key="index" class="ml-5 align-center d-flex">
					<span class="name_char">{{item.name}}:</span> 
					<span class="ml-3">{{item.value}}</span>
				</p>
			</div>
			
			<!--<div v-if="chars_data && chars_data[0] && chars_data[0].child_char">
				<p style="font-size: 18px; color: #000;">Характеристики номенклатуры на {{chars_data[0].date}}:</p>
				<p v-for="(item, index) in chars_data[0].child_char" :key="index" class="ml-5 align-center d-flex">
					<span class="name_char">{{item.name}}:</span>
					<span v-if="item.name != 'Фото'" class="ml-3">{{item.value}}</span>
					<img :src="item.value" width="80" alt="" v-else class="ml-3"/>
				</p>
			</div>

			<div v-if="chars_data && chars_data[0] && chars_data[0].variations_char">
				<p style="font-size: 18px; color: #000;">Характеристики вариаций на {{chars_data[0].date}}:</p>
				<div style="display:flex;">
					<div v-for="(item2, index2) in chars_data" :key="index2">
						<p v-for="(item, index) in item2.variations_char" :key="index" class="ml-5 align-center d-flex">
							<span class="name_char">{{item.name}}:</span> 
							<span class="ml-3">{{item.value}}</span>
						</p>
					</div>
				</div>
			</div>-->
		</div>
	</div>
</template>

<script>
import {Products} from '@/api/products';
export default {
	name: 'ProductCharacteristicsPerDay',
	props: ['product_id'],
	data() {
		return {
			loading: false,
			date: null,
			datePicker: {
                datepicker_date: null,
            },
			chars_data: []
		}
	},    
	computed: {
		dateTransform () {
            return this.date ? this.$options.filters.formatDateToLocaleNoTime(this.date) : null
        },
	},
	methods: {
		async fetchProductCharsByDate(){
			if(this.date){
				this.loading = true
				const response = await Products.fetchProductCharacteristicsByDate(this.product_id, this.date);
				this.chars_data = response.data;
				this.loading = false
				this.$emit('getCharByDate', response.data)
			}
			else{
				this.chars_data = []
				this.$emit('getCharByDate', [])
			}
		}
	},
}
</script>

<style lang="scss" scoped>
	.current_char{
		width: 47%;
	}
	.name_char{
		font-weight: bold;
		font-size: 15px;
	}
</style>
import axios from 'axios'

const axiosInstance = axios.create({
  // адрес api
  // baseURL: `http://localhost:8089/api/v1`
  baseURL: `/api/v1/`
})

axiosInstance.interceptors.request.use(
  config => {
    if (localStorage.t) {
      config.headers.Authorization = `JWT ${localStorage.t}`
    }
    config.withCredentials = true
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
axiosInstance.defaults.xsrfCookieName = 'csrftoken'

const HTTP = axiosInstance

export { HTTP }

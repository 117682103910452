var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"competitors_height"},[(_vm.dialog_detail)?_c('v-dialog',{model:{value:(_vm.dialog_detail),callback:function ($$v) {_vm.dialog_detail=$$v},expression:"dialog_detail"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Исторические данные по конкурентамм ")]),_c('div',[_c('CompetitorsDetailDialog',{attrs:{"nmid":_vm.detail_info_active}})],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-0 competitors",attrs:{"headers":_vm.headers,"items":_vm.list_competitors,"loading":_vm.tableLoading,"single-expand":"","expanded":_vm.expanded,"show-expand":"","hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pb-5"},[_c('KeywordTable',{key:item.id,ref:"child_table",attrs:{"product_id":item.art,"favorite":"true","not_show_admin":"true"}})],1)])]}},{key:"item.is_favorite",fn:function(ref){
var item = ref.item;
return [(item.our)?_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","input-value":item.is_favorite},on:{"change":function($event){return _vm.changeFavoriteCompetitor(item)}},model:{value:(item.is_favorite),callback:function ($$v) {_vm.$set(item, "is_favorite", $$v)},expression:"item.is_favorite"}}):_vm._e()]}},{key:"item.link_mpstats",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"padding":"0 16px"},attrs:{"href":_vm.getLinkMpStats(item.art),"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}},{key:"item.art",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"padding":"0 16px"},attrs:{"href":("https://www.wildberries.ru/catalog/" + (item.art) + "/detail.aspx"),"target":"_blank"}},[_vm._v(" "+_vm._s(item.art)+" ")])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.brand != 0 ? item.brand : '-')+" ")])]}},{key:"item.pic",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"img_competitors_container",staticStyle:{"padding":"5px 16px 0 16px","position":"relative"}},[_c('img',{staticClass:"img_competitors",staticStyle:{"max-height":"80px"},attrs:{"src":_vm.getImgPath(item.art),"alt":""}}),_c('img',{staticClass:"img_competitors_big",class:{'bottomImg': _vm.isBottomScale(index)},staticStyle:{"max-height":"80px"},attrs:{"src":_vm.getImgPath(item.art),"alt":""}})])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
                        'bad': item.price_new.price_with_spp < _vm.list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0, 
                        'good': item.price_new.price_with_spp > _vm.list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0,
                        'neutral': item.price_new.price_with_spp == _vm.list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0,
                        'not-data': item.our && !_vm.list_competitors[0].price_new.price_with_spp,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.price_new.price_with_spp ? item.price_new.price_with_spp : '-' )))+" ")])]}},{key:"item.price_without_spp",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
						'bad': item.price_new.price_without_spp < _vm.list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0, 
						'good': item.price_new.price_without_spp > _vm.list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0,
						'neutral': item.price_new.price_without_spp == _vm.list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0,
						'not-data': item.our && !_vm.list_competitors[0].price_new.price_without_spp,
					},staticStyle:{"padding":"0 16px","display":"flex"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.price_new.price_without_spp ? item.price_new.price_without_spp : '-')))+" "),(item.promotion_parser)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-1"},'div',attrs,false),on),[_c('a',{staticClass:"dashed small-text",attrs:{"href":"#"}},[_vm._v("(А)")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.promotion_parser))])]):_vm._e()],1)]}},{key:"item.spp",fn:function(ref){
					var item = ref.item;
return [_c('div',{class:{
                        'good': item.price_new.percent_spp < _vm.list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0, 
                        'bad': item.price_new.percent_spp > _vm.list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0,
                        'neutral': item.price_new.percent_spp == _vm.list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0,
                        'not-data': item.our && !_vm.list_competitors[0].price_new.percent_spp,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.price_new.percent_spp ? item.price_new.percent_spp : 0)+"% ")])]}},{key:"item.sales_30days",fn:function(ref){
                    var item = ref.item;
return [(item.our)?_c('div',{class:{
                        'bad': item.sales_30days > _vm.list_competitors[0].sales_30days && item.our && item.sales_30days != 0, 
                        'good': item.sales_30days < _vm.list_competitors[0].sales_30days && item.our && item.sales_30days != 0,
                        'neutral': item.sales_30days == _vm.list_competitors[0].sales_30days && item.our && item.sales_30days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].sales_30days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_30days != 0 ? item.sales_30days : '-')))+" ")]):_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"padding":"0 16px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_30days != 0 ? item.sales_30days : '-')))+" ")])]}}],null,true)},[_c('span',[_c('p',[_vm._v(" Сумма выкупов за 30 дней: "+_vm._s(item.sum_redemption.redemption_30days ? (item.sum_redemption.redemption_30days | _vm.toRUB) : 0)+" ")]),_c('p',[_vm._v(" Продажи без выкупов за 30 дней: "+_vm._s(_vm._f("toRUB")((item.sales_30days - item.sum_redemption.redemption_30days)))+" ")])])])]}},{key:"item.delta_sales_30days",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.delta_sales_30days > _vm.list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0, 
                        'good': item.delta_sales_30days < _vm.list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0,
                        'neutral': item.delta_sales_30days == _vm.list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].delta_sales_30days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.delta_sales_30days != 0 ? item.delta_sales_30days + '%' : '-')+" ")])]}},{key:"item.sales_14days",fn:function(ref){
                    var item = ref.item;
return [(item.our)?_c('div',{class:{
                        'bad': item.sales_14days > _vm.list_competitors[0].sales_14days && item.our && item.sales_14days != 0, 
                        'good': item.sales_14days < _vm.list_competitors[0].sales_14days && item.our && item.sales_14days != 0,
                        'neutral': item.sales_14days == _vm.list_competitors[0].sales_14days && item.our && item.sales_14days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].sales_14days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_14days != 0 ? item.sales_14days : '-' )))+" ")]):_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"padding":"0 16px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_14days != 0 ? item.sales_14days : '-')))+" ")])]}}],null,true)},[_c('span',[_c('p',[_vm._v(" Сумма выкупов за 14 дней: "+_vm._s(item.sum_redemption.redemption_14days ? item.sum_redemption.redemption_14days : 0)+" ")]),_c('p',[_vm._v(" Продажи без выкупов за 14 дней: "+_vm._s(item.sales_14days - item.sum_redemption.redemption_14days)+" ")])])])]}},{key:"item.delta_sales_14days",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.delta_sales_14days > _vm.list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0, 
                        'good': item.delta_sales_14days < _vm.list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0,
                        'neutral': item.delta_sales_14days == _vm.list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].delta_sales_14days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.delta_sales_14days != 0 ? item.delta_sales_14days + '%' : '-')+" ")])]}},{key:"item.sales_7days",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.sales_7days > _vm.list_competitors[0].sales_7days && item.our && item.sales_7days != 0, 
                        'good': item.sales_7days < _vm.list_competitors[0].sales_7days && item.our && item.sales_7days != 0,
                        'neutral': item.sales_7days == _vm.list_competitors[0].sales_7days && item.our && item.sales_7days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].sales_7days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_7days != 0 ? item.sales_7days : '-')))+" ")])]}},{key:"item.delta_sales_7days",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.delta_sales_7days > _vm.list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0, 
                        'good': item.delta_sales_7days < _vm.list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0,
                        'neutral': item.delta_sales_7days == _vm.list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0,
                        'not-data': item.our && !_vm.list_competitors[0].delta_sales_7days, 
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.delta_sales_7days != 0 ? item.delta_sales_7days + '%' : '-')+" ")])]}},{key:"item.sales_3days",fn:function(ref){
                    var item = ref.item;
return [(item.our)?_c('div',{class:{
                        'bad': item.sales_3days > _vm.list_competitors[0].sales_3days && item.our && item.sales_3days != 0, 
                        'good': item.sales_3days < _vm.list_competitors[0].sales_3days && item.our && item.sales_3days != 0,
                        'neutral': item.sales_3days == _vm.list_competitors[0].sales_3days && item.our && item.sales_3days != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].sales_3days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_3days != 0 ? (item.sales_3days) : '-' )))+" ")]):_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"padding":"0 16px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")((item.sales_3days != 0 ? item.sales_3days : '-')))+" ")])]}}],null,true)},[_c('span',[_c('p',[_vm._v(" Сумма выкупов за 3 дня: "+_vm._s(item.sum_redemption.redemption_3days ? item.sum_redemption.redemption_3days : 0)+" ")]),_c('p',[_vm._v(" Продажи без выкупов за 3 дня: "+_vm._s(item.sales_3days - item.sum_redemption.redemption_3days)+" ")])])])]}},{key:"item.delta_sales_3days",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.delta_sales_3days > _vm.list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0, 
                        'good': item.delta_sales_3days < _vm.list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0,
                        'neutral': item.delta_sales_3days == _vm.list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].delta_sales_3days,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.delta_sales_3days != 0 ? item.delta_sales_3days + '%' : '-')+" ")])]}},{key:"item.avg_sales",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.avg_sales > _vm.list_competitors[0].avg_sales && item.our && item.avg_sales != 0, 
                        'good': item.avg_sales < _vm.list_competitors[0].avg_sales && item.our && item.avg_sales != 0,
                        'neutral': item.avg_sales == _vm.list_competitors[0].avg_sales && item.our && item.avg_sales != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].avg_sales,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.avg_sales != 0 ? item.avg_sales : '-')+" ")])]}},{key:"item.warehouse_balance",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
								'bad': +item.warehouse.sum_balance > +_vm.list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0, 
								'good': +item.warehouse.sum_balance < +_vm.list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0,
								'neutral': item.warehouse.sum_balance == _vm.list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0, 
								'not-data': item.our && !_vm.list_competitors[0].warehouse.sum_balance,
							},staticStyle:{"padding":"0 16px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.warehouse.sum_balance != 0 ? item.warehouse.sum_balance : '-')+" "),(item.is_fbs_competitors.flag && item.our)?_c('v-chip',{staticClass:"mx-1 px-1 my-1 small-text",attrs:{"color":"red","outlined":"","x-small":""}},[_vm._v("fbs")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._l((item.warehouse.all_data),function(el,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(el.store)+" - "+_vm._s(el.balance)+" ")])}),_c('p',[_vm._v(_vm._s(item.is_fbs_competitors.warehouse)+"(Парсинг) - "+_vm._s(item.is_fbs_competitors.stock))])],2)])]}},{key:"item.feedback",fn:function(ref){
							var item = ref.item;
return [_c('div',{class:{
                        'bad': +item.feedback > +_vm.list_competitors[0].feedback && item.our && item.feedback != 0, 
                        'good': +item.feedback < +_vm.list_competitors[0].feedback && item.our && item.feedback != 0,
                        'neutral': +item.feedback == +_vm.list_competitors[0].feedback && item.our && item.feedback != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].feedback,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.feedback != 0 ? item.feedback : '-')+" ")])]}},{key:"item.rating",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': +item.rating > +_vm.list_competitors[0].rating && item.our && item.rating != 0, 
                        'good': +item.rating < +_vm.list_competitors[0].rating && item.our && item.rating != 0,
                        'neutral': +item.rating == +_vm.list_competitors[0].rating && item.our && item.rating != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].rating,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.rating != 0 ? item.rating : '-')+" ")])]}},{key:"item.quantity_category",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': +item.quantity_category > +_vm.list_competitors[0].quantity_category && item.our && item.quantity_category != 0, 
                        'good': +item.quantity_category < +_vm.list_competitors[0].quantity_category && item.our && item.quantity_category != 0,
                        'neutral': +item.quantity_category == +_vm.list_competitors[0].quantity_category && item.our && item.quantity_category != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].quantity_category,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.quantity_category != 0 ? item.quantity_category : '-')+" ")])]}},{key:"item.visibility",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.visibility > _vm.list_competitors[0].visibility && item.our && item.visibility != 0, 
                        'good': item.visibility < _vm.list_competitors[0].visibility && item.our && item.visibility != 0,
                        'neutral': item.visibility == _vm.list_competitors[0].visibility && item.our && item.visibility != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].visibility,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.visibility != 0 ? item.visibility : '-')+" ")])]}},{key:"item.avg_pos",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.avg_pos < _vm.list_competitors[0].avg_pos && item.our && item.avg_pos != 0, 
                        'good': item.avg_pos > _vm.list_competitors[0].avg_pos && item.our && item.avg_pos != 0,
                        'neutral': item.avg_pos == _vm.list_competitors[0].avg_pos && item.our && item.avg_pos != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].avg_pos,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.avg_pos != 0 ? item.avg_pos : '-')+" ")])]}},{key:"item.min_pos",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.min_pos < _vm.list_competitors[0].min_pos && item.our && item.min_pos != 0, 
                        'good': item.min_pos > _vm.list_competitors[0].min_pos && item.our && item.min_pos != 0,
                        'neutral': item.min_pos == _vm.list_competitors[0].min_pos && item.our && item.min_pos != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].min_pos,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.min_pos != 0 ? item.min_pos : '-')+" ")])]}},{key:"item.warehouse_store",fn:function(ref){
                    var item = ref.item;
return [_c('div',{class:{
                        'bad': item.warehouse.quantity_store > _vm.list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0, 
                        'good': item.warehouse.quantity_store < _vm.list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0,
                        'neutral': item.warehouse.quantity_store == _vm.list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0, 
                        'not-data': item.our && !_vm.list_competitors[0].warehouse.quantity_store,
                    },staticStyle:{"padding":"0 16px"}},[_vm._v(" "+_vm._s(item.warehouse.quantity_store != 0 ? item.warehouse.quantity_store : '-')+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"padding":"0 16px"}},[(item.our)?_c('v-icon',{staticStyle:{"padding":"0px"},attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),_c('v-icon',{staticClass:"ml-3",staticStyle:{"padding":"0px"},attrs:{"small":""},on:{"click":function($event){return _vm.openDialogDetailCompetitors(item)}}},[_vm._v(" mdi-history ")])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-row',{staticClass:"justify-space-between",staticStyle:{"padding":"0 24px 24px 24px"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)]},proxy:true}])},[_vm._v(" > ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
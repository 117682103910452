<template>
	<div class="py-5">
		<div class="px-5">
			<v-row>
				<v-col>
					<v-menu 
						v-model="datePicker.datepicker_date_after" :close-on-content-click="false"  
						prepend-icon="mdi-calendar" transition="scale-transition" 
						offset-y="offset-y" nudge-right="40" min-width="290px"
						ref="datePicker.datepicker_date_after"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable @click:clear="filters.date_after = null"
								v-model="dateFormattedAfter" label="Дата (начало)" readonly="readonly"
								v-bind="attrs" hide-details outlined v-on="on" dense
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_after" :first-day-of-week="1"
							@input="datePicker.datepicker_date_after = false"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-menu 
						v-model="datePicker.datepicker_date_before" :close-on-content-click="false" 
						transition="scale-transition" offset-y="offset-y" nudge-right="40" 
						min-width="290px" ref="datePicker.datepicker_date_before"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable @click:clear="filters.date_before = null"
								v-model="dateFormattedBefore" label="Дата (конец)" readonly="readonly"
								v-bind="attrs" hide-details outlined dense v-on="on"
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_before" :first-day-of-week="1"
							@input="datePicker.datepicker_date_before = false"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-autocomplete
						v-model="filters.wbaccount" :items="wbaccounts" item-text="name"
						item-value="id" multiple label="Аккаунт" clearable
						outlined hide-details dense
					></v-autocomplete>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filters.nmid" clearable outlined
						dense label="Артикул" hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filters.code_1c" clearable outlined
						dense label="Артикул 1C" hide-details
					></v-text-field>
				</v-col>
				<v-spacer></v-spacer>
				<v-col>
					<v-btn depressed color="primary" dark @click="fetchReports">Применить</v-btn>
				</v-col>
			</v-row>
		</div>
		<v-data-table
			ref="start_block" :headers="table.headers" :items="table.data"
			:pageCount="pagination.numberOfPages" :loading="table.loading"
			:options.sync="options" :items-per-page="50"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border small-font mt-5"
			:footer-props="{'items-per-page-options': [5, 15, 25, 50, 100]}"
        >
			<template v-slot:item.reward="{ item }">
                {{ item.reward | toRUB }}
            </template>
			<template v-slot:item.share="{ item }">
                {{ item.share * 100 }}%
            </template>
		</v-data-table>
	</div>
</template>

<script>

import { Stats } from '@/api/stats'
import { General } from '@/api/general'

export default {
	name: 'RegionSalesReportViews',
	data() {
		return {
			filters:{
				nmid: null,
				code_1c: null,
				wbaccount: null,
				date_after: "",
                date_before: "",
			},
			table: {
				data: [],
				headers: [
					{ text: 'Дата', value: 'date', sortable: true, width: '1%' },
					{ text: 'Артикул', value: 'nmid', sortable: false, width: '1%' },
					{ text: 'Код 1С', value: 'code_1c', sortable: false, width: '1%' },
					{ text: 'Страна', value: 'country', sortable: true, width: '1%' },
					{ text: 'Фед. округ', value: 'fed_okr', sortable: false, width: '1%' },
					{ text: 'Область', value: 'oblast', sortable: false, width: '1%' },
					{ text: 'Город', value: 'city', sortable: false, width: '1%' },
					{ text: 'Выкупили, шт', value: 'qty', sortable: true, width: '1%' },
					{ text: "Выкупили, руб", value: 'reward', sortable: true, width: '1%' },
					{ text: 'Доля', value: 'share', sortable: false, width: '1%' },
				],
				loading: false
			},
			first_init: false,
			options: {
				sortBy: ['date'],
				sortDesc: [true]
			},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			wbaccounts: [],
			datePicker: {
                datepicker_date_after: "",
                datepicker_date_before: "",
            },
		}
	},
	computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
		dateFormattedAfter () {
            return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
        },
        dateFormattedBefore () {
            return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
        },
	},
	watch: {
		'options': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.fetchReports()
			}
		},
	},
	methods: {
		async fetchReports(){
			this.table.loading = true
			this.$vuetify.goTo(this.$refs.start_block, {
				duration: 500,
				offset: 150,
				easing: 'linear',
			})
			const { page, itemsPerPage, sortBy, sortDesc } = this.options
			let pageNumber = page - 1
			try {
				const response = await Stats.fetchRegionSaleReports({
					'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage,
					'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
					...this.transfromFiltersForQuery
				})
				this.table.data = response.data.results
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.log(err.data)
			}
			this.table.loading = false
		},
		async fetchWbAccounts () {
			try{
				const response = await General.getWbAccounts()
				this.wbaccounts = response.data
			} catch (err){
				console.log(err.data)
			}
		},
	},
	async mounted() {
		document.title = 'Продажи по регионам ВБ | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Продажи по регионам ВБ`);
		await this.fetchWbAccounts()
		await this.fetchReports()
		this.first_init = true
		
	},
};
</script>

<style scoped>

</style>
<template>
   <v-card>
        <v-card-title>Калькулятор отзывов</v-card-title>
        <v-card-text>
            <div class="d-flex mt-5">
                <v-text-field
                    v-model="need_rating" label="Необходимая оценка" hide-details outlined dense
                    type='number'
                ></v-text-field>
                <v-btn
					class="ml-5" dark color="purple darken-2" @click="fetchFeedbacksCalculator"
                    :loading="loading" 
				>Рассчитать</v-btn>
            </div>
            <div v-if="data.uid">
                <div class="d-flex align-center mt-5">
                    <h2>Рейтинг:</h2>
                    <v-rating :value="data.rating" class="ml-5" color="amber" dense half-increments readonly size="32"></v-rating>
                    <div class="ml-5">{{ data.rating }}</div>
                </div>
                <div class="d-flex align-center mt-5">
                    <h2>Количество отзывов:</h2>
                    <div class="ml-5">{{ data.feedbacks_count }}</div>
                </div>
                <div class="d-flex align-center mt-5">
                    <h2>Оценок(5) до заданного рейтинга:</h2>
                    <div class="ml-5">{{ data.calc_feedbacks }}</div>
                </div>
            </div>
        </v-card-text>
   </v-card>
</template>

<script>
	import {Products} from '@/api/products'

	export default {
		name: 'FeedbackCalculator',
		props: {
			product_id: {
				type: Number,
				required: true
			},
        },
		data() {
			return {
                need_rating: null,
				data: {},
                loading: false,
            };
		},
		methods: {
			async fetchFeedbacksCalculator () {
                this.data = {}
				this.loading = true
				try {
					const response = await Products.feedbackCalculator(this.product_id, this.need_rating)
                    this.data = response.data
				} catch (err) {
					console.error(err)

				}
				this.loading = false
			}
        },
		async mounted() {
            //await fetchFeedbacksCalculator
		},

	};
</script>

<style scoped>

</style>
<template>
    <v-container fluid>
        <v-dialog v-model="editable.dialog" max-width="600" v-if="editable.dialog" scrollable>
            <v-card class="p-5">
                <v-card-title>Изменение данных</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="editable.value" label="Новое значение" type="number" 
                        outlined clearable dense class="mt-5"
                    ></v-text-field>
                    <v-btn 
                        color="primary" @click="update_data" :loading="editable.loading"
                    >Изменить значение</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_upload" v-if="dialog_upload" max-width="450px">
			<v-card>
				<v-card-title>Загрузка показателей</v-card-title>
				<v-card-text class="mt-5">
					<v-file-input
                        label="File input" outlined dense id="file_unit_economic" 
                        class="pt-5" hide-details
					></v-file-input>
					<p style="cursor:pointer" class="text-download mt-3" @click="download_template">Скачать шаблон</p>
				</v-card-text>
				<v-card-actions>
					<v-btn 
                        color="blue darken-1" text @click="upload_planned_unit_economic"
                        :loading="loading_upload"
					>Импортировать</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog v-model="versions.dialog" v-if="versions.dialog" max-width="1800px">
			<v-card>
				<v-card-title>Версии для sku: {{versions.item.sku}} и даты {{ versions.item.date }}</v-card-title>
				<v-card-text class="mt-5 planned_economic_block">
					<v-data-table
                        :headers="headers_versions" :items="versions.data" :loading="versions.loading"
                        class="elevation-0 mt-3 vertical-border" hide-default-footer disable-pagination
                    >
                        <template v-slot:item.date_upload="{ item }">
                            {{ item.date_upload | formatDateToLocale }}
                        </template>
                        <template v-for="prop in list_editable_fields" v-slot:[`item.${prop}`]="{ item }">
                            {{ item[prop] ? Number(item[prop]).toFixed(2): 0 }}
                        </template>
                        <template v-for="prop2 in list_editable_fields_percent" v-slot:[`item.${prop2}`]="{ item }">
                            {{ item[prop2] ? Number(item[prop2] * 100).toFixed(2): 0 }}%
                        </template>
                    </v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-card class="planned_economic_block">
            <v-card-title>
                Плановая unit экономика
                <v-btn class="ml-5" color="primary" @click="dialog_upload=true">
                    Загрузить показатели
                </v-btn>
                <v-btn class="ml-5" color="primary" @click="export_data" :loading="loading_export">
                    Экспорт
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="align-center mt-5">
                    <v-col>
                        <v-menu 
                            v-model="date_picker" :close-on-content-click="false" transition="scale-transition" 
                            offset-y="offset-y" nudge-right="40" min-width="290px" ref="date_picker"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable @click:clear="filters.date = null" v-model="filters.date"
                                    label="Дата" readonly v-bind="attrs" hide-details outlined dense v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="filters.date" @input="date_picker = false" type="month" :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.sku" clearable outlined dense
                            label="Артикул" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.code_1c" clearable outlined dense
                            label="Артикул 1С" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.marketplaces" :items="choices.marketplaces"
                            item-text="name" item-value="value" label="Маркетплейс" outlined
                            hide-details dense clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.account" :items="choices.accounts"
                            item-text="name" item-value="name" label="ЛК" outlined
                            hide-details dense clearable multiple
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.name" clearable outlined dense
                            label="Название" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.product_group" :items="choices.product_groups"
                            item-text="name" item-value="value" label="Категория" outlined
                            hide-details dense clearable multiple
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.tag" :items="choices.tags"
                            item-text="name" item-value="name" label="Тег" outlined
                            hide-details dense clearable multiple
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-switch
                            v-model="filters.no_warehouse_expenses" hide-details 
                            label="Без расхода склада"
                        ></v-switch>
                        <v-switch
                            v-model="filters.no_mp_expenses" hide-details 
                            label="Без расхода МП"
                        ></v-switch>
                    </v-col>
                    <v-col>
						<v-btn @click="fetch_data" depressed color="primary" dark>Применить</v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="table.headers" :items="table.data" :loading="table.loading"
                    class="elevation-0 mt-3 vertical-border" 
                    :items-per-page="50" :server-items-length="table.pagination.totalItems"
                    :footer-props="{'items-per-page-options': [5, 10, 25, 50, 100]}"
                    :pageCount="table.pagination.numberOfPages" :options.sync="table.options"
                    height="800px" fixed-header :item-class="row_classes"
                >
                    <template v-slot:item.versions="{ item }">
                        <v-btn icon title="версии" @click="open_dialog_versions(item)">
                            <v-icon small>mdi-history</v-icon>
                        </v-btn>
                    </template>
                    <template v-for="prop in list_editable_fields" v-slot:[`item.${prop}`]="{ item }">
                        <a :key="`field_${prop}`" @click="open_dialog_editable(item, prop)">
                            {{ item[prop] ? Number(item[prop]).toFixed(2): 0 }}
                        </a>
                    </template>
                    <template v-for="prop2 in list_editable_fields_percent" v-slot:[`item.${prop2}`]="{ item }">
                        <a :key="`field_${prop2}`" @click="open_dialog_editable(item, prop2)">
                            {{ item[prop2] ? Number(item[prop2] * 100).toFixed(2): 0 }}%
                        </a>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { Products } from '@/api/products'
	export default {
		name: 'PlannedUnitEconomicView',
		data() {
			return {
                date_picker: null,
                filters: {
                    sku: null,
                    code_1c: null,
                    marketplaces: null,
                    account: [],
                    name: null,
                    product_group: [],
                    tag: [],
                    date: null,
                    no_warehouse_expenses: false,
                    no_mp_expenses: false
                },
                choices: {
                    accounts: [],
                    product_groups: [],
                    tags: [],
                    marketplaces: ['wildberries', 'ozon', 'detmir']
                },
                table: {
                    loading: false,
                    data: [],
                    options: {},
                    pagination: {
                        next: null,
                        totalItems: 0,
                        numberOfPages: 0,
                        currentPage: 0
                    },
                    headers: [
                        { text: 'Версии', sortable: false, value: 'versions', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Дата', sortable: true, value: 'date', width: '1%', is_editable: false, is_percent: false },
                        { text: 'sku', sortable: true, value: 'sku', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Маркетплейс', sortable: true, value: 'marketplaces', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Артикул 1С', sortable: false, value: 'code_1c', width: '1%', is_editable: false, is_percent: false },
                        { text: 'ЛК', sortable: false, value: 'account', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Название', sortable: false, value: 'name', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Категория', sortable: false, value: 'product_group', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Тег', sortable: false, value: 'tag', width: '1%', is_editable: false, is_percent: false },
                        { text: 'Приемка', sortable: true, value: 'acceptance', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Хранение', sortable: true, value: 'storage', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Фасовка', sortable: true, value: 'packing', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Сборка', sortable: true, value: 'assembling', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Расходы склада/производства (сумма)', sortable: true, value: 'warehouse_expenses', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Логистика до МП', sortable: true, value: 'logistic_to_mp', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Расходы склада/производства + логистика до МП', sortable: true, value: 'warehouse_expenses_with_logistic_to_mp', width: '1%', is_editable: true, is_percent: false },                        
                        { text: 'Расходы на продвижение', sortable: true, value: 'advertisement_expenses', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Хранение МП', sortable: true, value: 'storage_mp', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Штрафы', sortable: true, value: 'penalty', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Транзит', sortable: true, value: 'transit', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Другие расходы МП', sortable: true, value: 'other_expenses_mp', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Расходы МП (сумма)', sortable: true, value: 'expenses_mp_sum', width: '1%', is_editable: true, is_percent: false },
                        { text: 'ВП_МП', sortable: true, value: 'gross_profit_mp', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Кол-во заказов', sortable: true, value: 'orders_count', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Сумма заказов', sortable: true, value: 'orders_sum', width: '1%', is_editable: true, is_percent: false },
                        { text: 'Производство Тотти', sortable: true, value: 'production_totty', width: '1%', is_editable: true, is_percent: false },
                    ]
                },
                editable:{
                    dialog: false,
                    item: null,
                    field: null,
                    value: null,
                    loading: false
                },
                dialog_upload: false,
                loading_upload: false,
                first_init: false,
                versions: {
                    dialog: false,
                    data: [],
                    loading: false,
                    item: null
                },
                loading_export: false,
            }
		},
        watch: {
			'table.options': async function (newVal, oldVal) {
				if (oldVal && this.first_init) {
					await this.fetch_data()
				}
			},
		},
        computed: {
            headers_versions(){
                return [
                    { text: 'Время загрузки', sortable: true, value: 'date_upload', width: '1%', is_editable: false, is_percent: false }, 
                    ...this.table.headers.slice(1)
                ]
            },
            list_editable_fields(){
                let result = []
                this.table.headers.forEach(item => {
                    if (item.is_editable && !item.is_percent)
                        result.push(item.value)
                })
                return result
            },
            list_editable_fields_percent(){
                let result = []
                this.table.headers.forEach(item => {
                    if (item.is_editable && item.is_percent)
                        result.push(item.value)
                })
                return result
            },
            transform_filters () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else if(f=='nmid'){
						query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
					}else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
        },
		methods: {
            row_classes(item) {
				if (item.is_change_vp) {
					return this.$vuetify.theme.dark ? 'green darken-4' : 'green lighten-5';
				}
			},
            async open_dialog_versions(item){
                this.versions.dialog = true
                this.versions.item = item
                await this.fetch_versions(item)
            },
            async fetch_versions(item){
                this.versions.loading = true
                this.versions.data = []
				try {
					const response = await Products.versionsPlannedUnitEconomic(item.id)
                    this.versions.data = response.data
				}
				catch (err){
					console.log(err)
				}
				this.versions.loading = false
            },
            open_dialog_editable(item, field){
                this.editable.dialog = true
                this.editable.item = item
                this.editable.value =  item[field]
                this.editable.field = field
            },
            async upload_planned_unit_economic(){
                this.loading_upload = true
				let fileToUpload = document.getElementById('file_unit_economic').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				try {
					await Products.uploadPlannedUnitEconomic(formData)
                    this.dialog_upload = false
                    await this.fetch_data()
				}
				catch (err){
					console.log(err)
				}
				this.loading_upload = false
			},
            async export_data(){
				this.loading_export = true
				try {
                    const { sortDesc, sortBy } = this.table.options
					const response = await Products.exportPlannedUnitEconomic({
                        ...this.transform_filters, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                    })
                    const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'planned_economic.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err)
				}
                this.loading_export = false
			},
            async download_template(){
				try {
					const response = await Products.exportTemplatePlannedUnitEconomic()
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'template_unit_economic.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
			},
            async fetch_data () {
				this.table.loading = true
				const { page, itemsPerPage, sortDesc, sortBy } = this.table.options
				let pageNumber = page - 1
				try {
					const response = await Products.fetchPlannedUnitEconomic({
                        ...this.transform_filters, 
                        'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage,
                        'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                    })
                    this.table.data = response.data.results
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err)
				}
				this.table.loading = false
			},
            async fetch_filters(){
				try {
					const response = await Products.fetchFiltersPlannedUnitEconomic()
                    this.choices.accounts = response.data.accounts
					this.choices.product_groups = response.data.product_groups
					this.choices.tags = response.data.tags
				} catch (err) {
					console.log(err)
				}               
            },
            async update_data(){
                this.editable.loading = true
                let json_data = {}
                json_data[this.editable.field] = this.editable.value
				try {
					await Products.updatePlannedUnitEconomic(this.editable.item.id, json_data)
                    await this.fetch_data()
                    this.editable.dialog = false
				} catch (err) {
					console.log(err.data)
				}
				this.editable.loading = false
            }
		},
		async mounted() {
			document.title = 'Плановая юнит экономика';
			this.$store.commit('setPageTitle', `Плановая юнит экономика`)
            await this.fetch_filters()
            await this.fetch_data()
            this.first_init = true
		},
	};
</script>

<style lang="scss">
.planned_economic_block th, .planned_economic_block td{
    font-size: 11px !important;
}
</style>
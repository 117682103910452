<template>
<v-card>
    <v-card-title>
        <span class="text-h5">Редактирование</span>
    </v-card-title>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                    <v-form>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="editData.quantity_product"
                            label="Количество"
                            required
                            type="number"
                            outlined
                            clearable
                            :rules="[rules.required, rules.positive]"
                            tabindex="2"
                            dense
                        ></v-text-field>
                        <v-menu
                            v-if="isRedemptionManager" 
                            v-model="datePicker.datepicker_date_order" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_order"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateOrderFormatted"
                                    @click:clear="editData.date_order = null"
                                    label="Дата заказа"
                                    :rules="[rules.required]"
                                    readonly="readonly"
                                    tabindex="9"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="editData.date_order" 
                                @input="datePicker.datepicker_date_order = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="editData.sum_order"
                            label="Сумма"
                            outlined
                            type="number"
                            tabindex="4"
                            :rules="[rules.required, rules.positive]"
                            dense
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="editData.type_redemption == 'DISTRIBUTION' && isRedemptionManager"
                            v-model="editData.cashback"
                            label="Кэшбэк, %"
                            outlined
                            tabindex="5"
                            :rules="[rules.cashback]"
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                        <v-select
                            v-if="isRedemptionManager"
                            v-model="editData.manager_id"
                            :items="data.list_manager"
                            item-text="username"
                            item-value="id"
                            label="Менеджер"
                            :rules="[rules.required]"
                            tabindex="6"
                            outlined
                            clearable
                            dense
                        ></v-select>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="editData.account_buyer"
                            label="Покупатель"
                            outlined
                            :rules="[rules.required]"
                            tabindex="7"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="editData.phone"
                            v-mask="'+7 (###) ###-##-##'"
                            dense
                            outlined
                            clearable
                            tabindex="8"
                            label="Номер аккаунта"
                        ></v-text-field>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="editData.address_pvz"
                            label="Адрес ПВЗ."
                            outlined
                            tabindex="9"
                            clearable
                            dense
                        ></v-text-field>
                        <v-menu 
                            v-if="editData.type_redemption != 'DISTRIBUTION'"
                            v-model="datePicker.datepicker_date_buy" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_buy"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateBuyFormatted"
                                    @click:clear="editData.date_buy = null"
                                    label="Дата покупки"
                                    readonly="readonly"
                                    tabindex="2"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="editData.date_buy" 
                                @input="datePicker.datepicker_date_buy = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-autocomplete
                            v-if="item.type_redemption == 'Отзыв'"
                            v-model="editData.feedback_uid"
                            :loading="loading.loading_feedback_autocomplete"
                            :items="data.list_filtered_feedback"
                            item-text="text"
                            item-value="uid"
                            :search-input.sync="search.search_feedback"
                            cache-items
                            label="Отзыв"
                            tabindex="1"
                            outlined
                            dense
                            clearable
                        ></v-autocomplete>
                        <v-text-field
                            v-if="item.type_redemption == 'Отзыв'"
                            v-model="editData.feedback_author"
                            label="Автор отзыва"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-select
                            v-if="editData.type_redemption != 'DISTRIBUTION'"
                            v-model="editData.report"
                            :items="onlySaleReports"
                            item-text="realizationreport_id"
                            item-value="id"
                            readonly
                            tabindex="3"
                            hint="Выберите способ заполнение ниже"
                            label="Документ о продаже"
                            :persistent-hint="true"
                            outlined
                            clearable
                            multiple
                            dense
                        ></v-select>
                        <v-row 
                            class="justify-space-between pb-5 px-3" 
                            v-if="editData.type_redemption != 'DISTRIBUTION'"
                        >
                            <v-btn
                                text
                                class="mt-3"
                                id="autoreport"
                                :disabled="!editData.date_buy"
                                dense
                                @click="getFilteredReport"
                                :loading="loading.loading_report_autocomplete"
                            >Автозаполнение</v-btn>
                            
                            <v-dialog
                                v-model="dialog_report"
                                persistent
                            >
                                <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        text
                                        class="mt-3"
                                        id="autoreport"
                                        dense
                                        v-bind="attrs"
                                        :disabled="!editData.date_buy"
                                        v-on="on"
                                    >   
                                        Ручное заполнение
                                    </v-btn>
                                </template>
                                <RedemptionFormReport :items="editData" @close="closeReport"/>
                            </v-dialog>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
            Cancel
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="save"
        >
            Save
        </v-btn>
    </v-card-actions>
</v-card>    
</template>

<script>

import _ from 'lodash'
import { Redemption } from '@/api/redemption'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
Vue.directive('mask', VueMaskDirective)
import RedemptionFormReport from '@/components/Redemption/RedemptionFormReport'

export default {
    name: "RedemptionFormUpdate",
    props: ['item'],
    components: {
        RedemptionFormReport
    },
    data() {
        return {
            dialog_report: null,
            editData: {
                id: null,
                product_id: null,
                quantity_product: null,
                sum_order: null,
                cashback: null,
                manager_id: null,
                variations: null,
                account_buyer: null,
                type_redemption: null,
                date_order: null,
                date_buy: null,
                feedback_uid: null,
                report: null,
                phone: null,
                address_pvz: null,
                feedback_author: null
            },
            loading: {
                loading_feedback_autocomplete: false,
                loading_report_autocomplete: false
            },
            data: {
                list_filtered_feedback: [],
                list_filtered_report: [],
                type_redemption_choise: [
                    {'name': 'Выкуп', 'value': 'REDEMPTION'},
                    {'name': 'Отзыв', 'value': 'FEEDBACK'},
                    {'name': 'Раздача', 'value': 'DISTRIBUTION'}
                ],
                list_manager: []
            },
            search: {
                search_feedback: null,
                search_report: null
            },
            datePicker: {
                datepicker_date_buy: "",
                datepicker_date_order: ""
            },
            rules: {
                required: value => !!value || 'Обязательное поле.',
                positive: value => value > 0 || 'Должно быть положительное число',
                cashback: value => (value <= 100 && value >= 0) || 'Число должно быть от 0 до 100',
            },
        }
    },
    computed: {
        dateBuyFormatted () {
            return this.editData.date_buy ? this.$options.filters.formatDateToLocaleNoTime(this.editData.date_buy) : null
        },
        dateOrderFormatted () {
            return this.editData.date_order ? this.$options.filters.formatDateToLocaleNoTime(this.editData.date_order) : null
        },
        isRedemptionManager() {
            return this.$store.getters.isRedemptionManager || false
        },
        isRedemptionUser() {
            return this.$store.getters.isRedemptionUser || false
        },
        onlySaleReports () {
          return this.data.list_filtered_report.filter(item => item.type === 'Продажа')
        }
    },
    watch: {
        'search.search_feedback': function(val){
            if (val && val !== this.editData.feedback_uid && val.length > 2){
                this.loading.loading_feedback_autocomplete = true
                this.debouncedGetFilteredFeedback(val)
            }
        },
    },
    methods: {
        closeReport(el){
            if (el){
                this.data.list_filtered_report = el
                this.editData.report = []
                el.forEach(item => {
                    if (item.type === 'Продажа') {
                      this.editData.report.push(item.id)
                    }
                })
            }
            this.dialog_report = false

        },
        close (){
            this.$emit('close')
        },
        save () {
            this.updateForm()
        },
        async updateForm(){
            let redemption_id = this.editData.id
            const data = {
                "product": this.editData.product_id,
                "variations": this.editData.variations,
                "quantity_product": this.editData.quantity_product,
                "sum_order": this.editData.sum_order,
                "cashback": this.editData.cashback ? this.editData.cashback : null,
                "manager": this.editData.manager_id,
                "account_buyer": this.editData.account_buyer,
                "type_redemption": this.editData.type_redemption,
                "date_order": new Date(this.editData.date_order),
                "date_buy": this.editData.date_buy ? new Date(this.editData.date_buy): null,
                'feedback': this.editData.feedback_uid ? this.editData.feedback_uid : null,
                "report_sale": this.editData.report,
                "phone": this.editData.phone ? this.editData.phone.replace(/[^\d]/g, ''): null,
                "address_pvz": this.editData.address_pvz,
                "feedback_author": this.editData.feedback_author
            }
            try{
                const response = await Redemption.updateRedemption(redemption_id, data)
                this.$emit('close', "success")
            } catch (err){
                console.log(err)
            }   
        },
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.data.list_manager = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
        transformDate(date){
            let tmp = new Date(date)
            return tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate()
        },
        autoComplete(){
            let manager = this.data.list_manager.find(obj => obj['username'] === this.item.manager)
            let type_redemption = this.data.type_redemption_choise.find(obj => obj['name'] === this.item.type_redemption).value
            this.editData.id = this.item.id
            this.editData.product_id = this.item.product_id
            this.editData.variations = this.item.variations ? this.item.variations.id : ""
            this.editData.quantity_product = this.item.quantity_product
            this.editData.sum_order = this.item.sum_order
            this.editData.manager_id = manager ? manager.id : ""
            this.editData.account_buyer = this.item.account_buyer
            this.editData.address_pvz = this.item.address_pvz
            this.editData.type_redemption = type_redemption
            this.editData.date_order = this.item.date_order ? this.transformDate(this.item.date_order) : ""
            this.editData.cashback = this.item.cashback
            this.editData.feedback_author = this.item.feedback_author
            this.editData.date_buy = this.item.date_buy ? this.transformDate(this.item.date_buy) : ""
            this.editData.report = []
            if (this.item.report_sale)
                this.item.report_sale.forEach(item => {
                    this.editData.report.push(item.id)
                })
            this.data.list_filtered_report = this.item.report_sale
            this.editData.feedback_uid = this.item.feedback ? this.item.feedback.id : null
            if (this.item.feedback.id && this.item.feedback.text)
                this.data.list_filtered_feedback.push({"uid": this.item.feedback.id, "text": this.item.feedback.text})
            this.editData.phone = this.item.phone ? "+7 (" + this.item.phone.substring(1, 4) + ") " + this.item.phone.substring(4, 7) + "-" + this.item.phone.substring(7,9) + "-" + this.item.phone.substring(9,11) : null
            this.getFilteredFeedback()
        },
        async getFilteredFeedback(val){
            let page_size = 15
            try {
                let query = {
                    'id': this.editData.product_id,
                    'text': val,
                    'date': this.editData.date_order ? this.editData.date_order : "",
                    'page_size': page_size 
                }
                const response = await Redemption.fetchRedemptionFeedback(query)
                this.data.list_filtered_feedback = response.data.results
            } catch (err) {
                console.log(err.data)
            }
            this.loading.loading_feedback_autocomplete = false
        },
        async getFilteredReport(val){
            this.loading.loading_report_autocomplete = true
            try {
                const response = await Redemption.oneAutoCompleteReport(
                    {
                        'id': this.editData.id,
                    }
                )
                if (response.data){
                    this.data.list_filtered_report = []
                    let data = response.data.result
                    this.data.list_filtered_report = data
                    this.editData.report = []
                    data.forEach(item => {
                        this.editData.report.push(item.id)
                    })
                }
                
            } catch (err) {
                console.log(err.data)
            }
            this.loading.loading_report_autocomplete = false
        },
    },
    created: function () {
        this.debouncedGetFilteredFeedback = _.debounce(this.getFilteredFeedback, 1000)
    }, 
    async mounted(){
        await this.getManagers()
        this.autoComplete()
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
	<div class="py-10">
		<AdvertisementFormCreate/>
	</div>
</template>

<script>
import AdvertisementFormCreate from '@/components/AdvertisementNew/AdvertisementFormCreate'

export default {
	name: 'AdvertisementCreateComponent',
	components: {
		AdvertisementFormCreate
	},
}
</script>
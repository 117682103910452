<template>
    <v-expansion-panels v-model="show_panels">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div class="title">Фильтры</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-menu 
                                v-model="datePicker.datepicker_date_order_after" 
                                :close-on-content-click="false"  
                                prepend-icon="mdi-calendar"
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px"
                                ref="datePicker.datepicker_date_order_after"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filter_data.date_order_after = null"
                                        v-model="dateOrderFormattedAfter"
                                        label="Дата заказа (начало)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filter_data.date_order_after" 
                                    @input="datePicker.datepicker_date_order_after = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-menu 
                                v-model="datePicker.datepicker_date_order_before" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datePicker.datepicker_date_order_before"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filter_data.date_order_before = null"
                                        v-model="dateOrderFormattedBefore"
                                        label="Дата заказа (конец)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filter_data.date_order_before" 
                                    @input="datePicker.datepicker_date_order_before = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2">
							<v-text-field
                                v-model="filter_data.mid"
                                clearable
                                outlined
                                dense
                                label="Артикул"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="filter_data.buyer"
                                clearable
                                outlined
                                dense
                                label="ФИО покупателя"
                                hide-details
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="3">
                            <v-select
                                v-model="filter_data.service"
                                :items="choices.service_redemption_choices"
                                item-text="name"
                                item-value="value"
                                label="Сервис"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-menu 
                                v-model="datePicker.datepicker_date_buy_after" 
                                :close-on-content-click="false" 
                                prepend-icon="mdi-calendar"
                                transition="scale-transition" 
                                offset-y="offset-y" 
                                min-width="290px"
                                nudge-right="40" 
                                ref="datePicker.datepicker_date_buy_after"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filter_data.date_buy_after = null"
                                        v-model="dateBuyFormattedAfter"
                                        label="Дата покупки (начало)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filter_data.date_buy_after"
                                    @input="datePicker.datepicker_date_buy_after= false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-menu 
                                v-model="datePicker.datepicker_date_buy_before" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y" 
                                nudge-right="40" 
                                min-width="290px"
                                ref="datePicker.datepicker_date_buy_before"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filter_data.date_buy_before = null"
                                        v-model="dateBuyFormattedBefore"
                                        label="Дата покупки (конец)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"    
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filter_data.date_buy_before" 
                                    @input="datePicker.datepicker_date_buy_before = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                v-model="filter_data.manager"
                                :items="choices.list_manager"
                                item-text="username"
                                item-value="id"
                                label="Менеджер"
                                outlined
                                dense
                                hide-details
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                v-model="filter_data.type_redemption"
                                :items="choices.type_redemption_choices"
                                item-text="name"
                                item-value="value"
                                label="Тип выкупа"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                            ></v-select>
                        </v-col>
						<v-col cols="12" sm="2">
                            <v-text-field
                                v-model="filter_data.order_number"
                                clearable
                                outlined
                                dense
                                label="Номер заказа"
                                hide-details
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2">
                            <v-select
                                v-model="filter_data.product_status"
                                :items="choices.product_status_redemption_choices"
                                item-text="name"
                                item-value="value"
                                label="Статус товара"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="filter_data.id"
                                clearable
                                outlined
                                dense
                                label="ID"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="filter_data.feedback_text"
                                clearable
                                outlined
                                dense
                                label="Текст отзыва"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="filter_data.phone"
                                clearable
                                outlined
                                dense
                                label="Телефон"
                                type="number"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-radio-group
                                v-model="filter_data.is_feedback"
                                hide-details
                                row
                                class="mt-1"
                            >
                                <v-radio
                                    label="Все"
                                    :value=0
                                ></v-radio>
                                <v-radio
                                    label="С отзывом"
                                    :value=1
                                ></v-radio>
                                <v-radio
                                    label="Без отзыва"
                                    :value=-1
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-radio-group
                                v-model="filter_data.is_report"
                                row
                                hide-details
                                class="mt-1"
                            >
                                <v-radio
                                    label="Все"
                                    :value=0
                                ></v-radio>
                                <v-radio
                                    label="С документом продажи"
                                    :value=1
                                ></v-radio>
                                <v-radio
                                    label="Без документа продажи"
                                    :value=-1
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    
                    <v-row class="justify-space-between pb-5 px-3 mt-4">
                        <v-btn
                            depressed
                            color="purple darken-2"
                            dark
                            @click="fetchDataWithFilters()"
                        >Применить</v-btn>
                        <v-btn
                            depressed
                            color="purple darken-2"
                            dark
                            @click="cleanFilters()"
                        >Сбросить фильтры</v-btn>
                    </v-row>
                </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

import _ from 'lodash'
import { Redemption } from '@/api/redemption'

export default {
    name: "OzonRedemptionFilters",
    data() {
        return {
            show_panels: 0,
            filter_data: {
                date_order_after: "",
                date_order_before: "",
                date_buy_after: "",
                date_buy_before: "",
                mid: "",
                buyer: "",
                manager: "",
                type_redemption: "",
                feedback_text: "",
                is_feedback: 0,
                is_report: 0,
                phone: "",
                id: "",
				service: '',
				product_status: '',
				order_number: ''
            },
            datePicker: {
                datepicker_date_order_after: "",
                datepicker_date_order_before: "",
                datepicker_date_buy_after: "",
                datepicker_date_buy_before: "",
            },
			choices: {
				type_redemption_choices: [],
				product_status_redemption_choices: [],
				service_redemption_choices: [],
				list_manager: []
			},
        }
    },
    computed: {
        dateOrderFormattedAfter () {
            return this.filter_data.date_order_after ? this.$options.filters.formatDateToLocaleNoTime(this.filter_data.date_order_after) : null
        },
        dateOrderFormattedBefore () {
            return this.filter_data.date_order_before ? this.$options.filters.formatDateToLocaleNoTime(this.filter_data.date_order_before) : null
        },
        dateBuyFormattedAfter () {
            return this.filter_data.date_buy_after ? this.$options.filters.formatDateToLocaleNoTime(this.filter_data.date_buy_after) : null
        },
        dateBuyFormattedBefore () {
            return this.filter_data.date_buy_before ? this.$options.filters.formatDateToLocaleNoTime(this.filter_data.date_buy_before) : null
        }
    },
    methods: {
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.choices.list_manager = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
        async fetchDataWithFilters (){
            this.$emit('filtersData', this.transfromFiltersForQuery())
            
        },
        transfromFiltersForQuery(){
            let query = {}
            for (const f in this.filter_data) {
                if (Array.isArray(this.filter_data[f])) {
                    query[f] = this.filter_data[f].join(',')
                } else {
                    query[f] = this.filter_data[f]
                }
            }
            return query
        },
        cleanFilters(){
            this.$emit('filtersData', {})
            this.filter_data.date_order_after = ""
            this.filter_data.date_order_before = ""
            this.filter_data.date_buy_after = ""
            this.filter_data.date_buy_before = ""
            this.filter_data.nmid = ""
            this.filter_data.buyer = ""
            this.filter_data.manager = ""
            this.filter_data.type_redemption = ""
            this.filter_data.feedback_text = ""
            this.filter_data.is_feedback = 0
            this.filter_data.is_report = 0
            this.filter_data.phone = ""
            this.filter_data.id = ""
			this.filter_data.service = ''
			this.filter_data.product_status = ''
			this.filter_data.order_number = ''

        },
		async getTypeRedemtion(){
            try {
                const responce = await Redemption.fetchBaseTypeRedemption()
                this.choices.type_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
		async getProductStatusRedemption(){
            try {
                const responce = await Redemption.fetchBaseProductStatusRedemption()
                this.choices.product_status_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
		async getServiceRedemption(){
            try {
                const responce = await Redemption.fetchBaseServiceRedemption()
                this.choices.service_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
    },
    mounted(){
        this.getManagers()
		this.getTypeRedemtion()
		this.getProductStatusRedemption()
		this.getServiceRedemption()
    }
}
</script>

<style lang="scss" scoped>

</style>
<template>
	<v-row>
		<v-col sm="12" lg="12" class="px-10 mt-5" ref="anchor_scroll">
			<div class="title">Фильтры</div>
			<v-row class="mt-5">
				<v-col>
					<v-menu 
						v-model="datePicker.datepicker_date_receipt_after" 
						:close-on-content-click="false"  
						prepend-icon="mdi-calendar"
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px"
						ref="datePicker.datepicker_date_receipt_after"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filterData.date_receipt_after = null"
								v-model="dateReceiptFormattedAfter"
								label="Дата забора (от)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								v-on="on"
								dense
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filterData.date_receipt_after" 
							@input="datePicker.datepicker_date_receipt_after = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-menu 
						v-model="datePicker.datepicker_date_receipt_before" 
						:close-on-content-click="false" 
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px" 
						ref="datePicker.datepicker_date_receipt_before"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filterData.date_receipt_before = null"
								v-model="dateReceiptFormattedBefore"
								label="Дата забора (до)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								dense
								v-on="on"
							>  
							</v-text-field>
						</template>
						<v-date-picker 
							v-model="filterData.date_receipt_before" 
							@input="datePicker.datepicker_date_receipt_before = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.address_pvz"
						clearable
						outlined
						dense
						label="Адрес ПВЗ"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.fio_profile"
						clearable
						outlined
						dense
						label="ФИО профиля"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.fio_specialist"
						clearable
						outlined
						dense
						label="ФИО специалиста"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.nmid"
						clearable
						outlined
						dense
						label="Артикул"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.code_1c"
						clearable
						outlined
						dense
						label="Артикул 1С"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filterData.courier"
						clearable
						outlined
						dense
						label="Курьер"
						hide-details
					></v-text-field>
				</v-col>
				<v-col>
                    <v-btn
						@click="applyFilter()"
						depressed
						color="purple darken-2"
						class="mb-3"
						dark
                    >Применить</v-btn>
                </v-col>
			</v-row>
		</v-col>
		<v-col sm="12" lg="12" class="mt-5">
            <v-data-table
                :headers="table.headers"
                :items="table.data"
                class="elevation-0 mt-0 vertical-border small-font"
                :loading="table.loading"
                :options.sync="options"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 15, 25, 50]
                }"
                :server-items-length="pagination.totalItems"
            >
				<template v-slot:top>
					<v-toolbar flat class="mt-5">
						<v-row class="justify-space-between" style="padding:0 24px 24px 24px">
							<v-btn
								color="primary"
								dark
								class="mb-2"
								:loading="loading_export"
								@click="exportExcel"
							>Экспорт в excel</v-btn>
							
							<v-btn
								color="primary"
								dark
								@click="loadRedemptionReception"

							>Обновление списка на получение</v-btn>
						</v-row>
					</v-toolbar>
				</template>
				<template v-slot:item.date_receipt="{ item }">
					<div>{{item.date_receipt | formatDateToLocaleNoTime}}</div>
				</template>
				<template v-slot:item.art_1c="{ item }">
					<div>{{item.catalog_item.code1C_code}}</div>
				</template>
				<template v-slot:item.name_1c="{ item }">
					<div>{{item.catalog_item.code1C_name}}</div>
				</template>
			</v-data-table>
        </v-col>
	</v-row>
</template>

<script>
import { Redemption } from '@/api/redemption'

export default {
    name: "RedemptionReceiption",
    data() {
        return {
			loading_export: false,
			first_init: false,
            filterData: {
                date_receipt_after: "",
                date_receipt_before: "",
				address_pvz: "",
				fio_profile: "",
				fio_specialist: "",
				nmid: "",
				code_1c: "",
				courier: ""
            },
            datePicker: {
                datepicker_date_receipt_after: "",
                datepicker_date_receipt_before: "",
            },
			options: {},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			table: {
				loading: false,
				data: [],
				headers: [
					{ text: 'id', value: 'id', sortable: false, width: '1%' },
					{ text: 'Курьер', value: 'courier', sortable: false, width: '1%' },
					{ text: 'Адресс ПВЗ', value: 'address_pvz', sortable: false, width: '1%' },
					{ text: 'Дата забора', value: 'date_receipt', sortable: true, width: '1%' },
					{ text: 'Артикул ВБ', value: 'nmid', sortable: false, width: '1%' },
					{ text: 'Размер товара', value: 'size', sortable: false, width: '1%' },
					{ text: 'Артикул 1С', value: 'art_1c', sortable: false, width: '1%' },
					{ text: 'Наименование 1С', value: 'name_1c', sortable: false, width: '1%' },
					{ text: 'Кол-во', value: 'quantity', sortable: false, width: '1%' },
					{ text: 'ФИО профиля', value: 'full_name_profile', sortable: false, width: '1%' },
					{ text: 'Телефон', value: 'phone', sortable: false, width: '1%' },
					{ text: 'Статус заказа', value: 'status_order', sortable: false, width: '1%' },
					{ text: 'Код доставки', value: 'receipt_code', sortable: false, width: '1%' },
					{ text: 'ФИО специалиста', value: 'full_name_specialist', sortable: false, width: '1%' },
					{ text: 'Цена продажи ', value: 'price', sortable: false, width: '1%' },
					{ text: 'Источник ', value: 'source', sortable: false, width: '1%' },
					{ text: 'Комментарий', value: 'comment', sortable: false, width: '1%' },
					{ text: 'Ссылка на QR', value: 'url_qr', sortable: false, width: '1%' },
				]
			}
        }
    },
    computed: {
        dateReceiptFormattedAfter () {
            return this.filterData.date_receipt_after ? this.$options.filters.formatDateToLocaleNoTime(this.filterData.date_receipt_after) : null
        },
        dateReceiptFormattedBefore () {
            return this.filterData.date_receipt_before ? this.$options.filters.formatDateToLocaleNoTime(this.filterData.date_receipt_before) : null
        },
		transfromFiltersForQuery () {
			let query = {}
            for (const f in this.filterData) {
                if (Array.isArray(this.filterData[f])) {
                    query[f] = this.filterData[f].join(',')
                } else {
                    query[f] = this.filterData[f]
                }
                if (this.filterData[f] == false) {
                    query[f] = null
                }
            }
            return query
		},
    },
	watch: {
		'options': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.fetchRedemptionReception()
			}
		},
	},
	methods: {
		async applyFilter(){
            this.options.page = 1
            await this.fetchRedemptionReception()
        },
		async fetchRedemptionReception(){
			this.table.loading = true
			this.$vuetify.goTo(this.$refs.anchor_scroll, {
				duration: 500,
				offset: 150,
				easing: 'linear',
			})
			const { page, itemsPerPage, sortBy, sortDesc } = this.options
			let pageNumber = page - 1
			try {
				const response = await Redemption.fetchRedemptionReception(
					{
						...this.transfromFiltersForQuery,
						...{
							'offset': itemsPerPage*pageNumber,
							'limit': itemsPerPage,
							'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
						}
					}
				)
				this.table.data = response.data.results
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.log(err.data)
			}
			this.table.loading = false
		},
		async exportExcel(){
            try {
				this.loading_export = true
                const response = await Redemption.exportRedemptionReception({
                    ...this.transfromFiltersForQuery,
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'redemption_reception.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export = false
        },
		async loadRedemptionReception(){
            return await Redemption.loadRedemptionReception()
        },
	},
	async mounted(){
		document.title = `Выкупы(список на получение) | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Выкупы(список на получение)`)
		await this.fetchRedemptionReception()
		this.first_init = true
	}
}
</script>
<template>
    <v-container fluid>
		<v-card>
			<v-card-title>
				Акции WB
				<v-switch 
					v-model="is_active_promotion" inset class="ml-5" hide-details :label="`Активные акции`"
				></v-switch>
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="table.headers"
					:items="table.data"
					class="elevation-0 vertical-border small-font"
					:loading="table.loading"
					:options.sync="table.options"
					:items-per-page="15"
					:footer-props="{'items-per-page-options': [5, 15, 25, 50, -1]}"
					:server-items-length="table.pagination.totalItems"
				>
					<template v-slot:item.is_entered_commission="{ item }">
						<v-text-field
							v-model="item.is_entered_commission" clearable outlined dense
							type='number' hide-details @change="update_promotion(item, {'is_entered_commission': item.is_entered_commission})"
						></v-text-field>
					</template>
					<template v-slot:item.min_commission="{ item }">
						<v-text-field
							v-model="item.min_commission" clearable outlined dense
							type='number' hide-details @change="update_promotion(item, {'min_commission': item.min_commission})"
						></v-text-field>
					</template>
					<template v-slot:item.is_entered_spp="{ item }">
						<v-text-field
							v-model="item.is_entered_spp" clearable outlined dense
							type='number' hide-details @change="update_promotion(item, {'is_entered_spp': item.is_entered_spp})"
						></v-text-field>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';

	export default {
		name: 'ProductPromotionWB',
		data() {
			return {
				is_active_promotion: false,
				table:{
					first_init: false,
					loading: false,
					data: [],
					headers: [
						{ text: 'Название', value: 'name', sortable: false, width: '1%' },
						{ text: 'Заголовок', value: 'title', sortable: false, width: '2%' },
						{ text: 'Описание', value: 'desc', sortable: false , width: '10%'},
						{ text: 'Дата начала', value: 'date_from', sortable: false , width: '1%'},
						{ text: 'Дата окончания', value: 'date_to', sortable: false , width: '1%'},
						{ text: 'Снижение комиссии', value: 'is_entered_commission', sortable: false , width: '1%'},
						{ text: 'Минимальная комиссия', value: 'min_commission', sortable: false , width: '1%'},
						{ text: 'Снижение СПП', value: 'is_entered_spp', sortable: false, width: '1%' },
					],
					options: {},
					pagination: {
						next: null,
						totalItems: 0,
						numberOfPages: 0,
						currentPage: 0
					},
				}
			};
		},
		watch:{
			'is_active_promotion': async function (newVal, oldVal) {
				if (oldVal != newVal && this.table.first_init)
					await this.fetch_promotions()
			},
			'table.options.sortDesc': async function (newVal, oldVal) {
				if (oldVal && this.table.first_init) {
					await this.fetch_promotions()
				}
			},
		},
		methods: {
			async fetch_promotions(){
				this.table.loading = true
				const { page, itemsPerPage, sortDesc, sortBy} = this.table.options
				try {
					let pageNumber = page - 1
					if (this.table.data.length != 0)
						window.scrollTo(0,0);
					let data = {
						'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
					if (this.is_active_promotion)
						data['is_active'] = true
					let response = await Products.fetchListWbPromotions(data)	
					this.table.data = response.data.results
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.error(err.response)
				}
				this.table.loading = false
				if (!this.table.first_init)
					this.table.first_init = true
			},
			async update_promotion(item, data){
				try {Products.updateWbPromotions(item.id, data)
					this.$toasted.show(`Успешно изменено`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
					});
				} catch(err){
					this.$toasted.show(`Ошибка изменения данных`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
					});
				}
			},
		},
		async mounted() {
			document.title = `Товары WB(Акции) | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Товары WB(Акции)`);
			await this.fetch_promotions()
		},
		
	};
</script>

<style scoped>
	.green_text{
		color: green;
	}
	.red_text{
		color: rgb(157, 17, 17);
	}
	.orange_text{
		color: rgb(215, 143, 9);
	}
</style>
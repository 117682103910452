<template>
    <v-container fluid dark>
		<v-expansion-panels v-model="panels.panels_filters">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Фильтры</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementListFilterComponent @applyFilters="apply_filters"/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-expansion-panels v-model="panels.panels_list_advertisement" class="mt-5">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Список рекламных кампаний</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementListTableComponent :filters="filters"/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-expansion-panels v-model="panels.panels_additional_func" class="mt-5">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Дополнительный функционал</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementListAdditionalFunctionalComponent/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<transition name="fade">
			<v-btn
				color="green" fab small dark bottom fixed right class="bottom_btn_up" 
				v-if="show_btn_scroll" @click="to_top"
			><v-icon>mdi-chevron-up</v-icon></v-btn>
		</transition>
	</v-container>
</template>

<script>
import AdvertisementListFilterComponent from '@/components/Advertisement/AdvertisementList/AdvertisementListFilterComponent'
import AdvertisementListTableComponent from '@/components/Advertisement/AdvertisementList/AdvertisementListTableComponent'
import AdvertisementListAdditionalFunctionalComponent from '@/components/Advertisement/AdvertisementList/AdvertisementListAdditionalFunctionalComponent'

export default {
    name: 'AdvertisementListView',
	components: {
		AdvertisementListFilterComponent, 
		AdvertisementListTableComponent,
		AdvertisementListAdditionalFunctionalComponent
	},
	data(){
		return {
			filters: {},
			panels: {
				panels_filters: 0,
				panels_list_advertisement: 0,
				panels_additional_func: 1
			},
			show_btn_scroll: false
		}
	},
	computed: {
		is_show_btn_scroll(){
			var height = document.body.scrollHeight;
			return height
		}
	},
	methods: {
		to_top(){
			window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		},
		check_scroll_position() {
			this.show_btn_scroll = window.pageYOffset > 200
		},
		apply_filters(query){
			this.filters = query
		}
	},
	async mounted(){
		document.title = `Список рекламных кампаний | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Список рекламных кампаний`)
		window.addEventListener('scroll', this.check_scroll_position);
	}
}
</script>

<style lang="scss" scoped>
	.expand_title{
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: 0.0125em;
		line-height: 2rem;
		word-break: break-all;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.bottom_btn_up{
		z-index: 50;
		margin: 0 0 16px 16px;
	}
</style>
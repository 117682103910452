<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="priceLoading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <v-data-table
                dense
                :headers="headers"
                :items="data_price"
                hide-default-footer
                disable-pagination
                class="elevation-0 vertical-border small-font"
            >
                <template v-slot:item.date_created="{ item }">
                    {{ item.date_created | onlyTime }}
                </template>
                <template v-slot:item.price_without_sale="{ item }">
                    <div v-if="item.price_without_sale">
                        {{ item.price_without_sale | toRUB }}
                    </div>
                    <div v-else> - </div>
                </template>
                <template v-slot:item.price_in_the_moment_with_base_sale="{ item }">
                    <div v-if="item.price_in_the_moment_with_base_sale">
                        {{ item.price_in_the_moment_with_base_sale | toRUB }}
                    </div>
                    <div v-else> - </div>
                </template>
                <template v-slot:item.price_in_the_moment="{ item }">
                    <div v-if="item.price_in_the_moment">
                        {{ item.price_in_the_moment | toRUB }}
                    </div>
                    <div v-else> - </div>
                </template>
                <template v-slot:item.percent_spp="{ item }">
                    <div v-if="item.percent_spp || item.spp_by_rub">
                        {{item.percent_spp ? item.percent_spp : 0}}% 
                        ({{ item.spp_by_rub ? item.spp_by_rub : 0 | toRUB }})
                    </div>
                    <div v-else> - </div>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>

<script>
    import { Products } from '@/api/products'

    export default {
        name: "PriceInTheMomentTable",
        props: {
            product_id: {
                type: String
            },
            date: {
                type: String
            }
        },
        data () {
            return {
                headers: [
                    { text: "Время", value: "date_created", sortable: false, width: "1%" },
                    { text: "Цена без скидок", value: "price_without_sale", sortable: false, width: "1%" },
                    { text: "Цена с базовой скидкой", value: "price_in_the_moment_with_base_sale", sortable: false, width: "1%" },
                    { text: "Цена с СПП", value: "price_in_the_moment", sortable: false, width: "1%" },
                    { text: "СПП % (руб)", value: "percent_spp", sortable: false, width: "1%" },
                ],
                data_price: null,
                priceLoading: true,

            }
        },
        methods: {
            async fetchPriceDay () {
                this.priceLoading = true
                try {
                    const res = await Products.fetchPriceDay(
                        this.product_id,
                        this.$options.filters.formatDateToQuery(this.date)
                    )
                    this.data_price = res.data
                } catch (err) {
                    console.log(err)
                }
                this.priceLoading = false
            }
        },
        async mounted () {
            await this.fetchPriceDay()
        }
    }
</script>
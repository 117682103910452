import router from "@/router";
import {Auth} from "@/api/auth";
import {HTTP} from "@/api/http";
import jwt_decode from "jwt-decode";

export default {
  state: () => ({
    jwt: localStorage.getItem('t'),
    user: JSON.parse(localStorage.getItem('user')),
    endpoints: {
      obtainJWT: '/auth/login/',
      refreshJWT: '/auth/token/refresh/'
    },
  }),
  mutations: {
    updateToken(state, newToken) {
      localStorage.setItem('t', newToken);
      state.jwt = newToken;
    },
    updateUser(state, userdata) {
      localStorage.setItem('user', JSON.stringify(userdata));
      state.user = userdata
    },
    removeToken(state) {
      localStorage.removeItem('t');
      state.jwt = null;
    },
    logout (state) {
      state.jwt = null
      localStorage.removeItem('t')
      localStorage.removeItem('user')
      router.push('/auth/login')
    },
  },
  actions: {
    async login({commit}, payload) {
      try {
        const response = await Auth.login(payload.username, payload.password)
        if (response.data.access) {
          this.commit('updateToken', response.data.access)
          this.commit('updateUser', response.data.user)
        }
        router.push('/')
      } catch (err) {
        throw Error(err)
      }
    },
    logout ({commit, dispatch}) {
      return new Promise(() => {
        delete HTTP.defaults.headers.common['Authorization']
        commit('logout')
      })
    },
    refreshToken () {
      const token = this.state.jwt
      try {
        const response = Auth.tokenRefresh(token)
        this.commit('updateToken', response.access)
      } catch (err) {
        console.log(err)
      }
    },
    inspectToken() {
      const token = this.state.auth.jwt;
      if (token) {
        const decoded = jwt_decode(token);
        const exp = decoded.exp
        const orig_iat = decoded.orig_iat
        console.log(decoded)
        // if (exp - (Date.now() / 1000) > 1800 && (Date.now() / 1000) - orig_iat < 628200) {
        //
        // } else if (exp - (Date.now() / 1000) < 1800) {
        //   console.log('refresh')
        //   router.push('auth/login')
        //   this.dispatch('refreshToken')
        // } else {
        //   console.log('auth-login blya')
        //
        // }
        if (exp - (Date.now() / 1000) < 1800) {
          router.push('/auth/login')
        }
      }
    }
  },
  getters: {
    userPK (state) {
      return state.user.id
    },
    userGroups (state) {
      return state.user.groups.map(item => {
        return item.name
      })
    },
    isAnalyticManager(state, getters){
      return getters.userGroups.includes('analytic_manager')
    },
    isCatalogManager(state, getters){
      return getters.userGroups.includes('catalog_manager')
    },
    isManager (state, getters) {
      return getters.userGroups.includes('manager')
    },
    isSuperManager (state, getters) {
      return getters.userGroups.includes('super_manager')
    },
    isSocialManager (state, getters) {
      return getters.userGroups.includes('social_manager')
    },
    isSupplManager (state, getters) {
      return getters.userGroups.includes('suppl')
    },
    isProductManager (state, getters) {
      return getters.userGroups.includes('product_manager')
    },
    isOzon (state, getters) {
      return getters.userGroups.includes('ozon')
    },
    isAdvertisementManager (state, getters) {
      return getters.userGroups.includes('advertisement_manager')
    },
    isRedemptionManager(state, getters) {
      return getters.userGroups.includes('redemption_manager')
    },
    isRedemptionUser(state, getters) {
      return getters.userGroups.includes('redemption_user')
    },
	isAdvertisementRoot(state, getters) {
		return getters.userGroups.includes('advertisement_root')
	},
    isPriceMonitoring(state, getters) {
      return getters.userGroups.includes('price_monitoring')
    }
  }
}

export default {
  state: () => ({
    pageTitle: ''
  }),
  mutations: {
    setPageTitle(state, title) {
      state.pageTitle = title
    }
  },
  actions: {

  },
  getters: {

  }
}


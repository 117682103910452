var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Планирование поставок(создание расчетов)")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","clearable":"","label":"Выбор аккаунта","items":_vm.choices.account_list,"item-text":"name","item-value":"id","hide-details":"","dense":""},on:{"change":_vm.fetch_products},model:{value:(_vm.filters.account),callback:function ($$v) {_vm.$set(_vm.filters, "account", $$v)},expression:"filters.account"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('v-menu',{ref:"datepicker_menu",attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Период","readonly":"readonly","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.dataRangeText),callback:function ($$v) {_vm.dataRangeText=$$v},expression:"dataRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu),callback:function ($$v) {_vm.datepicker_menu=$$v},expression:"datepicker_menu"}},[_c('v-date-picker',{attrs:{"range":"","first-day-of-week":1},model:{value:(_vm.filters.dates_range),callback:function ($$v) {_vm.$set(_vm.filters, "dates_range", $$v)},expression:"filters.dates_range"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"purple darken-2","dark":""}},on),[_vm._v("Выбрать фиксированный период")])]}}])},[_c('v-list',_vm._l(([7, 14, 30]),function(item,index){return _c('v-list-item',{key:index,staticClass:"list_item_period",on:{"click":function($event){return _vm.set_fixed_period(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item)+" дней")])],1)}),1)],1)],1),_c('v-col',[_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2","dark":"","loading":_vm.loading.create},on:{"click":function($event){return _vm.create_supply_planning(false)}}},[_vm._v("Спланировать поставку")]),_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2 mt-3","dark":"","loading":_vm.loading.create},on:{"click":function($event){return _vm.create_supply_planning(true)}}},[_vm._v("Спланировать поставку(ускоренно)")]),_c('p',{staticClass:"mt-3"},[_vm._v(" До нажатия, загрузите файл с неактивными товарами. Или выберите их в таблице ")])],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2","dark":"","loading":_vm.products.loading_xlsx},on:{"click":_vm.fetch_products_xlsx}},[_vm._v("Выгрузить список товаров")])],1),_c('v-col',[_c('div',{staticClass:"d-flex align-start justify-start"},[_c('v-file-input',{staticStyle:{"width":"250px"},attrs:{"label":"File input","outlined":"","dense":"","id":"products_xlsx","hide-details":""}})],1)]),_c('v-col',[_c('div',{staticClass:"ml-5 d-flex align-start justify-start"},[_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2","dark":"","loading":_vm.loading.save_inactive_product},on:{"click":_vm.save_inactive_product}},[_vm._v("Сохранить неакивные товары")])],1)]),_c('v-col',[_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":[
						{'value': 0, 'name': 'Без ограничения'},
						{'value': 7, 'name': '7 дней'}, 
						{'value': 14, 'name': '14 дней'}, 
						{'value': 30, 'name': '30 дней'} ],"label":"Ограничение оборачиваемости","clearable":"","outlined":"","dense":""},model:{value:(_vm.limit_turnover),callback:function ($$v) {_vm.limit_turnover=$$v},expression:"limit_turnover"}})],1),_c('v-col',[_c('div',{staticClass:"ml-5 d-flex align-start justify-start"},[_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2","dark":"","loading":_vm.loading.limit_turnover},on:{"click":_vm.save_limit_turnover}},[_vm._v("Сохранить оборачиваемость")])],1)])],1),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"small-font elevation-0 mt-5 vertical-border",attrs:{"headers":_vm.products.headers,"items":_vm.products.data,"item-key":"nmid","show-select":"","items-per-page":-1,"footer-props":{'items-per-page-options': [25, 50, 100, -1]},"loading":_vm.products.loading,"fixed-header":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
					var item = ref.item;
return [_c('img',{staticStyle:{"max-height":"60px"},attrs:{"src":item.image,"alt":""}})]}}]),model:{value:(_vm.products.selected_items),callback:function ($$v) {_vm.$set(_vm.products, "selected_items", $$v)},expression:"products.selected_items"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
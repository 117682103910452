import { HTTP } from './http'

export const SocialParser = {
    async ListSocialItem(query=null) {
        return HTTP.get('/social_parser/social_items/', {
            params: query
        })
    },
    async CreateSocialItem(form_data) {
        return HTTP.post('/social_parser/social_items/', form_data)
    },
    async SetDisableSocialItem(id) {
        return HTTP.post(`/social_parser/social_items/${id}/set_disable/`)
    },
    async TemplateImportSocialItem(){
        return HTTP.get('/social_parser/social_items/template_import_social_item/', {responseType:"blob"})
    },
    async ImportSocialItem(data){
        return HTTP.post('/social_parser/social_items/import_social_item/', data)
    },
    async ExportBaseSocialItem(query=null){
        return HTTP.get('/social_parser/social_items/base_export/', {responseType:"blob", params: query})
    },
    async ExportStatsSocialItem(query=null){
        return HTTP.get('/social_parser/social_items/history_export/', {responseType:"blob", params: query})
    },
    async CheckStatsSocialItem(id){
        return HTTP.post(`/social_parser/social_items/${id}/check_stats/`)
    },
    async DeleteStatsSocialItem(id){
        return HTTP.delete(`/social_parser/social_items/${id}/`)
    },
    async ListManagers(){
        return HTTP.get('/social_parser/social_items/list_managers/')
    }
}
import { render, staticRenderFns } from "./AdvertisementDetailSku.vue?vue&type=template&id=409a50bf&scoped=true&"
import script from "./AdvertisementDetailSku.vue?vue&type=script&lang=js&"
export * from "./AdvertisementDetailSku.vue?vue&type=script&lang=js&"
import style0 from "./AdvertisementDetailSku.vue?vue&type=style&index=0&id=409a50bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409a50bf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardText,VCardTitle,VContainer,VDataTable,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSheet,VSwitch})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[(_vm.priceLoading)?_c('v-skeleton-loader',{staticClass:"pa-4",attrs:{"type":"table-heading, table-row@5"}}):_c('div',[_c('v-data-table',{staticClass:"elevation-0 vertical-border small-font",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_price,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("onlyTime")(item.date))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.price_with_discount",fn:function(ref){
var item = ref.item;
return [(item.price_with_discount)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_with_discount))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [(item.discount)?_c('div',[_vm._v(" "+_vm._s(item.discount ? item.discount : 0)+"% ")]):_c('div',[_vm._v(" - ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
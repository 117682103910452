var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.charts_dialog)?_c('v-dialog',{attrs:{"max-width":"100%"},model:{value:(_vm.charts_dialog),callback:function ($$v) {_vm.charts_dialog=$$v},expression:"charts_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("График показов кампании "+_vm._s(_vm.charts_item))]),_c('v-card-text',{staticClass:"mt-5"},[_c('AdvertisementListChartsComponent',{attrs:{"campaign_id":_vm.charts_item}})],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 advertisement_list",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"color":"green","options":_vm.table.options,"items-per-page":50,"footer-props":{'items-per-page-options': [10, 15, 25, 50]},"server-items-length":_vm.table.pagination.totalItems,"height":"600px","fixed-header":true},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.banner",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center py-2"},_vm._l((_vm.get_image_list(item)),function(i,index){return _c('v-img',{key:index,attrs:{"src":i,"contain":"","max-height":"70px","max-width":"70px"}})}),1)]}},{key:"item.nmid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center flex-column align-center"},_vm._l((item.advertisement_products),function(el){return _c('div',{key:el.child_product.nmid},[(!el.is_fake)?_c('router-link',{attrs:{"target":"_blank","to":{name: 'product_card', params: { id: el.child_product.nmid }}}},[_vm._v(" "+_vm._s(el.child_product.code_1c)+" ")]):_vm._e()],1)}),0)]}},{key:"item.campaign_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.get_wb_link_to_adver(item)}},[_vm._v(_vm._s(item.campaign_id))])]}},{key:"item.campaign_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":{name: 'advertisement_detail', params: { campaign_id: item.campaign_id }}}},[_vm._v(" "+_vm._s(item.campaign_name)+" ")])]}},{key:"item.campaign_status",fn:function(ref){
var item = ref.item;
return [(item.campaign_status == 'ACTIVE')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.$vuetify.theme.dark ? '#4f7844' : '#c0f1b4',"dense":""}},[_vm._v(_vm._s(item.campaign_status_display))]):(item.campaign_status == 'PAUSE')?_c('v-chip',{staticClass:"ma-2",attrs:{"dense":"","color":_vm.$vuetify.theme.dark ? '#cb7029' : '#ffbd67'}},[_vm._v(_vm._s(item.campaign_status_display))]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.$vuetify.theme.dark ? 'blue-grey darken-1' : 'blue-grey lighten-3',"dense":""}},[_vm._v(_vm._s(item.campaign_status_display))])]}},{key:"item.cpm",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(item.cpm))+" ("+_vm._s(_vm._f("toRUB")(item.budget))+")")])]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
				'green-recommended-bet': item.profit >= 0, 'red-recommended-bet': item.profit < 0
			},staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("toRUB")(item.profit ? item.profit : 0)))]),_c('v-divider'),_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.drr ? item.drr.toFixed(2): 0)+"%")])]}},{key:"item.views",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"white-space":"nowrap"}},[_c('div',[_vm._v(_vm._s(item.views ? item.views : 0))]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.open_charts_dialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bell-curve-cumulative")])],1)],1)]}},{key:"item.action",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[(item.campaign_status == 'STOP')?_c('v-switch',{staticClass:"mt-0",attrs:{"color":_vm.$vuetify.theme.dark ? '#559843' : '#559843',"disabled":"","hide-details":""},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}}):(_vm.loading_change_status == item.campaign_id)?_c('v-switch',{staticClass:"mt-0",attrs:{"color":_vm.$vuetify.theme.dark ? '#559843' : '#559843',"loading":"warning","hide-details":""},on:{"change":function($event){return _vm.change_status(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}}):_c('v-switch',{staticClass:"mt-0",attrs:{"color":_vm.$vuetify.theme.dark ? '#559843' : '#559843',"hide-details":""},on:{"change":function($event){return _vm.change_status(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
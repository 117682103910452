import { HTTP } from './http'

export const PriceMonitoring = {
	async add (query=null) {
		return HTTP.post('/price_monitoring/', query)
	},
	async getAll (query=null) {
		return HTTP.get('/price_monitoring/', {params: query})
	},
	async deleteItem(id) {
		return HTTP.delete(`/price_monitoring/${id}/`, {pk: id})
	},
	async runCheck () {
		return HTTP.get('/price_monitoring/actions/run_check/')
	},
	async runCheckPartial (id) {
		return HTTP.post(`/price_monitoring/actions/run_check/${id}/`)
	},
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price))+" ")]}},{key:"item.price_with_base_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_with_base_sale))+" ")]}},{key:"item.price_with_spp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_with_spp))+" ")]}},{key:"item.base_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.base_sale ? item.base_sale : 0)+"% ")]}},{key:"item.spp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.spp ? item.spp : 0)+"% ")]}},{key:"item.orders_sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.orders_sum))+" ")]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.seller))]),_c('hr'),_c('dir',[_vm._v(_vm._s(item.brand))])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [(item.categories_count)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"dashed",attrs:{"href":"#"}},[_vm._v(_vm._s(item.categories_count))])])]}}],null,true)},[_c('span',_vm._l((item.categories),function(category,i){return _c('div',{key:i,staticClass:"small-text"},[_vm._v(" "+_vm._s(category)+" ")])}),0)]):_c('div',[_vm._v("0")])]}},{key:"item.stocks",fn:function(ref){
var item = ref.item;
return [(item.stocks)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"dashed",attrs:{"href":"#"}},[_vm._v(_vm._s(item.stocks))])])]}}],null,true)},[_c('span',_vm._l((item.stocks_by_warehouse),function(stock,i){return _c('div',{key:i,staticClass:"small-text"},[_vm._v(" "+_vm._s(stock.warehouse_name)+" - "+_vm._s(stock.stocks)+" ")])}),0)]):_c('div',[_vm._v("0")]),(item.is_fbs)?_c('v-chip',{staticClass:"mx-1 px-1 small-text",attrs:{"color":"red","outlined":"","x-small":""}},[_vm._v("fbs")]):_vm._e()]}},{key:"item.promotion",fn:function(ref){
var item = ref.item;
return [(item.promotion_parser)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'div',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-checkbox-marked-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.promotion_parser)+" ")])]):_c('div',[_vm._v("Нет акции")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import { HTTP } from './http'

export const OzonAdvertisement = {
	async getListAdvertisements (query=null) {
		return HTTP.get('/advertisement_new/ozon/advertisements/list/', {
			params: query
		})
	},
	async getDetailAdvertisements (id, query=null) {
		return HTTP.get(`/advertisement_new/ozon/advertisements/list/${id}/`, {
			params: query
		})
	},
	async getDetailOzonCampaign (campaign_id) {
		return HTTP.get(`/advertisement_new/ozon/advertisements/${campaign_id}/products/`)
	},
	async getOzonAdvertisementStats (campaign_id) {
		return HTTP.get(`/advertisement_new/ozon/advertisements/${campaign_id}/stats/`)
	},
	async getOzonAdvertisementProductStats (query=null) {
		return HTTP.get(`/advertisement_new/ozon/advertisements/products/stats/`, {params: query})
	},
	async getKeywordsData(adver_id, sku){
		return HTTP.get(`/advertisement_new/ozon/advertisements/${adver_id}/keywords/${sku}/`)
	},
	async changeGeneralBid(product_id, new_bid){
		return HTTP.post(`/advertisement_new/ozon/advertisements/${product_id}/change_general_bid/`, {'new_bid': new_bid})
	},
	async changeKeywordBud(product_id, new_bid, query_id){
		return HTTP.post(`/advertisement_new/ozon/advertisements/${product_id}/change_keyword_bid/${query_id}/`, {'new_bid': new_bid})
	},
	async fetchAdvertsByProduct(product_id){
		return HTTP.get('/advertisement_new/ozon/advertisements_by_product/', {params: {
			"mid": product_id
		}})
	},
	async getRecommendationProductBid(query=null){
		return HTTP.get(`/advertisement_new/ozon/advertisements/get_recommendation_product_bid/`, {params: query})
	},
	async getOzonProductAllStats(query=null){
		return HTTP.get(`/advertisement_new/ozon/advertisements/all_stats/`, {params: query})
	},
	async exportOzonProductAllStats(query=null) {
		return HTTP.get('/advertisement_new/ozon/advertisements/all_stats/export/', 
		{"params": query, "responseType": "blob"})
	},
	async setAdvertisementCampaign (campaign_id, json_data) {
		return HTTP.patch(`/advertisement_new/ozon/advertisements/list/${campaign_id}/`, json_data)
	},
}

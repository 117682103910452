<template>
	<div class="px-5">
		<v-chip-group
			v-model="current_variations"
			active-class="deep-purple--text text--accent-4"
			mandatory
		>
			<v-chip
					v-for="(size, index_size) in variations_all"
					:key="index_size"
					:value="size.id"
					class="different_variations__sizes"
			>{{ size.size ? size.size : '1 вариация' }}
			</v-chip>
		</v-chip-group>
		<v-text-field
			v-model="stocks_fbs"
			label="Введите остатов fbs"
			outlined
			type="number"
			dense
			clearable
			class="mt-5"
		></v-text-field>
		<div style="display:flex; justify-content:space-between;">
			<v-btn
				@click="deleteStock"
				depressed
				style="color: white;"
				color="error darken-2"
				class="mt-5"
				:disabled="(!current_variations)"
				:loading="loading_delete"
			>
				Сбросить
			</v-btn>
			<v-btn
				@click="updateStock"
				depressed
				style="color: white;"
				color="purple darken-2"
				class="mt-5"
				:disabled="(!current_variations || !stocks_fbs)"
				:loading="loading"
			>
				Обновить
			</v-btn>
		
		</div>
		
	</div>
</template>

<script>
	import { Products } from '@/api/products'
	export default {
		name: "ProductStockFBSUpdate",
		props: {
			product_id: {
				type: String
			},
			variations_all: {
				type: Array
			},
			current_stock: {
				type: Number
			},
			main_variation: {
				type: Number
			}
		},
		data () {
			return {
				current_variations: null,
				stocks_fbs: '',
				loading: false,
				loading_delete: false
			}
		},
		methods: {
			async deleteStock(){
				this.loading_delete = true
				try{
					const response = await Products.deleteStockFBS(this.current_variations)
					if (response.status == 200) {
						this.$toasted.show(`Остаток сброшен`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
						this.$emit('successUpdateStock')
					}
				}catch (err) {
					this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
				this.loading_delete = false
			},
			async updateStock(){
				this.loading = true
				try{
					const response = await Products.updateStockFBS(
						this.current_variations, this.stocks_fbs
					)
					if (response.status == 200) {
						this.$toasted.show(`Остаток обновлен`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
						this.$emit('successUpdateStock')
					}
				}catch (err) {
					this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
				this.loading = false
			}
		},
		mounted(){
			if ( this.stocks_fbs || this.stocks_fbs == 0)
				this.stocks_fbs = this.current_stock 
			if (this.variations_all.length > 1)
				this.current_variations = this.main_variation
		}
	}
</script>

<style scoped>

</style>

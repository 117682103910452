var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Документ продажи ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"datePicker.datepicker_sale_dt_after",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Дата покупки (начало)","readonly":"readonly","tabindex":"2","outlined":"","hide-details":"","dense":""},on:{"click:clear":_vm.clear_date_after},model:{value:(_vm.dateFormattedAfter),callback:function ($$v) {_vm.dateFormattedAfter=$$v},expression:"dateFormattedAfter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker.datepicker_sale_dt_after),callback:function ($$v) {_vm.$set(_vm.datePicker, "datepicker_sale_dt_after", $$v)},expression:"datePicker.datepicker_sale_dt_after"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":_vm.test,"input":function($event){_vm.datePicker.datepicker_sale_dt_after = false}},model:{value:(_vm.filterData.sale_dt_after),callback:function ($$v) {_vm.$set(_vm.filterData, "sale_dt_after", $$v)},expression:"filterData.sale_dt_after"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"datePicker.datepicker_sale_dt_before",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Дата покупки (конец)","readonly":"readonly","tabindex":"2","hide-details":"","outlined":"","dense":""},on:{"click:clear":_vm.clear_date_before},model:{value:(_vm.dateFormattedBefore),callback:function ($$v) {_vm.dateFormattedBefore=$$v},expression:"dateFormattedBefore"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker.datepicker_sale_dt_before),callback:function ($$v) {_vm.$set(_vm.datePicker, "datepicker_sale_dt_before", $$v)},expression:"datePicker.datepicker_sale_dt_before"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":_vm.test,"input":function($event){_vm.datePicker.datepicker_sale_dt_before = false}},model:{value:(_vm.filterData.sale_dt_before),callback:function ($$v) {_vm.$set(_vm.filterData, "sale_dt_before", $$v)},expression:"filterData.sale_dt_before"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Сумма","outlined":"","type":"number","tabindex":"4","hide-details":"","dense":"","clearable":""},on:{"change":_vm.test},model:{value:(_vm.filterData.sum_order),callback:function ($$v) {_vm.$set(_vm.filterData, "sum_order", $$v)},expression:"filterData.sum_order"}})],1)],1),_c('v-row',{staticStyle:{"padding":"12px 12px 24px 12px"}},[_c('p',[_vm._v("Дата заказа: "+_vm._s(_vm.showDate(_vm.items.date_order)))]),_c('p',{staticClass:"ml-5"},[_vm._v("Дата покупки: "+_vm._s(_vm.showDate(_vm.items.date_buy)))]),_c('p',{staticClass:"ml-5"},[_vm._v("Кол-во: "+_vm._s(_vm.items.quantity_product))]),_c('p',{staticClass:"ml-5"},[_vm._v("Цена, шт: "+_vm._s(_vm.items.sum_order / _vm.items.quantity_product))])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.headers,"items":_vm.data.list_report,"loading":_vm.tableLoading,"pageCount":_vm.pagination.numberOfPages,"options":_vm.options,"show-select":"","items-per-page":20,"footer-props":{
                    'items-per-page-options': [5, 10, 20, 50]
                },"server-items-length":_vm.pagination.totalItems},on:{"update:page":_vm.pageChanged,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.sale_dt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDate(item.sale_dt))+" ")]}}]),model:{value:(_vm.data.current_reports),callback:function ($$v) {_vm.$set(_vm.data, "current_reports", $$v)},expression:"data.current_reports"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close(1)}}},[_vm._v(" Добавить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { HTTP } from './http'

export const PriceManagement = {
	async fetchTemplateList (query=null) {
		return HTTP.get('/price_management/templates/', {params: query})
	},
	async createTemplate(data){
		return HTTP.post('/price_management/templates/', data)
	},
	async deleteTemplate(id){
		return HTTP.delete(`/price_management/templates/${id}/`)
	},
	async retrieveTemplate(id){
		return HTTP.get(`/price_management/templates/${id}/`)
	},
	async syncTable(id){
		return HTTP.post(`/price_management/templates/${id}/sync_table/`)
	},
	async addPromotionToTemplate(id, data){
		return HTTP.post(`/price_management/templates/${id}/add_promotion/`, data)
	},
	async accountsByTemplates(id){
		return HTTP.get(`/price_management/templates/${id}/accounts/`)
	},
	async promotionsByTemplates(id){
		return HTTP.get(`/price_management/templates/${id}/promotions/`)
	},
	async deletePromotions(id){
		return HTTP.delete(`/price_management/promotions/${id}/`)
	},
	async fetchPriceSalesByGoogleSheet(id){
		return HTTP.post(`/price_management/templates/${id}/prices/`)
	},
	async updatePrice(id, data){
		return HTTP.post(`/price_management/templates/${id}/update_price/`, {"data": data})
	}
}

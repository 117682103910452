<template>
	<div>
		<v-dialog v-model="charts_dialog" max-width="100%" v-if="charts_dialog">
			<v-card>
				<v-card-title>Статистика по фразе {{ charts_item }}</v-card-title>
				<v-card-text class="mt-5">
					<DialogAdvertisementStatsCluster 
						:campaign_id="campaign_id" 
						:keyword=charts_item
					/>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-data-table 
			ref="dataTable" :headers="headers" class="elevation-1" disable-pagination
			:items="filtered_keywords.slice(0, lazy_load_index)" 
			hide-default-footer height="500px" fixed-header v-model="selected_item" 
			show-select :item-class="item_classes" item-key="cluster"
			@toggle-select-all=selected_all
		>
			<template v-if="filtered_keywords.length > lazy_load_index" v-slot:[`body.append`]>
				<tr v-intersect.quiet="load_more">
					<td :colspan="headers.length" class="text-center">
						<v-skeleton-loader type="table-row" />
					</td>
				</tr>
			</template>
			<template v-slot:top>
				<v-row class="align-center mb-5">	
					<v-col>
						<v-radio-group v-model="filter_active" row hide-details class="mt-0">
							<v-radio label="Все" :value=0 :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
							<v-radio label="Активные" class="ml-15" :value=1 :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
							<v-radio label="Исключенные" :value=2 class="ml-15" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
				</v-radio-group>
					</v-col>
					<v-col>
						<v-text-field
							v-model="filter_name" clearable label="Фраза" outlined dense 
							:color="$vuetify.theme.dark ? '#208379' : '#20ddca'" hide-details
						></v-text-field>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.cluster="{ item }">
				<div class="d-flex align-center">
					{{item.cluster}}
					<v-btn icon class="ml-2" @click="open_dialog_charts(item)">
						<v-icon small>mdi-chart-bell-curve-cumulative</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:item.top_keyword="{ item }">
				<v-icon 
					title="Топовый запрос" v-if="item.cluster != data.top_keyword"
					@click="save_top_keyword(item.cluster)"
				>mdi-star-plus-outline</v-icon>
				<v-icon title="Топовый запрос" v-else @click="save_top_keyword('')">
					mdi-star-plus
				</v-icon>
			</template>
			<template v-slot:item.ctr="{ item }">
				{{item.ctr ? item.ctr.toFixed(2): 0}}
			</template>
			<template v-slot:item.cpc="{ item }">
				{{item.cpc ? item.cpc.toFixed(2): 0}}
			</template>
			<template v-slot:item.sum="{ item }">
				{{item.sum ? item.sum.toFixed(2): 0}}
			</template>
		</v-data-table>
		<v-data-table
			:headers="headers" :items="filtered_keywords" :loading="loading"
			class="elevation-0 mt-3 vertical-border phrase_list" hide-default-footer disable-pagination
			height="500px" fixed-header v-model="selected_item" show-select :item-class="item_classes"
			item-key="cluster" v-if=false
		>		
			<template v-slot:top>
				<v-row class="align-center mb-5">	
					<v-col>
						<v-radio-group v-model="filter_active" row hide-details class="mt-0">
							<v-radio label="Все" :value=0 :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
							<v-radio label="Активные" class="ml-15" :value=1 :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
							<v-radio label="Исключенные" :value=2 class="ml-15" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"></v-radio>
				</v-radio-group>
					</v-col>
					<v-col>
						<v-text-field
							v-model="filter_name" clearable label="Фраза" outlined dense 
							:color="$vuetify.theme.dark ? '#208379' : '#20ddca'" hide-details
						></v-text-field>
					</v-col>
				</v-row>
			</template>
			
			<template v-slot:item.cluster="{ item }">
				<div class="d-flex align-center">
					{{item.cluster}}
					<v-btn icon class="ml-2" @click="open_dialog_charts(item)">
						<v-icon small>mdi-chart-bell-curve-cumulative</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:item.top_keyword="{ item }">
				<v-icon 
					title="Топовый запрос" v-if="item.cluster != data.top_keyword"
					@click="save_top_keyword(item.cluster)"
				>mdi-star-plus-outline</v-icon>
				<v-icon title="Топовый запрос" v-else @click="save_top_keyword('')">
					mdi-star-plus
				</v-icon>
			</template>
			<template v-slot:item.ctr="{ item }">
				{{item.ctr ? item.ctr.toFixed(2): 0}}
			</template>
			<template v-slot:item.cpc="{ item }">
				{{item.cpc ? item.cpc.toFixed(2): 0}}
			</template>
			<template v-slot:item.sum="{ item }">
				{{item.sum ? item.sum.toFixed(2): 0}}
			</template>
		</v-data-table>
		<div class="d-flex align-center mt-5">
			<span>Выбрано: {{selected_item.length}}</span>
			<v-btn
				depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" :loading="loading_exclude"
				class="ml-5" dense :disabled="is_exclude_phrase_disable" @click="move_to_excluded"
			>Исключить</v-btn>
			<v-btn
				depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" :loading="loading_return"
				class="ml-5" dense :disabled="is_return_phrase_disable" @click="return_excluded"
			>Вернуть</v-btn>
		</div>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import DialogAdvertisementStatsCluster from '@/components/Advertisement/AdvertisementDetail/DialogAdvertisementStatsCluster'
export default {
	name: "AdvertisementDetailKeywordsComponent",
	props: ['campaign_id'],
	components: { DialogAdvertisementStatsCluster },
	data(){
		return {
			lazy_load_step: 20,
			lazy_load_index: 20,

			loading_return: false,
			loading_exclude: false,
			selected_item: [],
			filter_active: 0,
			charts_dialog: false,
			charts_item: null,
			filter_name: null,
			loading: false,
			data: null,
			headers: [
				{ text: 'Топ', sortable: false, value: 'top_keyword', width: '1%' },
				{ text: 'Кластер', sortable: false, value: 'cluster', width: '35%' },
				{ text: 'Частотность', sortable: true, value: 'frq', width: '10%' },
				{ text: 'Показы', sortable: true, value: 'views', width: '10%' },
				{ text: 'Клики', sortable: true, value: 'clicks', width: '10%' },
				{ text: 'CTR', sortable: true, value: 'ctr', width: '10%' },
				{ text: 'CPC', sortable: true, value: 'cpc', width: '10%' },
				{ text: 'Затраты', sortable: true, value: 'sum', width: '10%' },
			],
		}
	},
	computed: {
		is_return_phrase_disable(){
			let result = this.selected_item.filter(item => item.is_active)
			let result2 = this.selected_item.filter(item => !item.is_active)
			if (result.length > 0 || result2.length <= 0)
				return true
			return false
		},
		is_exclude_phrase_disable(){
			let result = this.selected_item.filter(item => item.is_active)
			let result2 = this.selected_item.filter(item => !item.is_active)
			if (result.length <= 0 || result2.length > 0)
				return true
			return false
		},
		filtered_keywords(){
			if (!this.data)
				return []
			let active_phrase = this.data.keywords
			let excluded_phrase = this.data.excluded
			if (!active_phrase || active_phrase.length <= 0){
				active_phrase = []
			}
			if (!excluded_phrase || excluded_phrase.length <= 0){
				excluded_phrase = []
			}
			let result = active_phrase.concat(excluded_phrase)
			if (this.filter_name)
				result = result.filter(item => item.cluster.includes(this.filter_name))
			if(this.filter_active == 1)
				result = result.filter(item => item.is_active)
			if(this.filter_active == 2)
				result = result.filter(item => !item.is_active)
			return result
		}
	},
	methods: {
		selected_all(){
			if (this.selected_item.length != this.filtered_keywords.length)
				this.selected_item = this.filtered_keywords
			else
				this.selected_item = []
		},
		load_more(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.lazy_load_index += this.lazy_load_step
				if (this.lazy_load_index > this.filtered_keywords.length)
					this.lazy_load_index = this.filtered_keywords.length
			}
		},
		item_classes(item){
			if(!item.is_active)
				return 'excluded_phrase'
			return ''
		},
		open_dialog_charts(item){
			this.charts_dialog = true
			this.charts_item = item.cluster
		},
		async save_top_keyword(keyword){
			await this.update_advertisement_search_phrase({'top_keyword': keyword}, false)
			await this.get_advertisement_search_phrase()
		},
		async get_advertisement_search_phrase(){
			this.loading = true
			this.data = {}
			try{
				const response = await Advertisement.fetchAdvertisementDetailSearchPhrase(this.campaign_id)
				this.data = response.data
			} catch(err){
				console.log(err)
			}
			this.loading = false
		},
		async update_advertisement_search_phrase(data, is_update=true){
			try{
				await Advertisement.updateSearchPhraseAdvertisementCampaign(this.data.id, data)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка изменения данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			if (is_update)
				await this.fill_advertisement_search_phrase()
		},
		async fill_advertisement_search_phrase(){
			try{
				await Advertisement.fillOneSearchPhraseAdvertisementCampaign(this.data.id)
			} catch(err){
				console.log(err)
			}
			await this.get_advertisement_search_phrase()
		},		
		async return_excluded(data){
			this.loading_return = true
			let keywords = this.data.excluded.filter(el => !this.selected_item.includes(el))
			try{
				await Advertisement.saveExcluded(this.data.id, keywords)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка изменения`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			await this.fill_advertisement_search_phrase()
			this.loading_return = true
		},
		async move_to_excluded(){
			this.loading_exclude = true
			let keywords = this.data.excluded
			keywords.push(...this.selected_item)
			try{
				await Advertisement.saveExcluded(this.data.id, keywords)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка изменения`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			await this.fill_advertisement_search_phrase()
			this.loading_exclude = false
		},
	},
	async mounted(){
		await this.get_advertisement_search_phrase()
	}
}
</script>

<style lang="scss">
	.theme--light .phrase_list .v-progress-linear__background{
        background-color: #20ddca !important;
		border-color: #20ddca !important;
    }
	.theme--dark .phrase_list .v-progress-linear__background{
        background-color: #208379 !important;
		border-color: #208379 !important;
    }
	.theme--light .phrase_list .v-progress-linear__indeterminate{
        background-color: #20ddca !important;
		border-color: #20ddca !important;
    }
	
	.theme--dark .phrase_list .v-progress-linear__indeterminate{
        background-color: #208379 !important;
		border-color: #208379 !important;
    }
	.excluded_phrase{
		background-color: #f1b7bd;
	}
	.theme--dark .excluded_phrase{
		background-color: #a0535b !important;
	}
</style>
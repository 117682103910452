<template>
	<div>
		<v-dialog
			v-model="change_product_group.dialog"
			v-if="change_product_group.dialog"
			max-width="500px"
		>
			<v-card>
				<v-card-title class="text-h5">
					Изменение группы товаров
				</v-card-title>
				<v-card-text>
					<v-autocomplete
                        v-model="change_product_group.product_group"
                        :items="product_groups_choices"
                        item-text="name"
                        item-value="value"
                        label="Группа товаров"
						clearable
                        outlined
                        hide-details
                        dense
                    ></v-autocomplete>
					<v-switch
						v-model="change_product_group.main_component"
						:label="'Основной компонент'"
						@change="changeMainComponent"
					></v-switch>
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="changeProductGroup"
						:loading="change_product_group.loading"
						depressed
						color="purple darken-2"
						class="mb-3"
						dark
					>Изменить</v-btn>
				</v-card-actions>
				
			</v-card>
		</v-dialog>
		<v-sheet class="pa-5">
			<v-row>
				<v-col><h1>Компоненты</h1></v-col>
				<v-col>
					<v-text-field
						v-model="filters.name" clearable outlined dense label="Название" hide-details="hide-details">
					</v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						v-model="filters.code" clearable outlined dense label="Артикул" hide-details="hide-details">
					</v-text-field>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:headers="table.headers"
				:items="table.data"
				item-key="id"
				:loading="table.loading"
				class="elevation-0 vertical-border small-font"
				:items-per-page="50"
				:footer-props="{
					'items-per-page-options': [15, 25, 50, 100, -1]
				}"
			>
				<template v-slot:item.name="{ item }">
					<router-link 
						target="_blank"
						:to="{ name: 'kits_component_card', params: { id: item.id }}"
					>{{ item.name }}</router-link>
				</template>
				<template v-slot:item.is_main_kit="{ item }">
					<v-icon
						v-if="item.main_kit"
						@click="openDialogChangeGroup(item)" 
						color="green"
					>mdi-checkbox-marked-circle</v-icon>
					<v-icon
						v-else
						@click="openDialogChangeGroup(item)" 
						color="red"
					>mdi-close-circle</v-icon>
				</template>
				<template v-slot:item.group="{ item }">
					<span 
						@click="openDialogChangeGroup(item)" 
						v-if="item.product_group"
						style="cursor: pointer;"
					>{{ item.product_group.group_name }}</span>
					<a 
						@click="openDialogChangeGroup(item)" 
						v-else
						style="font-size: 0.6rem;"
					>Установить группу</a>
				</template>
			</v-data-table>
		</v-sheet>
	</div>
</template>

<script>
	import { Catalog } from '@/api/catalog';
	import { General } from '@/api/general'

	export default {
		name: 'KitsComponents',
		data() {
			return {
				table: {
					headers: [
						{ text: "Компонент", value: "name", sortable: false, width: "1%", filter: value => this.filterSearchText(this.filters.name, value)},
						{ text: "Артикул", value: "code", sortable: false, width: "1%", filter: value => this.filterSearchText(this.filters.code, value)},
						{ text: "Кол-во компонентов", value: "kits", sortable: false, width: "1%" },
						{ text: "Основной компонент", value: "is_main_kit", sortable: false, width: "1%" },
						{ text: "Группа товаров", value: "group", sortable: false, width: "1%" },
					],
					data: [],
					loading: false
				},
				filters: {
					name: null,
					code: null
				},
				components: null,
				product_groups_choices: null,
				change_product_group: {
					edit_item: null,
					loading: false,
					dialog: false,
					product_group: null,
					main_component: false
				}
			};
		},
		async mounted() {
			document.title = "Компоненты | mp.lomex-group.ru";
			this.$store.commit('setPageTitle', `Компоненты`);
			await this.getComponentsList();
			await this.getProductGroups();
		},
		methods: {
			filterSearchText(item, value){
				if(!item){
					return true
				}
				let value_low = value.toLowerCase()
				let item_low = item.toLowerCase()
				return value_low.includes(item_low)
			},
			async getComponentsList() {
				this.table.loading = true
				try{
					const response = await Catalog.getComponentsList()
					this.table.data = response.data
				} catch(err){
					console.log(err)
				}
				this.table.loading = false
			},
			async getProductGroups(){
				try {
					const response = await General.fetchProductGroup()
					this.product_groups_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			openDialogChangeGroup(item){
				this.change_product_group.dialog = true
				this.change_product_group.edit_item = item
				this.change_product_group.product_group = item.product_group ? item.product_group.group_id : null
				this.change_product_group.main_component = item.main_kit
			},
			async changeProductGroup(){
				this.change_product_group.loading = true
				try{
                    const response = await Catalog.setGroupToKitComponent(
						this.change_product_group.edit_item.id, this.change_product_group.product_group
					)
                    this.change_product_group.edit_item = null
					this.change_product_group.dialog = null
					this.change_product_group.product_group = null
					await this.getComponentsList()
                } catch (err){
                    console.log(err)
                }
				this.change_product_group.loading = false   
			},
			async changeMainComponent(){
				try{
                    const response = await Catalog.setMainKitComponent(this.change_product_group.edit_item.id)
                } catch (err){
                    console.log(err)
                }
				this.change_product_group.edit_item = null
				this.change_product_group.dialog = null
				await this.getComponentsList()
				this.change_product_group.product_group = null
				this.change_product_group.main_component = null
			},
		},
	};
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"icon":"","title":"Сводка по типам выкупа","color":"grey","dark":""},on:{"click":function($event){return _vm.getRedemptionTypeStats()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-poll ")])],1)]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 darken-2"},[_vm._v(" Сводка по типам выкупа ")]),_c('div',{staticStyle:{"position":"relative"}},[(_vm.redemption_type_stats)?_c('v-data-table',{staticClass:"elevation-1 pb-5 mt-3",class:{'loading': _vm.redemption_type_stats_loading},attrs:{"dense":"","headers":_vm.redemptionTypeStatsHeader,"items":_vm.redemption_type_stats,"hide-default-footer":""}}):_vm._e()],1)],1)],1),_c('router-link',{staticClass:"d-flex ml-5 align-center",attrs:{"to":{name: 'selection_account_redemption'},"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-account-arrow-right")])],1)],1),_c('RedemptionFilters',{on:{"filtersData":_vm.filtersData}}),(_vm.initLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"block_redemption_product",staticClass:"elevation-0 mt-0 vertical-border small-font",attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.listRedemption,"loading":_vm.tableLoading,"pageCount":_vm.pagination.numberOfPages,"options":_vm.options,"items-per-page":20,"footer-props":{
                'items-per-page-options': [5, 10, 20, 50]
            },"server-items-length":_vm.pagination.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product.nmid",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product_card', params: { id: item.product.nmid }},"target":"_blank"}},[_vm._v(" "+_vm._s(item.product.nmid)+" ")])]}},{key:"item.date_order",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDate(item.date_order))+" ")]}},{key:"item.real_price",fn:function(ref){
            var item = ref.item;
return [(item.real_price)?_c('div',[_vm._v(" "+_vm._s(item.real_price.calculated_price ? item.real_price.calculated_price : "")+" ("+_vm._s(item.real_price.real_price)+") ")]):_c('div',[_vm._v("-")])]}},{key:"item.date_buy",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDate(item.date_buy))+" ")]}},{key:"item.phone",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.phoneMask(item.phone))+" "),_c('div',[(!item.sim_card_is_active)?_c('v-icon',{attrs:{"small":"","title":"Симкарта не работает"}},[_vm._v(" mdi-sim-off ")]):_vm._e(),(item.is_virtual_sim_card)?_c('v-icon',{attrs:{"small":"","title":"Виртуальная сим карта"}},[_vm._v(" mdi-virtual-reality ")]):_vm._e(),(item.is_refusal_to_receive)?_c('v-icon',{attrs:{"small":"","title":"Отказ от получения"}},[_vm._v(" mdi-cancel ")]):_vm._e()],1)]}},{key:"item.cashback",fn:function(ref){
            var item = ref.item;
return [(item.cashback)?_c('div',[_vm._v(" "+_vm._s(item.cashback)+"% ("+_vm._s(item.cashback_in_money)+"руб.) ")]):_vm._e()]}},{key:"item.product.art",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"art_product"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.product.art)+" "+_vm._s(_vm.transformProductSize(item.variations))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product.art)+" "+_vm._s(_vm.transformProductSize(item.variations)))])])]}},{key:"item.feedback.source",fn:function(ref){
            var item = ref.item;
return [(_vm.isRedemptionManager && item.feedback.source)?_c('div',[_c('v-select',{staticStyle:{"font-size":".8rem"},attrs:{"value":item.feedback.base_source,"items":_vm.sourceFeedback,"item-text":"name","item-value":"value","label":"Источник отзыва","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.updateSourceFeedback($event, item)}}})],1):_c('div',[_vm._v(_vm._s(item.feedback.source))])]}},{key:"item.feedback.text",fn:function(ref){
            var item = ref.item;
return [(item.feedback.text)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"art_product"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.feedback.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.feedback.text))])])],1):(item.type_redemption == 'Отзыв' && item.feedback_deleted_from_lk)?_c('div',[_c('v-icon',{attrs:{"small":"","title":"Отзыв удален"}},[_vm._v(" mdi-file-document-remove-outline ")]),_vm._v(" Отзыв удален с ЛК ")],1):_c('div',[(item.type_redemption == 'Отзыв')?_c('v-autocomplete',{staticStyle:{"font-size":".8rem"},attrs:{"loading":_vm.loading_feedback_autocomplete[item.id],"item-text":"text","item-value":"uid","search-input":_vm.search_feedback[item.id],"cache-items":"","label":"Отзыв","tabindex":"1","outlined":"","hide-details":"","dense":"","items":_vm.feedback_items[item.id]},on:{"update:searchInput":function($event){return _vm.$set(_vm.search_feedback, item.id, $event)},"update:search-input":function($event){return _vm.$set(_vm.search_feedback, item.id, $event)},"focus":function($event){return _vm.currentFeedback(item)},"change":function($event){return _vm.saveFeedback(item)}},model:{value:(_vm.feedback_model[item.id]),callback:function ($$v) {_vm.$set(_vm.feedback_model, item.id, $$v)},expression:"feedback_model[item.id]"}}):_vm._e()],1)]}},{key:"item.report_sale",fn:function(ref){
            var item = ref.item;
return _vm._l((item.report_sale),function(report,index){return _c('div',{key:index},[(report.type.toLowerCase() === 'продажа')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(report.realizationreport_id)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showDate(report.date))+" | "+_vm._s(report.sum))])])],1):_vm._e()])})}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-row',{staticClass:"justify-space-between",staticStyle:{"padding":"0 24px 24px 24px"}},[(_vm.isRedemptionUser || _vm.isRedemptionManager)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","loading":_vm.loading_upload}},'v-btn',attrs,false),on),[_vm._v(" Импорт из Excel ")])]}}],null,false,3750455639),model:{value:(_vm.dialog_upload),callback:function ($$v) {_vm.dialog_upload=$$v},expression:"dialog_upload"}},[_c('v-card',[_c('v-card-text',{staticClass:"pb-0"},[_c('v-file-input',{staticClass:"pt-5",attrs:{"label":"File input","outlined":"","dense":"","id":"upload_input","hide-details":""}}),_c('p',{staticClass:"text-download mt-3",on:{"click":_vm.downloadTempateExport}},[_vm._v("Скачать шаблон")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_upload = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.uploadFromExel}},[_vm._v(" Импортировать ")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportExcel}},[_vm._v(" Экспорт в excel ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportExcelWarehouse}},[_vm._v(" Экспорт для склада ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.loading_bundle},on:{"click":_vm.autoBundleReport}},[_vm._v(" Автосвязка документа продажи ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.loading_bundle_cashback},on:{"click":_vm.startBundleCashback}},[_vm._v(" Загрузка кешбэков ")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить выкуп ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.editedIndex === -1)?_c('RedemptionFormCreate',{on:{"close":_vm.close}}):_c('RedemptionFormUpdate',{attrs:{"item":_vm.item},on:{"close":_vm.close}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.isRedemptionManager || _vm.isRedemptionUser)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"Отзыв удален"},on:{"click":function($event){return _vm.changeFeedackDeleted(item)}}},[_vm._v(" mdi-file-document-remove-outline ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{attrs:{"small":"","title":"Симкарта не работает"},on:{"click":function($event){return _vm.changeSimCard(item)}}},[_vm._v(" mdi-sim-off ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{attrs:{"small":"","title":"Виртуальная сим карта"},on:{"click":function($event){return _vm.changeVirtualSimCard(item)}}},[_vm._v(" mdi-virtual-reality ")]):_vm._e(),(_vm.isRedemptionManager)?_c('v-icon',{attrs:{"small":"","title":"Отказ от получения"},on:{"click":function($event){return _vm.changeRefusalReceive(item)}}},[_vm._v(" mdi-cancel ")]):_vm._e()]}}])}),_c('div',{staticClass:"stat px-5 pt-5 pb-5"},[_c('div',{staticClass:"body-1"},[_vm._v("Общая сумма заказа - "+_vm._s(_vm.stats.sum_order))]),_c('div',{staticClass:"body-1"},[_vm._v("Общее количество товаров - "+_vm._s(_vm.stats.sum_quantity))]),_c('div',{staticClass:"body-1"},[_vm._v("Общее количество выкупов - "+_vm._s(_vm.stats.count_redemption))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <v-dialog v-model="dialog" max-width="100%" v-if="dialog">
			<v-card>
				<v-card-title>Информация по задаче</v-card-title>
				<v-card-text class="mt-5">{{ dialog_text }}</v-card-text>
			</v-card>
		</v-dialog>
        <v-data-table
            :headers="table.headers"
            :items="table.data"
            class="elevation-0 vertical-border small-font"
            :loading="table.loading"
            :options.sync="table.options"
            :items-per-page="25"
            :footer-props="{'items-per-page-options': [10, 15, 25, 50]}"
            :server-items-length="table.pagination.totalItems"
        >
            <template v-slot:item.detalization="{ item }">
                <v-btn icon class="ml-2" @click="open_dialog(item.error_notes)">
                    <v-icon small>mdi-chart-bell-curve-cumulative</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementStatsTableComponent',
    data() {
        return {
            dialog: false,
            dialog_text: null,
			table:{
				first_init: false,
				loading: false,
				data: [],
				headers: [
					{ text: 'Старт', value: 'date_start', sortable: true, width: '1%' },
					{ text: 'Завершение', value: 'date_end', sortable: true, width: '1%' },
					{ text: 'Название', value: 'name', sortable: false, width: '1%' },
					{ text: 'Аккаунт', value: 'account', sortable: false, width: '1%' },
					{ text: 'Время выполнения(м)', value: 'execution_time', sortable: false, width: '1%' },
                    { text: 'Кол-во ошибок', value: 'error_count', sortable: false, width: '1%'},
					{ text: 'Детализация', value: 'detalization', sortable: false, width: '1%' },
				],
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
			},
		}		
    },
	props: ['filters'],
    watch:{
		async filters(){
			this.table.options.page = 1
            await this.get_advertisement_task()
		},
        'table.options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && this.table.first_init) {
				await this.get_advertisement_task()
			}
        },
	},
    methods: {
        open_dialog(text){
            this.dialog = true
            this.dialog_text = text
        },
		async get_advertisement_task(){
            this.table.loading = true
            const { page, itemsPerPage, sortDesc, sortBy} = this.table.options
            try {
				let pageNumber = page - 1
				if (this.table.data.length != 0)
					window.scrollTo(0,0);
                const response = await Advertisement.fetchAdvertisementTaskHistory({
					...this.filters, 'offset': itemsPerPage * pageNumber, 
					'limit': itemsPerPage, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				})
                this.table.data = response.data.results
                this.table.pagination.totalItems = response.data.count
                this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
            } catch (err) {
                console.error(err.response)
            }
            this.table.loading = false
			if (!this.table.first_init)
				this.table.first_init = true
        },
    },
}
</script>
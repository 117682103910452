<template>
	<div v-if="loading" style="display: flex; justify-content:center;">
		<v-progress-circular :size="50" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" indeterminate></v-progress-circular>
	</div>
    <div v-else>
		<apexchart 
            type="line" height="500" :options="chartOptions" :series="seriesMain" ref="chart" @mounted="hidden_series"
		></apexchart>
        <div class="d-flex justify-center">
			<button @click="hidden_all">Скрыть все</button>
			<button @click="show_all" class="ml-3">Показать все</button>
		</div>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'AdvertisementDetailChartsComponent',
    props: ['campaign_id'],
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            data: [],
            loading: false,
            chartOptions: {
                theme: { mode: this.$vuetify.theme.dark ? 'dark': 'light'},
                chart: { height: 1000, width: '100%', type: 'line', zoom: { enabled: true }, toolbar: { show: true }, background: 'rgba(0, 0, 0, 0)' },
                stroke: { curve: 'smooth', width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3] },
                yaxis: [
                    { showAlways: true, seriesName: 'Просмотры', labels: { rotate:'-45', show: true, formatter: function(val) { return val }} }, 
                    { showAlways: true, seriesName: 'CTR(%)', opposite: true, title: { text: '%' }, labels: { rotate:'45', show: true, formatter: function(val) {return `${val}%`}} },
                    { seriesName: 'Добавления в корзину', show:false },
                    { seriesName: 'Клики', show:false },
                    { seriesName: 'CPC', show:false },
                    { seriesName: 'CR', show:false, opposite: true },
                    { seriesName: 'CPM', show:false },
                    { seriesName: 'CPO', show:false },
                    { seriesName: 'Расход', show:false },
                    { seriesName: 'Ср. позиция', show:false },
                    { seriesName: 'Заказы', show:false },
                    { seriesName: 'ДРР', show:false },
                    { seriesName: 'ВП, общая', show:false },
                    { seriesName: 'Доходность', show:false },
                    { seriesName: 'Уникальных пользователей', show:false },
                    { seriesName: 'Выручка', show:false },
                ],
                xaxis: { type: 'datetime' },
            },
        }
    },
    computed: {
        seriesMain() {
            return [
                { name: 'Просмотры', type: 'line', data: this.prepare_series('views'), color: "#fcb2c2" },
                { name: 'CTR(%)', type: 'line', data: this.prepare_series('ctr'), color: "#f6e8b1" },
                { name: 'Добавления в корзину', type: 'line', data: this.prepare_series('add_to_basket'), color: "#ceb3f6" },
                { name: 'Клики', type: 'line', data: this.prepare_series('clicks'), color: "#ffca9d" },
                { name: 'CPC', type: 'line', data: this.prepare_series('cpc'), color: "#054084" },
                { name: 'CR', type: 'line', data: this.prepare_series('cr'), color: "#83a56b" },
                { name: 'CPM', type: 'line', data: this.prepare_series('cpm'), color: "#bece63" },
                { name: 'CPO', type: 'line', data: this.prepare_series('cpo'), color: "#0d1731" },
                { name: 'Расход', type: 'line', data: this.prepare_series('expenses'), color: "#4b4e2a" },
                { name: 'Ср. позиция', type: 'line', data: this.prepare_series('auto_avg_position'), color: "#d31a5b" },
                { name: 'Заказы', type: 'line', data: this.prepare_series('delta_orders'), color: "#eeb11b" },
                { name: 'ДРР', type: 'line', data: this.prepare_series('delta_drr'), color: "#bdae63" },
                { name: 'ВП, общая', type: 'line', data: this.prepare_series('vp_total_plan_delta'), color: "#88aab6" },
                { name: 'Доходность', type: 'line', data: this.prepare_series('profit_plan_delta'), color: "#8b7163" },
                { name: 'Уникальных пользователей', type: 'line', data: this.prepare_series('unique_users'), color: "#dedcdf" },
                { name: 'Выручка', type: 'line', data: this.prepare_series('delta_revenue'), color: "#6200b5" },
            ];
        },
    },
    methods: {
        hidden_all(){
            this.seriesMain.forEach(item=>{
                this.$refs.chart.hideSeries(item.name)
            })
        },
        show_all(){
            this.seriesMain.forEach(item=>{
				this.$refs.chart.showSeries(item.name)
			})
		},
        hidden_series(){
            if (this.$refs.chart){
                this.$refs.chart.hideSeries("CR")
                this.$refs.chart.hideSeries("CPO")
                this.$refs.chart.hideSeries("ДРР")
                this.$refs.chart.hideSeries("ВП, общая")
                this.$refs.chart.hideSeries("Доходность")
                this.$refs.chart.hideSeries("Уникальных пользователей")
                this.$refs.chart.hideSeries("Выручка")           
            }
        },
        prepare_series(field){
            const map_result =  this.data.map(item => ({ x: item.date, y: item[field] ? item[field] : 0 }))
            return map_result.sort(function(a, b){ return new Date(b.x) - new Date(a.x) })
        },
        async get_campaign_stats(){
            this.loading = true
            try {
                const response = await Advertisement.fetchAdvertisementStatsViews(this.campaign_id)
                this.data = response.data
            } catch (err) {
                console.log(err.data)
            }
            this.loading = false		
        },
    },
    async mounted(){
        await this.get_campaign_stats()
    }
}
</script>

<template>
    <v-container fluid grey lighten-4 class="pa-5">
        <v-card class="mx-auto" flat>
			<v-card-title>Основная информация</v-card-title>
			<v-card-text>
				<DetmirProductDetailComponent :product_id="product_id" @fill_catalog_id="fill_data"/>
			</v-card-text>
        </v-card>
        <v-card class="mx-auto mt-5 elevation-0" flat>
			<v-card-title>Динамика показателей</v-card-title>
			<v-card-text>
				<DetmirProductDataDays :product_id="product_id" :catalog_id="catalog_item"/>
			</v-card-text>
            
        </v-card>
    </v-container>
</template>

<script>
import DetmirProductDataDays from '@/components/DetmirProduct/DetmirProductDataDays';
import DetmirProductDetailComponent from '@/components/DetmirProduct/DetmirProductDetailComponent';

export default {
	name: 'DetmirProductsDetail',
	components: { DetmirProductDataDays, DetmirProductDetailComponent },
	data(){
		return {
			catalog_item: ""
		}
	},
	computed: {
		product_id() {
			return this.$route.params.id;
		},
	},
	methods: {
		fill_data(data){
			this.catalog_item = data.catalog_item ? data.catalog_item.code1C_code: ''
		}
	},
	async mounted() {
		document.title = `Товары ДМ | ${this.product_id} | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Товар ДМ ${this.product_id}`);
	},
};
</script>
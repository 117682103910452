<template>
    <div>
        <v-skeleton-loader v-if="loading" type="article, card-heading"></v-skeleton-loader>
		<div v-else class="d-flex">
			<div class="block_image">
				<img v-if="data.image" width="120" :src="`https://catalog.detmir.st/media/${data.image}`" alt="">
				<p v-else>Нет изображения</p>
			</div>
			<div class="block_info px-5">
				<div class="text-body-1 text--primary">{{ data.name }}</div>
				<div class="mt-3 d-flex">
					<div>
						<div><span class="grey--text">ДМ ID: </span>{{ data.mp_id }}</div>
						<div><span class="grey--text">PROMO ID: </span>{{ data.promo_id }}</div>
						<div><span class="grey--text">Code 1c: </span>{{ data.catalog_item ? data.catalog_item.code1C_code: '-' }}</div>
						<div><span class="grey--text">ШК: </span>{{ data.barcode }}</div>
						<div><span class="grey--text">Аккаунт: </span>{{ data.catalog_item ? data.catalog_item.account_name: '-' }}</div>
						<a 
							target="_blank" :href="`https://www.detmir.ru/product/index/id/${data.promo_id}/`" 
							class="mt-5" style="white-space: nowrap;"
						>Смотреть на ДМ</a>
					</div>
					<div class="ml-10">
						<div><span class="grey--text">Бренд: </span>{{ data.catalog_item ? data.catalog_item.brand: '-' }}</div>
						<div><span class="grey--text">Группа: </span>{{ data.catalog_item ? data.catalog_item.code1C_tag_group_name : '-' }}</div>
						<div><span class="grey--text">Тег: </span>{{ data.catalog_item ? data.catalog_item.code1C_tag_name : '-' }}</div>
						<div><span class="grey--text">Статус: </span>{{ data.catalog_item ? data.catalog_item.status_name : '-' }}</div>
						<div><span class="grey--text">Менеджер: </span>{{ data.catalog_item ? data.catalog_item.our_manager : '-' }}</div>
						<div>
							<span class="grey--text">Рецепт: </span>
							<div v-for="c in data.kit_components" :key="c.id" class="px-5" style="font-size: 12px;">
								<router-link 
									style="color: rgba(0, 0, 0, 0.6);" target="_blank" 
									:class="['mb-0', {'font-weight-bold': c.main_kit, 'black--text': c.main_kit}]" 
									:to="{ name: 'kits_component_card', params: { id: c.id }}"
								>{{ c.name }} ({{ c.code }}) - {{ c.count }}</router-link>
								<hr>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
	import {Products} from '@/api/products';
	
	export default {
		name: 'DetmirProductDetailComponent',

		data() {
			return {
				loading: false,
				data: {}
			};
		},
		computed: {
			product_id() {
				return this.$route.params.id;
			},
		},
		
		methods: {
			async fetch_product_data(){
				this.loading = true
				try{
					const response = await Products.fetchDetmirProductDetail(this.product_id)
					this.data = response.data
				} catch(e){
					console.log(e)
				}
				this.loading = false
				this.$emit("fill_catalog_id", this.data)
			}
		},
		async mounted() {
			await this.fetch_product_data()
		},
	};
</script>
<template>
    <RedemptionMain/>
</template>

<script>

	import RedemptionMain from '@/components/Redemption/RedemptionMain.vue';

	export default {
		name: 'Redemption',
		components: {
			RedemptionMain,
		},
		mounted() {
			document.title = 'Выкупы WB | mp.lomex-group.ru';
			this.$store.commit('setPageTitle', `Выкуп товаров WB`);
		},
	};
</script>

<style scoped>

</style>
<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                temporary
                absolute
                app

        >
            <v-divider></v-divider>
            <v-list subheader>
                <v-subheader>По продавцам</v-subheader>
                <v-list-item>
                    <div>
                        <div v-for="account in filter_choices.wbaccount" :key="account.id" class="mb-2">
                            <v-chip
                                    class="mr-2"
                                    :class="{
                                      'purple white--text':filters.wbaccount == account.id, 
                                      'grey lighten-4': filters.wbaccount !== account.id && !$vuetify.theme.dark,
                                      'grey darken-4': filters.wbaccount !== account.id && $vuetify.theme.dark,
                                    }"
                                    @click="filters.wbaccount=account.id"
                            >
                                {{ account.name }}
                            </v-chip>
                        </div>
                        <!--                        <v-select-->
                        <!--                                :items="filter_choices.wbaccount"-->
                        <!--                                item-text="name"-->
                        <!--                                filled-->
                        <!--                                @change="wb"-->
                        <!--                                label="Filled style"-->
                        <!--                        ></v-select>-->
                    </div>
                </v-list-item>
            </v-list>
            <div class="pa-5">
                <v-btn
                        @click="fetchDataWithFilters()"
                        depressed
                        color="purple darken-2"
                        class="mb-3"
                        dark
                >
                    Применить
                </v-btn>
                <v-btn
                        @click="clearFilters()"
                        depressed
                        class="mb-10"
                >
                    Сбросить
                </v-btn>
            </div>
        </v-navigation-drawer>
        <div class="px-5">
            <v-btn icon @click="drawer = !drawer">
                <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
        </div>
        <v-sheet>
            <v-container fluid>
                <v-row class="" justify="space-between">
                    <v-col cols="12" md="2">
                        <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_from"><template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    clearable
                                    @click:clear="filters.date_after = null"
                                    v-model="dateFromFormatted"
                                    label="Начало периода"
                                    prepend-icon="mdi-calendar"
                                    readonly="readonly"
                                    v-bind="attrs"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    v-on="on">
                            </v-text-field>
                        </template>
                            <v-date-picker
                                    v-model="filters.date_after"
                                    @input="datepicker_menu_from = false"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col  cols="12" md="2">
                        <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_to"><template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    clearable
                                    @click:clear="filters.date_before = null"
                                    v-model="dateToFormatted"
                                    label="Конец периода"
                                    prepend-icon="mdi-calendar"
                                    readonly="readonly"
                                    hide-details="auto"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on"
                            ></v-text-field>
                        </template>
                            <v-date-picker
                                    v-model="filters.date_before"
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"></v-date-picker>
                        </v-menu>
                        <div class="text-right">
                            <a href="#" @click="setYesterdayToDateFilter()">Вчера</a>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-text-field
                                v-model="filters.order_number"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchFeedbacksWithFilters()"
                                append-icon="mdi-magnify"
                                label="Номер заказа"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="filters.nmid"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchFeedbacksWithFilters()"
                                append-icon="mdi-magnify"
                                label="Артикул"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="fetchDataWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">

                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <v-data-table
                v-else
                dense
                :headers="dataTable.headers"
                :pageCount="pagination.numberOfPages"
                :items="listData"
                item-key="uid"
                @update:page="pageChanged"
                @update:sort-by="sortChanged"
                @update:sort-desc="sortChanged"
                :loading="tableLoading"
                :options.sync="options"
                :items-per-page="50"
                :server-items-length="pagination.totalItems"
                class="elevation-1 vertical-border small-font"
        >
<!--            <template v-slot:item.nmid="{ item }">-->
<!--                <a :href="`https://www.wildberries.ru/catalog/${item.nmid}/detail.aspx`" target="_blank">Смотреть на wb</a> |-->
<!--                <router-link :to="{ name: 'product_card', params: { id: item.nmid }}">{{ item.nmid }}</router-link>-->
<!--            </template>-->
            <template v-slot:item.brand="{ item }">
                {{ item.brand }}
            </template>
            <template v-slot:item.discount_percent="{ item }">
                {{ item.discount_percent }}%
            </template>
            <template v-slot:item.total_price="{ item }">
                {{ item.total_price }} ₽
            </template>
            <template v-slot:item.nmid="{ item }">

                <v-tooltip bottom max-width="300" open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            {{ item.nmid.name.slice(0,15) }}...
                        </span>
                    </template>
                    <span>{{ item.nmid.name}}</span>
                </v-tooltip>

            </template>
            <template v-slot:item.wbaccount="{ item }">
                {{ item.wbaccount.name }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
  import { Stats } from '@/api/stats'
  import { General } from '@/api/general'

  export default {
    name: 'OrdersList',
    data () {
      return {
        initLoading: false,
        tableLoading: false,
        pagination: {
          next: null,
          totalItems: 0,
          numberOfPages: 0,
          currentPage: 0
        },
        options: {},
        drawer: false,
        filters: {
          wbaccount: null,
          date_before: null,
          date_after: null,
          nmid: null
        },
        filter_choices: {
          wbaccount:[]
        },
        datepicker_menu_from: null,
        datepicker_menu_to: null,
        listData: [],
        dataTable: {
          headers: [
            {text: 'id', align: 'start', sortable: false, value: 'id', width: '1%'},
            {text: 'Номер заказа', align: 'start', sortable: false, value: 'number', width: '1%'},
            {text: 'Дата', align: 'start', sortable: false, value: 'date', width: '1%'},
            {text: 'Штрихкод', align: 'start', sortable: false, value: 'barcode', width: '1%'},
            {text: 'Кат.', align: 'start', sortable: false, value: 'category', width: '1%'},
            {text: 'Subject', align: 'start', sortable: false, value: 'subject', width: '1%'},
            {text: 'Бренд', align: 'start', sortable: false, value: 'brand', width: '1%'},
            { text: 'Товар', value: 'nmid', width: '1%', sortable: false },
            { text: 'Продавец', value: 'wbaccount', width: '1%' },
            { text: 'Кол-во', value: 'quantity', width: '1%', sortable: false },
            { text: 'Цена', value: 'total_price', width: '1%', sortable: false },
            { text: '% скидки', value: 'discount_percent', width: '1%', sortable: false },
            // { align: 'left', sortable: false,text: '', value: 'name' }
          ],
        }
      }
    },
    computed: {
      transfromFiltersForQuery () {
        let query = {}
        for (const f in this.filters) {
          if (Array.isArray(this.filters[f])) {
            query[f] = this.filters[f].join(',')
          } else {
            query[f] = this.filters[f]
          }
        }
        return query
      },
      dateFromFormatted () {
        return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
      },
      dateToFormatted () {
        return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
      }
    },
    methods: {
      setDefaultFromDate () {
        const d = new Date()
        d.setMonth(d.getMonth() - 1)
        this.filters.date_after = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
      },
      setYesterdayToDateFilter () {
        const d = new Date()
        d.setDate(d.getDate() - 1)
        const d_str = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
        this.filters.date_after = d_str
        this.filters.date_before = d_str
      },
      addUniqueToArray (arr, value) {
        if (arr.indexOf(value) === -1) {
          arr.push(value)
        } else {
          arr.splice(arr.indexOf(value), 1)
        }
      },
      async sortChanged () {
        await this.fetchOrders()
      },
      async pageChanged () {
        await this.fetchOrders()
      },
      async clearFilters () {
        let dirtyFilters = false
        for (let item in this.filters) {
          if (this.filters[item]) {
            dirtyFilters = true
          }
          this.filters[item] = Array.isArray(this.filters[item]) ? [] : null
        }
        if (dirtyFilters) {
          await this.fetchOrders()
        }
      },
      async fetchWbAccounts () {
        const response = await General.getWbAccounts()
        for (let account in response.data) {
          this.filter_choices.wbaccount.push(response.data[account])
        }
      },
      async fetchDataWithFilters () {
        this.drawer = false
        this.options.page = 1
        await this.fetchOrders()
      },
      async fetchOrders () {
        this.tableLoading = true
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;
        let pageNumber = page - 1;
        try {
          const response = await Stats.fetchOrders(
            {
              ...this.transfromFiltersForQuery,
              ...{
                'offset': itemsPerPage*pageNumber,
                'limit': itemsPerPage,
                'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
              }
            }
          )
          this.listData = response.data.results
          this.pagination.totalItems = response.data.count
          this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
        } catch (err) {
          console.log(err.data)
        }
        this.tableLoading = false
      }
    },
    async mounted () {
      this.setDefaultFromDate()
      await this.fetchWbAccounts()
      this.initLoading = true
      await this.fetchOrders()
      this.initLoading = false
    }
  }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    ::v-deep .small-font td{
        font-size: .75rem !important;
    }
    ::v-deep .vertical-border td{
        border-right: 1px solid #eaeaea;
    }
    ::v-deep tbody {
        tr:hover {
            background-color: #f5f5f5 !important;
        }
    }
</style>

<template>
    <div>
        <v-sheet>
            <v-container fluid>
                <v-subheader>По дате</v-subheader>
                <v-row class="">
                    <v-col>
                        <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_from">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateFromFormatted"
                                        label="Начало периода"
                                        prepend-icon="mdi-calendar"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_after"
                                    :first-day-of-week="1"
                                    @input="datepicker_menu_from = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_to">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateToFormatted"
                                        label="Конец периода"
                                        prepend-icon="mdi-calendar"
                                        readonly="readonly"
                                        hide-details="auto"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_before"
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <div class="text-right" style="font-size: .8rem;">
                            <a @click="setYesterdayToDateFilter()">Вчера</a>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
					<v-col>
                        <v-text-field
                                v-model="filters.posting_number"
                                clearable
                                outlined
                                dense
                                append-icon="mdi-magnify"
                                label="Номер отправления"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="filters.rid"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchDataWithFilters()"
                                append-icon="mdi-magnify"
                                label="id операции"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="fetchDataWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">

                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <div style="position:relative;">
            <div class="loading" v-if="loading"></div>
            <v-data-table
                    v-if="data"
                    dense
                    :headers="headers"
                    :pageCount="pagination.numberOfPages"
                    :items="data"
                    item-key="id"
                    @update:page="fetchData"
                    @update:sort-by="fetchData"
                    @update:sort-desc="fetchData"
                    :loading="tableLoading"
                    :options.sync="options"
                    :items-per-page="50"
                    item-class="row_classes"
                    :server-items-length="pagination.totalItems"
                    class="elevation-0 vertical-border small-font"
            >
                <template v-slot:item.warehouse="{ item }">
                    <div>{{ item.warehouse_id }}</div>
                    <div>{{ item.warehouse_name }}</div>
                    <div>FBS: {{ item.warehouse_fbs }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
	import {Stats} from '@/api/stats';

	export default {
		name: 'OzonProductOrdersList',
		props: {
			product_id: {
				type: String,
				require: true,
			},
		},
		data() {
			return {
				data: null,
				loading: false,
				tableLoading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				headers: [
					{ text: 'Дата операции', sortable: false, value: 'date' },
					{ text: 'id заказа', sortable: false, value: 'order_id' },
					{ text: 'Номер заказа', sortable: false, value: 'order_number' },
					{ text: 'Ном. отправления', sortable: false, value: 'posting_number' },
					{ text: 'Статус', sortable: false, value: 'status' },
					{ text: 'id причины отмены', sortable: false, value: 'cancel_reason_id' },
					{ text: 'Причина отмены (fbs)', sortable: false, value: 'cancel_reason' },
					{ text: 'Тип отмены (fbs)', sortable: false, value: 'cancellation_type' },
					{ text: 'Цена', sortable: true, value: 'price' },
					{ text: 'Комиссия', sortable: false, value: 'commission_amount' },
					{ text: 'Схема', sortable: false, value: 'type' },
					{ text: 'Склад', sortable: false, value: 'warehouse' },
					{ text: 'sku', sortable: false, value: 'sku' },
					{ text: 'Расход', sortable: false, value: 'expenses' }
				],
				filters: {
					date_before: null,
					date_after: null,
					order_id: null,
					posting_number: null
				},
				supplier_oper_name_choices: [],
				datepicker_menu_from: null,
				datepicker_menu_to: null
			};
		},
		computed: {
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
			dateFromFormatted () {
				return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
			},
			dateToFormatted () {
				return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
			}
		},
		async mounted() {
			await this.fetchData()
		},
		methods: {
			setYesterdayToDateFilter() {
				const d = new Date()
				d.setDate(d.getDate() - 1)
				const d_str = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
				this.filters.date_after = d_str
				this.filters.date_before = d_str
			},
			async fetchDataWithFilters () {
				this.options.page = 1
				await this.fetchData()
			},
			async fetchData () {
				this.loading = true
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				console.log(page, itemsPerPage, pageNumber)
				try {
					const res = await Stats.fetchSingleProductOzonOrders(
						this.product_id,
						{
							...this.transfromFiltersForQuery,
							...{
								'offset': itemsPerPage*pageNumber,
								'limit': itemsPerPage,
								// 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
							}
						}
					)
					this.data = res.data.results
					this.pagination.totalItems = res.data.count
					this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
				} catch (err) {
					console.log(err)
				}
				this.loading = false
			},
		},
	};
</script>

<style scoped>

</style>
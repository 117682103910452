<template>
    <div>
        <v-sheet>
            <v-container fluid>
                <v-subheader>По дате</v-subheader>
                <v-row class="">
                    <v-col>
                        <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_from">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateFromFormatted"
                                        label="Дата заказа(от)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_after"
                                    :first-day-of-week="1"
                                    @input="datepicker_menu_from = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_to">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateToFormatted"
                                        label="Дата заказа(до)"
                                        readonly="readonly"
                                        hide-details="auto"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_before"
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <div class="text-right" style="font-size: .8rem;">
                            <a @click="setYesterdayToDateFilter()">Вчера</a>
                        </div>
                    </v-col>
					<v-col>
                        <v-menu v-model="datepicker_date_buy_from" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_date_buy_from">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_buy_after = null"
                                        v-model="dateBuyFromFormatted"
                                        label="Дата продажи(от)"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_buy_after"
                                    :first-day-of-week="1"
                                    @input="datepicker_date_buy_from = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu v-model="datepicker_date_buy_before" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_date_buy_before">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_buy_before = null"
                                        v-model="dateBuyToFormatted"
                                        label="Дата продажи(до)"
                                        readonly="readonly"
                                        hide-details="auto"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_buy_before"
                                    @input="datepicker_date_buy_before = false"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
					<v-col>
						<v-select
							v-model="filters.operation_type_name"
							:items="transaction_type_choices"
							item-text="name"
							item-value="value"
							label="Тип"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
					</v-col>
					<v-col>
                        <v-text-field
                                v-model="filters.posting_number"
                                clearable
                                outlined
                                dense
                                append-icon="mdi-magnify"
                                label="Номер отправления"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="filters.rid"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchDataWithFilters()"
                                append-icon="mdi-magnify"
                                label="id операции"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="fetchDataWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">

                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <div style="position:relative;">
            <div class="loading" v-if="loading"></div>
            <v-data-table
                    v-if="data"
                    dense
                    :headers="headers"
                    :pageCount="pagination.numberOfPages"
                    :items="data"
                    item-key="id"
                    @update:page="fetchData"
                    @update:sort-by="fetchData"
                    @update:sort-desc="fetchData"
                    :loading="tableLoading"
                    :options.sync="options"
                    :items-per-page="50"
                    :item-class= "row_classes"
                    :server-items-length="pagination.totalItems"
                    class="elevation-0 vertical-border small-font"
            >
            </v-data-table>
        </div>
    </div>
</template>

<script>
	import {Stats} from '@/api/stats';
	import {General} from '@/api/general'

	export default {
		name: 'OzonProductTransactionsList',
		props: {
			product_id: {
				type: String,
				require: true,
			},
		},
		data() {
			return {
				transaction_type_choices: null,
				data: null,
				loading: false,
				tableLoading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				headers: [
					{ text: 'Дата операции', sortable: false, value: 'operation_date' },
					{ text: 'id опер.', sortable: false, value: 'operation_id' },
					{ text: 'Тип', sortable: false, value: 'operation_type_name' },
					{ text: 'Стоим. с учетом скидок', sortable: false, value: 'accruals_for_sale' },
					{ text: 'Комиссия', sortable: false, value: 'sale_commission' },
					{ text: 'Итог. сумма.', sortable: false, value: 'amount' },
					{ text: 'Тип начисл.', sortable: true, value: 'type' },
					{ text: 'Склад', sortable: false, value: 'warehouse_id' },
					{ text: 'Ном. отправления', sortable: false, value: 'posting_number' },
					{ text: 'Дата начала обработки', sortable: false, value: 'order_date' },
					{ text: 'Схема', sortable: false, value: 'delivery_schema' },
					{ text: 'sku', sortable: false, value: 'sku' },
					{ text: 'Кол-во в отпр.', sortable: false, value: 'count' },
					{ text: 'Расход', sortable: false, value: 'expenses' }
				],
				filters: {
					date_before: null,
					date_buy_after: null,
					date_buy_before: null,
					date_after: null,
					order_id: null,
					rid: null,
					supplier_oper_name: null,
					is_bundle: 0,
					posting_number: null,
					operation_type_name: null
				},
				supplier_oper_name_choices: [],
				datepicker_menu_from: null,
				datepicker_menu_to: null,
				datepicker_date_buy_from: null,
				datepicker_date_buy_before: null
            };
		},
		computed: {
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
			dateFromFormatted () {
				return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
			},
			dateToFormatted () {
				return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
			},
			dateBuyFromFormatted () {
				return this.filters.date_buy_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_buy_after) : null
			},
			dateBuyToFormatted () {
				return this.filters.date_buy_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_buy_before) : null
			}
		},
		async mounted() {
			await this.fetchOzonTransactionTypeNameChoices()
            await this.fetchData()
		},
		methods: {
			setYesterdayToDateFilter() {
				const d = new Date()
				d.setDate(d.getDate() - 1)
				const d_str = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
				this.filters.date_after = d_str
				this.filters.date_before = d_str
			},
			async fetchDataWithFilters () {
				this.options.page = 1
				await this.fetchData()
			},
			async fetchData () {
				this.loading = true
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				console.log(page, itemsPerPage, pageNumber)
				try {
					const res = await Stats.fetchSingleProductOzonTransactions(
						this.product_id,
						{
							...this.transfromFiltersForQuery,
							...{
								'offset': itemsPerPage*pageNumber,
								'limit': itemsPerPage,
								// 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
							}
						}
					)
					this.data = res.data.results
					this.pagination.totalItems = res.data.count
					this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
				} catch (err) {
					console.log(err)
				}
				this.loading = false
			},
			async fetchOzonTransactionTypeNameChoices () {
				try {
					const response = await General.fetchOzonTransactionTypeNameChoices()
					this.transaction_type_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
		},
	};
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wb_keyword_component"},[(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteKeyword()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1):_vm._e(),(_vm.dialog_keyword_seller)?_c('v-dialog',{attrs:{"max-width":"1800px"},model:{value:(_vm.dialog_keyword_seller),callback:function ($$v) {_vm.dialog_keyword_seller=$$v},expression:"dialog_keyword_seller"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Кол-во запросов по товару")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 vertical-border",attrs:{"headers":_vm.header_keywords_seller,"items":_vm.data_keywords_seller,"dense":"","hide-default-footer":"","disable-pagination":"","loading":_vm.loading_keyword_seller}})],1)],1)],1):_vm._e(),_c('BeeGridTable',{key:_vm.itter,staticClass:"mt-5 keyword_monitoring_table",attrs:{"loading":_vm.loading_table,"border":"","showFilter":false,"showPager":false,"height":"460","columns":_vm.keys,"data":_vm.list_data_prepare},scopedSlots:_vm._u([{key:"delete",fn:function(ref){
var row = ref.row;
return [(row.isMain && !_vm.not_show_admin)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDialog(row.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"favorite",fn:function(ref){
var row = ref.row;
return [(row.isMain && !_vm.not_show_admin)?_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","input-value":row.favorite},on:{"change":function($event){return _vm.changeFavoriteKeyword(row)}},model:{value:(row.favorite),callback:function ($$v) {_vm.$set(row, "favorite", $$v)},expression:"row.favorite"}}):_vm._e()]}},{key:"text_keyword",fn:function(ref){
var row = ref.row;
return [(row.isMain)?_c('a',{class:{'error_keyword': row.is_data == false},attrs:{"href":_vm.urlWBMonitoring(row.keyword),"target":"_blank"}},[_vm._v(" "+_vm._s(row.keyword)+" ")]):(row.is_card_analytics)?_c('a',{staticClass:"dashed",on:{"click":_vm.change_show_card}},[_vm._v(_vm._s(row.keyword))]):(row.is_keyword_seller)?_c('a',{staticClass:"dashed",on:{"click":_vm.openKeywordSeller}},[_vm._v(_vm._s(row.keyword))]):_c('div',[_vm._v(" "+_vm._s(row.keyword)+" ")])]}},{key:"posCell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(row[column.key]['pos'] != 0 && row[column.key]['pos'])?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{'fill_cell_keyword': row.isMain, 'fill_index': row.isIndex}},'span',attrs,false),on),[_vm._v(" "+_vm._s(row[column.key]["pos"])+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":".7rem"}},[(row[column.key]['auto'] && row[column.key]['auto'].length > 0)?_c('div',[_c('p',[_vm._v("Ставка автореклама: "+_vm._s(row[column.key]['auto'][0]))]),_c('p',[_vm._v("Позиция автореклама: "+_vm._s(row[column.key]['auto'][1]))])]):_c('div',[_vm._v("Нет информации")])])]),(row[column.key]['prevPos'] < 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"red","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row[column.key]["prevPos"])+" ")]):(row[column.key]['prevPos'] > 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"success","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row[column.key]["prevPos"])+" ")]):_vm._e()],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v("-")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":".7rem"}},[(row[column.key]['auto'] && row[column.key]['auto'].length > 0)?_c('div',[_c('p',[_vm._v("Ставка автореклама: "+_vm._s(row[column.key]['auto'][0]))]),_c('p',[_vm._v("Позиция автореклама: "+_vm._s(row[column.key]['auto'][1]))])]):_c('div',[_vm._v("Нет информации")])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
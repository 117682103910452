<template>
    <div>
		<v-dialog 
			v-model="dialog_create_request_for_delete" 
			max-width="500px"
			v-if="dialog_create_request_for_delete"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Создание запроса в поддержку</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-col cols="12" md="12">
							<p>uid: {{create_request_for_delete_item.uid}}</p>
							<v-chip-group
								v-model="form_data.nmid"
								active-class="deep-purple--text text--accent-4"
								mandatory
							>
								<v-chip
										v-for="p in create_request_for_delete_item.product.child_products" 
										:key="p.nmid"
										:value="p.nmid"
										class="different_variations__sizes"
								>{{ p.nmid }}
								</v-chip>
							</v-chip-group>
							<v-select
								v-model="form_data.template"
								:items="filter_choices.template_response_choices"
								item-text="name"
								item-value="value"
								label="Шаблон ответа"
								clearable
								outlined
								dense
								class="mt-5"
							></v-select>
						</v-col>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="dialog_create_request_for_delete=false"
					>
						Закрыть
					</v-btn>
					<v-btn
						color="blue darken-1"
						:disabled="!form_data.template"
						text
						:loading="loading_create_request"
						@click="createFeedbackRequestDelete"
					>
						Создать
					</v-btn>
				</v-card-actions>
			</v-card>    
		</v-dialog>
        <v-navigation-drawer v-model="drawer" temporary absolute app>
            <v-list subheader>
                <v-subheader>По рейтингу</v-subheader>
                <v-list-item>
                    <section class="">
                        <div v-for="i in 5" :key="i" class="mb-2">
                            <v-chip
                                    class="mr-2"
                                    :class="{'purple darken-3':filters.product_rating.includes(i), 'grey lighten-4': !filters.product_rating.includes(i)}"
                                    @click="addUniqueToArray(filters.product_rating, i)"
                            >
                                <v-rating
                                        length="5"
                                        small
                                        readonly
                                        background-color="orange"
                                        color="orange"
                                        :value="i"
                                ></v-rating>
                            </v-chip>
                        </div>
                    </section>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item>
                    <div class="py-4">
                        <v-switch
                                v-model="filters.no_rating"
                                inset
                                class="mt-0 mb-2"
                                hide-details
                                :label="`Исключенные`"
                        ></v-switch>
						<v-switch
                                v-model="filters.has_text"
                                inset
                                class="mt-0 mb-2"
                                hide-details
                                :label="`С текстом`"
                        ></v-switch>
                        <v-switch
                                v-model="filters.has_photos"
                                inset
                                class="mt-0 mb-2"
                                hide-details
                                :label="`С фото`"
                        ></v-switch>
						<v-switch
							v-model="filters.has_video"
							inset
							class="mt-0 mb-2"
							hide-details
							:label="`С видео`"
                        ></v-switch>
                        <v-switch
                                v-model="filters.is_deleted_by_client"
                                inset
                                class="mt-0"
                                hide-details
                                :label="`Удаленные пользователем`"
                        ></v-switch>
                        <v-switch
                                v-model="filters.is_deleted_by_mp"
                                inset
                                class="mt-0"
                                hide-details
                                :label="`Удаленные WB`"
                        ></v-switch>
						<v-switch
							v-model="filters.support_request"
							inset
							class="mt-0 mb-2"
							hide-details
							:label="`С запросом на удаление`"
                        ></v-switch>
						<v-switch
							v-model="filters.without_answer"
							inset
							class="mt-0 mb-2"
							hide-details
							:label="`Без ответа`"
                        ></v-switch>
                    </div>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list subheader>
                <v-subheader>По продавцам</v-subheader>
                <v-list-item>
                    <div>
                        <div v-for="account in filter_choices.wbaccount" :key="account.id" class="mb-2">
                            <v-chip
                                    class="mr-2"
                                    :class="{
										'purple white--text':filters.wbaccount == account.id, 
										'grey darken-4': filters.wbaccount !== account.id && $vuetify.theme.dark,
										'grey lighten-4': filters.wbaccount !== account.id && !$vuetify.theme.dark
									}"
                                    @click="filters.wbaccount = filters.wbaccount==account.id ? null : account.id"
                            >
                                {{ account.name }}
                            </v-chip>
                        </div>
                    </div>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list subheader>
                <v-subheader>По источнику</v-subheader>
                <v-list-item>
                    <div>
                        <div v-for="source in sourceChoices" :key="source.value" class="mb-2">
                            <v-chip
                                    class="mr-2"
                                    :class="{'purple white--text':filters.source == source.value, 'grey lighten-4': filters.source !== source.value}"
                                    @click="filters.source = filters.source==source.value ? null : source.value"
                            >
                                {{ source.text }}
                            </v-chip>
                        </div>
                    </div>
                </v-list-item>
            </v-list>
			<v-list subheader>
				<div class="pa-4">
					<v-select
						v-model="filters.deletion_status"
						:items="filter_choices.deletion_status_choices"
						item-text="name"
						item-value="value"
						label="Статус удаления"
						outlined
						multiple
						hide-details
						dense
						clearable
					></v-select>
				</div>
			</v-list>

            <div class="pa-5">
                <v-btn
                        @click="fetchFeedbacksWithFilters()"
                        depressed
                        color="purple darken-2"
                        class="mb-3"
                        dark
                >
                    Применить
                </v-btn>
                <v-btn
                        @click="clearFilters()"
                        depressed
                        color="grey lighten-2"
                        class="mb-10"
                >
                    Сбросить
                </v-btn>
            </div>
        </v-navigation-drawer>
		<v-sheet>
			<v-btn icon @click="drawer = !drawer">
            <v-icon>mdi-filter-variant</v-icon>
			</v-btn>
			<router-link class="ml-3" target="_blank" :to="{name: 'feedbacks_template_response'}">
				<v-icon>mdi-frequently-asked-questions</v-icon>
			</router-link>
		</v-sheet>
        <v-sheet>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col>
                                <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y="offset-y" min-width="290px"
                                        ref="datepicker_menu_from">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                clearable
                                                @click:clear="filters.date_after = null"
                                                v-model="dateFromFormatted"
                                                label="Начало периода"
                                                prepend-icon="mdi-calendar"
                                                readonly="readonly"
                                                v-bind="attrs"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="filters.date_after"
                                            :first-day-of-week="1"
                                            @input="datepicker_menu_from = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y="offset-y" min-width="290px"
                                        ref="datepicker_menu_to">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                clearable
                                                @click:clear="filters.date_before = null"
                                                v-model="dateToFormatted"
                                                label="Конец периода"
                                                prepend-icon="mdi-calendar"
                                                readonly="readonly"
                                                hide-details="auto"
                                                v-bind="attrs"
                                                outlined
                                                dense
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="filters.date_before"
                                            @input="datepicker_menu_to = false"
                                            :first-day-of-week="1"
                                    ></v-date-picker>
                                </v-menu>
                                <div class="text-right" style="font-size: .8rem;">
                                    <a href="#" @click="setYesterdayToDateFilter()">Вчера</a>
                                </div>
                            </v-col>
                            <v-col>
                                <v-dialog
                                        width="700"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                @click="getFeedbacksSourceStats()"
                                                icon
                                                title="Сводка по источникам"
                                                color="grey"
                                                v-bind="attrs"
                                                v-on="on"
                                                dark
                                        >
                                            <v-icon dark>
                                                mdi-poll
                                            </v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="text-h6 purple--text darken-2">
                                            Сводка по источникам
                                        </v-card-title>
                                        <v-card-subtitle>За даты: {{ filters.date_after }} - {{ filters.date_before }}
                                        </v-card-subtitle>
                                        <div style="position:relative;">
                                            <v-data-table v-if="feedbacksSourceStats"
                                                          class="elevation-1 pb-2"
                                                          :class="{'loading': loading.feedbacksSourceStats}"
                                                          dense
                                                          :headers="feedbacksSourceStatsTableHeaders"
                                                          :items="feedbacksSourceStats"
                                                          :items-per-page="10"
                                                          hide-default-footer
                                            ></v-data-table>
                                        </div>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-text-field
                                        v-model="filters.text"
                                        clearable
                                        outlined
                                        dense
                                        @keyup.enter="fetchFeedbacksWithFilters()"
                                        append-icon="mdi-magnify"
                                        label="Текст отзыва"
                                        class="mb-3"
                                        hide-details="hide-details">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="filters.nmid"
                                        clearable
                                        outlined
                                        dense
                                        @keyup.enter="fetchFeedbacksWithFilters()"
                                        append-icon="mdi-magnify"
                                        label="Артикул"
                                        hide-details="hide-details">
                                </v-text-field>
                                <div v-if="filters.nmid && filters.nmid.length > 5" class="mt-1">
                                    <v-btn
                                            :loading="fetchSingleProductFeedbacksLoading"
                                            @click="fetchSingleProductFeedbacks(filters.nmid)"
                                            x-small
                                            rounded>Подгрузить отзывы
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col>
                                <v-btn
                                        @click="fetchFeedbacksWithFilters()"
                                        depressed
                                        color="purple darken-2"
                                        class="mb-3"
                                        dark
                                >
                                    <v-icon dark>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
							<v-col>
                                <v-btn
									@click="exportFeedbacks" depressed
									color="purple darken-2" class="mb-3" dark
									:loading="loading_export"
                                >Экспорт</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" class="py-0">
                        <v-chip dense
                                class="ma-2"
                        >
                            Подгрузка seller.wildberries.ru
                            <v-icon v-if="sellerFeedbackStatus" right large color="green">
                                mdi-circle-small
                            </v-icon>
                            <v-icon v-else right large color="red">
                                mdi-circle-small
                            </v-icon>
                        </v-chip>
                        <span v-if="!sellerFeedbackStatus" style="font-size: .7rem; text-align: center;">{{ sellerFeedbackStatusError }}</span>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <v-data-table
			v-else
			:headers="dataTable.headers"
			:pageCount="pagination.numberOfPages"
			:items="feedbacks"
			item-key="uid"
			@update:page="pageChanged"
			@update:sort-by="sortChanged"
			@update:sort-desc="sortChanged"
			:loading="tableLoading"
			:options.sync="options"
			height="calc(100vh - 100px)"
			:items-per-page="50"
			:fixed-header="true"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border"
        >
            <template v-slot:item.product="{ item }">
                <div>
                    <v-btn small icon @click="copyToClipboard(`${origin}/feedbacks/${item.uid}/`)">
                        <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn small icon :to="{name: 'singleFeedback', params: {id:item.uid, item: item}}">
                        <v-icon small>mdi-book-open</v-icon>
                    </v-btn>
                </div>
                <div>{{ item.product.pk }}</div>
                <div v-for="p in item.product.child_products" :key="p.nmid" style="display: inline; font-size: .7rem;">
                    <a :href="`https://www.wildberries.ru/catalog/${p.nmid}/detail.aspx`" target="_blank">{{ p.nmid
                        }}</a>,
                </div>
            </template>
            <template v-slot:item.nmid="{ item }">
                <div style="font-size: .7rem;" class="pt-2">{{ item.product.name }}</div>
            </template>
            <template v-slot:item.text="{ item }">
                <div style="font-size: .8rem;" v-if="item.user_profile">
                    <a :href="`https://wildberries.ru/${item.user_profile.wb_profile_url}`" target="_blank">
                        {{ item.user_profile.username }}
                    </a>
                </div>
				<div style="font-size: .8rem;" v-else>{{ item.username }}</div>
                <div style="font-size: .8rem;">{{ item.text }}</div>
                <v-expansion-panels v-if="item.answer" flat>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>Ответ от {{ item.answer_date | formatDateToLocaleNoTime }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div v-if="item.answer">
                                {{ item.answer }}
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
			<template v-slot:item.date_created="{ item }">
                <div style="font-size: .8rem;">{{ item.formatted_date }}</div>
            </template>
            <template v-slot:item.product_rating="{ item }">
                <v-rating
                        length="5"
                        small
                        readonly
                        background-color="orange lighten-3"
                        color="orange"
                        :value="item.product_rating"
                ></v-rating>
            </template>
            <template v-slot:item.source="{ item }">
                <div>
                    <v-select
                            :items="sourceChoices"
                            label="Источник"
                            dense
                            style="font-size: .8rem;"
                            solo
                            @change="(selected) => sourceChange(selected, item.uid)"
                            :value="item.source"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip small :color="item.color" dark>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
            </template>
			<template v-slot:item.deletion_status="{ item }">
                <div>
                    <v-select
						:items="filter_choices.deletion_status_choices"
						label="Статус удаления"
						dense
						item-text="name"
						item-value="value"
						hide-details
						style="font-size: .6rem;"
						solo
						@change="(selected) => deletionStatusChange(selected, item.uid)"
						:value="item.deletion_status"
                    >
                    </v-select>
                </div>
            </template>
            <template v-slot:item.helpness="{ item }">
                <div>
                    <v-chip
                            v-if="item.likes"
                            class="ma-1"
                            color="green"
                            text-color="white"
                            x-small
                    >
                        {{ item.likes }}
                    </v-chip>
                    <v-chip
                            v-if="item.dislikes"
                            class="ma-1"
                            color="red"
                            text-color="white"
                            x-small
                    >
                        {{ item.dislikes }}
                    </v-chip>
                </div>
            </template>
			<template v-slot:item.is_excluded="{ item }">
                <v-icon 
					v-if="item.no_rating || item.is_deleted_by_client || itemis_deleted_by_mp" 
					color="red"
				>mdi-checkbox-marked-circle</v-icon>
            </template>
            <template v-slot:item.has_photos="{ item }">
                <v-icon v-if="item.has_photos" color="green">
					mdi-checkbox-marked-circle
                </v-icon>
            </template>
			<template v-slot:item.has_video="{ item }">
                <v-icon v-if="item.has_video" color="green">
					mdi-checkbox-marked-circle
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import {mapState} from 'vuex';

	import {Products} from '@/api/products';
	import {General} from '@/api/general';

	export default {
		name: 'FeedbacksList',
		data() {
			return {
				loading_export: false,
				loading_create_request: false,
				form_data: {
					template: null,
					nmid: null
				},
				dialog_create_request_for_delete: false, // создание запроса на удаление
				create_request_for_delete_item: null,
				loading: {
					feedbacksSourceStats: false,
				},
				feedbacksSourceStats: null,
				feedbacksSourceStatsTableHeaders: [
					{text: 'Источник', value: 'source_by_name', sortable: false},
					{text: 'Всего отзывов', value: 'total', sortable: false},
					{text: 'Удал. (польз.)', value: 'deleted', sortable: false},
					{text: 'Удал. (WB)', value: 'deleted_by_mp', sortable: false},
					{text: '5 звезд', value: 'five_stars', sortable: false},
					{text: '1 звезда', value: 'one_star', sortable: false},
				],
				sellerFeedbackStatus: null,
				sellerFeedbackStatusError: '',
				initLoading: false,
				tableLoading: false,
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0,
				},
				options: {},
				drawer: false,
				filters: {
					has_text: true,
					deletion_status: null,
					no_rating: null,
					product_rating: [],
					has_photos: null,
					has_video: null,
					wbaccount: null,
					answer: null,
					date_before: null,
					date_after: null,
					text: null,
					nmid: null,
					is_deleted: null,
					is_deleted_by_mp: null,
					is_deleted_by_client: null,
					source: null,
					support_request: null,
					without_answer: null
				},
				filter_choices: {
					wbaccount: [],
					deletion_status_choices: [],
					template_response_choices: []
				},
				datepicker_menu_from: null,
				datepicker_menu_to: null,
				feedbacks: [],
				fetchSingleProductFeedbacksLoading: false,
				sourceChoices: [
					{
						text: 'Органика',
						value: 'ORGANIC',
						color: 'green',
					},
					{
						text: 'Визитки',
						value: 'BUSINESS_CARDS',
						color: 'orange darken-2',
					},
					{
						text: 'Подрядчик',
						value: 'TG_BOT',
						color: 'blue',
					},
					{
						text: 'Выкуп новый аккаунт',
						value: 'REDEEM_NEW',
						color: 'green lighten-3',
					},
					{
						text: 'Выкуп старый аккаунт',
						value: 'REDEEM_OLD',
						color: 'grey',
					},
					{
						text: 'Раздачи кэшбек телеграмм',
						value: 'CHAT',
						color: 'purple',
					},
					{
						text: 'MPBoost',
						value: 'MPBOOST',
						color: 'deep-purple darken-1',
					},
					{
						text: 'MP Bot',
						value: 'MP_BOT',
						color: 'blue lighten-2',
					},
					{
						text: 'Проблемы с товаром',
						value: 'ISSUES',
						color: 'red darken-1',
					},
					{
						text: 'Wildbest',
						value: 'WILDBEST',
						color: 'blue accent-4',
					},
					{
						text: 'buyouts-shop',
						value: 'BUYOUTS_SHOP',
						color: 'green lighten-1',
					},
					{
						text: 'Бот-опросник Спетс',
						value: 'BOTS',
						color: 'yellow darken-3',
					},
					{
						text: 'Кэшбек ВК',
						value: 'CASHBACK_VK',
						color: 'cyan darken-3'
					}
				],
				dataTable: {
					headers: [
						{text: 'Root ID', align: 'start', sortable: false, value: 'product', width: '200px',},
						{text: 'nmid', align: 'start', sortable: false, value: 'nmid', width: '200px'},
						{text: 'Текст', value: 'text', width: '100%', sortable: false},
						{text: 'Источник', value: 'source', width: '150px', sortable: false},
						{text: 'Дата отзыва', value: 'date_created', width: '100%'},
						{text: 'Оценка', value: 'product_rating', width: '1%'},
						{text: 'Полезность', value: 'helpness', width: '140px', align: 'center', sortable: false},
						{text: 'Исключен', value: 'is_excluded', width: '1%', align: 'center', sortable: false},
						{text: 'Статус удаления', value: 'deletion_status', align: 'center', width: '1%', sortable: false},
						{text: 'Фото', value: 'has_photos', width: '1%', align: 'center', sortable: false},
						{text: 'Видео', value: 'has_video', width: '1%', align: 'center', sortable: false},
					],
				},
			};
		},
		computed: {
			feedbacks_state() {
				return this.$store.state.products.feedbacks;
			},
			transfromFiltersForQuery() {
				let query = {};
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',');
					} else {
						query[f] = this.filters[f];
					}
				}
				return query;
			},
			dateFromFormatted() {
				return this.filters.date_after ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) :
					null;
			},
			dateToFormatted() {
				return this.filters.date_before ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) :
					null;
			},
		},
		methods: {
			copyToClipboard(text) {
				if (window.clipboardData && window.clipboardData.setData) {
					// IE specific code path to prevent textarea being shown while dialog is visible.
					return window.clipboardData.setData('Text', text);

				} else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
					var textarea = document.createElement('textarea');
					textarea.textContent = text;
					textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
					document.body.appendChild(textarea);
					textarea.select();
					try {
						document.execCommand('copy');  // Security exception may be thrown by some browsers.
						this.$toasted.show('Ссылка скопирована в буфер обмена', {
							duration: 3000,
						});
					} catch (ex) {
						console.warn('Copy to clipboard failed.', ex);
						return false;
					} finally {
						document.body.removeChild(textarea);
					}
				}
			},
			openDialogCreateRequest(item){
				this.dialog_create_request_for_delete = true
				this.create_request_for_delete_item = item
			},
			async fetchSingleProductFeedbacks(nmid) {
				this.fetchSingleProductFeedbacksLoading = true;
				this.tableLoading = true;
				try {
					const response = await Products.fetchSingleProductFeedbacks(nmid);
					console.log(response);
					console.log(response.data);
					if (response.data.count) {
						this.$toasted.show(`Добавлено отзывов: ${response.data.count} `, {
							theme: 'bubble',
							type: 'success',
							position: 'top-right',
							duration: 3000,
						});
						await this.fetchFeedbacksWithFilters();
					} else {
						this.$toasted.show(`Новых отзывов не найдено`, {
							theme: 'bubble',
							type: 'info',
							position: 'top-right',
							duration: 3000,
						});
					}
				} catch (err) {
					console.error(err);
				}
				this.fetchSingleProductFeedbacksLoading = false;
				this.tableLoading = false;
			},
			async sourceChange(v, feedback_id) {
				try {
					const response = await Products.setSourceToFeedback(v, feedback_id);
					this.$toasted.show(`Отзыв ${feedback_id} обновлен`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
				} catch (err) {
					console.log(err);
				}
			},
			async deletionStatusChange(v, feedback_id) {
				console.log('work')
				console.log(v)
				try {
					const response = await Products.setDeletionStatusToFeedback(v, feedback_id);
					this.$toasted.show(`Отзыв ${feedback_id} обновлен`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
				} catch (err) {
					console.log(err);
				}
			},
			setDefaultFromDate() {
				const d = new Date();
				d.setMonth(d.getMonth() - 1);
				this.filters.date_after = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
			},
			setYesterdayToDateFilter() {
				const d = new Date();
				d.setDate(d.getDate() - 1);
				const d_str = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
				this.filters.date_after = d_str;
				this.filters.date_before = d_str;
			},
			addUniqueToArray(arr, value) {
				if (arr.indexOf(value) === -1) {
					arr.push(value);
				} else {
					arr.splice(arr.indexOf(value), 1);
				}
			},
			async sortChanged() {
				await this.getFeedbacks();
			},
			async pageChanged() {
				await this.getFeedbacks();
			},
			async clearFilters() {
				let dirtyFilters = false;
				for (let item in this.filters) {
					if (this.filters[item]) {
						dirtyFilters = true;
					}
					this.filters[item] = Array.isArray(this.filters[item]) ? [] : null;
				}
				if (dirtyFilters) {
					await this.getFeedbacks();
				}
			},
			async fetchWbAccounts() {
				const response = await General.getWbAccounts();
				for (let account in response.data) {
					this.filter_choices.wbaccount.push(response.data[account]);
				}
			},
			async fetchSellerFeedbackStatus() {
				try {
					await Products.sellerFeedbackStatus();
					this.sellerFeedbackStatus = true;
				} catch (err) {
					this.sellerFeedbackStatus = false;
					this.sellerFeedbackStatusError = err.response.data;
				}
			},
			async fetchFeedbacksWithFilters() {
				this.drawer = false;
				this.options.page = 1;
				await this.getFeedbacks();
			},
			async getFeedbacksSourceStats() {
				this.loading.feedbacksSourceStats = true;
				try {
					const response = await Products.feedbackSourceStats(this.transfromFiltersForQuery);
					this.feedbacksSourceStats = response.data.results;
				} catch (err) {
					console.error(err);
				}
				this.loading.feedbacksSourceStats = false;
			},
			async getFeedbacks() {
				this.tableLoading = true;
				const {page, itemsPerPage, sortBy, sortDesc} = this.options;
				let pageNumber = page - 1;
				try {
					const query = {
						...this.transfromFiltersForQuery,
						...{
							'offset': itemsPerPage * pageNumber,
							'limit': itemsPerPage,
							'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
						},
					};
					await this.$store.dispatch('fetchFeedbacks', {query, filters: this.filters});
					this.feedbacks = this.$store.state.products.feedbacks.items;
					this.pagination.totalItems = this.$store.state.products.feedbacks.count;
					this.pagination.numberOfPages = Math.ceil(this.$store.state.products.feedbacks.count / itemsPerPage);
				} catch (err) {
					console.log(err.data);
				}
				this.tableLoading = false;
			},
			async getDeletionStatusChoices(){
				try {
					const response = await General.fetchDeletionStatusFeedbackChoices()
					this.filter_choices.deletion_status_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async getFeedbackTempaleResponseChoices(){
				try {
					const response = await General.fetchFeedbackTemplateResponseChoices()
					this.filter_choices.template_response_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async createFeedbackRequestDelete(){
				if (!this.create_request_for_delete_item.uid){
					return
				}
				this.loading_create_request = true
				try {
                    const response = await Products.createFeedbackSupportRequest(
						this.create_request_for_delete_item.uid,
						{
							"template_id": this.form_data.template,
							"nmid": this.form_data.nmid
						}
					)
					if (response.status == 200){
						this.$toasted.show(`Запрос создан. Номер: ${response.data.request_number} `, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
					}
				} catch (err) {
					this.$toasted.show(err.response.data.error, {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
				}
				this.loading_create_request = false
				this.dialog_create_request_for_delete = false,
				this.create_request_for_delete_item = null	
			},
			async exportFeedbacks(){
				this.loading_export = true
				const {sortBy, sortDesc} = this.options;
				try {
					const response = await Products.exportFeedbacks({
						...this.transfromFiltersForQuery,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `feedbacks.xlsx`)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export = false
			}
		},
		async mounted() {
			this.fetchSellerFeedbackStatus();
			this.getDeletionStatusChoices()
			this.setDefaultFromDate();
			this.getFeedbackTempaleResponseChoices()
			await this.fetchWbAccounts();
			if (Object.keys(this.feedbacks_state).length !== 0) {
				console.log('state not null');
				this.feedbacks = this.feedbacks_state.items;
				this.filters = this.feedbacks_state.filters;
				this.pagination.totalItems = this.feedbacks_state.count;
				// this.pagination.numberOfPages = Math.ceil(this.feedbacks_state.count / itemsPerPage)
			} else {
				console.log('fetch');
				this.initLoading = true;
				await this.getFeedbacks();
			}
			this.initLoading = false;
		},
	};
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon {
        padding: 0 !important;
    }

    ::v-deep .vertical-border td {
        border-right: 1px solid #eaeaea;;
    }

    ::v-deep tbody {
        tr:hover {
            background-color: transparent !important;
        }

        td {
            .v-expansion-panel {
                margin-top: 5px;
                margin-bottom: 10px;
            }

            .v-expansion-panel-header {
                padding: 5px 10px !important;
                min-height: 0 !important;
                display: flex;
                background: #eee;
                font-size: .8rem;

                &:hover {
                    background: #e4e4e4;
                }
            }
			.theme--dark .v-expansion-panel-header{
				background: #121212;
				&:hover{
					background: #343434;
				}
			}

            .v-expansion-panel-content {
                background: #fafafa;
                padding-top: 10px;
                font-size: .8rem;
                line-height: 1rem;
            }
			.theme--dark .v-expansion-panel-content{
				background: #121212;
			}
        }
    }
</style>

<template>
	<AdvertisementMainComponent/>
</template>

<script>
import AdvertisementMainComponent from '@/components/AdvertisementNew/AdvertisementMainComponent'

export default {
	name: 'AdvertisementNew',
	components:{
		AdvertisementMainComponent
	},
	mounted () {
		document.title = `Реклама | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Рекламные кампании V2`)
	}
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[(_vm.priceLoading)?_c('v-skeleton-loader',{staticClass:"pa-4",attrs:{"type":"table-heading, table-row@5"}}):_c('div',[_c('v-data-table',{staticClass:"elevation-0 vertical-border small-font",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_price,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("onlyTime")(item.date_created))+" ")]}},{key:"item.price_without_sale",fn:function(ref){
var item = ref.item;
return [(item.price_without_sale)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_without_sale))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.price_in_the_moment_with_base_sale",fn:function(ref){
var item = ref.item;
return [(item.price_in_the_moment_with_base_sale)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_in_the_moment_with_base_sale))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.price_in_the_moment",fn:function(ref){
var item = ref.item;
return [(item.price_in_the_moment)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.price_in_the_moment))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.percent_spp",fn:function(ref){
var item = ref.item;
return [(item.percent_spp || item.spp_by_rub)?_c('div',[_vm._v(" "+_vm._s(item.percent_spp ? item.percent_spp : 0)+"% ("+_vm._s(_vm._f("toRUB")(item.spp_by_rub ? item.spp_by_rub : 0))+") ")]):_c('div',[_vm._v(" - ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
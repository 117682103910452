<template>
    <v-container fluid grey lighten-4 class="pa-5">
        <v-card
                class="mx-auto"
                flat
        >		
			<v-sheet class="d-flex">
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Артикул</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								{{ product.nmid }}
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Остаток 1С</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection" v-if="product.dynamic_fields.last_stock_1c">
								{{ product.dynamic_fields.last_stock_1c }}
                            </v-list-item-subtitle>
							<v-list-item-subtitle v-else>
								-
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Остаток WB</v-list-item-title>
                            <v-list-item-subtitle v-if="product.manager">
								{{ product.dynamic_fields.last_stock_wb }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title>Остатки fbs</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection" v-if="product.dynamic_fields.last_stock_fbs">
								{{ product.dynamic_fields.last_stock_fbs}}
							</v-list-item-subtitle>
							<v-list-item-subtitle class="wrap_subsection" v-else>
								-
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
				<v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title>Скорость заказов</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection" v-if="product.dynamic_fields.speed_orders">
								{{ product.dynamic_fields.speed_orders.toFixed(2) }}
							</v-list-item-subtitle>
							<v-list-item-subtitle class="wrap_subsection" v-else>
								-
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-sheet>
			<ProductStockFBSUpdate 
				v-if="product"
				:product_id="product_id" 
				:variations_all="product.variations_all"
				:current_stock="product.recommendation_stock"
				@successUpdateStock="successUpdateStock"
			></ProductStockFBSUpdate>

        </v-card>        
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';
	import ProductStockFBSUpdate from '@/components/Product/ProductStockFBSUpdate'
	
	export default {
		name: 'ProductRecommendationStock',
		data() {
			return {
				product: {},
				new_stock: null
			};
		},
		components: {
			ProductStockFBSUpdate
		},
		computed: {
			product_id() {
				return this.$route.params.id;
			},
			urlWB() {
				return `https://www.wildberries.ru/catalog/${this.product_id}/detail.aspx`;
			},
		},
		methods: {
			async fetchProductData() {
				const response = await Products.fetchProductRecommendationStock(this.product_id);
				this.product = response.data;
				this.new_stock = response.data.recommendation_stock
			},
			successUpdateStock(){
				alert("Остатки обновлены")
			}
		},
		async mounted() {
			document.title = `Изменение остатка fbs | ${this.product_id}`;
			this.$store.commit('setPageTitle', `Изменение остака fbs ${this.product_id}`);
			this.fetchProductData();
		},
	};
</script>

<style scoped lang="scss">
	.wrap_subsection{
		max-width: 150px; 
		overflow: unset; 
		text-overflow: unset; 
		white-space: unset;
	}
    ::v-deep .small-font td {
        font-size: .7rem !important;
    }

    ::v-deep .vertical-border td {
        border-right: 1px solid #eaeaea;
    }

    ::v-deep tbody {
        tr:hover {
            background-color: #f5f5f5 !important;
        }
    }
</style>

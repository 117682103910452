<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="dataLoading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <v-tabs
                    v-model="sizeTab"
                    background-color="transparent"
                    color="basil"
            >
                <v-tab
                        v-for="(balance, size) in balances"
                        :key="size"
                >
                    <span v-if="size == 'summary' || isSingleVariation">Все размеры</span>
                    <span v-else>{{ size }}</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="sizeTab">
                <v-tab-item
                        v-for="(balance, size) in balances"
                        :key="size"
                >
                    <v-simple-table dense class="vertical-border small-font">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Склад
                                </th>
                                <th class="text-left">
                                    Ост.
                                </th>
                                <th class="text-left">
                                    К клиенту
                                </th>
                                <th class="text-left">
                                    От клиента
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in balance"
                                :key="index"
                            >
                                <td>{{ row.warehouse }}</td>
                                <td>{{ row.count }}</td>
                                <td>{{ row.count_in_way_to_client }}</td>
                                <td>{{ row.count_in_way_from_client }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "ProductWarehouses",
    props: {
      product_id: {
        type: String
      },
      date: {
        type: String
      }
    },
    data () {
      return {
        balances: null,
        dataLoading: true,
        sizeTab: 0
      }
    },
      computed: {
          isSingleVariation () {
              return Object.keys(this.balances).length === 1
          },
      },
      methods: {
      async fetchProductWarehousesDay () {
        this.dataLoading = true
        try {
          const res = await Products.fetchProductWarehousesDay(
            this.product_id,
            this.$options.filters.formatDateToQuery(this.date)
          )
          this.balances = res.data
        } catch (err) {
          console.log(err)
        }
        this.dataLoading = false
      }
    },
      async mounted () {
      await this.fetchProductWarehousesDay()
    }
  }
</script>

<style scoped>

</style>

<template>
	<v-row class="mt-3">
		<v-col>
			<div class="d-flex flex-column">
				<v-menu 
					v-model="date_picker.datepicker_date_after" :close-on-content-click="false" transition="scale-transition" 
					offset-y="offset-y" nudge-right="40" min-width="290px" ref="date_picker.datepicker_date_after"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							clearable @click:clear="filters.date_after = null" v-model="dateAfter"
							label="Дата, от" readonly v-bind="attrs" hide-details outlined dense v-on="on"
							:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
						></v-text-field>
					</template>
					<v-date-picker 
						v-model="filters.date_after" 
						@input="date_picker.datepicker_date_after = false"
						:first-day-of-week="1"
					></v-date-picker>
				</v-menu>
				<v-menu 
					v-model="date_picker.datepicker_date_before" :close-on-content-click="false" transition="scale-transition" 
					offset-y="offset-y" nudge-right="40" min-width="290px" ref="date_picker.datepicker_date_before"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							clearable @click:clear="filters.date_before = null" v-model="dateBefore" label="Дата, до"
							readonly v-bind="attrs" hide-details outlined dense v-on="on" class="mt-3"
							:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
						></v-text-field>
					</template>
					<v-date-picker 
						v-model="filters.date_before" 
						@input="date_picker.datepicker_date_before = false"
						:first-day-of-week="1"
					></v-date-picker>
				</v-menu>
			</div>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.account" :items="choices.account" label="Аккаунт"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.campaign_type" :items="choices.campaign_type" label="Тип рекламы"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.campaign_status" :items="choices.campaign_status" label="Статус"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.nmid" clearable label="Артикул"
				outlined dense append-icon="mdi-magnify" hide-details 
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.campaign_id" label="id РК" clearable
				outlined dense append-icon="mdi-magnify" hide-details
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
			<v-text-field
				v-model="filters.campaign_name" clearable outlined dense class="mt-3"
				append-icon="mdi-magnify" label="Название РК" hide-details
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.product_group" :items="choices.product_group" label="Группа товаров"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
			<v-text-field
				v-model="filters.code_1c" clearable outlined dense class="mt-3"
				append-icon="mdi-magnify" label="Артикул 1С" hide-details
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.purpose" :items="choices.purpose" item-text="name"
				item-value="value" label="Цель РК" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-btn
				@click="apply_filters" depressed class="mb-3" 
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			>Применить</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import {Advertisement} from '@/api/advertisement'
import {General} from '@/api/general'

export default {
    name: 'AdvertisementStatsFilterComponent',
    data() {
        return {
			filters: {
				account: null,
				campaign_type: null,
				campaign_status: null,
				nmid: null,
				campaign_id: null,
				product_group: null,
				purpose: null,
				date_after: null,
				date_before: null,
				campaign_name: null,
				code_1c: null
			},
			choices: {
				account: [],
				product_group: [],
				campaign_type: [],
				campaign_status: [],
				purpose: []
			},
			date_picker: {
                datepicker_date_before: null,
                datepicker_date_after: null
            },
		}		
    },
    computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
		dateAfter () {
            return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
        },
        dateBefore () {
            return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
        },
    },
    methods: {
		async fetch_product_filters(){
            try {
                const response = await General.fetchProductGroup()
                this.choices.product_group = response.data
            } catch (err) {
                 console.log(err.data)
            }
		},
		async fetch_advertisement_filters(){
			try {
                const responce = await Advertisement.fetchAdvertisementChoices()
                this.choices.purpose = responce.data.campaign_purpose
				this.choices.account = responce.data.accounts
				this.choices.campaign_type = responce.data.campaign_type
				this.choices.campaign_status = responce.data.campaign_status
            } catch (err) {
                console.log(err)
            }
		},
		apply_filters(){
			this.$emit('applyFilters', this.transfromFiltersForQuery)
		}
    },
    async mounted(){
        await this.fetch_advertisement_filters()
        await this.fetch_product_filters()
		this.apply_filters()
    }
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <apexchart 
        type="line" 
        height="100" 
        :options="options"
        :series="seriesSales"
    ></apexchart>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'OrdersChartProductList',
        components: {apexchart: VueApexCharts},
		props: {
			series: {
				type: Array, default() {return []}, require: true,
			},
			series_price: {
				type: Array, default() {return []}, require: false,
			},
			is_drr: { type: Boolean, default() {return false}},
			is_vp: { type: Boolean, default() {return false}},
			is_visibility: { type: Boolean, default() {return false} }
		},
        data() {
            return {
				seriesSales: null,
				seriesOrders: null,
				seriesPrice: null,
				options: null
            };
        },
		methods: {
			calc_options(){
				if (this.series_price && this.series_price.length > 0){
					this.options = {
						theme: {
							mode: this.$vuetify.theme.dark ? 'dark': 'light'
						},
						chart: {
							height: 100, stacked: false, type: 'line', zoom: { enabled: false }, toolbar: { show: false },
							background: 'rgba(0, 0, 0, 0)'
						},
						legend: { show: false },
						stroke: { curve: 'smooth', width: 2 },
						dataLabels: { enabled: false },
						xaxis: {show: false, labels: {show: false}},
						grid: {
							show: false, row: { colors: ['#f3f3f3', 'transparent'], opacity: 0 },
						},
						yaxis: [
							{ show: false }, { opposite: true, show: false }	
						],
						tooltip: {
							fixed:{ enabled: true, offsetX: -130 }							
						},
					}
				}
				else{
					this.options = {
						theme: {
							mode: this.$vuetify.theme.dark ? 'dark': 'light'
						},
						chart: {
							height: 100, width: '100%', type: 'bar', zoom: {enabled: false}, toolbar: {show: false},
							background: 'rgba(0, 0, 0, 0)'
						},
						stroke: {show: false, width: 0},
						colors: ['#5bb95e'],
						dataLabels: {enabled: false},
						yaxis: {show: false},
						xaxis: {show: false, labels: {show: false}},
						tooltip: { 
							fixed:{ enabled: true, offsetX: -100 } 
						},
						grid: {
							show: false, row: {colors: ['#f3f3f3', 'transparent'], opacity: 0},
						},
					}
				}
			}
		},
		created(){
			let name_orders = !this.is_drr ? 'Заказов' : "Расход"
			let name_price = !this.is_drr ? 'Цена' : "ДРР"
			if (this.is_vp){
				name_orders = 'ВП МП'
				name_price = 'Рентабельность МП'
			}
			if (this.is_visibility){
				name_orders = 'Видимость'
			}
			this.seriesOrders = this.series.map(item => ({x: item.date, y: item.order_count}))
			if (this.series_price && this.series_price.length > 0){
				this.seriesPrice = this.series_price.map(item => (
					{x: item.date, y: item.price ? item.price.toFixed(1): 0}
				))
				this.seriesSales = [
					{
						name: name_orders, type: 'column', data: this.seriesOrders, 
						color: function({ value, seriesIndex, w }) {
							if (value > 0) {
								return '#5ece8c'
							} else {
								return '#6200b5'
							}
						}
					},
					{name: name_price, type: 'line', data: this.seriesPrice, color: '#e8a2af'},
				]
			}
			else{
				this.seriesSales = [
					{name: name_orders, type: 'bar', data: this.seriesOrders},
				]
			}
			this.calc_options()
		},
    };
</script>

<style scoped>

</style>
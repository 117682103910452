<template>
	<div>
		<v-sheet>
			<v-container fluid>
				<v-row>
					<v-col >
						<v-menu 
							v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_from"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable @click:clear="filters.date_after = null" v-model="dateFromFormatted"
									label="Начало периода" prepend-icon="mdi-calendar" readonly="readonly"
									v-bind="attrs" hide-details="auto" outlined dense v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="filters.date_after" :first-day-of-week="1" @input="datepicker_menu_from = false"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col >
						<v-menu 
							v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_to"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable @click:clear="filters.date_before = null" v-model="dateToFormatted"
									label="Конец периода" prepend-icon="mdi-calendar" readonly="readonly"
									hide-details="auto" v-bind="attrs" outlined dense v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="filters.date_before" @input="datepicker_menu_to = false" :first-day-of-week="1"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-spacer></v-spacer>
					<v-col>
						<v-btn
							@click="fetchDataWithFilters" depressed color="purple darken-2" class="mb-3" dark
						>Применить</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-sheet>
		<v-data-table
			dense class="elevation-0 vertical-border small-font"
			:headers="table.headers" :items="table.data" :loading="table.loading"
			:pageCount="table.pagination.numberOfPages"
			@update:page="fetchData" @update:sort-by="fetchData" @update:sort-desc="fetchData"
			:options.sync="table.options" :items-per-page="50" :server-items-length="table.pagination.totalItems"		
		>
			<template v-slot:item.date="{ item }">
				<div style="white-space: nowrap;">{{ item.date }}</div>
			</template>
			<template 
				v-for="rub_prop in ['orders_sum_rub', 'buyout_sum_rub', 'cancel_sum_rub', 'avg_rub_price']" 
				v-slot:[`item.${rub_prop}`]="{ item }"
			>
				<div :key="`val_${rub_prop}`" style="white-space: nowrap; text-align: center;">
					<div>{{ item[rub_prop].current | toRUB }}</div>
					<div :class="{'green_delta': item[rub_prop].dynamic_value > 0, 'red_delta': item[rub_prop].dynamic_value < 0}">
						<v-icon v-if="item[rub_prop].dynamic_value > 0" small color="#067b00">mdi-trending-up</v-icon>
						<v-icon v-else-if="item[rub_prop].dynamic_value < 0" small color="#f96666">mdi-trending-down</v-icon>
						{{ (item[rub_prop].dynamic_percent * 100).toFixed(1) }}% ({{ item[rub_prop].dynamic_value | toRUB }})
					</div>
				</div>
			</template>
			<template 
				v-for="percent_prop in ['cr_cart', 'cr_orders', 'buyout_percent', 'cr_open']" 
				v-slot:[`item.${percent_prop}`]="{ item }"
			>
				<div :key="`val_${percent_prop}`" style="white-space: nowrap; text-align: center;" :class="{'bold_text': percent_prop=='cr_open'}">
					<div>{{ item[percent_prop].current.toFixed(1) }}%</div>
					<div :class="{'green_delta': item[percent_prop].dynamic_value > 0, 'red_delta': item[percent_prop].dynamic_value < 0}">
						<v-icon v-if="item[percent_prop].dynamic_value > 0" small color="#067b00">mdi-trending-up</v-icon>
						<v-icon v-else-if="item[percent_prop].dynamic_value < 0" small color="#f96666">mdi-trending-down</v-icon>
						{{ item[percent_prop].dynamic_value.toFixed(1) }}%
					</div>
				</div>
			</template>
			<template 
				v-for="count_prop in [
					'open_card', 'add_to_cart', 'orders', 'buyout_count', 'cancel_count', 'stock_wb_qty', 
					'stock_mp_qty', 'avg_order_count_per_day'
				]" 
				v-slot:[`item.${count_prop}`]="{ item }"
			>
				<div :key="`val_${count_prop}`" style="white-space: nowrap; text-align: center;" :class="{'bold_text': count_prop=='open_card'}">
					<div>{{ item[count_prop].current }} шт</div>
					<div :class="{'green_delta': item[count_prop].dynamic_value > 0, 'red_delta': item[count_prop].dynamic_value < 0}">
						<v-icon v-if="item[count_prop].dynamic_value > 0" small color="#067b00">mdi-trending-up</v-icon>
						<v-icon v-else-if="item[count_prop].dynamic_value < 0" small color="#f96666">mdi-trending-down</v-icon>
						{{ (item[count_prop].dynamic_percent * 100).toFixed(1) }}% ({{ item[count_prop].dynamic_value }} шт)
					</div>
				</div>
			</template>
		</v-data-table>
	</div>
 </template>
 
 <script>
import { Stats } from '@/api/stats'

export default {
	name: 'ProductCardAnalyticsReports',
	props: ['nmid'],
	data () {
		return {
			table:{
				loading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				data: [],
				test: ['cr_cart'],
				headers_with_rub_value: ['orders_sum_rub', 'buyout_sum_rub', 'cancel_sum_rub', 'avg_rub_price'],
				headers_with_percent_value: ['cr_cart', 'cr_orders', 'buyout_percent'],
				headers_with_count_value: [
					'open_card', 'add_to_cart', 'orders', 'buyout_count', 'cancel_count', 'stock_wb_qty', 
					'stock_mp_qty', 'avg_order_count_per_day'
				],
				headers: [
					{ text: 'Дата', sortable: true, value: 'date', width: '2%' },
					{ text: 'Перешли в карточку',value: 'open_card', width: '2%', sortable: false },
					{ text: 'Положили в корзину', value: 'add_to_cart', width: '2%', sortable: false },
					{ text: 'Конверсия в корзину', value: 'cr_cart', width: '2%', sortable: false },
					{ text: 'Заказали товаров, шт', value: 'orders', width: '2%', sortable: false },
					{ text: 'Конверсия в заказ', value: 'cr_orders', width: '2%', sortable: false },
					{ text: 'CR переход - заказ', value: 'cr_open', width: '2%', sortable: false },
					{ text: 'Заказали товаров, руб', value: 'orders_sum_rub', width: '2%', sortable: false },
					{ text: 'Выкупили товаров, шт', value: 'buyout_count', width: '2%', sortable: false },
					{ text: 'Отменили товаров, шт', value: 'cancel_count', width: '2%', sortable: false },
					{ text: 'Процент выкупа', value: 'buyout_percent', width: '2%', sortable: false },
					{ text: 'Выкупили на сумму, руб', value: 'buyout_sum_rub', width: '2%', sortable: false },
					{ text: 'Отменили на сумму, руб', value: 'cancel_sum_rub', width: '2%', sortable: false },
					{ text: 'Средняя цена', value: 'avg_rub_price', width: '2%', sortable: false },
					{ text: 'Остатки на складе', value: 'stock_wb_qty', width: '2%', sortable: false },
					{ text: 'Остатки МП', value: 'stock_mp_qty', width: '2%', sortable: false },
					{ text: 'Среднее кол-во заказов', value: 'avg_order_count_per_day', width: '2%', sortable: false }
				],
			},
			filters: {
				date_before: null,
				date_after: null,
			},
			datepicker_menu_from: null,
			datepicker_menu_to: null
		}
	},
	computed: {
		transfromFiltersForQuery () {
			let query = {}
			for (const f in this.filters) {
				if (Array.isArray(this.filters[f])) {
					query[f] = this.filters[f].join(',')
				} else {
					query[f] = this.filters[f]
				}
				if (this.filters[f] == false) {
					query[f] = null
				}
			}
			return query
		},
		dateFromFormatted () {
			return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
		},
		dateToFormatted () {
			return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
		}
	},
	methods: {
		async fetchDataWithFilters () {
			this.table.options.page = 1
			await this.fetchData()
		},
		async fetchData () {
			this.table.loading = true
			const { page, itemsPerPage, sortBy, sortDesc } = this.table.options
			let pageNumber = page - 1
			try {
				const res = await Stats.fetchProductCardAnalyticsReport({
					...this.transfromFiltersForQuery, 'offset': itemsPerPage*pageNumber, 
					'limit': itemsPerPage, 'product': this.nmid, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
				})
				this.table.data = res.data.results
				this.table.pagination.totalItems = res.data.count
				this.table.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
			} catch (err) {
				console.log(err)
			}
			this.table.loading = false
		}
	},
	async mounted () {
		await this.fetchData()
	},
}
</script>
 
<style scoped>
	.green_delta{
        color: #067b00;
    }
    .red_delta{
        color: #f96666;
    }
	.bold_text{
		font-weight: bold;
	}
</style>
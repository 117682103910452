<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumb_items">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-row>
            <v-col cols="4">
                <v-card>
                    <v-card-text>
                        <v-list
                                subheader
                                two-line
                        >
                            <v-subheader>Инфо</v-subheader>

                            <v-list-item>
                                <v-list-item-content v-if="component_data">
                                    <v-list-item-title>Название компонента</v-list-item-title>
                                    <v-list-item-subtitle>{{ component_data.component.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content v-if="component_data">
                                    <v-list-item-title>Артикул</v-list-item-title>
                                    <v-list-item-subtitle>{{ component_data.component.code }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

							<v-list-item>
                                <v-list-item-content v-if="component_data">
                                    <v-list-item-title>Группа товаров</v-list-item-title>
                                    <v-list-item-subtitle>
										{{ component_data.component.product_group ? component_data.component.product_group.group_name : "-" }}
									</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
                <v-card class="mt-4">
                    <v-card-subtitle>Себестоимость и остатки</v-card-subtitle>
                    <v-card-text>
                        <v-data-table
                                v-if="component_cost_price_data"
                                :headers="component_cost_price_data_headers"
                                :items="component_cost_price_data"
                                style="width: 100%"
                                class="small-font"
                                dense
                        >
                            <template v-slot:item.date="{ item }">
                                {{ item.date | formatDateToLocaleNoTime }}
                            </template>
                            <template v-slot:item.cost_price="{ item }">
                                {{ item.cost_price | toRUB }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-subtitle>Комплекты</v-card-subtitle>
                    <v-card-text>
                        <v-simple-table dense class="small-font">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Компонент
                                    </th>
                                    <th class="text-left">
                                        Артикул
                                    </th>
                                    <th class="text-left">
                                        Ссылки
                                    </th>
                                    <th class="text-left">
                                        Рецепт
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in component_data.kits"
                                        :key="item.id"
                                >
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <td>
                                        <div><a href="#" @click="toCard(item.code, 'ozon')">Ozon Card</a></div>
                                        <div><a href="#" @click="toCard(item.code, 'wb')">WB Card</a></div>
                                    </td>
                                    <td>
                                        <div v-for="c in item.components" :key="c.id">
											<p :class="['mb-0', {'font-weight-bold': c.kit_component.main_kit}]">
												{{ c.kit_component.name }} ({{ c.kit_component.code }}) - {{ c.count }}
											</p>
                                        </div>
                                    </td>
                                    <!--                                    <td>-->
                                    <!--                                        <router-link-->
                                    <!--                                                target="_blank"-->
                                    <!--                                                :to="{ name: 'kits_component_card', params: { id: item.id }}">-->
                                    <!--                                            {{ item.name }}-->
                                    <!--                                        </router-link>-->
                                    <!--                                    </td>-->
                                    <!--                                    <td>{{ item.code }}</td>-->
                                    <!--                                    <td>{{ item.kits }}</td>-->
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
	import {Catalog} from '@/api/catalog';

	export default {
		name: 'KitsComponentsItem',
		data() {
			return {
				breadcrumb_items: [
					{
						text: 'Компоненты',
						disabled: false,
						href: '/kits-components',
					},
					{
						text: 'Компонент',
						disabled: true,
						href: `/kits-components/${this.component_id}`,
					},
				],
				component_data: null,
				component_cost_price_data: null,
				component_cost_price_data_headers: [
					{text: 'Дата', value: 'date', sortable: false, width: '1%'},
					{text: 'Кол-во', value: 'catalog_count', sortable: false, width: '1%'},
					{text: 'Себест', value: 'cost_price', sortable: false, width: '1%'},
				],
			};
		},
		computed: {
			component_id() {
				return this.$route.params.id;
			},
		},
		async mounted() {
			this.$store.commit('setPageTitle', `Загрузка...`);
			await this.getComponent();
			document.title = `Компонент ${this.component_data.component.name} | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Компонент ${this.component_data.component.name}`);
			await this.getComponentCostPriceDays();
		},
		methods: {
			async getComponent() {
				const response = await Catalog.getComponent(this.component_id);
				if (response) {
					this.component_data = response.data;
				}
			},
			async getComponentCostPriceDays() {
				const response = await Catalog.getComponentCostPriceDays(this.component_data.component.code);
				if (response) {
					this.component_cost_price_data = response.data;
				}
			},
			async toCard(code, mp_name) {
				const response = await Catalog.toCard(code);
				if (mp_name === 'ozon') {
					document.location.href = `/ozon-products/${response.data.ozon}`;
				} else if (mp_name === 'wb') {
					document.location.href = `/products/${response.data.wb}`;
				}
			},
		},
	};
</script>

<style scoped>

</style>
<template>
    <v-container>
		<h2 class="mt-5" fl>Индекс локализации и время доставки</h2>
		<div class="mt-5 d-flex align-center">
			<div>
				<p class="mb-0">Дата обновления доставки: {{ data.last_date_update_delivery ? data.last_date_update_delivery : '-' }}</p>
				<p class="mb-0">Дата обновления индекса: {{ data.last_date_update_index ? data.last_date_update_index : '-' }}</p>
			</div>
			<div class="ml-10 d-flex">
				<v-select
					v-model="filter_supplier" :items="suppliers_list" item-text="name" item-value="id" 
					label="Аккаунт" outlined multiple hide-details dense clearable
				></v-select>
				<v-btn 
					depressed color="purple darken-2" class="ml-5" dark @click="fetchData" :loading="loading"
				>Применить</v-btn>
			</div>
			
		</div>
		<v-card class="mt-5" v-for="(item, index) in data.accounts" :key="index">
			<v-card-title>{{ item.value }}</v-card-title>
			<v-card-text>
				<div class="title">
					Индекс локализации(из поставок): {{getDataByAccount(data.data, item.key, {}).index}}%
				</div>
				<div class="title">
					Среднее время доставки: {{getTextDelivery(getDataByAccount(data.data, item.key, {}).delivery)}}
				</div>
				<div class="title">
					KTR(из поставок): {{getDataByAccount(data.data, item.key, {}).ktr}}
				</div>
				<div class="title">
					Индекс локализации: {{getDataByAccount(data.data, item.key, {}).index2.toFixed(2)}}
				</div>
				<div class="title">
					KTR: {{getDataByAccount(data.data, item.key, {}).ktr2}}
				</div>
			</v-card-text>
		</v-card>	
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';
	import {General} from '@/api/general';

	export default {
		name: 'ProductsWBIndices',
		data() {
			return {
				data: [],
				suppliers_list: [],
				filter_supplier: null,
				loading: false
			};
		},
		methods: {
			async fetchData(){
				this.loading = true
				try{
					let query = {}
					if (this.filter_supplier){
						query = {'accounts': this.filter_supplier.join(',')}
					}
					const response = await Products.wbProductIndicesByAccount(query)
					this.data = response.data
				} catch(err){
					console.log(err)
				}
				this.loading = false
			},
			getDataByAccount(data, account, default_value){
				try{
					return data[account] ? data[account] : 0
				} catch(err){
					return default_value
				}
			},
			async getAccounts(){
				try{
					const response = await General.getWbAccounts()
					this.suppliers_list = response.data
				} catch(err){
					console.log(err)
				}
			},
			getTextDelivery(item){
				if (!item || item.length < 3)
					return `0д 0ч 0м`
				return `${item[0]}д ${item[1]}ч ${item[2]}м`
			}
		},
		async mounted() {
			document.title = `Товары ВБ(Индексы) | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Товары ВБ(Индексы)`);
			await this.fetchData()
			await this.getAccounts()
		},
		
	};
</script>

<style scoped>
	.green_text{
		color: green;
	}
	.red_text{
		color: rgb(157, 17, 17);
	}
	.orange_text{
		color: rgb(215, 143, 9);
	}
</style>
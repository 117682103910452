var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"pa-4",attrs:{"type":"table-heading, table-row@5"}}):_c('v-data-table',{staticClass:"elevation-0 mt-0 vertical-border small-font",attrs:{"headers":_vm.headers,"items":_vm.data},scopedSlots:_vm._u([{key:"item.orders",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orders ? item.orders : 0)+" / "+_vm._s(item.part_orders ? (item.part_orders * 100).toFixed(2) : 0)+"% ")]}},{key:"item.stocks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stocks ? item.stocks : 0)+" / "+_vm._s(item.part_stocks ? (item.part_stocks * 100).toFixed(2) : 0)+"% ")]}},{key:"item.base_tariff_logistic",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"dashed"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.base_logistic ? item.base_logistic : 0))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre"},domProps:{"textContent":_vm._s(item.help_text_base_logistic)}})])]}},{key:"item.base_tariff_storage",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"dashed"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.base_storage_cost ? item.base_storage_cost : 0))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre"},domProps:{"textContent":_vm._s(item.help_text_base_storage_cost)}})])]}},{key:"item.warehouse_tariffs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariffs_logistic ? item.tariffs_logistic : 0)+"% / "+_vm._s(item.tariffs_storage ? item.tariffs_storage : 0)+"% ")]}},{key:"item.logistic_stocks",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"dashed"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.logistic_stocks ? item.logistic_stocks : 0))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre"},domProps:{"textContent":_vm._s(item.help_text_logistic_stocks)}})])]}},{key:"item.logistic_orders",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"dashed"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.logistic_orders ? item.logistic_orders : 0))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre"},domProps:{"textContent":_vm._s(item.help_text_logistic_orders)}})])]}},{key:"item.storage_cost",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"dashed"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.storage_cost ? item.storage_cost : 0))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre"},domProps:{"textContent":_vm._s(item.help_text_storage_cost)}})])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"sticky-table-footer",staticStyle:{"font-weight":"bold"}},_vm._l((_vm.headers),function(col,index){return _c('td',{key:index},[(index == 0)?_c('div',[_vm._v("Итог: ")]):(!col.summary)?_c('div'):_c('div',[_vm._v(_vm._s(_vm.sum_values(col.value)))])])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-subheader',[_vm._v("Вывод отчета")]),_c('v-row',{},[_c('v-col',{attrs:{"cols":"4","md":"4","lg":"3"}},[_c('div',{staticClass:"mb-2"},[_c('v-select',{attrs:{"items":_vm.choices.sellers,"item-text":"name","item-value":"id","label":"Аккаунт","prepend-icon":"mdi-account","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.seller),callback:function ($$v) {_vm.$set(_vm.filters, "seller", $$v)},expression:"filters.seller"}})],1),_c('div',{staticClass:"mb-1"},[_c('v-menu',{ref:"datepicker_menu_from",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Начало периода","prepend-icon":"mdi-calendar","readonly":"readonly","hide-details":"auto","outlined":"","dense":""},on:{"click:clear":function($event){_vm.filters.date_from = null}},model:{value:(_vm.dateFromFormatted),callback:function ($$v) {_vm.dateFromFormatted=$$v},expression:"dateFromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_from),callback:function ($$v) {_vm.datepicker_menu_from=$$v},expression:"datepicker_menu_from"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.datepicker_menu_from = false}},model:{value:(_vm.filters.date_from),callback:function ($$v) {_vm.$set(_vm.filters, "date_from", $$v)},expression:"filters.date_from"}})],1)],1),_c('div',[_c('v-menu',{ref:"datepicker_menu_to",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Конец периода","prepend-icon":"mdi-calendar","readonly":"readonly","hide-details":"auto","outlined":"","dense":""},on:{"click:clear":function($event){_vm.filters.date_to = null}},model:{value:(_vm.dateToFormatted),callback:function ($$v) {_vm.dateToFormatted=$$v},expression:"dateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_to),callback:function ($$v) {_vm.datepicker_menu_to=$$v},expression:"datepicker_menu_to"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.datepicker_menu_to = false}},model:{value:(_vm.filters.date_to),callback:function ($$v) {_vm.$set(_vm.filters, "date_to", $$v)},expression:"filters.date_to"}})],1)],1),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-btn',{attrs:{"disabled":!_vm.allowGenerateReport,"depressed":"","color":"primary"},on:{"click":_vm.getTransactionsTotalsReport}},[_vm._v("Сформировать")])],1)]),_c('v-col',{attrs:{"cols":"8","lg":"9"}},[(_vm.reportsData && _vm.reportsData.summary_reports_data)?_c('div',{staticClass:"mb-3"},[_c('h2',{staticClass:"mb-1"},[_vm._v("Суммарный ("+_vm._s(_vm.reportsData.date_from)+" - "+_vm._s(_vm.reportsData.date_to)+")")]),_c('v-data-table',{staticClass:"elevation-1 small-font",attrs:{"headers":_vm.reportsTable.headersSummary,"items":_vm.reportsData.summary_reports_data,"hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.processing_and_delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.processing_and_delivery))+" ")]}},{key:"item.accruals_for_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.accruals_for_sale))+" ")]}},{key:"item.sale_commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.sale_commission))+" ")]}},{key:"item.refunds_and_cancellations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.refunds_and_cancellations))+" ")]}},{key:"item.compensation_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.compensation_amount))+" ")]}},{key:"item.money_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.money_transfer))+" ")]}},{key:"item.others_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.others_amount))+" ")]}},{key:"item.detail",fn:function(ref){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.reportDetailOpen(_vm.reportsData.date_from, _vm.reportsData.date_to, _vm.reportsData.account)}}},[_vm._v(" Детализация ")])]}}],null,false,3970427404)})],1):_vm._e(),(_vm.reportsData && _vm.reportsData.everyday_reports_data.length > 0)?_c('div',{staticClass:"mb-3"},[_c('h2',{staticClass:"mb-1"},[_vm._v("Ежедневный")]),_c('v-data-table',{staticClass:"elevation-1 small-font",attrs:{"headers":_vm.reportsTable.headersEveryday,"items":_vm.reportsData.everyday_reports_data,"item-key":"id"},scopedSlots:_vm._u([{key:"item.processing_and_delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.processing_and_delivery))+" ")]}},{key:"item.accruals_for_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.accruals_for_sale))+" ")]}},{key:"item.sale_commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.sale_commission))+" ")]}},{key:"item.refunds_and_cancellations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.refunds_and_cancellations))+" ")]}},{key:"item.compensation_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.compensation_amount))+" ")]}},{key:"item.money_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.money_transfer))+" ")]}},{key:"item.others_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.others_amount))+" ")]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.reportDetailOpen(item.date, item.date, item.account)}}},[_vm._v(" Детализация ")])]}}],null,false,3239607916)})],1):_vm._e()])],1)],1),(_vm.reportDetailModal)?_c('v-dialog',{attrs:{"max-width":"960"},model:{value:(_vm.reportDetailModal),callback:function ($$v) {_vm.reportDetailModal=$$v},expression:"reportDetailModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.reportDetailModalHeadingTitle)+" ")]),_c('OzonTransactionsReportsDetail',{attrs:{"date-from":_vm.reportDetailQuery.date_from,"date-to":_vm.reportDetailQuery.date_to,"account-id":_vm.reportDetailQuery.account_id}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { HTTP } from './http'

export const Analytics = {
	async fetchCoefficientsByCode1C (code_1c, date) {
		return HTTP.get(`/analytics/coefficients/`, {
			params: {
				code_1c,
				date
			}
		})
	},
}
<template>
	<AdvertisementCreateComponent/>
</template>

<script>
import AdvertisementCreateComponent from '@/components/AdvertisementNew/AdvertisementCreateComponent'

export default {
	name: 'AdvertisementCreateView',
	components:{
		AdvertisementCreateComponent
	},
	mounted () {
		document.title = `Создание РК | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Создание рекламной кампании`)
	}
}
</script>
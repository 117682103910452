import { render, staticRenderFns } from "./PlansBlockComponent.vue?vue&type=template&id=4cc03c56&scoped=true&"
import script from "./PlansBlockComponent.vue?vue&type=script&lang=js&"
export * from "./PlansBlockComponent.vue?vue&type=script&lang=js&"
import style0 from "./PlansBlockComponent.vue?vue&type=style&index=0&id=4cc03c56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc03c56",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.reportDetailFetchLoading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}}):(_vm.items && _vm.items.length > 0)?_c('v-data-table',{staticClass:"elevation-1 small-font",attrs:{"headers":_vm.reportDetailTable.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateToLocaleNoTime")(item.date))+" ")]}},{key:"item.accruals_for_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.accruals_for_sale))+" ")]}},{key:"item.sale_commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.sale_commission))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.amount))+" ")]}},{key:"item.expenses",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.expenses))+" ")]}},{key:"item.operation_type_name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.operation_type_name))])]}}])}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>

import {PriceMonitoring} from '@/api/price_monitoring';
import { nextTick } from 'vue'

export default{
  name: 'PriceMonitoringProductsDataTable',
  data () {
    return {
      loading: true,
      tableData: null,
      tableSearch: '',
      dataTable: {
        loading: false,
        headers: [
          { text: 'Название', align: 'start', sortable: false, value: 'name', width: '1%'},
          { text: 'Группа', align: 'start', sortable: false, value: 'group', width: '1%' },
          { text: 'Бренд', align: 'start', sortable: false, value: 'brand', width: '1%' },
          { text: 'URL', align: 'start', sortable: false, value: 'link', width: '1%' },
          { text: 'Артикул WB', align: 'start', sortable: false, value: 'nmid', width: '1%' },
          { text: 'СПП', value: 'spp_data', width: '1%', sortable: false },
          { text: 'В продаже', value: 'on_sale_data', width: '1%', sortable: true },
          { text: 'Цена посл.', value: 'price_last_data', width: '1%', sortable: true },
          { text: 'Цена нов.', value: 'price_new_data', width: '1%', sortable: true },
          { text: 'Посл. проверка', value: 'last_check_date', width: '1%', sortable: true },
          { text: 'Действия', value: 'actions', width: '1%', sortable: true },
        ],
      },
    }
  },
  methods: {
    itemRowBackground (item) {
      if (!item.available) {
        return 'red lighten-4'
      }
    },
    async fetchData () {
      this.loading = true
      try {
        const response = await PriceMonitoring.getAll()
        this.tableData = response.data
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async deleteItem(id) {
      await PriceMonitoring.deleteItem(id)
      await this.fetchData()
    },
    async refreshItem(row) {
      const newRowDataResponse = await PriceMonitoring.runCheckPartial(row.id)
      const itemIndex = this.tableData.findIndex((obj => obj.id == row.id))
      this.$set(this.tableData, itemIndex, newRowDataResponse.data)
    }
  },
  async mounted () {
      await this.fetchData()
  }
}
</script>

<template>
  <div>
    <v-skeleton-loader type='table' v-if="loading"></v-skeleton-loader>
    <div v-else>
      <v-container fluid class="d-flex justify-start">
        <v-row>
          <v-col cols="4">
            <v-text-field
                v-model="tableSearch"
                append-icon="mdi-magnify"
                label="Фильтр"
                single-line
                dense
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        dense
        :headers="dataTable.headers"
        :items="tableData"
        item-key="nmid"
        :search="tableSearch"
        :loading="dataTable.loading"
        class="elevation-1 small-font"
        :item-class="itemRowBackground"
        :footer-props="{
					'items-per-page-options': [5, 15, 25, 50, 100]
				}"
    >
      <template v-slot:item.link="{ item }">
        <a :href="item.link" target="_blank">WB url</a>
      </template>
      <template v-slot:item.spp_data="{ item }">
        <div>
          <div class="font-weight-bold">{{item.spp}}%</div>
          <div v-if="item.spp_date">{{item.spp_date | formatDateToLocale}}</div>
        </div>
      </template>
      <template v-slot:item.price_last_data="{ item }">
        <div>
          <div class="font-weight-bold">{{item.price_last}}₽</div>
          <div v-if="item.price_last_date">{{item.price_last_date | formatDateToLocale}}</div>
        </div>
      </template>
      <template v-slot:item.price_new_data="{ item }">
        <div>
          <div class="font-weight-bold">{{item.price_new}}₽</div>
          <div v-if="item.price_new_date">{{item.price_new_date | formatDateToLocale}}</div>
        </div>
      </template>
      <template v-slot:item.last_check_date="{ item }">
        <div v-if="item.last_check_date">
          {{item.last_check_date | formatDateToLocale}}
        </div>
      </template>
      <template v-slot:item.on_sale_data="{ item }">
        <div v-if="item.on_sale">
          <v-icon style="color: green"> mdi-check </v-icon>
        </div>
        <div v-else-if="item.on_sale == null">
        </div>
        <div v-else>
          <v-icon style="color: red"> mdi-close </v-icon>
        </div>
        <div v-if="item.on_sale_date">Посл. наличие: {{item.on_sale_date | formatDateToLocale}}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top open-delay="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="deleteItem(item.id)" v-bind="attrs" v-on="on"> mdi-delete </v-icon>
          </template>
          <span style="font-size: .7rem;">Удалить позицию</span>
        </v-tooltip>
        <v-tooltip top open-delay="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="refreshItem(item)" v-bind="attrs" v-on="on"> mdi-refresh </v-icon>
          </template>
          <span style="font-size: .7rem;">Обновить данные</span>
        </v-tooltip>
      </template>
    </v-data-table>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<template>
    <v-app id="wb-feedbacks-parser">
        <div>
            <v-toolbar
                    dark
                    color="purple darken-3"
                    dense
                    height="50px"
            >
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title>DashWB | {{ $store.state.general.pageTitle || 'Начало' }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!--            <v-btn icon>-->
                <!--                <v-icon>mdi-filter-variant</v-icon>-->
                <!--            </v-btn>-->
				<v-switch 
					hide-details label="Темная тема" class="mr-10" v-model="is_dark_scheme"
					@change="changeDarkScheme"
				></v-switch>
                <span>{{ $store.state.auth.user.username }}</span>
                <v-btn icon link href="/auth/logout">
                    <v-icon>mdi-logout-variant</v-icon>
                </v-btn>
            </v-toolbar>
        </div>
        <v-navigation-drawer
                v-model="drawer"
                color="purple darken-4"
                dark
                temporary
                absolute
                app
        >

            <v-list dense>
                <v-list-item
                        v-for="item in actualNavLinks"
                        :key="item.icon"
                        link
                        @click="$router.push({ name: item.slug })"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
	export default {
		data: () => ({
			is_dark_scheme: false,
			drawer: null,
			superLinks: [
				{
					'icon': 'mdi-view-dashboard-variant',
					'title': 'Сводка',
					'slug': 'dashboard',
				},
				{
					'icon': 'mdi-post-outline',
					'title': 'Отзывы',
					'slug': 'feedbacks',
				},
				{
					'icon': 'mdi-account-details',
					'title': 'Профили пользователей',
					'slug': 'profiles',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ВБ',
					'slug': 'products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары OZON',
					'slug': 'ozon-products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ДМ',
					'slug': 'detmir-products',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[Wildberries] Отчет по динамике показателей по дням',
					'slug': 'dynamic_indicators_day',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Отчет по динамике показателей по дням',
					'slug': 'ozon_dynamic_indicators_day',
				},
				{
					'icon': 'mdi-floor-plan',
					'title': 'Плановая юнит экономика',
					'slug': 'planned_unit_economics',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Компоненты',
					'slug': 'kits-components',
				},
				{
					'icon': 'mdi-cart',
					'title': 'Заказы',
					'slug': 'orders',
				},
				{
					'icon': 'mdi-order-bool-descending-variant',
					'title': 'Продажи и возвраты',
					'slug': 'sales',
				},
				{
					'icon': 'mdi-poll',
					'title': 'Аналитика(Продажи и заказы)',
					'slug': 'analytics',
				},
				{
					'icon': 'mdi-truck-delivery-outline',
					'title': 'Поставки',
					'slug': 'supples',
				},
				{
					'icon': 'mdi-truck-delivery',
					'title': 'Поставки WB',
					'slug': 'incomes-from-wb',
				},
				{
					'icon': 'mdi-table-clock',
					'title': 'Планирование поставок',
					'slug': 'supply_planning_list',
				},
				{
					'icon': 'mdi-package-variant-closed',
					'title': 'Каталог',
					'slug': 'catalog',
				},
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы WB',
					'slug': 'redemption',
				},
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы Ozon',
					'slug': 'ozon-redemption',
				},
				{
					'icon': 'mdi-hammer-screwdriver',
					'title': 'Администрирование',
					'slug': 'administration',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама ВБ',
					'slug': 'advertisement_list',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама OZON',
					'slug': 'ozon_advertisement_new',
				},
				{
					'icon': 'mdi-list-box-outline',
					'title': 'Выкупы(список на получение)',
					'slug': 'redemption_receiption',
				},
				{
					'icon': 'mdi-list-box-outline',
					'title': 'ОЗОН Выкупы(список на получение)',
					'slug': 'ozon_redemption_receiption',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Недельные отчеты ВБ',
					'slug': 'weekly_wb_reports',
				},
				{
					'icon': 'mdi-cart-percent',
					'title': 'Продажи по регионам ВБ',
					'slug': 'region_sale_reports',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Финансовые отчеты ОЗОН',
					'slug': 'ozon_finance_report',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Финансовые отчеты ОЗОН',
					'slug': 'ozon_finance_report',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром WB',
					'slug': 'management_product_templates',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром OZON',
					'slug': 'ozon_management_product_list',
				},
				{
					'icon': 'mdi-test-tube',
					'title': 'Управление ценой WB',
					'slug': 'price_management_templates',
				},
				{
					'icon': 'mdi-sale-outline',
					'title': 'Акции ВБ',
					'slug': 'wb_product_promotions'
				},
				{
					'icon': 'mdi-file-eye-outline',
					'title': '[WB] Мониторинг цен',
					'slug': 'price_monitoring',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Финансовые отчеты',
					'slug': 'ozon_transactions_reports',
				},	
				{
					'icon': 'mdi-table-clock',
					'title': 'Проверка коэффициентов складов',
					'slug': 'check_free_suppl_coef'
				},	
				{
					'icon': 'mdi-ethereum',
					'title': 'Парсер социальных сетей',
					'slug': 'social_parser'
				}
			],
			analyticLinks: [
				{
					'icon': 'mdi-view-dashboard-variant',
					'title': 'Сводка',
					'slug': 'dashboard',
				},
				{
					'icon': 'mdi-post-outline',
					'title': 'Отзывы',
					'slug': 'feedbacks',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ВБ',
					'slug': 'products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары OZON',
					'slug': 'ozon-products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ДМ',
					'slug': 'detmir-products',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[Wildberries] Отчет по динамике показателей по дням',
					'slug': 'dynamic_indicators_day',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Отчет по динамике показателей по дням',
					'slug': 'ozon_dynamic_indicators_day',
				},
				{
					'icon': 'mdi-floor-plan',
					'title': 'Плановая юнит экономика',
					'slug': 'planned_unit_economics',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Компоненты',
					'slug': 'kits-components',
				},
				{
					'icon': 'mdi-cart',
					'title': 'Заказы',
					'slug': 'orders',
				},
				{
					'icon': 'mdi-order-bool-descending-variant',
					'title': 'Продажи и возвраты',
					'slug': 'sales',
				},
				{
					'icon': 'mdi-poll',
					'title': 'Аналитика(Продажи и заказы)',
					'slug': 'analytics',
				},
				{
					'icon': 'mdi-truck-delivery',
					'title': 'Поставки WB',
					'slug': 'incomes-from-wb',
				},
				{
					'icon': 'mdi-table-clock',
					'title': 'Планирование поставок',
					'slug': 'supply_planning_list',
				},
				{
					'icon': 'mdi-package-variant-closed',
					'title': 'Каталог',
					'slug': 'catalog',
				},
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы WB',
					'slug': 'redemption',
				},
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы Ozon',
					'slug': 'ozon-redemption',
				},
				{
					'icon': 'mdi-hammer-screwdriver',
					'title': 'Администрирование',
					'slug': 'administration',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама ВБ',
					'slug': 'advertisement_list',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама OZON',
					'slug': 'ozon_advertisement_new',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Недельные отчеты ВБ',
					'slug': 'weekly_wb_reports',
				},
				{
					'icon': 'mdi-cart-percent',
					'title': 'Продажи по регионам ВБ',
					'slug': 'region_sale_reports',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Финансовые отчеты ОЗОН',
					'slug': 'ozon_finance_report',
				},
				{
					'icon': 'mdi-file-chart-outline',
					'title': 'Финансовые отчеты ОЗОН',
					'slug': 'ozon_finance_report',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром WB',
					'slug': 'management_product_templates',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром OZON',
					'slug': 'ozon_management_product_list',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Финансовые отчеты',
					'slug': 'ozon_transactions_reports',
				},	
				{
					'icon': 'mdi-ethereum',
					'title': 'Парсер социальных сетей',
					'slug': 'social_parser'
				}
			],
			priceMonitoringLinks: [
				{
				'icon': 'mdi-file-eye-outline',
				'title': '[WB] Мониторинг цен',
				'slug': 'price_monitoring',
				}
			],
			redemptionLinks: [
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы WB',
					'slug': 'redemption',
				},
				{
					'icon': 'mdi mdi-cart-arrow-right',
					'title': 'Выкупы Ozon',
					'slug': 'ozon-redemption',
				},
				{
					'icon': 'mdi-list-box-outline',
					'title': 'Выкупы(список на получение)',
					'slug': 'redemption_receiption',
				},
				{
					'icon': 'mdi-list-box-outline',
					'title': 'ОЗОН Выкупы(список на получение)',
					'slug': 'ozon_redemption_receiption',
				}
			],
			advertisementLinks: [
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама ВБ',
					'slug': 'advertisement_list',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Реклама OZON',
					'slug': 'ozon_advertisement_new',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Статистика реклама WB',
					'slug': 'advertisement_stats',
				},
				{
					'icon': 'mdi-view-list-outline',
					'title': 'Статистика реклама OZON',
					'slug': 'ozon_advertisement_stats',
				}
			],
			socialLinks: [
				{
					'icon': 'mdi-ethereum',
					'title': 'Парсер социальных сетей',
					'slug': 'social_parser'
				}
			],
			managerLinks: [
				{
					'icon': 'mdi-archive',
					'title': 'Товары ВБ',
					'slug': 'products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары OZON',
					'slug': 'ozon-products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ДМ',
					'slug': 'detmir-products',
				},
				{
					'icon': 'mdi-post-outline',
					'title': 'Отзывы',
					'slug': 'feedbacks',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[Wildberries] Отчет по динамике показателей по дням',
					'slug': 'dynamic_indicators_day',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Отчет по динамике показателей по дням',
					'slug': 'ozon_dynamic_indicators_day',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Компоненты',
					'slug': 'kits-components',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром WB',
					'slug': 'management_product_templates',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром OZON',
					'slug': 'ozon_management_product_list',
				}
			],
			productManagerLinks: [
				{
					'icon': 'mdi-archive',
					'title': 'Товары ВБ',
					'slug': 'products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары OZON',
					'slug': 'ozon-products',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Товары ДМ',
					'slug': 'detmir-products',
				},
				{
					'icon': 'mdi-post-outline',
					'title': 'Отзывы',
					'slug': 'feedbacks',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[Wildberries] Отчет по динамике показателей по дням',
					'slug': 'dynamic_indicators_day',
				},
				{
					'icon': 'mdi-chart-box-outline',
					'title': '[OZON] Отчет по динамике показателей по дням',
					'slug': 'ozon_dynamic_indicators_day',
				},
				{
					'icon': 'mdi-archive',
					'title': 'Компоненты',
					'slug': 'kits-components',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром WB',
					'slug': 'management_product_templates',
				},
				{
					'icon': 'mdi-wrench',
					'title': 'Управление товаром OZON',
					'slug': 'ozon_management_product_list',
				},
			],
			supplManagerLinks: [
				{
					'icon': 'mdi-truck-delivery-outline',
					'title': 'Поставки',
					'slug': 'supples',
				},
				{
					'icon': 'mdi-table-clock',
					'title': 'Планирование поставок',
					'slug': 'supply_planning_list',
				},
				{
					'icon': 'mdi-table-clock',
					'title': 'Проверка коэффициентов складов',
					'slug': 'check_free_suppl_coef'
				}
			],
			ozonLinks: [
				{
					'icon': 'mdi-archive',
					'title': 'Товары OZON',
					'slug': 'ozon-products',
				}
			],
			catalogManager: [
				{
					'icon': 'mdi-package-variant-closed',
					'title': 'Каталог',
					'slug': 'catalog',
				},
			]
		}),
		computed: {
			isAnalyticManager(){
				return this.$store.getters.isAnalyticManager || false
			},
			isManager() {
				return this.$store.getters.isManager || false;
			},
			isSuperManager() {
				return this.$store.getters.isSuperManager || false;
			},
			isSupplManager() {
				return this.$store.getters.isSupplManager || false;
			},
			isProductManager() {
				return this.$store.getters.isProductManager || false;
			},
			isCatalogManager(){
				return this.$store.getters.isCatalogManager || false
			},
			isOzonManager() {
				return this.$store.getters.isOzon || false;
			},
			isAdvertisementManager(){
				return this.$store.getters.isAdvertisementManager || false;
			},
			isRedemptionUser() {
				return (this.$store.getters.isRedemptionUser || this.$store.getters.isRedemptionManager) || false;
			},
			isPriceMonitoring() {
				return (this.$store.getters.isPriceMonitoring || false)
			},
			isSocialManager() {
				return (this.$store.getters.isSocialManager || false)
			},
			actualNavLinks() {
				let actualLinks = [];
				if (this.isAnalyticManager){
					actualLinks.push(...this.analyticLinks)
				}
				if (this.isSocialManager){
					actualLinks.push(...this.socialLinks)
				}
				if (this.isCatalogManager){
					actualLinks.push(...this.catalogManager)
				}
				if (this.isSuperManager) {
					actualLinks.push(...this.superLinks);
				}
				if (this.isManager) {
					actualLinks.push(...this.managerLinks);
				}
				if (this.isSupplManager) {
					actualLinks.push(...this.supplManagerLinks);
				}
				if (this.isRedemptionUser) {
					actualLinks.push(...this.redemptionLinks);
				}
				if (this.isAdvertisementManager) {
					actualLinks.push(...this.advertisementLinks)
				}
				if (this.isOzonManager) {
				actualLinks.push(...this.ozonLinks)
				}
				if (this.isPriceMonitoring) {
				actualLinks.push(...this.priceMonitoringLinks)
				}

				// отсекаем дубликаты в линках
				const slugs = actualLinks.map(o => o.slug)
                const filteredActualLinks = actualLinks.filter(({slug}, index) => !slugs.includes(slug, index + 1))

				return filteredActualLinks;
			},
		},
		methods: {
			changeDarkScheme(){
				if (localStorage.getItem("lomex_dark_scheme")){
					this.$vuetify.theme.dark = false;
					localStorage.removeItem('lomex_dark_scheme')
				} else{
					this.$vuetify.theme.dark = true;
					localStorage.setItem('lomex_dark_scheme', true)
				}
			},
			checkDarkScheme(){
				if (localStorage.getItem("lomex_dark_scheme")){
					this.is_dark_scheme = true
					this.$vuetify.theme.dark = true;
				} else{
					this.is_dark_scheme = false
					this.$vuetify.theme.dark = false;
				}
			},
		},
		mounted() {
			this.$store.dispatch('inspectToken');
			this.checkDarkScheme()
		},
		updated() {
			this.$store.dispatch('inspectToken');
		},
	};
</script>

<style lang="scss">
    /*::v-deep .toasted{*/
    /*    font-family: '11', sans-serif;*/
    /*}*/
    .scroll {
        overflow-y: scroll
    }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product_stock_balances"},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"datepicker_menu_from",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Диапазон","prepend-icon":"mdi-calendar","readonly":"readonly","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dataRangeText),callback:function ($$v) {_vm.dataRangeText=$$v},expression:"dataRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_from),callback:function ($$v) {_vm.datepicker_menu_from=$$v},expression:"datepicker_menu_from"}},[_c('v-date-picker',{attrs:{"range":"","first-day-of-week":1},model:{value:(_vm.dates_range),callback:function ($$v) {_vm.dates_range=$$v},expression:"dates_range"}})],1)],1),_c('v-col',[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"purple darken-2","dark":""},on:{"click":function($event){return _vm.fetch_product_data_days()}}},[_vm._v(" Показать ")])],1),_c('v-spacer')],1),(_vm.table_loading)?_c('v-skeleton-loader',{staticClass:"pa-4",attrs:{"type":"table-heading, table-row@5"}}):_c('BeeGridTable',{staticClass:"product_table mt-5",attrs:{"border":"","showFilter":false,"showPager":false,"height":"800","columns":_vm.prepared_headers,"data":_vm.prepared_data},scopedSlots:_vm._u([{key:"column_name",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"small-text",staticStyle:{"flex-direction":"column","align-items":"center","padding":"0px 5px"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"head",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('div',{class:['small-text', {'black-cell': _vm.is_empty_cell(row.label)}]},[_vm._v(" "+_vm._s(row[column.key])+" ")])]}},{key:"data",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(_vm.is_empty_cell(row.label))?_c('div',{staticClass:"black-cell"},[_vm._v(" "+_vm._s(row[column.key])+" ")]):(row.label=='price')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-1"},'div',attrs,false),on),[_c('a',{staticClass:"dashed small-text",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key][0] ? row[column.key][0] : 0))+" ")])])]}}],null,true)},[_c('span',[_c('p',[_vm._v("Цена без скидкой: "+_vm._s(_vm._f("toRUB")(row[column.key][1] ? row[column.key][1] : 0)))]),_c('p',[_vm._v("Цена со скидкой: "+_vm._s(_vm._f("toRUB")(row[column.key][2] ? row[column.key][2] : 0)))]),_c('p',[_vm._v("Залоговая цена: "+_vm._s(_vm._f("toRUB")(row[column.key][3] ? row[column.key][3] : 0)))]),_c('p',[_vm._v("Процент скидки: "+_vm._s(row[column.key][4] ? row[column.key][4] : 0)+"%")])])])],1):(row.label == 'cost_price')?_c('div',{staticClass:"small-text"},[_c('a',{staticClass:"dashed",on:{"click":function($event){return _vm.open_cost_price_dialog(column.key)}}},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key] ? row[column.key] : 0))+" ")])]):(row.label=='vp_plan')?_c('div',{staticClass:"yellow-cell"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"dashed small-text",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][0] ? row[column.key][0] : 0))+" ")])])]}}],null,true)},[_c('span',[_c('div',{staticClass:"small-text"},[_vm._v(" Цена: "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][1] ? row[column.key][1] : 0))+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Комиссия : "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][1] ? row[column.key][1] * 0.2 : 0))+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Логистика : "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][1] ? row[column.key][1] * 0.02 : 0))+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Себестоимость : "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][2] ? row[column.key][2] : 0))+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Налог("+_vm._s(row[column.key] && row[column.key][3] ? row[column.key][3] : 0)+"%): "+_vm._s(_vm._f("toRUB")(row[column.key] && row[column.key][3] && row[column.key][1] ? row[column.key][3] * row[column.key][1] / 100 : 0))+" ")])])])],1):(row.label=='rentab_plan' || row.label=='markup_plan')?_c('div',{staticClass:"yellow-cell"},[_c('div',{staticClass:"small-text"},[_vm._v(" "+_vm._s(row[column.key] ? (row[column.key] * 100).toFixed(2) : 0)+" % ")])]):(
					row.label=='orders_sum' || row.label=='sales_sum' || 
					row.label=='sales_cost_price' || row.label=='warenwert_mp'
				)?_c('div',[_c('div',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key] ? row[column.key] : 0))+" ")])]):(row.label=='vp_plan_orders')?_c('div',{staticClass:"yellow-cell"},[_c('div',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key] ? row[column.key] : 0))+" ")])]):(row.label=='stocks_1c')?_c('div',[(row[column.key] && (row[column.key][0] || row[column.key][1] || row[column.key][2]))?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"dashed small-text",class:{'bold-text': row[column.key][2]},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(row[column.key][0] || row[column.key][2] ? Math.floor(row[column.key][0] + row[column.key][2]) : '-')+" ")])])]}}],null,true)},[_c('span',[_c('div',{staticClass:"small-text"},[_vm._v(" Остаток на складе: "+_vm._s(row[column.key] ? row[column.key][0]: 0)+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Остаток основного компонента: "+_vm._s(row[column.key] ? row[column.key][2]: 0)+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Возможные комплекты: "+_vm._s(row[column.key] ? row[column.key][1]: 0)+" ")])])]):_vm._e()],1):(row.label=='warenwert_1c')?_c('div',[(row[column.key] && row[column.key][0] && (row[column.key][1] || row[column.key][2]))?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"dashed small-text",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm._f("toRUB")(row[column.key][0] * (row[column.key][1] + row[column.key][2])))+" ")])])]}}],null,true)},[_c('span',[_c('div',{staticClass:"small-text"},[_vm._v(" Склад: "+_vm._s(_vm._f("toRUB")(row[column.key][1] * row[column.key][0]))+" ")]),_c('div',{staticClass:"small-text"},[_vm._v(" Основной компонент: "+_vm._s(_vm._f("toRUB")(row[column.key][2] * row[column.key][0]))+" ")])])]):_c('div',{staticClass:"small-text"},[_vm._v("-")])],1):_c('div',{staticClass:"small-text"},[(row[column.key])?_c('div',{staticClass:"small-text"},[_vm._v(_vm._s(row[column.key]))]):_c('div',{staticClass:"small-text"},[_vm._v("-")])])]}}])}),(_vm.dialogs.cost_price_dialog)?_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogs.cost_price_dialog),callback:function ($$v) {_vm.$set(_vm.dialogs, "cost_price_dialog", $$v)},expression:"dialogs.cost_price_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Себестоимость на "+_vm._s(_vm._f("formatDateToLocaleNoTime")(_vm.dates.cost_price_dates))+" ")]),_c('div',{staticStyle:{"height":"400px"}},[_c('CostPriceDetails',{attrs:{"catalog_item_id":_vm.catalog_id,"date":_vm.dates.cost_price_dates}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./PriceApiInTheMomentTable.vue?vue&type=template&id=33d543b6&"
import script from "./PriceApiInTheMomentTable.vue?vue&type=script&lang=js&"
export * from "./PriceApiInTheMomentTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VDataTable,VSheet,VSkeletonLoader})

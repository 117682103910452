<template>
	<div>
		<v-dialog v-model="dialog_interval_show" max-width="1200" v-if="dialog_interval_show">
			<v-card>
				<v-card-title>Управление интервалами показа</v-card-title>
				<v-card-text>
					<DialogAdvertisementManagementIntervalShow 
						:robot_settings="data.robot_settings" @successSetDisplayArea="success_set_display_area"
					/>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-dialog v-model="dialog_change" v-if="dialog_change" max-width="800">
			<v-card>
				<v-card-title>{{ dialog_change_title }}</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="data.robot_settings.robot_cpm_wanted_placement" outlined 
						dense v-if="change_field=='wanted_placement'" 
						@change="update_advertisement_robots_settings({'robot_cpm_wanted_placement': data.robot_settings.robot_cpm_wanted_placement})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-text-field
						v-model="data.robot_settings.robot_cpm_max_cpm" outlined 
						dense v-if="change_field=='max_bet'" 
						@change="update_advertisement_robots_settings({'robot_cpm_max_cpm': data.robot_settings.robot_cpm_max_cpm})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<div v-if="change_field == 'cpm' && data.campaign_type_no_display == 'SEARCH_WITH_CATALOG'">
						<v-select
							v-model="cpm_instrument" :items="instrument_list" item-text="text"
							item-value="id" label="Место ставки" outlined hide-details dense
						></v-select>
						<v-text-field
							v-model="data.united_cpm_catalog" outlined dense v-if="cpm_instrument==4" 
							hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" class="mt-5"
						></v-text-field>
						<v-text-field
							v-model="data.cpm" outlined dense v-if="cpm_instrument==6" class="mt-5"
							hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
						></v-text-field>
						<v-btn
							depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
							dense class="mt-5" :cpm="cpm_loading" @click="change_cpm"
						>Изменить</v-btn>
					</div>
					<div v-if="change_field == 'cpm' && data.campaign_type_no_display != 'SEARCH_WITH_CATALOG'">
						<v-text-field
							v-model="data.cpm" outlined dense 
							hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
						></v-text-field>
						<v-btn
							depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
							dense class="mt-5" :cpm="cpm_loading" @click="change_cpm"
						>Изменить</v-btn>
					</div>
					<v-text-field
						v-model="data.robot_settings.robot_stocks_value" outlined 
						dense v-if="change_field=='robot_stocks'" 
						@change="update_advertisement_robots_settings({'robot_stocks_value': data.robot_settings.robot_stocks_value})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-text-field
						v-model="data.robot_settings.robot_daily_budget_value" outlined 
						dense v-if="change_field=='daily_budget'" 
						@change="update_advertisement_robots_settings({'robot_daily_budget_value': data.robot_settings.robot_daily_budget_value})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-text-field
						v-model="data.robot_settings.robot_budget_value" outlined 
						dense v-if="change_field=='robot_budget'" 
						@change="update_advertisement_robots_settings({'robot_budget_value': data.robot_settings.robot_budget_value})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-text-field
						v-model="data.robot_settings.robot_budget_second_value" outlined 
						dense v-if="change_field=='robot_budget_second'" 
						@change="update_advertisement_robots_settings({'robot_budget_second_value': data.robot_settings.robot_budget_second_value})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-text-field
						v-model="data.robot_settings.robot_min_budget" outlined 
						dense v-if="change_field=='min_budget'" 
						@change="update_advertisement_robots_settings({'robot_min_budget': data.robot_settings.robot_min_budget})"
						hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-progress-linear v-if="loading" indeterminate color="deep-purple-accent-4"></v-progress-linear>
		<div v-if="data && !data.robot_settings" class="py-5 d-flex justify-center align-center flex-column">
			<v-alert density="compact" type="warning">
				Настройки рекламной кампании еще не созданы. Нажмите на кнопку, чтобы создать
			</v-alert>
			<v-btn
				@click="create_robot_setting" depressed color="purple darken-2" class="mt-3" 
				dark
			>Настроить рекламную кампанию</v-btn>
		</div>
		<div v-else-if="data">
			<v-row>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Целевая позиция</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('wanted_placement', 'Целевая позиция')">
									{{ data.robot_settings.robot_cpm_wanted_placement ? data.robot_settings.robot_cpm_wanted_placement: 'Установить' }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Тек. позиция</v-list-item-title>
							<v-list-item-subtitle class="mt-1"><a>
								{{ data.robot_settings.is_robot_cpm ? data.robot_settings.last_cpm_position : 'Не управляется' }}
							</a></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Время показа</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="dialog_interval_show = true">
									{{ data.robot_settings.is_robot_interval_show ? 'Активно': 'Не управляется'}}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Макс. ставка</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('max_bet', 'Максимальная ставка')">
									{{ data.robot_settings.robot_cpm_max_cpm ? data.robot_settings.robot_cpm_max_cpm: 0 | toRUB }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Тек. ставка</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<v-tooltip bottom max-width="300" open-delay="500" v-if="data.campaign_type_no_display == 'SEARCH_WITH_CATALOG'">
									<template v-slot:activator="{ on, attrs }">
										<a @click="open_dialog_change('cpm', 'Ставка')" v-bind="attrs" v-on="on">
											{{ data.cpm ? data.cpm : 0 | toRUB }}
										</a>
									</template>
									<span>
										<div>Ставка в поиске: {{ data.united_cpm_search ? data.united_cpm_search : 0 | toRUB  }}</div>
										<div>Ставка в каталоге: {{ data.united_cpm_catalog ? data.united_cpm_catalog : 0 | toRUB  }}</div>
									</span>
								</v-tooltip>
								<a @click="open_dialog_change('cpm', 'Ставка')" v-else>
									{{ data.cpm ? data.cpm : 0 | toRUB }}
								</a></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Мин. остаток</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('robot_stocks', 'Минимальный остаток')">
									{{ data.robot_settings.robot_stocks_value ? data.robot_settings.robot_stocks_value: 'Установить' }}
									{{ data.robot_settings.robot_stocks_value ? 'шт': '' }} 
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Тек. остаток</v-list-item-title>
							<v-list-item-subtitle class="mt-1"><a>
								{{ data.robot_settings.last_stocks_robot ? data.robot_settings.last_stocks_robot: 0 }} шт
							</a></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Макс. расход</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('daily_budget', 'Максимальный расход')">
									{{ data.robot_settings.robot_daily_budget_value ? data.robot_settings.robot_daily_budget_value: 0 | toRUB }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Тек. расход</v-list-item-title>
							<v-list-item-subtitle class="mt-1"><a>
								{{ data.robot_settings.robot_daily_budget_last_expenses ? data.robot_settings.robot_daily_budget_last_expenses: 0 | toRUB }}
							</a></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Автопополнение</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('robot_budget', 'Автопополнение')">
									{{ data.robot_settings.robot_budget_value ? data.robot_settings.robot_budget_value : 0}}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Счет пополнения</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="change_is_bonus">
									{{ data.robot_settings.is_budget_bonus ? 'Бонусы': 'Внутренний баланс' }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Вечернее автопополнение</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('robot_budget_second', 'Вечернее пополнение')">
									{{ data.robot_settings.robot_budget_second_value ? data.robot_settings.robot_budget_second_value : 0}}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Вечерний счет пополнения</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="change_is_bonus">
									{{data.robot_settings.is_budget_bonus ? 'Бонусы': 'Внутренний баланс' }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Мин. бюджет</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a @click="open_dialog_change('min_budget', 'Минимальный бюджет')">
									{{ data.robot_settings.robot_min_budget ? data.robot_settings.robot_min_budget : 0}}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Тек. бюджет</v-list-item-title>
							<v-list-item-subtitle class="mt-1">
								<a>{{ data.budget ? data.budget : 0 }}</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col>
					<v-switch 
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  hide-details 
						v-model="data.robot_settings.is_robot_cpm"
						label="Управляется" @change="update_advertisement_robots_settings({'is_robot_cpm': data.robot_settings.is_robot_cpm})"
					></v-switch>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import {Advertisement} from '@/api/advertisement'
import DialogAdvertisementManagementIntervalShow from '@/components/Advertisement/AdvertisementDetail/DialogAdvertisementManagementIntervalShow'


export default {
	name: "AdvertisementDetailManagementComponent",
	components: { DialogAdvertisementManagementIntervalShow },
	props: ['data', 'loading'],
	data(){
		return {
			cpm_instrument: 6,
			instrument_list: [
				{'id': 4, 'text': 'В каталоге'}, {'id': 6, 'text': 'В поиске'}
			],
			dialog_change: false,
			dialog_change_title: null,
			change_field: null,
			cpm_loading: false,
			dialog_interval_show: false
		}
	},
	methods: {
		async change_is_bonus(){
			await this.update_advertisement_robots_settings({'is_budget_bonus': !this.data.robot_settings.is_budget_bonus})
			this.data.robot_settings.is_budget_bonus = !this.data.robot_settings.is_budget_bonus
		},
		open_dialog_change(field, title){
			this.dialog_change = true
			this.change_field = field
			this.dialog_change_title = title
		},
		success_set_display_area(){
			this.dialog_interval_show = false
			this.$emit('get_data')
		},
		async update_advertisement_robots_settings(data, key=null){
			try{
				await Advertisement.updateAdvertisementCampaignRobotSettings(this.data.robot_settings.id, data)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				if (key)
					this.data.robot_settings[key] = data[key]
			} catch(err){
				this.$toasted.show(`Ошибка изменения данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		},
		async change_cpm(){
			this.cpm_loading = true
			let new_cpm = this.data.campaign_type_no_display == 'SEARCH_WITH_CATALOG' && this.cpm_instrument == 4 ? this.data.united_cpm_catalog : this.data.cpm
			try{
				await Advertisement.changeCPMAdvertisementCampaign(this.data.campaign_id, new_cpm, this.cpm_instrument)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				this.$emit('get_data')
			} catch(err){
				this.$toasted.show(`Ошибка изменения ставки`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.cpm_loading = false
		},
		async create_robot_setting(){
			try{
				await Advertisement.createRobotSettings(this.data.campaign_id)
				this.$toasted.show(`Успешно создан`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				this.$emit('get_data')
			} catch(err){
				this.$toasted.show(`Ошибка создания`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		}
	},
}
</script>

<style lang="scss" scoped>

</style>
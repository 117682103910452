<template>
    <apexchart 
		type="line" height="350" :options="chartOptions" :series="seriesOrders"
	></apexchart>
</template>

<script>
	import VueApexCharts from 'vue-apexcharts';

	export default {
		name: 'OrdersChart',
		components: {
			apexchart: VueApexCharts,
		},
		props: [
			'series',
		],
		computed: {
			chartOptions() {
				return {
					theme: {
						mode: this.$vuetify.theme.dark ? 'dark': 'light'
					},
					chart: {
						height: 350,
						type: 'line',
						zoom: {
							enabled: false,
						},
						background: 'rgba(0, 0, 0, 0)'
					},
					animations: {
						enabled: true,
					},
					colors: ['#0076ce', '#0faf71', '#cd2a2a'],
					dataLabels: {
						enabled: false,
					},
					markers: {
						size: [4, 4, 0],
						strokeColors: '#fff',
						strokeWidth: 1,
						strokeOpacity: 0.9,
						strokeDashArray: 1,
					},
					stroke: {
						curve: 'straight',
						width: [3, 3],
					},
					xaxis: {
						type: 'datetime',
					},
					yaxis: [
						{
							seriesName: 'Отмененные',
							title: {
								text: 'Продажи',
								style: {
									color: '#0076ce',
								},
							},
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#0076ce',
							},
							labels: {
								style: {
									colors: '#0076ce',
								},
								formatter: function(val) {
									return val.toFixed(0);
								},
							},
						},
						{
							opposite: true,
							title: {
								text: 'Сумма',
								style: {
									color: '#197235',
								},
							},
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#197235',
							},
							labels: {
								style: {
									colors: '#197235',
								},
								formatter: function(val) {
									return `${val}₽`;
								},
							},
						},
						{
							show: false,
							labels: {
								formatter: function(val) {
									return val.toFixed(0);
								},
							},
						},
					],
				}
			},
			seriesOrders() {
				return [
					{
						name: 'Заказы',
						type: 'line',
						data: this.seriesOrdersCount,
					},
					{
						name: 'Сумма заказов',
						type: 'line',
						data: this.seriesOrdersSum,
					},
					{
						name: 'Отмененные',
						type: 'area',
						data: this.seriesOrdersCanceled,
					},
				];
			},
			seriesOrdersCount() {
				return this.series.map(item => (
					{
						x: item.day,
						y: item.orders_count,
					}
				));
			},
			seriesOrdersSum() {
				return this.series.map(item => (
					{
						x: item.day,
						y: item.orders_amount,
					}
				));
			},
			seriesOrdersCanceled() {
				return this.series.map(item => (
					{
						x: item.day,
						y: item.canceled_count,
					}
				));
			},
		},
	};
</script>

<template>
	<div>
		<v-dialog v-model="dialog_error" max-width="100%" v-if="dialog_error">
			<v-card>
				<v-card-title>Ошибка</v-card-title>
				<v-card-text class="mt-5">
					<v-progress-circular
						v-if="loading_error" indeterminate color="primary"
					></v-progress-circular>
					<p v-else>{{ error ? error : 'Нет ошибок' }}</p>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_change_all_chars" max-width="100%" v-if="dialog_change_all_chars">
			<v-card>
				<v-card-title>Изменение всех характеристик</v-card-title>
				<v-card-text class="mt-5">
					<v-progress-circular
						v-if="loading_chars_all" indeterminate color="primary"
					></v-progress-circular>
					<div v-else>
						<div 
							class="d-flex"
							v-for="(item, i) in possible_chars"
							:key="i"
						>
							<v-text-field
								v-if="item.name != 'Описание'"
								v-model="item.value"
								clearable
								outlined
								:counter="item.name == 'Наименование' ? true : false"
								dense
								:label="`${item.name} ${item.req ? '(Обязательное)' : ''} ${item.type == 4 ? '(Число)' : ''}`"
								:hint="getHintChangeChar(item)"
							></v-text-field>
							<v-textarea
								v-else
								outlined
								clearable
								auto-grow
								counter
								col="2"
								v-model="item.value"
								:label="`${item.name} ${item.req ? '(Обязательное)' : ''} ${item.type == 4 ? '(Число)' : ''}`"
							></v-textarea>
							<v-btn 
								:loading="loading_new" 
								class="ml-3" 
								@click="updateCharsProductNew(item.value, item)"
							>Изменить</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card flat class="elevation-2 flat" v-if="chars_data">
			<v-card-title>
				Характеристики продукта
				<v-btn icon class="ml-3" @click="openDialogError">
					<v-icon>mdi-alert-circle</v-icon>
				</v-btn>
				<v-btn icon class="ml-1" @click="openDialogChangeChars">
					<v-icon>mdi-cog</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text style="display: flex; justify-content:space-between;">
				<div class="current_char">
					<div style="display:flex">
						<v-text-field
							v-model="new_name"
							clearable
							outlined
							dense
							counter
							maxlength="60"
							label="Введите название карточки"
						></v-text-field>
						<v-btn :loading="loading" class="ml-10" @click="updateCharsProduct">Изменить</v-btn>
					</div>
					
					<div v-if="chars_data && chars_data[0] && chars_data[0].characteristics" class="mt-5">
						<p style="font-size: 18px">Характеристики продукта:</p>
						<p 
							v-for="(item, index) in chars_data[0].characteristics" 
							:key="index" 
							class="ml-5 align-center d-flex"
							:class="{'active_char': compare_array && compare_array.includes(item.name)}"
						>
							<span class="name_char">{{item.name}}:</span> 
							<span class="ml-3">{{item.value}}</span>
						</p>
					</div>
				</div>
				<div class="current_char">
					<ProductCharacteristicsPerDay 
						:product_id="product_id"
						@getCharByDate="getCharByDate"
					/>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import {Products} from '@/api/products';
import ProductCharacteristicsPerDay from '@/components/Product/ProductCharacteristicsPerDay';

export default {
	name: 'ProductCharacteristics',
	components: {
		ProductCharacteristicsPerDay
	},
	props: ['product_id'],
	data() {
		return {
			loading: false,
			loading_new: false,
			chars_data: [],
			new_name: [],
			start_name: [],
			chars_data_by_date: [],
			compare_array: [],
			dialog_error: false,
			dialog_change_all_chars: false,
			error: null,
			loading_error: false,
			loading_chars_all: false,
			possible_chars: []
		}
	},    
	methods: {
		getHintChangeChar(item){
			if (item.counter > 1)
				return `Можете ввести несколько значений через запятую(не больше ${item.counter})`
			return 'Введите одно значение'
		},
		compareChar(current_char, char_by_date){
			let result = []
			if(!current_char || !char_by_date)
				return []
			current_char.forEach(item=>{
				let find_index = char_by_date.find(el => el.name==item.name && el.value == item.value)
				if (!find_index)
					result.push(item.name)
			})
			return result
		},
		async fetchProductChars(){
			const response = await Products.fetchProductCharacteristics(this.product_id);
			this.chars_data = response.data;
			if (response.data && response.data[0] && response.data[0].characteristics){
				const name = response.data[0].characteristics.filter((item) =>
					item.name == 'Наименование'
				)
				if (name){
					this.new_name = name[0].value
					this.start_name = name[0].value
				}
					
			}
		},
		async updateCharsProductNew(val, item){
			this.loading_new = true
			try{
				const response = await Products.updateCharsProductNew(this.product_id, val, item);
				this.$toasted.show(`Хар-ка успешно обновлена`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-center',
					duration: 3000,
				});
			}
			catch(err){
				this.$toasted.show(err.response.data?.error, {
					theme: 'bubble',
					type: 'error',
					position: 'top-center',
					duration: 3000,
				});
			}
			this.loading_new = false
		},
		async updateCharsProduct(){
			if (this.new_name.length <= 60 && this.new_name.length > 1){
				this.loading = true
				try{
					const response = await Products.updateCharsProduct(this.product_id, this.new_name);
					this.$toasted.show(`Название успешно обновлено`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-center',
						duration: 3000,
					});
					await this.fetchProductChars()
				}
				catch(err){
					this.$toasted.show(err.response.data?.error, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
				this.loading = false
			}
		},
		getCharByDate(data){
			this.chars_data_by_date = data
			if (data.length == 0 || this.chars_data.length == 0){
				this.compare_array = []
			}
			else{
				if (this.chars_data && this.chars_data[0].characteristics && data[0].characteristics)
					this.compare_array = this.compareChar(this.chars_data[0].characteristics, data[0].characteristics)
			}
		},
		async checkError(){
			this.loading_error = true
				try{
					const response = await Products.checkUpdateError(this.product_id);
					this.error = response.data
				}
				catch(err){
					this.error = 'Ошибка запроса'
				}
				this.loading_error = false
		},
		async openDialogError(){
			await this.checkError()
			this.dialog_error = true
		},
		async getAllPossibleChars(){
			this.loading_chars_all = true
				try{
					const response = await Products.getAllPossibleChars(this.product_id);
					let data = response.data
					if (this.chars_data && this.chars_data[0] && this.chars_data[0].characteristics){
						data.forEach(item=>{
							let tmp = this.chars_data[0].characteristics.filter(
								i => i.name == item.name
							)
							if (tmp && tmp.length > 0){
								item['value'] = tmp[0].value
							} else{
								item['value'] = null
							}
						})
					}
					this.possible_chars = data

				}
				catch(err){
					this.possible_chars = []
				}
			this.loading_chars_all = false
		},
		async openDialogChangeChars(){
			this.dialog_change_all_chars = true
			await this.getAllPossibleChars()
		}
	},
	async mounted(){
		await this.fetchProductChars()
	}
}
</script>

<style lang="scss" scoped>
	.current_char{
		width: 47%;
	}
	.name_char{
		font-weight: bold;
		font-size: 15px;
	}
	.active_char{
		background-color: #a07343;
	}
</style>
<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="priceLoading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <v-data-table
                dense
                :headers="headers"
                :items="data_price"
                hide-default-footer
                disable-pagination
                class="elevation-0 vertical-border small-font"
            >
                <template v-slot:item.date="{ item }">
                    {{ item.date | onlyTime }}
                </template>
                <template v-slot:item.price="{ item }">
                    <div v-if="item.price">
                        {{ item.price | toRUB }}
                    </div>
                    <div v-else> - </div>
                </template>
                <template v-slot:item.price_with_discount="{ item }">
                    <div v-if="item.price_with_discount">
                        {{ item.price_with_discount | toRUB }}
                    </div>
                    <div v-else> - </div>
                </template>
                <template v-slot:item.discount="{ item }">
                    <div v-if="item.discount">
                        {{ item.discount ? item.discount : 0}}%
                    </div>
                    <div v-else> - </div>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>

<script>
    import { Products } from '@/api/products'

    export default {
        name: "PriceApiInTheMomentTable",
        props: {
            product_id: {
                type: String
            },
            date: {
                type: String
            }
        },
        data () {
            return {
                headers: [
                    { text: "Время", value: "date", sortable: false, width: "1%" },
                    { text: "Цена без скидок", value: "price", sortable: false, width: "1%" },
                    { text: "Скидка", value: "discount", sortable: false, width: "1%" },
                    { text: "Цена со скидкой", value: "price_with_discount", sortable: false, width: "1%" },
                ],
                data_price: null,
                priceLoading: true,

            }
        },
        methods: {
            async fetchPriceDay () {
                this.priceLoading = true
                try {
                    const res = await Products.fetchPriceApiDay(
                        this.product_id,
                        this.$options.filters.formatDateToQuery(this.date)
                    )
                    this.data_price = res.data
                } catch (err) {
                    console.log(err)
                }
                this.priceLoading = false
            }
        },
        async mounted () {
            await this.fetchPriceDay()
        }
    }
</script>
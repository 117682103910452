var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"datepicker_menu_from",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Начало периода","prepend-icon":"mdi-calendar","readonly":"readonly","hide-details":"auto","outlined":"","dense":""},on:{"click:clear":function($event){_vm.filters.date_after = null}},model:{value:(_vm.dateFromFormatted),callback:function ($$v) {_vm.dateFromFormatted=$$v},expression:"dateFromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_from),callback:function ($$v) {_vm.datepicker_menu_from=$$v},expression:"datepicker_menu_from"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.datepicker_menu_from = false}},model:{value:(_vm.filters.date_after),callback:function ($$v) {_vm.$set(_vm.filters, "date_after", $$v)},expression:"filters.date_after"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"datepicker_menu_to",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"offset-y","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Конец периода","prepend-icon":"mdi-calendar","readonly":"readonly","hide-details":"auto","outlined":"","dense":""},on:{"click:clear":function($event){_vm.filters.date_before = null}},model:{value:(_vm.dateToFormatted),callback:function ($$v) {_vm.dateToFormatted=$$v},expression:"dateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_to),callback:function ($$v) {_vm.datepicker_menu_to=$$v},expression:"datepicker_menu_to"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.datepicker_menu_to = false}},model:{value:(_vm.filters.date_before),callback:function ($$v) {_vm.$set(_vm.filters, "date_before", $$v)},expression:"filters.date_before"}})],1)],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","color":"purple darken-2","dark":""},on:{"click":_vm.fetchDataWithFilters}},[_vm._v("Применить")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 vertical-border small-font",attrs:{"dense":"","headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"pageCount":_vm.table.pagination.numberOfPages,"options":_vm.table.options,"items-per-page":50,"server-items-length":_vm.table.pagination.totalItems},on:{"update:page":_vm.fetchData,"update:sort-by":_vm.fetchData,"update:sort-desc":_vm.fetchData,"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.date))])]}},_vm._l((['orders_sum_rub', 'buyout_sum_rub', 'cancel_sum_rub', 'avg_rub_price']),function(rub_prop){return {key:("item." + rub_prop),fn:function(ref){
var item = ref.item;
return [_c('div',{key:("val_" + rub_prop),staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(item[rub_prop].current)))]),_c('div',{class:{'green_delta': item[rub_prop].dynamic_value > 0, 'red_delta': item[rub_prop].dynamic_value < 0}},[(item[rub_prop].dynamic_value > 0)?_c('v-icon',{attrs:{"small":"","color":"#067b00"}},[_vm._v("mdi-trending-up")]):(item[rub_prop].dynamic_value < 0)?_c('v-icon',{attrs:{"small":"","color":"#f96666"}},[_vm._v("mdi-trending-down")]):_vm._e(),_vm._v(" "+_vm._s((item[rub_prop].dynamic_percent * 100).toFixed(1))+"% ("+_vm._s(_vm._f("toRUB")(item[rub_prop].dynamic_value))+") ")],1)])]}}}),_vm._l((['cr_cart', 'cr_orders', 'buyout_percent', 'cr_open']),function(percent_prop){return {key:("item." + percent_prop),fn:function(ref){
var item = ref.item;
return [_c('div',{key:("val_" + percent_prop),class:{'bold_text': percent_prop=='cr_open'},staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('div',[_vm._v(_vm._s(item[percent_prop].current.toFixed(1))+"%")]),_c('div',{class:{'green_delta': item[percent_prop].dynamic_value > 0, 'red_delta': item[percent_prop].dynamic_value < 0}},[(item[percent_prop].dynamic_value > 0)?_c('v-icon',{attrs:{"small":"","color":"#067b00"}},[_vm._v("mdi-trending-up")]):(item[percent_prop].dynamic_value < 0)?_c('v-icon',{attrs:{"small":"","color":"#f96666"}},[_vm._v("mdi-trending-down")]):_vm._e(),_vm._v(" "+_vm._s(item[percent_prop].dynamic_value.toFixed(1))+"% ")],1)])]}}}),_vm._l(([
				'open_card', 'add_to_cart', 'orders', 'buyout_count', 'cancel_count', 'stock_wb_qty', 
				'stock_mp_qty', 'avg_order_count_per_day'
			]),function(count_prop){return {key:("item." + count_prop),fn:function(ref){
			var item = ref.item;
return [_c('div',{key:("val_" + count_prop),class:{'bold_text': count_prop=='open_card'},staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('div',[_vm._v(_vm._s(item[count_prop].current)+" шт")]),_c('div',{class:{'green_delta': item[count_prop].dynamic_value > 0, 'red_delta': item[count_prop].dynamic_value < 0}},[(item[count_prop].dynamic_value > 0)?_c('v-icon',{attrs:{"small":"","color":"#067b00"}},[_vm._v("mdi-trending-up")]):(item[count_prop].dynamic_value < 0)?_c('v-icon',{attrs:{"small":"","color":"#f96666"}},[_vm._v("mdi-trending-down")]):_vm._e(),_vm._v(" "+_vm._s((item[count_prop].dynamic_percent * 100).toFixed(1))+"% ("+_vm._s(item[count_prop].dynamic_value)+" шт) ")],1)])]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
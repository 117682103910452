var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-container',{staticClass:"d-flex justify-start",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Фильтр","single-line":"","dense":"","hide-details":""},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 small-font",attrs:{"dense":"","headers":_vm.dataTable.headers,"items":_vm.tableData,"item-key":"nmid","search":_vm.tableSearch,"loading":_vm.dataTable.loading,"item-class":_vm.itemRowBackground,"footer-props":{
					'items-per-page-options': [5, 15, 25, 50, 100]
				}},scopedSlots:_vm._u([{key:"item.link",fn:function(ref){
				var item = ref.item;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("WB url")])]}},{key:"item.spp_data",fn:function(ref){
				var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.spp)+"%")]),(item.spp_date)?_c('div',[_vm._v(_vm._s(_vm._f("formatDateToLocale")(item.spp_date)))]):_vm._e()])]}},{key:"item.price_last_data",fn:function(ref){
				var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.price_last)+"₽")]),(item.price_last_date)?_c('div',[_vm._v(_vm._s(_vm._f("formatDateToLocale")(item.price_last_date)))]):_vm._e()])]}},{key:"item.price_new_data",fn:function(ref){
				var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.price_new)+"₽")]),(item.price_new_date)?_c('div',[_vm._v(_vm._s(_vm._f("formatDateToLocale")(item.price_new_date)))]):_vm._e()])]}},{key:"item.last_check_date",fn:function(ref){
				var item = ref.item;
return [(item.last_check_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateToLocale")(item.last_check_date))+" ")]):_vm._e()]}},{key:"item.on_sale_data",fn:function(ref){
				var item = ref.item;
return [(item.on_sale)?_c('div',[_c('v-icon',{staticStyle:{"color":"green"}},[_vm._v(" mdi-check ")])],1):(item.on_sale == null)?_c('div'):_c('div',[_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v(" mdi-close ")])],1),(item.on_sale_date)?_c('div',[_vm._v("Посл. наличие: "+_vm._s(_vm._f("formatDateToLocale")(item.on_sale_date)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":".7rem"}},[_vm._v("Удалить позицию")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.refreshItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":".7rem"}},[_vm._v("Обновить данные")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
		<v-dialog
            width="700"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="getRedemptionTypeStats()"
                    icon
                    title="Сводка по типам выкупа"
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
					class="ml-5"
                    dark
                >
                    <v-icon dark>
                        mdi-poll
                    </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h6 darken-2">
                    Сводка по типам выкупа
                </v-card-title>
                <div style="position:relative;">
					<v-skeleton-loader type='table' v-if="redemption_type_stats_loading"></v-skeleton-loader>
                    <v-data-table 
						v-else
                        class="elevation-1 pb-5 mt-3"
                        :class="{'loading': redemption_type_stats_loading}"
                        dense
                        :headers="redemptionTypeStatsHeader"
                        :items="redemption_type_stats"
                        hide-default-footer
                    ></v-data-table>
                </div>
            </v-card>
        </v-dialog>
		<v-dialog v-model="dialog_delete" max-width="500px" v-if="dialog_delete">
			<v-card>
				<v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="dialog_delete = false">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
				<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <OzonRedemptionFilters @filtersData='filtersData'/>
		<v-skeleton-loader type='table' v-if="init_loading"></v-skeleton-loader>
        <v-data-table
            :headers="data_table.headers"
            :items="data_table.data"
            :loading="table_loading"
            class="elevation-0 mt-0 vertical-border small-font"
            v-else
            :pageCount="pagination.numberOfPages"
            :options.sync="options"
            :items-per-page="20"
            :footer-props="{
                'items-per-page-options': [5, 10, 20, 50]
            }"
            :server-items-length="pagination.totalItems"
        >
			<template v-slot:item.product.mid="{ item }">
                <router-link 
                    :to="{ name: 'ozon_product_card', params: { id: item.product.mid }}"
                    target="_blank"
                >
                    Ozon id: {{ item.product.mid }}
                </router-link>
				<hr>
				fbo sku: {{ item.product.fbo_sku }}
				<hr>
				fbs sku: {{ item.product.fbs_sku }}
				<hr>
				code_1c: {{ item.product.code_1c }}
            </template>
			<template v-slot:item.sum_order="{ item }">
				{{ item.sum_order | toRUB}}
            </template>
			<template v-slot:item.cashback="{ item }">
				<div v-if="item.cashback">
					{{item.cashback}}% ({{item.cashback_in_money ? item.cashback_in_money : 0 | toRUB}})
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.order_number="{ item }">
				<div v-if="item.order_number" style="padding: 10px 5px">
					<div :key="index" v-for="(obj, index) in convertStrToArrayOrderNumber(item.order_number)">
						{{obj}}
						<hr>
					</div>
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.real_price="{ item }">
				{{ item.quantity_product ? item.sum_order / item.quantity_product : 0 | toRUB}}
            </template>
			<template v-slot:item.product.art="{ item }">
                <v-tooltip bottom max-width=350>
                    <template v-slot:activator="{ on, attrs }">
                        <div 
                            v-bind="attrs" 
                            v-on="on" 
                            class="art_product" 
                        >
                            {{item.product.art}}
                        </div>
                    </template>
                    <span>{{item.product.art}}</span>
                </v-tooltip>
            </template>
			<template v-slot:item.feedback_text="{ item }">
                <div v-if="item.feedback_text">
                    <v-tooltip bottom max-width=350>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                v-bind="attrs" 
                                v-on="on" 
                                class="art_product" 
                            >
                                {{item.feedback_text}}
                            </div>
                        </template>
                        <span>{{item.feedback_text}}</span>
                    </v-tooltip>
                </div>
            </template>
			<template v-slot:item.is_report="{ item }">
				<v-tooltip bottom max-width=350 v-if="item.is_report && item.is_report.length != 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
								v-bind="attrs" 
                                v-on="on" 
								v-if="item.is_report.length == item.quantity_product"
								color="green"
							>mdi-checkbox-marked-circle
							</v-icon>
							<v-icon
								v-bind="attrs" 
                                v-on="on" 
								v-else
								color="orange"
							> mdi-pause-circle
							</v-icon>
                        </template>
						<div>
							<p v-for="(obj, index) in item.is_report" :key="index">
								{{obj.posting_number}}
							</p>
						</div>
                    </v-tooltip>
                
            </template>
			<template v-slot:item.phone="{ item }">
                {{phoneMask(item.phone)}}
            </template>
			<template v-slot:item.expenses="{ item }">
				<p v-if="item.expenses">{{item.expenses | toRUB}}</p>
				<p v-else>-</p>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="mt-5">
                    <v-row class="justify-space-between" style="padding:0 24px 24px 24px">
						<v-dialog
                            v-model="dialog_import"
                            max-width="500px"
                            v-if="isRedemptionUser || isRedemptionManager"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    :loading="loading_import"
                                >
                                    Импорт из Excel
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="pb-0">
                                    <v-file-input
                                        label="File input"
                                        outlined
                                        dense
                                        id="upload_input"
                                        class="pt-5"
                                        hide-details
                                    ></v-file-input>
                                    <p 
                                        class="text-download mt-3"
                                        @click="downloadTempateExport"
                                    >Скачать шаблон</p>
                                </v-card-text>
                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="dialog_import = false"
                                    >
                                        Закрыть
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="uploadFromExel"
                                    >
                                        Импортировать
                                    </v-btn>    
                                </v-card-actions>
                            </v-card>  
                        </v-dialog>
						<v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="exportExcel"
							:loading="loading_export"
                        >
                            Экспорт в excel
                        </v-btn>
                         <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="exportExcelWarehouse"
							:loading="loading_export_for_warehouse"
                        >
                            Экспорт для склада
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="startBundleCashback"
							:loading="loading_bundle_cashback"
                        >
                            Загрузка кешбэков
                        </v-btn>
                        <v-dialog
                            v-model="dialog_forms"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Добавить выкуп
                                </v-btn>
                            </template>
							<OzonRedemptionFormCreate
								v-if="type_forms === -1"
								@close="close"
							/>
							<OzonRedemptionFormUpdate
								v-else
								:item=editable_item
								@close="close"
							/>
                        </v-dialog>
                    </v-row>
                </v-toolbar>
            </template>
			<template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    v-if="isRedemptionManager || isRedemptionUser"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
				<v-icon
                    small
                    v-if="isRedemptionManager"
                    @click="deleteRedemption(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
		<div class="stat px-5 pt-5 pb-5">
            <div class="body-1">Общая сумма заказа - {{stats.sum_order}}</div>
            <div class="body-1">Общее количество товаров - {{stats.sum_quantity}}</div>
            <div class="body-1">Общее количество выкупов - {{stats.count_redemption}}</div>
        </div>
    </div>
</template>

<script>

import { Redemption } from '@/api/redemption'
import OzonRedemptionFilters from '@/components/RedemptionOzon/OzonRedemptionFilters'
import OzonRedemptionFormCreate from '@/components/RedemptionOzon/OzonRedemptionFormCreate'
import OzonRedemptionFormUpdate from '@/components/RedemptionOzon/OzonRedemptionFormUpdate'

export default {
    name: 'OzonRedemptionMain',
    components: {
        OzonRedemptionFilters,
		OzonRedemptionFormCreate,
		OzonRedemptionFormUpdate
    },
    data () {
      return {
        loading_bundle_cashback: false,
		redemption_type_stats_loading: false,
		redemption_type_stats: null,
		redemptionTypeStatsHeader: [
            {text: 'Тип', value: 'type_by_name',  sortable: false},
            {text: 'Всего', value: 'total',  sortable: false},
            {text: 'С документом', value: 'with_report',  sortable: false},
            {text: 'Без документа', value: 'without_report',  sortable: false},
        ],
		dialog_import: false,
		loading_import: false,
		loading_export_for_warehouse: false,
		loading_export: false,
		deleted_item: null,
		dialog_delete: false,
		editable_item: null,
		dialog_forms: false, // показ форм создания и редактирования
		type_forms: -1, // форма создания или редактирования
        first_init: false, // чтобы повторно не отправлялись запросы из-за фильтров(первая загрузка)
        init_loading: false, // отображение skeleton table
        filters_str: {}, // фильтры, конвертированные в строку
        table_loading: false,
        pagination: {
            next: null,
            totalItems: 0,
            numberOfPages: 0,
            currentPage: 0
        },
		stats: {
            sum_order:0,
            sum_quantity: 0,
            count_redemption: 0
        },
        options: {},
        data_table: {
			headers: [
                { text: 'ID', align: 'start', value: 'id', width: '1%' },
				{ text: 'Артикул товара', align: 'start', value: 'product.mid', width: '1%', sortable: false },
                { text: 'Наименование', align: 'start', value: 'product.art', width: '1%', sortable: false },
				{ text: 'Кол-во', align: 'start', sortable: false, value: 'quantity_product', width: '1%' },
				{ text: 'Сервис', align: 'start', sortable: false, value: 'services_display', width: '1%' },
				{ text: 'Вид выкупа', align: 'start', value: 'type_redemption_display', width: '1%' },
				{ text: 'id заказа', align: 'start', sortable: false, value: 'order_number', width: '1%' },
				{ text: 'Дата заказа', align: 'start', value: 'date_order', width: '1%' },
				{ text: 'Сумма', value: 'sum_order', width: '1%' },
                { text: 'Цена выкупа за шт', value: 'real_price', width: '1%', sortable: false },
				{ text: 'Кешбек', value: 'cashback', width: '1%', sortable: false },
				{ text: 'Менеджер', value: 'manager', width: '1%', sortable: false },
				{ text: 'ФИО покупателя', value: 'account_buyer', width: '1%', sortable: false },
                { text: 'Номер аккаунта', value: 'phone', width: '1%', sortable: false },
				{ text: 'Дата покупки', value: 'date_buy', width: '1%'},
                { text: 'Статус товара', value: 'status_product_display', width: '1%', sortable: false },
                { text: 'Текст основного отзыва', value: 'feedback_text', width: '1%', sortable: false },
                { text: 'ПВЗ', value: 'address_pvz', width: '1%', sortable: false },
				{ text: 'Расход', value: 'expenses', width: '1%', sortable: false },
				{ text: 'Продажа', value: 'is_report', width: '1%', sortable: false },
				{ text: 'Действия', value: 'actions', sortable: false, width: '1%' },
			],
			data:[]
        },
      }
    },
    computed: {
        isRedemptionManager() {
            return this.$store.getters.isRedemptionManager || false
        },
        isRedemptionUser() {
            return this.$store.getters.isRedemptionUser || false
        }
    },
    watch: {
        dialog (val) {
            val || this.close()
        },
        'options': async function (newVal, oldVal) {
          if (oldVal && this.first_init) {
            await this.getRedemption()
          }
        },
		dialogDelete (val) {
            val
        },
    },

    methods: {
        async startBundleCashback(){
            this.loading_bundle_cashback = true
            try{
                await Redemption.startFetchRedemptionDistribution()
            } catch (err){
                console.log(err)
            }
            this.$toasted.show(`Задача по получению выкупов запущена`, {
                theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
            });
            this.loading_bundle_cashback = false
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        phoneMask(phone){
            if(phone)
                return "+7(" + phone.substring(1, 4) + ") " + phone.substring(4, 7) + "-" + phone.substring(7,9) + "-" + phone.substring(9,11) 
            return "-"
        },
        showDate(date){
            return date  ? this.$options.filters.formatDateToLocaleNoTime(date ) : null
        },
        filtersData(query){
            this.filters_str = query
            this.options.page = 1
            this.getRedemption()
        },
        async pageChanged () {
            await this.getRedemption()
        },
        async getRedemption () {
            this.table_loading = true
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            try {
                const response = await Redemption.fetchOzonRedemption({
                    ...this.filters_str,
                    'page': page,
                    'page_size': itemsPerPage,
                    'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]

                })
                if (this.data_table.data.length != 0)
                    this.scrollToTop()
                this.data_table.data = response.data.results.objects
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				this.stats.sum_order = response.data.results.stats.sum_order
                this.stats.sum_quantity = response.data.results.stats.sum_quantity
                this.stats.count_redemption = response.data.count
            } catch (err) {
                console.log(err.data)
            }
            this.table_loading = false
        },
		async close (status="") {
            this.dialog_forms = false
            this.$nextTick(() => {
                this.editable_item = Object.assign({}, this.defaultItem)
                this.type_forms = -1
            })
            if (status === "success")
                await this.getRedemption()
        },
		editItem (item) {
            this.type_forms = this.data_table.data.indexOf(item)
            this.editable_item = item
            this.dialog_forms = true
        },
		deleteRedemption (item) {
            this.deleted_item = item.id
            this.dialog_delete = true
        },  
		async deleteItemConfirm () {
            try {
                const response = await Redemption.deleteOzonRedemption(this.deleted_item)
                this.getRedemption()
            }
            catch (err){
                console.log(err)
            }
            this.dialog_delete = false
        },
		async exportExcel(){
			this.loading_export = true
            try {
                const response = await Redemption.exportOzonRedemption({
                    ...this.filters_str
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'ozon_redemption.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export = false
        },
        async exportExcelWarehouse(){
			this.loading_export_for_warehouse = true
            try {
                const response = await Redemption.exportOzonRedemption({
                    ...this.filters_str,
                    for_warehouse: true
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'ozon_redemption.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export_for_warehouse = false
        },
		async downloadTempateExport(){
            try {
                const response = await Redemption.downloadOzonTemplateRedemption()
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'template.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
		async uploadFromExel(){
			this.loading_import = true
            let fileToUpload = document.getElementById('upload_input').files[0]
            let formData = new FormData()
            formData.append('fileToUpload', fileToUpload)
            try {
                
                this.dialog_import = false
                const response = await Redemption.importOzonRedemption(
                    formData
                )
                
                this.getRedemption()
            }
            catch (err){
                console.log(err)
            }
			this.loading_import = false
        },
		convertStrToArrayOrderNumber(str){
			return str.replaceAll(' ','').split(',')
		},
		async getRedemptionTypeStats() {
			this.redemption_type_stats_loading = true
			try {
				const response = await Redemption.OzonredemptionTypeStats({
					...this.filters_str
				})
				this.redemption_type_stats = response.data.results
			} catch (err) {
				console.error(err)
			}
			this.redemption_type_stats_loading = false
		},
    },
    async mounted () {
		this.init_loading = true
		await this.getRedemption()
		this.init_loading = false
		this.first_init = true
    },
  }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .field-name{
        font-size: .7rem;
        color: #888888;
    }
    .v-data-table{
        margin-top: 50px;
    }
    .art_product{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 0.775rem;        
    }
    .text-download{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
</style>

<template>
    <div>
        <v-row class="py-10">
            <v-col sm="12" lg="12" class="px-10">
                <div class="title">Фильтры</div>
                <v-row class="mt-3">
                    <v-col>
                        <div class="d-flex flex-column">
                            <v-menu 
                                v-model="datePicker.datepicker_date_after" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datePicker.datepicker_date_after"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateAfter"
                                        label="Дата, от"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_after" 
                                    @input="datePicker.datepicker_date_after = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu 
                                v-model="datePicker.datepicker_date_before" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datePicker.datepicker_date_before"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateBefore"
                                        label="Дата, до"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                        class="mt-5"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_before" 
                                    @input="datePicker.datepicker_date_before = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.type"
                            :items="choices.advertisement_type"
                            item-text="name"
                            item-value="name"
                            label="Вид рекламы"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
					<v-col>
                        <v-select
                            v-model="filters.group"
                            :items="choices.advertisement_group"
                            item-text="name"
                            item-value="value"
                            label="Группа товаров"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid"
                            clearable
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            label="Артикул"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col> 
                    <v-col>
                        <v-select
                            v-model="filters.purpose"
                            :items="choices.advertisement_purpose"
                            item-text="name"
                            item-value="value"
                            label="Цель РК"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
					<v-col>
                        <v-text-field
                            v-model="filters.advertisement"
                            clearable
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            label="id кампании"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col> 
					<v-col>
						<v-select
							v-model="filters.suppliers"
							:items="suppliers"
							item-text="name"
							item-value="id"
							label="Аккаунт"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
					</v-col>
                    <v-col>
                        <v-btn
                                @click="applyFilter()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="12" lg="12">
                <v-data-table
                    :headers="headers"
                    :items="stats_data"
                    class="elevation-0 mt-0 small-font w-100"
                    :loading="table_loading"
                    @update:page="pageChanged"
                    :options.sync="options"
                    :items-per-page="10"
                    :server-items-length="pagination.totalItems"
                >
				<template v-slot:top>
					<div class="d-flex">
						<div class="d-flex w-100 px-7 pt-5">
							<v-btn color="primary" dark class="mb-2" @click="exportToExcel">Экспорт в excel</v-btn>
						</div>
						<div class="d-flex w-100 px-7 pt-5">
							<v-btn color="primary" dark class="mb-2" @click="exportDeltaToExcel">Экспорт дельты в excel</v-btn>
						</div>
					</div>
				</template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import {General} from '@/api/general'

export default {
	name: 'AdvertisementNewStatsV2',
	data() {
		return {
            filters: {
                type: null,
                nmid:null,
                date_after: null,
                date_before: null,
                purpose: null,
				advertisement: null,
				suppliers: null,
				group: null,
            },
            choices: {
                advertisement_type: [],
                advertisement_purpose: []
            },
            datePicker: {
                datepicker_date_before: null,
                datepicker_date_after: null
            },
			suppliers: null,
			headers: [
				{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
				{ text: 'Кампания', value: 'campaign_id', sortable: false, width: '1%' },
				{ text: 'SKU', value: 'nmid', sortable: false, width: '1%' },
				{ text: 'Вид рекламы', value: 'adver_type', sortable: false, width: '1%' },
				{ text: 'Цель', value: 'purpose', sortable: false, width: '1%' },
				{ text: 'Добавлений в корзину', value: 'add_to_basket', sortable: false, width: '1%' },
				{ text: 'Заказы', value: 'orders', sortable: false, width: '1%' },
				{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
				{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
				{ text: 'CPC', value: 'cpc', sortable: false, width: '1%' },
				{ text: 'CR', value: 'cr', sortable: false, width: '1%' },
				{ text: 'CTR(%)', value: 'ctr', sortable: false, width: '1%' },
				{ text: 'Частота', value: 'frq', sortable: false, width: '1%' },
				{ text: 'CPM', value: 'cpm', sortable: false, width: '1%' },
				{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
				{ text: 'ВП, шт ', value: 'vp_unit', sortable: false, width: '1%' },
				{ text: 'ВП план, шт ', value: 'vp_unit_plan_simple', sortable: false, width: '1%' },
				{ text: 'ВП, общая', value: 'vp_total', sortable: false, width: '1%' },
				{ text: 'ВП план, общая', value: 'vp_total_plan_simple', sortable: false, width: '1%' },
				{ text: 'Выручка', value: 'revenue', sortable: false, width: '1%' },
				{ text: 'Доходность', value: 'profit', sortable: false, width: '1%' },
				{ text: 'Доходность план', value: 'profit_plan_simple', sortable: false, width: '1%' },
				{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
				{ text: 'Дельта заказы', value: 'delta_orders', sortable: false, width: '1%' },
				{ text: 'Дельта ctr', value: 'delta_conversion', sortable: false, width: '1%' },
				{ text: 'Дельта ВП, общая', value: 'delta_vp_total', sortable: false, width: '1%' },
				{ text: 'Дельта ВП план, общая', value: 'vp_total_plan_delta', sortable: false, width: '1%' },
				{ text: 'Дельта выручка', value: 'delta_revenue', sortable: false, width: '1%' },
				{ text: 'Дельта доходность', value: 'delta_profit', sortable: false, width: '1%' },
				{ text: 'Дельта доходность план', value: 'profit_plan_delta', sortable: false, width: '1%' },
				{ text: 'Дельта ДРР', value: 'delta_drr', sortable: false, width: '1%' },
				{ text: 'ВП с заказов, шт', value: 'vp_from_orders_unit', sortable: false, width: '1%' },
				{ text: 'ВП с заказов, общая', value: 'vp_from_orders_total', sortable: false, width: '1%' },
				{ text: 'Доходность с заказов', value: 'profit_from_orders', sortable: false, width: '1%' },
				{ text: 'Заказов с парсинга', value: 'product_orders_from_parsing', sortable: false, width: '1%' },
				{ text: 'Кол-во выкупов', value: 'product_count_redemption', sortable: false, width: '1%' },
				{ text: '% выкупа', value: 'product_redemption_percent', sortable: false, width: '1%' },
			],
            stats_data: [],
            table_loading: false,
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
		}
	},    
    computed: {
        dateAfter () {
            return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
        },
        dateBefore () {
            return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
        },
        transfromFiltersForQuery(){
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
            }
            return query
        },
    },
	methods: {
        async pageChanged () {
            await this.getAdvertisementStats()
        },
        async getAdvertisementTypeAndAndGroup(){
            try {
                const response = await General.fetchAdvertisementType()
                this.choices.advertisement_type = response.data
            } catch (err) {
                 console.log(err.data)
            }
            try {
                const response3 = await General.fetchProductGroup()
                this.choices.advertisement_group = response3.data
            } catch (err) {
                 console.log(err.data)
            }
        },
        async getAdvertisementStats(){
            this.table_loading = true
            const { page, itemsPerPage} = this.options
            let pageNumber = page - 1
            try {
                let response = await AdvertisementNew.getAdvertisementCampaignsStatsV2(
                    {
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                        },
                        ...this.transfromFiltersForQuery,
                    }
                )
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.stats_data = response.data.results
            } catch (err) {
                console.error(err.response)
            }
            this.table_loading = false
        },
        showDate(date) {
            return date ? this.$options.filters.formatDateToLocaleNoTime(date) : "-";
        },
        async applyFilter(){
            this.options.page = 1
            await this.getAdvertisementStats()
        },
        async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices.advertisement_purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		async exportToExcel(){
            try {
                const response = await AdvertisementNew.exportNewAdvertisementStats({
                    ...this.transfromFiltersForQuery
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
		async exportDeltaToExcel(){
			try {
                const response = await AdvertisementNew.exportNewAdvertisementDeltaStats({
                    ...this.transfromFiltersForQuery
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_delta_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
		},
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.suppliers = response.data
        },
	},
    async mounted () {
		document.title = `Статистика рекламы | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Статистика рекламных кампаний`)
        await this.getAdvertisementStats()
        await this.getAdvertisementTypeAndAndGroup()
        await this.getAdvertisementPurpose()
		await this.fetchWbAccounts()
	}
}
</script>
import { HTTP } from './http'

export const Auth = {
  async login (login, password) {
    try {
      return await HTTP.post('/auth/dj-rest-auth/login/', {'username':login, 'password':password})
    } catch (err) {
      throw Error(err)
    }
  },
  async tokenRefresh (token) {
    try {
      return await HTTP.post('/auth/dj-rest-auth/token/refresh/', {'token':token})
    } catch (err) {
      throw Error(err)
    }
  },
}

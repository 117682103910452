<template>
    <v-container fluid class="pa-10" style="height: 100%">
        <v-row>
            <v-col cols="9">
                <seller-summary-data></seller-summary-data>
            </v-col>
            <v-col cols="3">
                <task-history items-count="50"></task-history>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';
	import TaskHistory from '../components/Dashboard/TaskHistory';
	import SellerSummaryData from '../components/Dashboard/SellerSummaryData';

	export default {
		name: 'base',
		components: {SellerSummaryData, TaskHistory},
		data() {
			return {};
		},
		mounted() {
			document.title = "Сводка | mp.lomex-group.ru";
			this.$store.commit('setPageTitle', `Дашборд`);
		},
		methods: {
			async getFeedbacks() {
				try {
					const response = await Products.getFeedbacks();
					console.log(response);
				} catch (err) {
					console.log(err.data);
				}
			},
		},
	};
</script>

<style scoped>

</style>

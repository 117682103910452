var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Изменение цен")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 small-font mt-3 vertical-border",attrs:{"item-key":"nmid","headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","disable-pagination":"","item-class":_vm.row_color_fill,"dense":"","show-select":""},scopedSlots:_vm._u([{key:"item.img",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"max-height":"50px"},attrs:{"src":_vm.getImgPath(item.nmid),"alt":""}})]}},{key:"item.old_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.old_price))+" ")]}},{key:"item.new_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.new_price))+" ")]}},{key:"item.cost_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.cost_price))+" ")]}},{key:"item.old_final_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.old_final_price))+" ")]}},{key:"item.final_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRUB")(item.final_price))+" ")]}},{key:"item.old_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.old_discount ? item.old_discount : 0)+"% ")]}},{key:"item.new_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.new_discount ? item.new_discount : 0)+"% ")]}},{key:"item.changing",fn:function(ref){
var item = ref.item;
return [(item.new_price != item.old_price)?_c('div',[_vm._v("Изменение цены")]):_vm._e(),(item.new_discount != item.old_discount)?_c('div',[_vm._v("Изменение скидки")]):_vm._e()]}}]),model:{value:(_vm.table.selected_items),callback:function ($$v) {_vm.$set(_vm.table, "selected_items", $$v)},expression:"table.selected_items"}}),_c('div',{staticClass:"d-flex mt-10"},[_c('v-btn',{attrs:{"color":"blue","loading":_vm.table.loading},on:{"click":_vm.fetch_price_sales}},[_vm._v("Обновить данные в таблице")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"lime","loading":_vm.loading_update},on:{"click":_vm.update_new_price}},[_vm._v("Изменить цену")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
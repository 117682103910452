<template>
    <v-container>
        <v-card max-width="700">
            <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>
            <v-card-title>Авторизация</v-card-title>
            <v-card-text>
                <div class="item-form">
                    <v-text-field
                        label="Номер телефона"
                        outlined
                        v-model="formData.phone"
                        v-mask="'+7 (###) ###-##-##'"
                        clearable
                        hide-details
                        tabindex="1"
                        class="item-form__input"
                    ></v-text-field>
                    <v-btn 
                        @click="sendCode"
                        :loading="loading.send_code" 
                        :disabled="loading.send_code"
                        tabindex="2"
                        color="primary"
                    >Отправить код</v-btn>
                </div>
                <div class="item-form">
                    <v-text-field
                        label="Код"
                        tabindex="3"
                        outlined
                        v-model="formData.confirm_code"
                        hide-details
                        :disabled="disableConfirmCode"
                        class="item-form__input"
                    ></v-text-field>
                    <v-btn 
                        @click="confirmCode" 
                        class="mt-3" 
                        tabindex="4"
                        :loading="loading.confirm_code"
                        :disabled="loading.confirm_code || disableConfirmCode"
                        color="primary"
                    >Войти</v-btn>
                </div>
            </v-card-text>
                
        </v-card>
    </v-container>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'

export default {
    name: 'AdvertisementAuthorization',
    data() {
        return {
            loading:{
                send_code: false,
                confirm_code: false
            },
            formData: {
                phone: null,
                confirm_code: null
            },
            data: {
                send_token: null
            },
        }
    },
    computed: {
        formatPhone(){
            return this.formData.phone.replace(/[^\d]/g, '')
        },
        disableConfirmCode(){
            return this.data.send_token ? false : true
        }
    },
    methods: {
        async sendCode() {
            this.loading.send_code = true
            const res = await AdvertisementNew.sendCode(this.formatPhone)
            if (res['token']){
                this.data.send_token = res['token']
            }
            else
                this.$toasted.show(`Ошибка сервера`, {
                    theme: "bubble",
                    type: 'error',
                    position: "top-center",
                    duration: 3000
                })
            this.loading.send_code = false
        },
        async confirmCode() {
            this.loading.confirm_code = true
            try {
                await AdvertisementNew.confirmCode(
                    this.formData.confirm_code, 
                    this.data.send_token
                )
                this.$emit('successAuthorization')
            } catch (err) {
                if (err.response.status) {
                    this.$toasted.show(err.response.data, {
                        theme: "bubble",
                        type: 'error',
                        position: "top-center",
                        duration: 3000
                    })
                }
            }
            this.loading.confirm_code = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .item-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__input{
            width: 70%;
            max-width: 70%;
        }
        &:not(:first-of-type){
            margin-top: 25px;
        }
    }
</style>
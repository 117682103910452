<template>
    <v-container fluid>
      <v-dialog v-model="check_data.dialog" max-width="1200px" v-if="check_data.dialog">
        <v-card>
          <v-card-title>Загрузка статусов и менеджеров</v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-file-input 
                label="Загрузка статусов и менеджеров" outlined dense id="upload_status_manager"
                truncate-length="50" show-size chips small-chips class="mr-5"
              ></v-file-input>
              <v-btn color="blue darken-1" :loading="check_data.loading" @click="check_change_status_manager">
                Проверить
              </v-btn>
            </v-row>
            <div v-if="check_data.step == 1" class="mt-5">
              <v-data-table
                :headers="check_data.headers" :items="check_data.for_change_manager"
                class="elevation-1" dense hide-default-footer disable-pagination
              >
                <template v-slot:item.sku="{ item }">
                  <div class="small-text" style="white-space: nowrap;">
                    <span class="field-name">sku1: </span>{{ item.sku ? item.sku : '-' }}
                    <span class="field-name">sku2: </span>{{ item.sku2 ? item.sku2 : '-' }}
                    <span class="field-name">code_1c: </span>{{ item.code_1c ? item.code_1c : '-' }}
                  </div>
                </template>
              </v-data-table>
              <v-btn color="blue darken-1" :loading="check_data.loading_action" @click="setManagerAll" class="mt-5">
                Изменить менеджеров
              </v-btn>
            </div>
            <div v-if="check_data.step == 2" class="mt-5">
              <v-data-table
                :headers="check_data.headers" :items="check_data.for_change_status"
                class="elevation-1" dense hide-default-footer disable-pagination
              >
                <template v-slot:item.sku="{ item }">
                  <div class="small-text" style="white-space: nowrap;">
                    <span class="field-name">sku1: </span>{{ item.sku ? item.sku : '-' }}
                    <span class="field-name">sku2: </span>{{ item.sku2 ? item.sku2 : '-' }}
                    <span class="field-name">code_1c: </span>{{ item.code_1c ? item.code_1c : '-' }}
                  </div>
                </template>
              </v-data-table>
              <v-btn color="blue darken-1" :loading="check_data.loading_action" @click="setStatusAll" class="mt-5">
                Изменить статусы
              </v-btn>
            </div>
          </v-card-text>
        </v-card>    
      </v-dialog>
      <v-dialog v-model="dialog_set_status" max-width="500px" v-if="dialog_set_status">
        <v-card>
          <v-card-title>
            <span class="text-h5">Изменение статусов у товаров</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="tmp_status" :items="filterChoices.status" class="mt-3"
              label="Новый статус" outlined hide-details dense clearable
              item-text="name" item-value="id"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text :loading="loading_set_status" @click="setStatus">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>    
      </v-dialog>
      <v-dialog v-model="dialog_set_manager" max-width="500px" v-if="dialog_set_manager">
        <v-card>
          <v-card-title>
            <span class="text-h5">Изменение менеджера у товаров</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="tmp_manager" :items="filterChoices.users" class="mt-3"
              label="Новый менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text :loading="loading_set_manager" @click="setManager">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>    
      </v-dialog>
        <v-row>
          <router-link class="ml-3" target="_blank" :to="{name: 'catalog_tax'}" title="Налоги">
            <v-icon>mdi-cash</v-icon>
          </router-link>
          <router-link class="ml-3" target="_blank" :to="{name: 'catalog_redemption_percent'}" title="% выкупа">
            <v-icon>mdi-currency-usd-off</v-icon>
          </router-link>
        </v-row>    
        <div class="block_fixed_button" v-if="selected_item.length > 0 && !dialog_set_manager && !dialog_set_status">
          <v-btn class="mx-2" dark color="primary" rounded @click="dialog_set_manager = true">Изменить менеджера</v-btn>
          <v-btn class="mx-2" dark color="success" rounded @click="dialog_set_status = true">Изменить статус</v-btn>
        </div>
        <v-row class="mt-3">
          <v-col>
            <v-autocomplete
              v-model="filters.status" :items="filterChoices.status"
              label="Статус" outlined hide-details dense clearable multiple
              item-text="name" item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.marketplace" :items="filterChoices.marketplace"
              label="МП" outlined hide-details dense clearable multiple
              item-text="name" item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.manager" :items="filterChoices.manager" item-text="name" item-value="id"
              label="Менеджер" outlined hide-details dense clearable multiple
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.product_group" :items="filterChoices.product_group"
              label="Группа" outlined hide-details dense clearable multiple
              item-text="group_name" item-value="group_id"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              clearable outlined dense label="Название" hide-details="hide-details"
              v-model="filters.name"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              clearable outlined dense label="Артикул МП" hide-details="hide-details"
              v-model="filters.code_mp"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-autocomplete
              v-model="filters.profile" :items="filterChoices.profile"
              label="Профиль" outlined hide-details dense clearable multiple
              item-text="name" item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.seller" :items="filterChoices.seller"
              label="Аккаунт" outlined hide-details dense clearable multiple
              item-text="name" item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.brand" :items="filterChoices.brand"
              label="Бренд" outlined hide-details dense clearable multiple
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              clearable outlined dense label="Тег" hide-details="hide-details"
              v-model="filters.tag"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              clearable outlined dense label="Артикул 1С" hide-details="hide-details"
              v-model="filters.code_1c"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              depressed color="purple darken-2" class="mb-3 ml-3" dark @click="fetchDataWithFilters"
          >Применить</v-btn>
          </v-col>
        </v-row>
        <v-skeleton-loader type='table' v-if="initLoading" class="mt-5"></v-skeleton-loader>
        <v-data-table
                v-else
                :headers="dataTable.headers"
                :pageCount="pagination.numberOfPages"
                :items="items"
                item-key="id"
                @update:page="pageChanged"
                @update:sort-by="sortChanged"
                @update:sort-desc="sortChanged"
                :loading="tableLoading"
                :options.sync="options"
                :items-per-page="100"
                :server-items-length="pagination.totalItems"
                class="elevation-1 mt-5"
                dense 
                v-model="selected_item" 
                show-select
        >
            <template v-slot:item.names="{ item }">
                <div class="small-text">{{ item.code1C_name ? item.code1C_name: '-' }}</div>
            </template>
            <template v-slot:item.barcode="{ item }">
                <div class="small-text">{{ item.barcode ? item.barcode: '-' }}</div>
            </template>
            <template v-slot:item.account_seller_name="{ item }">
                <div class="small-text">{{ item.account_seller_name ? item.account_seller_name: '-' }}</div>
            </template>
            <template v-slot:item.account_marketplace_name="{ item }">
                <div class="small-text">{{ item.account_marketplace_name ? item.account_marketplace_name: '-' }}</div>
            </template>          
            <template v-slot:item.sku="{ item }">
              <div class="small-text" style="white-space: nowrap;">
                <a style="display: block;" :href="mp_link(item, item.sku, item.account_marketplace_id)" target="_blank">
                  <span class="field-name">sku1: </span>{{ item.sku ? item.sku : '-' }}
                </a>
                <a style="display: block;" :href="mp_link(item, item.sku2, item.account_marketplace_id)" target="_blank">
                  <span class="field-name">sku2: </span>{{ item.sku2 ? item.sku2 : '-' }}
                </a>
              </div>
            </template>
            <template v-slot:item.codes="{ item }">
              <div class="small-text" style="white-space: nowrap;">
                <div><span class="field-name">code_mp: </span>{{ item.codeMP ? item.codeMP : '-' }}</div>
                <a style="display: block;" :href="dash_link(item, item.sku, item.account_marketplace_id)" target="_blank">
                  <span class="field-name">code_1c: </span>{{ item.code1C_code ? item.code1C_code : '-' }}
                </a>
              </div>
            </template>
            <template v-slot:item.brand="{ item }">
              <div class="small-text" style="white-space: nowrap;">
                <div><span class="field-name">brand_mp: </span>{{ item.brand ? item.brand : '-' }}</div>
                <div><span class="field-name">brand_1c: </span>{{ item.code1C_brand ? item.code1C_brand : '-' }}</div>
              </div>
            </template>
            <template v-slot:item.tag="{ item }">
              <div class="small-text">{{ item.code1C_tag_name ? item.code1C_tag_name: '-' }}</div>
            </template>
            <template v-slot:item.product_group="{ item }">
              <div class="small-text">{{ item.code1C_tag_group_name ? item.code1C_tag_group_name: '-' }}</div>
            </template>
            <template v-slot:item.profile="{ item }">
              <div class="small-text">{{ item.code1C_profile_name ? item.code1C_profile_name: '-' }}</div>
            </template>
            <template v-slot:item.manager="{ item }">
              <div class="small-text">{{ item.our_manager ? item.our_manager: '-' }}</div>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="small-text">{{ item.status_name ? item.status_name: '-' }}</div>
            </template>4
            <template v-slot:top>
              <v-row class="mb-5">
                <div class="ml-5">
                  <v-btn
                    color="primary" dark @click="export_catalog" :loading="loading_export"
                  >Экспорт</v-btn>
                </div>
                <div class="ml-5">
                  <v-btn
                    color="primary" dark @click="check_data.dialog=true"
                  >Загрузка статусов и менеджеров</v-btn>
                </div>
              </v-row>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
  import { Products } from '@/api/products'
  import { Catalog } from '@/api/catalog';

  export default {
    name: 'CatalogList',
    data () {
      return {
        check_data: {
          for_change_status: [],
          for_change_manager: [],
          step: 0,
          loading: false,
          dialog: false,
          headers: [
            {text: 'SKU', align: 'start', sortable: false, value: 'sku', width: '1%'},
            {text: 'Старое значение', align: 'start', sortable: false, value: 'old_value', width: '1%'},
            {text: 'Новое значение', align: 'start', sortable: false, value: 'new_value', width: '1%'},
          ],
          loading_action: false
        },
        loading_export: false,
        dialog_set_manager: false,
        loading_set_manager: false,
        tmp_manager: null,
        dialog_set_status: false,
        loading_set_status: false,
        tmp_status: null,
        initLoading: false,
        tableLoading: false,
        pagination: {
          next: null,
          totalItems: 0,
          numberOfPages: 0,
          currentPage: 0
        },
        selected_item: [],
        options: {},
        filters: {
        },
        filterChoices: {},
        items: [],
        dataTable: {
          headers: [
            {text: 'Название', align: 'start', sortable: false, value: 'names', width: '11%'},
            {text: 'Артикулы 1с', align: 'start', sortable: false, value: 'codes', width: '7%'},
            {text: 'Артикулы МП', align: 'start', sortable: false, value: 'sku', width: '7%'},
            { text: 'ШК', value: 'barcode', width: '7%' },
            { text: 'Бренд', value: 'brand', width: '11%', sortable: false },
            { text: 'Аккаунт', value: 'account_seller_name', width: '7%', sortable: false },
            { text: 'МП', value: 'account_marketplace_name', width: '7%', sortable: false },
            { text: 'Профиль', value: 'profile', width: '7%', sortable: false },
            { text: 'Категория', value: 'product_group', width: '7%', sortable: false },
            { text: 'Тег', value: 'tag', width: '7%', sortable: false },
            { text: 'Статус', value: 'status', width: '7%', sortable: false },
            { text: 'Менеджер', value: 'manager', width: '7%', sortable: false },
          ],
        }
      }
    },
    computed: {
      transfromFiltersForQuery () {
        let query = {}
        for (const f in this.filters) {
          if (Array.isArray(this.filters[f])) {
            query[f] = this.filters[f].join(',')
          } else {
            query[f] = this.filters[f]
          }
          if (this.filters[f] == false) {
            query[f] = null
          }
        }
        return query
      },
    },
    methods: {
      async sortChanged () {
        await this.fetchData()
      },
      async pageChanged () {
        await this.fetchData()
      },
      async fetchDataWithFilters () {
        this.options.page = 1
        this.selected_item = []
        await this.fetchData()
      },
      async fetchData () {
        this.tableLoading = true
        const { page, itemsPerPage, sortBy, sortDesc } = this.options
        let pageNumber = page - 1
        try {
          const response = await Products.fetchCatalog(
            {
              ...this.transfromFiltersForQuery,
              ...{
                'offset': itemsPerPage*pageNumber,
                'limit': itemsPerPage,
                'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
              }
            }
          )
          this.items = response.data.results
          this.pagination.totalItems = response.data.count
          this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
        } catch (err) {
          console.log(err.data)
        }
        this.tableLoading = false
      },
      async fetchCatalogFilterChoices() {
        try {
          const response = await Products.fetchCatalogChoices()
          this.filterChoices = response.data
          let tmp_list_manager = [{'id': -1, 'name': 'Без менеджера'}]
          tmp_list_manager.push(...this.filterChoices.manager)
          this.filterChoices.manager = tmp_list_manager
        } catch (err) {
            console.log('err')
            console.log(err.response)
        }
      },
      async setManager(){
        this.loading_set_manager = true
        let products = []
        this.selected_item.forEach(item => products.push(item.id))
        try{
          const response = await Catalog.setManager({manager: this.tmp_manager, products: products})
          this.$toasted.show(`Обновлено товаров ${response.data.count} из ${this.selected_item.length}`, {
            theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
          });
          this.selected_item = []
          await this.fetchData()
        } catch(err){
          this.$toasted.show(`Возникла ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
          });
        }
        this.loading_set_manager = false
        this.dialog_set_manager = false
      },
      async setStatus(){
        this.loading_set_status = true
        let products = []
        this.selected_item.forEach(item => products.push(item.id))
        try{
          const response = await Catalog.setStatus({status: this.tmp_status, products: products})
          this.$toasted.show(`Обновлено товаров ${response.data.count} из ${this.selected_item.length}`, {
            theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
          });
          if (response.data.errors && response.data.errors.length > 0)
            this.$toasted.show(`Ошибки по товаров ${response.data.errors}`, {
              theme: 'bubble', type: 'error', position: 'top-center', duration: 10000,
            });
          this.selected_item = []
          await this.fetchData()
        } catch(err){
          this.$toasted.show(`Возникла ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
          });
        }
        this.loading_set_status = false
        this.dialog_set_status = false
      },
      mp_link(item, sku, mp){
        if (mp == '000000009') // ВБ
          return `https://www.wildberries.ru/catalog/${sku}/detail.aspx`
        if (mp == '000000001') // ОЗОН
          return `https://www.ozon.ru/context/detail/id/${sku}/`
        if (mp == '000000011') // Дет. мир
          return `https://www.detmir.ru/product/index/id/${item.detmir_products_link.promo_id}/`
        return ''
      },
      dash_link(item, sku, mp){
        if (mp == '000000009')
          return `https://mp.lomex-group.ru/products/${sku}`
        if (mp == '000000001')
          return `https://mp.lomex-group.ru/ozon-products/${item.ozon_products_link.mid}`
        if (mp == '000000011')
          return `https://mp.lomex-group.ru/detmir-products/${item.detmir_products_link.detmir_id}`
        return ''
      },
      async export_catalog(){
				this.loading_export = true
        const { sortBy, sortDesc } = this.options
				try {
					const response = await Catalog.exportCatalog({
            ...this.transfromFiltersForQuery,
              'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
          })
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'catalog.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export = false
			},
    async check_change_status_manager(){
      this.check_data.loading = true

        let products = []
        this.selected_item.forEach(item => products.push(item.id))
        try{
          let fileToUpload = document.getElementById('upload_status_manager').files[0]
          let formData = new FormData()
          formData.append('fileToUpload', fileToUpload)
          const response = await Catalog.checkUploadFileStatusManager(formData)
          this.check_data.for_change_manager = response.data.for_change_manager
          this.check_data.for_change_status = response.data.for_change_status
          if (this.check_data.for_change_manager.length > 0)
            this.check_data.step = 1
          else if (this.check_data.for_change_status.length > 0)
            this.check_data.step = 2
          else{
            this.$toasted.show(`Изменений не найдено`, {
              theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
            });
            this.check_data.loading = false
            this.check_data.dialog = false
          }
        } catch(err){
          this.$toasted.show(`Возникла ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
          });
        }
        this.check_data.loading = false
      },
      async setStatusAll(){
        this.check_data.loading_action = true
        let products = []
        this.check_data.for_change_status.forEach(
          item => products.push({'status': item.status_id, 'product': item.catalog_mp_id})
        )
        try{
          const response = await Catalog.setStatusALl({products: products})
          this.$toasted.show(`Обновлено товаров ${response.data.count} из ${products.length}`, {
            theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
          });
          if (response.data.errors && response.data.errors.length > 0)
            this.$toasted.show(`Ошибки по товаров ${response.data.errors}`, {
              theme: 'bubble', type: 'error', position: 'top-center', duration: 10000,
            });
        } catch(err){
          this.$toasted.show(`Возникла ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
          });
        }
        this.check_data.loading_action = false
        this.check_data.dialog = false
        this.check_data.for_change_manager = []
        this.check_data.for_change_status = []
        this.check_data.step = 0
        await this.fetchData()
      },
      async setManagerAll(){
        this.check_data.loading_action = true
        let products = []
        this.check_data.for_change_manager.forEach(
          item => products.push({'user': item.manager_id, 'product': item.catalog_mp_id})
        )
        try{
          const response = await Catalog.setManagerAll({products: products})
          this.$toasted.show(`Обновлено товаров ${response.data.count} из ${products.length}`, {
            theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
          });
        } catch(err){
          this.$toasted.show(`Возникла ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
          });
        }
        this.check_data.loading_action = false
        if (this.check_data.for_change_status.length > 0)
          this.check_data.step = 2
        else{
          this.check_data.loading_action = false
          this.check_data.dialog = false
          this.check_data.for_change_manager = []
          this.check_data.for_change_status = []
          this.check_data.step = 0
          await this.fetchData()
        }
      },
    },
    async mounted () {
      document.title = `Каталог | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Каталог`);
      await this.fetchCatalogFilterChoices()
      this.initLoading = true
      await this.fetchData()
      this.initLoading = false
    }
  }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .rating-chip{
        display: inline-block;
        padding: 3px 8px;
        color: #ffffff;
        border-radius: 5px;
        font-size: .9rem;
    }
    .field-name{
        font-size: .7rem;
        color: #888888;
    }
    .block_fixed_button{
      display: flex;
      justify-content: center;
      position: fixed;
      z-index: 100;
      bottom: 25px;
      left: calc(50% - 220px);
    }
</style>

<template>
	<v-data-table
		:headers="table.headers"
		:items="table.data"
		class="elevation-0 vertical-border small-font"
		:loading="table.loading"
		:options.sync="table.options"
		:items-per-page="50"
		:footer-props="{'items-per-page-options': [10, 15, 25, 50]}"
		:server-items-length="table.pagination.totalItems"
	>
		<template v-slot:top>
			<div class="d-flex">
				<v-btn 
				:color="$vuetify.theme.dark ? '#208379' : '#20ddca'" 
				class="mb-5" @click="export_advertisement_stats" :loading="export_loading"
				>Экспорт в excel</v-btn>
			</div>
		</template>
	</v-data-table>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementStatsTableComponent',
    data() {
        return {
			table:{
				first_init: false,
				loading: false,
				data: [],
				headers: [
					{ text: 'Дата', value: 'date', sortable: true, width: '1%' },
					{ text: 'id РК', value: 'campaign_id', sortable: false, width: '1%' },
					{ text: 'sku', value: 'nmid', sortable: false, width: '1%' },
					{ text: 'Тип РК', value: 'campaign_type', sortable: false, width: '1%' },
					{ text: 'Цель РК', value: 'purpose', sortable: false, width: '1%' },
					{ text: 'Добавление в корзину', value: 'add_to_basket', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'Средняя позиция', value: 'auto_avg_position', sortable: false, width: '1%' },
					{ text: 'Заказы', value: 'orders', sortable: false, width: '1%' },
					{ text: 'Дельта заказы', value: 'delta_orders', sortable: false, width: '1%' },
					{ text: 'CPO дельта', value: 'delta_cpo', sortable: false, width: '1%' },
					{ text: 'CPC', value: 'cpc', sortable: false, width: '1%' },
					{ text: 'CR', value: 'cr', sortable: false, width: '1%' },
					{ text: 'CTR', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'FRQ', value: 'frq', sortable: false, width: '1%' },
					{ text: 'CPM', value: 'cpm', sortable: false, width: '1%' },
					{ text: 'Уник. польз.', value: 'unique_users', sortable: false, width: '1%' },
					{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
					{ text: 'Выручка', value: 'revenue', sortable: false, width: '1%' },
					{ text: 'Дельта выручка', value: 'delta_revenue', sortable: false, width: '1%' },
					{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
					{ text: 'Дельта ДРР', value: 'delta_drr', sortable: false, width: '1%' },
					{ text: 'ВП, шт', value: 'vp_unit_plan_simple', sortable: false, width: '1%' },
					{ text: 'ВП, сумма', value: 'vp_total_plan_simple', sortable: false, width: '1%' },
					{ text: 'Дельта ВП, сумма', value: 'vp_total_plan_delta', sortable: false, width: '1%' },
					{ text: 'Доход', value: 'profit_plan_simple', sortable: false, width: '1%' },
					{ text: 'Дельта доход', value: 'profit_plan_delta', sortable: false, width: '1%' },
					{ text: 'Дельта конверсия', value: 'calc_delta_conversion', sortable: false, width: '1%' },
					{ text: '% выкупа', value: 'calc_redemption_percent', sortable: false, width: '1%' },
					{ text: 'Цена продажи', value: 'calc_price', sortable: false, width: '1%' },
					{ text: 'Цена с СПП', value: 'calc_price_with_spp', sortable: false, width: '1%' },
					{ text: 'СПП %', value: 'calc_spp', sortable: false, width: '1%' },
					{ text: 'С/С', value: 'calc_cost_price', sortable: false, width: '1%' },
					{ text: 'Заказы товара', value: 'calc_orders_from_parsing', sortable: false, width: '1%' },
					{ text: 'Налог', value: 'calc_seller_tax', sortable: false, width: '1%' },
					{ text: 'Выкупов, шт', value: 'calc_count_redemption', sortable: false, width: '1%' },
					{ text: 'Логистика', value: 'calc_logistic', sortable: false, width: '1%' },
					{ text: 'Комиссия', value: 'calc_commission', sortable: false, width: '1%' },					
				],
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
			},
			export_loading: false
		}		
    },
	props: ['filters'],
    watch:{
		async filters(){
			this.table.options.page = 1
            await this.get_advertisement_stats()
		},
        'table.options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && this.table.first_init) {
				await this.get_advertisement_stats()
			}
        },
	},
    methods: {
		async get_advertisement_stats(){
            this.table.loading = true
            const { page, itemsPerPage, sortDesc, sortBy} = this.table.options
            try {
				let pageNumber = page - 1
				if (this.table.data.length != 0)
					window.scrollTo(0,0);
                const response = await Advertisement.fetchAdvertisementStats({
					...this.filters, 'offset': itemsPerPage * pageNumber, 
					'limit': itemsPerPage, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				})
                this.table.data = response.data.results
                this.table.pagination.totalItems = response.data.count
                this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
            } catch (err) {
                console.error(err.response)
            }
            this.table.loading = false
			if (!this.table.first_init)
				this.table.first_init = true
        },
		async export_advertisement_stats(){
			this.export_loading = true
			const { sortDesc, sortBy} = this.table.options
			try {
				const response = await Advertisement.exportAdvertisementStats({
					...this.filters, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				})
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_delta_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.export_loading = false
		}
    },
}
</script>
import { HTTP } from './http'

export const General = {
  async getWbAccounts () {
    return await HTTP.get('/products/wbaccounts/')
  },
	async getOzonApiAccounts () {
		return await HTTP.get('/accounts/ozon_accounts/')
	},
  async fetchProductGroup(query=null) {
		return HTTP.get('/products/list-product-group-choices/', {params: query})
	},
  async fetchProductStatus() {
		return HTTP.get('/products/list-product-status-choices/')
	},
  async fetchProductManager() {
		return HTTP.get('/products/list-product-manager-choices/')
	},
  async fetchProductBrand() {
		return HTTP.get('/products/list-product-brand-choices/')
	},
	async fetchProductProfile() {
		return HTTP.get('/products/list-product-profile-choices/')
	},
	async fetchReportSupplier() {
		return HTTP.get('/stats/list-report-supplier-choices/')
	},
	async fetchAdvertisementType() {
		return HTTP.get('/advertisement_new/list-advertisement-type-choices/')
	},
	async fetchAdvertisementStatus() {
		return HTTP.get('/advertisement_new/list-advertisement-status-choices/')
	},
	async fetchAdvertisementPurpose() {
		return HTTP.get('/advertisement_new/list-advertisement-purpose-choices/')
	},
	async fetchDeletionStatusFeedbackChoices(){
		return HTTP.get('/products/list-deletion-status-choices/')
	},
	async fetchFeedbackTemplateResponseChoices() {
		return HTTP.get('/products/list-feedback-template-response/')
	},
	async fetchSellersChoices() {
		return HTTP.get('/catalog/sellers_choices/')
	},
	async fetchOzonAdverStatusChoices() {
		return HTTP.get('/advertisement_new/ozon/advertisements/status_choices/')
	},
	async fetchOzonAdverStatusType() {
		return HTTP.get('/advertisement_new/ozon/advertisements/type_choices/')
	},
	async fetchOzonTransactionTypeNameChoices(){
		return HTTP.get('/stats/ozon/list_transactions_choices/')
	},
	async fetchDelayedTaskStatusChoices(){
		return HTTP.get('/management_product/delayed_task_status_choices/')
	},
	async fetchAmoCrmToken(){
		return HTTP.get('/amo_crm/tokens/')
	}
}
<template>
    <div class="">
        <div class="pa-5">
            <h2 class="v-heading text-h4 text-sm-h4 mb-3">Аналитика по заказам\продажам</h2>
        </div>
        <AnalyticsProductsList></AnalyticsProductsList>
    </div>
</template>

<script>
  import AnalyticsProductsList from '@/components/AnalyticsProductsList'
  export default {
    name: 'Analytics',
    components: {
      AnalyticsProductsList
    },
    mounted () {
      this.$store.commit('setPageTitle', `Аналитика`)
    }
  }
</script>

<style scoped>

</style>

<template>
    <v-container>
		<v-card max-width="900">
            <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>
            <v-card-title>Создание рекламной кампании</v-card-title>
            <v-card-text>
				<div class="main_form">
					<v-autocomplete
                        v-model="formData.supplier" :items="wb_accounts" item-text="name" item-value="id"
                        label="Аккаунт" @click:clear="clearField(['products'])" outlined
                        hide-details dense clearable
                    ></v-autocomplete>
					<v-text-field
                        label="Название кампании" outlined dense v-model="formData.name"
						maxlength="50" counter class="mt-5"
                    ></v-text-field>
					<v-autocomplete
                        v-model="formData.adver_type" :items="adver_type" item-text="name"
                        item-value="value" label="Тип рекламы" @click:clear="clearField(['products'])"
                        outlined hide-details dense clearable
                    ></v-autocomplete>
					<v-text-field
						v-model="formData.change_budget" label="Пополнение бюджета" outlined
						type="number" class="mt-6" dense clearable hide-details
					></v-text-field>
					<v-autocomplete
						v-model="formData.purpose" :items="advertisement_purpose" item-text="name"
						item-value="value" label="Цель" outlined dense hide-details clearable class="mt-5"
					></v-autocomplete>
					<v-text-field
						v-model="formData.comment" label="Комментарий" outlined dense clearable 
						class="mt-5" hide-details
					></v-text-field>
					<v-row class="mt-2" v-if="formData.supplier">
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.nmid = null"
								v-model="search.nmid" label="Введите артикул" hide-details
								outlined @change="getProducts" dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.name = null" v-model="search.name"
								label="Введите название" hide-details @change="getProducts"
								outlined dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-autocomplete
								v-model="formData.products" :items="products"
								item-text="text" item-value="id" :loading="search.loading" multiple
								label="Выберите товары" outlined hide-details dense clearable
							></v-autocomplete>
						</v-col>
					</v-row>
					<div class="mt-3" v-if="formData.supplier">
						Итоговые данные по товарам: {{ prepare_products }}
					</div>
				</div>
				<v-row class="mt-5 ml-1" v-if="formData.supplier">
					<v-btn 
						:loading="formData.loading_check" @click="checkFillData"
						color="primary" :disabled="this.products.length < 1"
					>Проверить</v-btn>	
					<v-btn
						v-if="check" class="ml-5" color="primary" @click="createAdvertisement"
						:loading="loading_create"
					>Создать</v-btn>	
				</v-row>
            </v-card-text>
        </v-card>
	</v-container>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import {General} from '@/api/general'

export default {
    name: 'AdvertisementFormCreate',
    data() {
        return {
			check: false,
			search: {
				nmid: null,
				name: null,
				loading: false
			},
			products: [],
			wb_accounts: [],
			formData:{
				supplier: null,
				name: null,
				adver_type: null,
				products: [],
				purpose: null,
				comment: null,
				change_budget: null,
				loading_check: false
			},
			prepare_products: [],
			adver_type: [
				{name: "Поиск", value: "search"},
				{name: "Каталог", value: "catalog"},
				{name: "Карточка товара", value: "carousel-auction"},
				{name: "Рекомендации", value: "recom"},
				{name: "Поиск + каталог", value: "seacat"},
			],
			advertisement_purpose: [],
			group_product_list: [],
			products_list: [],
			loading_create: false
        }
    },
    methods: {
		clearField(array){
			array.forEach(item=>{
				this.formData[item] = []
			})
		},
		async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.advertisement_purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		async createAdvertisement(){
			this.loading_create = true
			let query = {
				'supplier_id': this.formData.supplier, 
				'url_type': this.formData.adver_type, 
				"products": this.prepare_products,
				"name": this.formData.name,
				"comment": this.formData.comment,
				"purpose": this.formData.purpose,
				"change_budget": this.formData.change_budget,
			}
			try {
				const responce = await AdvertisementNew.createAdvertisementCampaign(query)
				if (responce && responce.status == 200)
					this.$router.push({name: 'advertisement_new_detail', params: {"id": responce.data.id}})
				else 
					this.$toasted.show(`Ошибка: ${responce.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
			}catch (err) {
				this.$toasted.show(`Ошибка: ${err}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
			}
			this.loading_create = false
		},
		async getProducts (){
			if (!this.formData.supplier)
				return
			try{
				this.search.loading = true
				let query = {"wbaccount": this.formData.supplier}
				if(this.search.nmid)
					query['nmid'] = this.search.nmid
				if (this.search.name)
					query['name'] = this.search.name
				const resp = await AdvertisementNew.getListProductsForCreateAdverSearch(query)
				this.products = resp.data
			} catch(err){
				console.log(err)
			}
			this.search.loading = false
		},
		async checkFillData(){
			this.formData.loading_check = true
			this.check = false
			this.prepare_products = []
			this.formData.products
			try {
                const resp = await AdvertisementNew.PrepareProducts({
					"products": this.formData.products, "supplier": this.formData.supplier, 
					"campaign_type": this.formData.adver_type, "is_various_types": true
				})
				this.prepare_products = resp.data
				this.check = true
            } catch (err) {
                console.log(err)
            }
			this.formData.loading_check = false
		},
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.wb_accounts = response.data
        },
	},
	async mounted(){
		await this.fetchWbAccounts()
		await this.getAdvertisementPurpose()
	}
}
</script>

<style lang="scss" scoped>

</style>
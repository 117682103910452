<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<v-card class="mt-5">
			<v-card-text>
				<v-row class="mt-5">
					<v-col>
						<v-menu 
							v-model="datePicker.datepicker_date_created_after" 
							:close-on-content-click="false"  
							prepend-icon="mdi-calendar"
							transition="scale-transition" 
							offset-y="offset-y"
							nudge-right="40" 
							min-width="290px"
							ref="datePicker.datepicker_date_created_after"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable
									@click:clear="filters.date_created_after = null"
									v-model="dateCreatedFormattedAfter"
									label="Дата создания (от)"
									readonly="readonly"
									v-bind="attrs"
									hide-details
									outlined
									v-on="on"
									dense
								></v-text-field>
							</template>
							<v-date-picker 
								v-model="filters.date_created_after" 
								@input="datePicker.datepicker_date_created_after = false"
								:first-day-of-week="1"
							></v-date-picker>
						</v-menu>
						<v-menu 
							v-model="datePicker.datepicker_date_created_before" 
							:close-on-content-click="false"  
							prepend-icon="mdi-calendar"
							transition="scale-transition" 
							offset-y="offset-y"
							nudge-right="40" 
							min-width="290px"
							ref="datePicker.datepicker_date_created_before"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable
									@click:clear="filters.date_created_before = null"
									v-model="dateCreatedFormattedBefore"
									label="Дата создания (до)"
									readonly="readonly"
									v-bind="attrs"
									hide-details
									outlined
									class="mt-3"
									v-on="on"
									dense
								></v-text-field>
							</template>
							<v-date-picker 
								v-model="filters.date_created_before" 
								@input="datePicker.datepicker_date_created_before = false"
								:first-day-of-week="1"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col>
						<v-menu 
							v-model="datePicker.datepicker_date_implementation_after" 
							:close-on-content-click="false"  
							prepend-icon="mdi-calendar"
							transition="scale-transition" 
							offset-y="offset-y"
							nudge-right="40" 
							min-width="290px"
							ref="datePicker.datepicker_date_implementation_after"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable
									@click:clear="filters.date_implementation_after = null"
									v-model="dateImplementationFormattedAfter"
									label="Дата исполнения (от)"
									readonly="readonly"
									v-bind="attrs"
									hide-details
									outlined
									v-on="on"
									dense
								></v-text-field>
							</template>
							<v-date-picker 
								v-model="filters.date_implementation_after" 
								@input="datePicker.datepicker_date_implementation_after = false"
								:first-day-of-week="1"
							></v-date-picker>
						</v-menu>
						<v-menu 
							v-model="datePicker.datepicker_date_implementation_before" 
							:close-on-content-click="false"  
							prepend-icon="mdi-calendar"
							transition="scale-transition" 
							offset-y="offset-y"
							nudge-right="40" 
							min-width="290px"
							ref="datePicker.datepicker_date_implementation_before"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									clearable
									@click:clear="filters.date_implementation_before = null"
									v-model="dateImplementationFormattedBefore"
									label="Дата исполнения (до)"
									readonly="readonly"
									v-bind="attrs"
									hide-details
									outlined
									class="mt-3"
									v-on="on"
									dense
								></v-text-field>
							</template>
							<v-date-picker 
								v-model="filters.date_implementation_before" 
								@input="datePicker.datepicker_date_implementation_before = false"
								:first-day-of-week="1"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col>
						<v-text-field
							v-model="filters.manager"
							clearable
							outlined
							dense
							label="Менеджер"
							hide-details="hide-details">
						</v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="filters.template"
							clearable
							outlined
							dense
							label="Название шаблона"
							hide-details="hide-details">
						</v-text-field>
					</v-col>
					<v-col>
						<v-autocomplete
							v-model="filters.status"
							:items="delayed_task_status_choices"
							item-text="name"
							item-value="value"
							label="Статус"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-autocomplete>
					</v-col>
					<v-col>
						<v-btn
							@click="getDelayedTask"
							depressed
							color="purple darken-2"
							style="width: 100%;"
							dark
						>Применить</v-btn>
					</v-col>
				</v-row>		
				<v-data-table
					ref="block_list_delayed_task"
					:headers="table.headers"
					:pageCount="pagination.numberOfPages"
					:items="table.data"
					dense
					:loading="table.loading"
					:options.sync="options"
					:items-per-page="25"
					:server-items-length="pagination.totalItems"
					class="elevation-1 mt-5"
					style="font-size: .6em;"
					:footer-props="{
						'items-per-page-options': [5, 10, 25, 50, 100]
					}"
				>
				<template v-slot:item.date_created="{ item }">
					<div v-if="item.date_created">{{ item.date_created | formatDateToLocale }}</div>
					<div v-else>-</div>
				</template>
				<template v-slot:item.date_implementation="{ item }">
					<div v-if="item.date_implementation">{{ item.date_implementation | formatDateToLocale }}</div>
					<div v-else>-</div>
				</template>
				<template v-slot:item.template="{ item }">
					<router-link target="_blank" :to="{name: 'management_product_main', params: { template_id: item.template_id }}">
						{{item.template}}
					</router-link>
				</template>
				<template v-slot:item.json_data="{ item }">
					<div v-if="item.json_data">
						<div v-for="(value, index) in item.json_data" :key="index">
							{{value.nmid}} - {{Number(value.price) | toRUB }} - {{value.discount}}%
						</div>
					</div>
				</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	import {ManagementProduct} from '@/api/management_product';
	import { General } from '@/api/general'

	export default {
		name: 'ManagementWbProductDelayesTask',
		data() {
			return {
				table: {
					loading: false,
					data: [],
					headers: [
						{ text: 'Дата создания', sortable: true, value: 'date_created' },
						{ text: 'Дата исполнения', sortable: true, value: 'date_implementation'},
						{ text: 'Менеджер', sortable: false, value: 'manager' },
						{ text: 'Шаблон', value: 'template', sortable: false },
						{ text: 'Изменяемые данные', value: 'json_data', sortable: false},
						{ text: 'Статус выполнения', value: 'status', sortable: false },
						{ text: 'Ошибки', value: 'error', sortable: false},
						{ text: 'Действия', value: 'action', sortable: false},
					]
				},
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				first_init: false,
				filters: {
					manager: null,
					template: null,
					status: null,
					date_created_after: null,
					date_created_before: null,
					date_implementation_after: null,
					date_implementation_before: null,
				},
				delayed_task_status_choices: [],
				datePicker: {
					datepicker_date_created_after: "",
					datepicker_date_created_before: "",
					datepicker_date_implementation_after: "",
					datepicker_date_implementation_before: "",
				},
			}
		},
		watch: {
			'options': async function (newVal, oldVal) {
				if (oldVal && this.first_init) {
					await this.getDelayedTask()
				}
			},
		},
		computed: {
			dateCreatedFormattedAfter () {
				return this.filters.date_created_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_created_after) : null
			},
			dateCreatedFormattedBefore () {
				return this.filters.date_created_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_created_before) : null
			},
			dateImplementationFormattedAfter () {
				return this.filters.date_implementation_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_implementation_after) : null
			},
			dateImplementationFormattedBefore () {
				return this.filters.date_implementation_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_implementation_before) : null
			},
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
		},
		methods: {
			async fetchDelayedTaskChoicesChoices () {
				try {
					const response = await General.fetchDelayedTaskStatusChoices()
					this.delayed_task_status_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async getDelayedTask() {
				this.table.loading = true
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				try {
					const response = await ManagementProduct.fetchDelayedTaskManagement({
						...this.transfromFiltersForQuery,
						...{
							'offset': itemsPerPage*pageNumber,
							'limit': itemsPerPage,
							'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
						}
					})
					this.table.data = response.data.results
					this.pagination.totalItems = response.data.count
					this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
					this.$vuetify.goTo(this.$refs.block_list_delayed_task, {
						duration: 300, offset: 150, easing: 'linear',
					})
				} catch (err) {
					console.log(err.data)
				}
				this.table.loading = false
			}
		},
		async mounted() {
			document.title = 'Управление ценой (Отложенное изменение цены)';
			this.$store.commit('setPageTitle', `Управление ценой (Отложенное изменение цены)`)
			await this.fetchDelayedTaskChoicesChoices()
			await this.getDelayedTask()
			this.first_init = true
		},
	};
</script>

<style scoped lang="scss">
    
</style>

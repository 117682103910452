<template>
	<div>	
		<v-sheet>
			<v-skeleton-loader
					v-if="balancesLoading"
					type="table-heading, table-row@5"
					class="pa-4"
			></v-skeleton-loader>
			<div v-else>
				<v-card>
					<v-data-table
							dense
							:headers="headers"
							:items="summaryDataPerDay"
							:items-per-page="20"
							class="elevation-0 vertical-border small-font"
					>
						<template v-slot:item.date="{ item }">
							<div class="d-flex"><span>{{ item.date | formatDateToLocaleNoTime }} </span><v-icon class="mx-2" small color="blue lighten-2" v-if="artificial_sales_dates.includes(item.date)">mdi-account-arrow-down</v-icon></div>
						</template>
						<template v-slot:item.stocks="{ item }">
							<div><span class="grey--text">Ожид. поставка: </span>{{ item.coming || '-' }}</div>
							<div><span class="grey--text">На складе: </span>{{ item.present || '-' }}</div>
							<div><span class="grey--text">Зарезервировано: </span>{{ item.reserved || '-' }}</div>
						</template>
						<template v-slot:item.orders_sum="{ item }">
							<div>{{ item.orders_sum | toRUB }}</div>
						</template>
						<template v-slot:item.sales_sum="{ item }">
							<div>{{ item.sales_sum | toRUB }}</div>
						</template>
	<!--                    <template v-slot:item.stock_balance="{ item }">-->
	<!--                        <div>-->
	<!--                            <div>-->
	<!--                                <a @click="openBalancesHourDialog(item)">{{ item.stock_balance }}</a>-->
	<!--                                <v-chip-->
	<!--                                        v-if="item.fbs"-->
	<!--                                        class="mx-1 px-1"-->
	<!--                                        color="red"-->
	<!--                                        outlined-->
	<!--                                        x-small-->
	<!--                                >-->
	<!--                                    FBS-->
	<!--                                </v-chip>-->
	<!--                            </div>-->
	<!--                        </div>-->
	<!--                    </template>-->
						<template v-slot:item.cost_price="{ item }">
							<div><a class="dashed" @click="openCostPriceDetailsDialog(item)">{{ item.cost_price | toRUB
								}}</a></div>
						</template>
					</v-data-table>
				</v-card>
				<v-dialog
						v-model="costPriceDetailsDialog"
						width="600"
						v-if="costPriceDetailsDialog"
				>
					<v-card>
						<v-card-title class="text-h5">
							Себестоимость на {{ activeCostPriceDetailsDay.date | formatDateToLocaleNoTime}}
						</v-card-title>
						<div style="height: 400px;">
							<CostPriceDetails :catalog_item_id="catalog_item_id"
											:date="activeCostPriceDetailsDay.date"></CostPriceDetails>
						</div>
					</v-card>
				</v-dialog>
				<v-dialog
						v-model="balancesDialog"
						max-width="600"
						v-if="balancesDialog"
						scrollable
				>
					<v-card>
						<v-card-title class="text-h5">
							История за {{activeBalanceDay.date | formatDateToLocaleNoTime}}
						</v-card-title>
						<div style="height: 400px;">
							<ProductStockBalancesHours :product_id="product_id"
													:date="activeBalanceDay.date"></ProductStockBalancesHours>
						</div>
					</v-card>
				</v-dialog>
				<v-dialog
						v-model="stocksDialog"
						max-width="600"
						v-if="stocksDialog"
						scrollable
				>
					<v-card>
						<v-card-title class="text-h5">
							Склады на {{activeStocksDay.date | formatDateToLocaleNoTime}}
						</v-card-title>
						<div style="height: 400px;">
							<ProductWarehouses :product_id="product_id" :date="activeStocksDay.date"></ProductWarehouses>
						</div>
					</v-card>
				</v-dialog>
				<v-dialog
						v-model="wbParsingOrdersPerDayDialog"
						max-width="600"
						v-if="wbParsingOrdersPerDayDialog"
						scrollable
				>
					<v-card>
						<v-card-title class="text-h5">
							Заказы на {{activeStocksDay.date | formatDateToLocaleNoTime}}
						</v-card-title>
						<div style="height: 400px;">
							<ProductWbParsingOrdersPerDay :product_id="product_id"
														:date="activeStocksDay.date"></ProductWbParsingOrdersPerDay>
						</div>
					</v-card>
				</v-dialog>
			</div>
		</v-sheet>
	</div>
</template>

<script>
	import {Products} from '@/api/products';
	import CostPriceDetails from '@/components/Product/CostPriceDetails';

	export default {
		name: 'OzonProductDataDays',
		props: {
			product_id: {
				type: String,
			},
			catalog_item_id: {
				type: String,
			},
			artificial_sales_dates: {
				type: Array,
				required: false
			}
		},
		components: {
			CostPriceDetails,
			// ProductStockBalancesHours,
			// ProductWarehouses,
			// ProductWbParsingOrdersPerDay,
		},
		data() {
			return {
				first_load: true,
				activeBalanceDay: null,
				balancesDialog: false,
				wbParsingOrdersPerDayDialog: false,
				stocksDialog: false,
				costPriceDetailsDialog: false,
				activeCostPriceDetailsDay: null,
				summaryDataPerDay: null,
				balancesLoading: true,
				table_filters: {
					dates: {
						from: null,
						to: null,
					},
					search: '',
					warehouse: '',
				},
				headers: [
					{
						text: 'Дата',
						align: 'start',
						sortable: true,
						value: 'date',
					},
					{text: 'Остаток 1С', value: 'catalog_count'},
					{text: 'Себестоимость', value: 'cost_price'},
					{text: 'Остатки OZON', value: 'stocks'},
					{text: 'Кол-во заказов (API)', value: 'orders_count'},
					{text: 'Сумма заказов (API)', value: 'orders_sum'},
					{text: 'Кол-во продаж (API)', value: 'sales_count'},
					{text: 'Сумма продаж (API)', value: 'sales_sum'},
				],
			};
		},
		async mounted() {
			await this.fetchProductDataDays();
		},
		methods: {
			openCostPriceDetailsDialog(item) {
				this.activeCostPriceDetailsDay = item;
				this.costPriceDetailsDialog = true;
			},
			async fetchProductDataDays() {
				this.balancesLoading = true;
				try {
					const res = await Products.fetchOzonProductDataDays(this.product_id)
					this.summaryDataPerDay = res.data.data;
				} catch (err) {
					console.log(err);
				}
				this.balancesLoading = false;
			},
		},
	};
</script>

<style scoped>

</style>
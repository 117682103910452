<template>
    <apexchart 
        type="line" 
        height="500" 
        :options="options"
        :series="series"
    ></apexchart>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'SummaryGraphVPRentab',
        components: {apexchart: VueApexCharts},
		props: ['summary_data', 'is_show_week'],
        data() {
            return {
                series: null,
				options: null
            };
        },
		methods: {
			calc_options(){
                this.options = {
                    theme: {mode: this.$vuetify.theme.dark ? 'dark': 'light'},
                    chart: {
                        height: 100, stacked: false, type: 'line', zoom: { enabled: false }, toolbar: { show: false },
                        background: 'rgba(0, 0, 0, 0)', width: '100%'
                    },
                    legend: { show: false },
                    stroke: { curve: 'smooth', width: 2 },
                    dataLabels: { enabled: false },
                    xaxis: {type: !this.is_show_week ? 'datetime': 'numeric'},
                    grid: {
                        show: false, row: { colors: ['#f3f3f3', 'transparent'], opacity: 0 },
                    },
                    yaxis: [
                        { show: true, title: { text: 'ВП МП, руб'}}, 
                        { opposite: true, show: true, title: { text: 'Рентабельность МП, %'} }
                    ]
                }
			}
		},
		created(){
            let prepare_data = []
            for (const key in this.summary_data) {
                prepare_data.push({
                    "date": key, 
                    "rentab": this.summary_data[key].rentab_mp.toFixed(2),
                    "vp": this.summary_data[key].vp_mp.toFixed(2),
                })
            }
            let series_rentab = prepare_data.map(item => ({x: item['date'], y: item['rentab']}))
            let series_vp = prepare_data.map(item => ({x: item['date'], y: item['vp']}))
			this.series = [
                {
                    name: 'ВП_МП', type: 'column', data: series_vp, 
                    color: function({ value, seriesIndex, w }) {
                        if (value > 0) {
                            return '#5ece8c'
                        } else {
                            return '#6200b5'
                        }
                    }
                },
                {name: 'Рентабельность МП', type: 'line', data: series_rentab, color: '#e8a2af'},
            ]
			this.calc_options()
		},
    };
</script>

<style scoped>

</style>
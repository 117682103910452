var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex mt-3"},[_c('v-select',{attrs:{"items":_vm.city_dict,"item-text":"name","item-value":"value","label":"Город","clearable":"","outlined":"","dense":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('v-btn',{attrs:{"depressed":"","color":"purple darken-2 ml-5","dark":""},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Загрузить")])],1),_c('v-data-table',{staticClass:"elevation-0 mt-0 vertical-border small-font",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.table_loading,"item-class":_vm.rowOurCompany,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.new_bet",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.calculate_new_bet(item)))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.time)+"ч")])]}},{key:"item.cpm",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(item.cpm)))])]}},{key:"item.weight_delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight_delivery.toFixed(3))+" ")]}},{key:"item.weight_cpm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight_cpm.toFixed(3))+" ")]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight.toFixed(3))+" ")]}}])},[_vm._v(" > ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
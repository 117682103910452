<template>
    <v-card rounded elevation="0" :loading="loadingData">
        <v-card-title>Последние задачи</v-card-title>
        <v-tabs fixed-tabs color="purple darken-2" v-model="tab">
            <v-tab>Все</v-tab>
            <v-tab>Ошибки 1С</v-tab>
        </v-tabs>
        <v-card-text class="pa-0">
            <v-divider></v-divider>
            <v-skeleton-loader v-if="loadingData" type="list-item-two-line@6"></v-skeleton-loader>
            <v-tabs-items v-else v-model="tab">
                <v-tab-item>
                    <task-history-list :data="data"></task-history-list>
                </v-tab-item>
                <v-tab-item>
                    <task-history-list :data="tasksWithErrors"></task-history-list>
                </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>
        </v-card-text>
    </v-card>
</template>

<script>
	import {Dashboard} from '@/api/dashboard';
	import TaskHistoryList from './TaskHistoryList';

	export default {
		name: 'TaskHistory',
		components: {TaskHistoryList},
		props: {
			itemsCount: {
				type: Number,
				default: 20,
				require: false,
			},
		},
		data() {
			return {
				loadingData: true,
				data: [],
                tab: null
			};
		},
		computed: {
			tasksWithErrors() {
				return this.data.filter(item => item.error_notes);
			},
		},
		async mounted() {
			await this.fetchData();
		},
		methods: {
			async fetchData() {
				this.loadingData = true;
				try {
					const response = await Dashboard.fetchTaskHistory({'items_count': this.itemsCount});
					this.data = response.data.results;
				} catch (err) {
					console.log(err);
				}
				this.loadingData = false;
			},
		},
	};
</script>

<style scoped lang="scss">

</style>
<template>
	<v-container fluid grey lighten-4 class="pa-5">
        <v-card class="mt-5">
            <v-card-title>
				Добавление товаров в список
				<router-link class="ml-3" target="_blank" :to="{name: 'management_product_templates'}">
					<v-icon title="Список шаблонов">mdi-frequently-asked-questions</v-icon>
				</router-link>
				<router-link class="ml-3" target="_blank" :to="{name: 'management_product_delayed_task'}">
					<v-icon title="Отложенные задачи">mdi-timetable</v-icon>
				</router-link>
			</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.account"
                            :items="filters_data.list_account"
                            item-text="name"
                            item-value="id"
                            label="ЛК"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.group"
                            :items="filters_data.list_product_group"
                            item-text="name"
                            item-value="value"
                            label="Группа"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.brand"
                            :items="filters_data.list_brand"
                            item-text="name"
                            item-value="name"
                            label="Бренд"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>               
                    <v-col>
                        <v-autocomplete
                            v-model="filters.manager" :items="filters_data.list_manager"
                            label="Менеджер" outlined hide-details dense clearable multiple item-text="name" item-value="id"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                          <v-autocomplete
                            v-model="filters.status"
                            :items="filters_data.list_status"
                            item-text="name"
                            item-value="value"
                            label="Статус"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.code_1c"
                            clearable
                            outlined
                            dense
                            label="Артикул 1С"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid"
                            clearable
                            outlined
                            dense
                            label="Артикул"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="fetchProducts"
                            depressed
                            color="purple darken-2"
                            style="width: 100%;"
                            dark
                        >Применить</v-btn>
                    </v-col>
                </v-row>
                <div class="mt-5" style="display: flex; justify-content:space-around;">
                    <v-btn
                        @click="addAllProductToList"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Добавить выделенные</v-btn>
                    <v-btn
                        @click="removeAllSelection"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Снять выделение</v-btn>
                </div>
                <v-data-table
					ref="block_add_template"
                    :headers="table_product.headers"
                    :pageCount="pagination.numberOfPages"
                    v-model="table_product.dedicated_product"
                    :items="table_product.data"
                    item-key="id"
                    dense
                    :loading="table_product.loading"
                    :options.sync="options"
                    :items-per-page="25"
                    :server-items-length="pagination.totalItems"
                    class="elevation-1 mt-5"
                    style="font-size: .6em;"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 25, 50, 100]
                    }"
                    show-select
                >
					<template v-slot:item.nmid="{ item }">
						<router-link 
							target="_blank" 
							:to="{ name: 'product_card', params: { id: item.nmid }}"
						>{{ item.nmid }}</router-link>
					</template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon 
							v-if="listAddedProductId.includes(item.id)" 
							color="green" small
							@click="removeSingleAddedProduct(item)"
						>
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon @click="addSingleProductToList(item)" v-else>mdi-plus</v-icon>
                    </template>
					<template v-slot:footer.prepend>
						<div>Выделено: {{table_product.dedicated_product.length}}</div>
					</template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-title>Выбранные товары</v-card-title>
            <v-card-text>
                <div class="mt-5" style="display: flex; justify-content:space-around;">
                    <v-btn
                        @click="saveData"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Сохранить</v-btn>
				</div>
                <v-data-table
                    :headers="table_product.headers"
                    :items="added_product"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					disable-pagination
                    class="elevation-0 mt-5"
                >
					<template v-slot:item.nmid="{ item }">
						<router-link 
							target="_blank" 
							:to="{ name: 'product_card', params: { id: item.nmid }}"
						>{{ item.nmid }}</router-link>
					</template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="removeSingleAddedProduct(item)" small>
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { General } from '@/api/general'
import { Products } from '@/api/products'
import { ManagementProduct } from '@/api/management_product'

export default {
	name: 'ManagementWbChangeList',
	data() {
        return {
            added_product: [],
            table_product: {
                dedicated_product: [],
                loading: false,
                data: [],
                headers: [
                    { text: 'Артикул', sortable: false, value: 'nmid', width: '5%' },
                    { text: 'Артикул 1с', sortable: false, value: 'catalog_item.code1C_code', width: '10%' },
					{ text: 'Наименование', sortable: false, value: 'catalog_item.code1C_name', width: '20%' },
                    { text: 'Статус', sortable: false, value: 'catalog_item.status_name', width: '10%' },
                    { text: 'ЛК', sortable: false, value: 'account', width: '10%' },
                    { text: 'Бренд', sortable: false, value: 'catalog_item.brand', width: '10%' },
                    { text: 'Группа', sortable: false, value: 'catalog_item.code1C_tag_group_name', width: '15%' },
                    { text: 'Менеджер', sortable: false, value: 'catalog_item.our_manager', width: '15%' },
                    { text: 'Действия', sortable: false, value: 'actions', width: '5%' }
                ]
            },
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
            first_init: false,
            filters: {
                account: null,
                group: null,
                brand: null,
                manager: null,
                status: ['0001'],
                code_1c: null,
                nmid: null
            },
            filters_data: {
                list_account: [],
                list_product_group: [],
                list_brand: [],
                list_manager: [],
                list_status: []
            }
        }
    },
    watch: {
        'options': async function (newVal, oldVal) {
            if (oldVal && this.first_init) {
                await this.fetchProducts()
            }
        },
    },
    computed: {
        listAddedProductId(){
            let result = []
            this.added_product.forEach(item => {
                result.push(item.id)
            })
            return result
        },
        transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else if(f=='nmid'){
					query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
				} else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
		getTempateId(){
			return this.$route.params.id
		}
    },
    methods: {
        async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.filters_data.list_account = response.data
        },
        async fetchProductGroup() {
            const response = await General.fetchProductGroup()
            this.filters_data.list_product_group = response.data
        },
        async fetchProductStatus() {
            const response = await General.fetchProductStatus()
            this.filters_data.list_status = response.data
        },
        async fetchProductManager() {
            const response = await General.fetchProductManager()
            this.filters_data.list_manager = response.data
        },
        async fetchProductBrand() {
            const response = await General.fetchProductBrand()
            this.filters_data.list_brand = response.data
        },
        async fetchProducts () {
            this.table_product.loading = true
            const { page, itemsPerPage, sortBy, sortDesc } = this.options
            let pageNumber = page - 1
            try {
                const response = await Products.fetchProducts(
                    {
                        ...this.transfromFiltersForQuery,
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                            'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                        }
                    }
                )
                this.table_product.data = response.data.results.objects
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				this.$vuetify.goTo(this.$refs.block_add_template, {
					duration: 300,
					offset: 150,
					easing: 'linear',
				})
            } catch (err) {
                console.log(err.data)
            }
            this.table_product.loading = false
        },
		async fetchProductsByTemplate(){
            try {
                const response = await ManagementProduct.getProductsByTemplate(this.getTempateId)
				this.added_product = response.data
            } catch (err) {
                console.log(err.data)
            }
		},
        addAllProductToList(){
            let added_list = this.listAddedProductId
            this.table_product.dedicated_product.forEach(item=>{
                if (!added_list.includes(item.id))
                    this.added_product.push(item)
            })
        },
        addSingleProductToList(item){
            if (!this.listAddedProductId.includes(item.id))
                this.added_product.push(item)
        },
        removeAllSelection(){
            this.table_product.dedicated_product = []
        },
        cleanAllAddedProduct(){
            this.added_product = []
        },
        removeSingleAddedProduct(item){
            this.added_product = this.added_product.filter(el => el.id != item.id);
        },
		async saveData(){
			let result = []
            this.added_product.forEach(item => {
                result.push({"id": item.id, "nmid": item.nmid})
            })
			try{
				await ManagementProduct.updateTemplateProduct(this.getTempateId, {"products": result})
				this.$router.push({name: 'management_product_main', params: {template_id: this.getTempateId}})
			} catch (err){
				console.log(err)
			}
		},
    },
    async mounted(){
        await this.fetchWbAccounts()
        await this.fetchProductGroup()
        await this.fetchProductStatus()
        await this.fetchProductManager()
        await this.fetchProductBrand()
        await this.fetchProducts()
		await this.fetchProductsByTemplate()
        this.first_init = true
		document.title = `Управление ценой (Изменение шаблона)`;
		this.$store.commit('setPageTitle', `Управление ценой (Изменение шаблона)`)
    }
}
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"px-10",attrs:{"sm":"12","lg":"12"}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.choices.suppliers,"item-text":"name","item-value":"value","label":"Аккаунт","outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.suppliers),callback:function ($$v) {_vm.$set(_vm.filters, "suppliers", $$v)},expression:"filters.suppliers"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.choices.status,"item-text":"name","item-value":"value","label":"Статус","outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","append-icon":"mdi-magnify","label":"Campaign id","hide-details":"hide-details"},model:{value:(_vm.filters.campaign_id),callback:function ($$v) {_vm.$set(_vm.filters, "campaign_id", $$v)},expression:"filters.campaign_id"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","color":"purple darken-2","dark":""},on:{"click":function($event){return _vm.applyFilter()}}},[_vm._v(" Применить ")])],1)],1)],1),_c('v-data-table',{ref:"start_block",staticClass:"elevation-1 vertical-border small-font",attrs:{"headers":_vm.table.headers,"pageCount":_vm.pagination.numberOfPages,"items":_vm.table.data,"item-key":"id","loading":_vm.table.loading,"options":_vm.options,"items-per-page":25,"server-items-length":_vm.pagination.totalItems,"footer-props":{
				'items-per-page-options': [5, 15, 25, 50, 100]
			}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.campaign_id",fn:function(ref){
			var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":{ name: 'ozon_advertisement_detail_new', params: { campaign_id: item.campaign_id }}}},[_vm._v(_vm._s(item.campaign_id))])]}},{key:"item.expenses",fn:function(ref){
			var item = ref.item;
return [(item.expenses)?_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(item.expenses)))]):_c('div',[_vm._v("-")])]}},{key:"item.orders_sum",fn:function(ref){
			var item = ref.item;
return [(item.orders_sum)?_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(item.orders_sum)))]):_c('div',[_vm._v("-")])]}},{key:"item.drr",fn:function(ref){
			var item = ref.item;
return [(item.orders_sum && item.expenses)?_c('div',[_vm._v(" "+_vm._s((item.expenses / item.orders_sum * 100).toFixed(2))+"% ")]):_c('div',[_vm._v("-")])]}},{key:"item.budget",fn:function(ref){
			var item = ref.item;
return [(item.budget)?_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(_vm.convertKopecksToRub(item.budget))))]):_c('div',[_vm._v("-")])]}},{key:"item.daily_budget",fn:function(ref){
			var item = ref.item;
return [(item.daily_budget)?_c('div',[_vm._v(_vm._s(_vm._f("toRUB")(_vm.convertKopecksToRub(item.daily_budget))))]):_c('div',[_vm._v("-")])]}},{key:"item.avg_price_views",fn:function(ref){
			var item = ref.item;
return [(item.views && item.expenses)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.expenses / item.views * 1000))+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.avg_price_clicks",fn:function(ref){
			var item = ref.item;
return [(item.clicks && item.expenses)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toRUB")(item.expenses / item.clicks))+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.ctr",fn:function(ref){
			var item = ref.item;
return [(item.clicks && item.views)?_c('div',[_vm._v(" "+_vm._s((item.clicks / item.views * 100).toFixed(2))+" ")]):_c('div',[_vm._v("-")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
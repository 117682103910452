import Vue from 'vue'

Vue.filter('formatDateToLocale', function (value){
    let date_obj = new Date(Date.parse(value))
    return date_obj.toLocaleString('default', {
        day:'2-digit',
        month:'2-digit',
        year:'numeric',
        hour:'numeric',
        minute:'numeric'
    })
})

Vue.filter('formatDateToLocaleNoTime', function (value){
    let date_obj = new Date(Date.parse(value))
    return date_obj.toLocaleString('default', {
        day:'2-digit',
        month:'2-digit',
        year:'numeric'
    })
})

Vue.filter('formatDateMonth', function (value){
    let date_obj = new Date(Date.parse(value))
    return date_obj.toLocaleString('default', { month: 'long' });
})


Vue.filter('onlyTime', function (value){
    let date_obj = new Date(Date.parse(value))
    return date_obj.toLocaleString('default', {
        hour:'numeric',
        minute:'numeric'
    })
})


Vue.filter('formatDateToQuery', function (value){
    let date_obj = new Date(Date.parse(value))
    return `${date_obj.getFullYear()}-${date_obj.getMonth() + 1}-${date_obj.getDate()}`
})

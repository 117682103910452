<template>
   <div>
		<v-col sm="12" lg="12" class="px-10">          
            <v-row class="mt-3">
                <v-col>
                    <v-select
                        v-model="filters.suppliers"
                        :items="choices.suppliers"
                        item-text="name"
                        item-value="value"
                        label="Аккаунт"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="filters.status"
                        :items="choices.status"
                        item-text="name"
                        item-value="value"
                        label="Статус"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
				<v-col>
                    <v-text-field
                        v-model="filters.mid"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Товар"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="filters.campaign_id"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Campaign id"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                            @click="applyFilter()"
                            depressed
                            color="purple darken-2"
                            class="mb-3"
                            dark
                    >
                        Применить
                    </v-btn>
                </v-col>
            </v-row>
            
        </v-col>
		<v-data-table
			ref="start_block"
			:headers="table.headers"
			:pageCount="pagination.numberOfPages"
			:items="table.data"
			item-key="id"
			:loading="table.loading"
			:options.sync="options"
			:items-per-page="25"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border small-font"
			:footer-props="{
				'items-per-page-options': [5, 15, 25, 50, 100]
			}"
        >
			<template v-slot:item.campaign_id="{ item }">
				<router-link 
					target="_blank" 
					:to="{ name: 'ozon_advertisement_detail_new', params: { campaign_id: item.campaign_id }}"
				>{{ item.campaign_id }}</router-link>
                </template>
			<template v-slot:item.expenses="{ item }">
				<div v-if="item.expenses">{{ item.expenses| toRUB }}</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.orders_sum="{ item }">
				<div v-if="item.orders_sum">{{ item.orders_sum| toRUB }}</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.drr="{ item }">
				<div v-if="item.orders_sum && item.expenses">
					{{ (item.expenses / item.orders_sum * 100).toFixed(2) }}%
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.budget="{ item }">
				<div v-if="item.budget">{{ convertKopecksToRub(item.budget) | toRUB }}</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.daily_budget="{ item }">
				<div v-if="item.daily_budget">{{ convertKopecksToRub(item.daily_budget) | toRUB }}</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.avg_price_views="{ item }">
				<div v-if="item.views && item.expenses">
					{{ item.expenses / item.views * 1000 | toRUB}}
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.avg_price_clicks="{ item }">
				<div v-if="item.clicks && item.expenses">
					{{ item.expenses / item.clicks | toRUB}}
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.ctr="{ item }">
				<div v-if="item.clicks && item.views">
					{{ (item.clicks / item.views * 100).toFixed(2) }}
				</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.rentab_value="{ item }">
				<div style="white-space: nowrap;" :class="{'green-block': item.rentab.rentab_plan >= 15, 'red-block': item.rentab.rentab_plan < 15}">
					План: {{ item.rentab.rentab_plan ? item.rentab.rentab_plan : 0 }}% ({{ item.rentab.vp_plan ? item.rentab.vp_plan : 0 | toRUB }})
				</div>
				<hr>
				<div style="white-space: nowrap;" :class="{'green-block': item.rentab.rentab_sales >= 15, 'red-block': item.rentab.rentab_sales < 15}">
					Факт: {{ item.rentab.rentab_sales ? item.rentab.rentab_sales : 0 }}% ({{ item.rentab.vp_sales ? item.rentab.vp_sales : 0 | toRUB }})
				</div>
			</template>       
        </v-data-table>
   </div>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'
import {General} from '@/api/general'

export default {
    name: 'AdvertisementTableSku',
    data() {
        return {
			table: {
				data: [],
				headers: [
					{ text: 'ID', value: 'campaign_id', sortable: true, width: '1%' },
					{ text: 'Название', value: 'campaign_name', sortable: false, width: '1%' },
					{ text: 'Размещение', value: 'placement_type_display', sortable: false, width: '1%' },
					{ text: 'Статус', value: 'status_display', sortable: false, width: '1%' },
					{ text: 'Заказы, шт', value: 'orders_count', sortable: false, width: '1%' },
					{ text: 'Дневной бюджет', value: 'daily_budget', sortable: false, width: '1%' },
					{ text: 'Расход, р', value: 'expenses', sortable: false, width: '1%' },
					{ text: 'Показы', value: 'views', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'Ср. цена за 1000 показов', value: 'avg_price_views', sortable: false, width: '1%' },
					{ text: 'CTR, %', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'Ср. цена клика', value: 'avg_price_clicks', sortable: false, width: '1%' },
					{ text: 'Заказы, р', value: 'orders_sum', sortable: false, width: '1%' },
					{ text: 'Бюджет', value: 'budget', sortable: false, width: '1%' },
					{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
					{ text: 'Аккаунт', value: 'account_name', sortable: false, width: '1%' },
					{ text: 'Маржинальность (ВП)', value: 'rentab_value', width: '2%', sortable: false },
				],
				loading: false,
			},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			options: {},
			first_init: false,
			filters: {
				campaign_id: null,
				type: ["SKU"],
				status: ["CAMPAIGN_STATE_RUNNING"],
				suppliers: null,
				mid: null
			},
			choices: {
				status: null,
				suppliers: null
			}
        }
    },
	watch: {
		'options': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.getListAdvertisement()
			}
		},
	},
	computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        }
	},
	methods: {
		async applyFilter(){
            this.options.page = 1
            await this.getListAdvertisement()
        },
		async getListAdvertisement(){
            this.table.loading = true
			this.$vuetify.goTo(this.$refs.start_block, {
				duration: 500,
				offset: 150,
				easing: 'linear',
			})
			const { page, itemsPerPage, sortBy, sortDesc } = this.options
			let pageNumber = page - 1
			try {
				const response = await OzonAdvertisement.getListAdvertisements(
					{
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
						...this.transfromFiltersForQuery,
					}
				)
				this.table.data = response.data.results.objects
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.log(err.data)
			}
			this.table.loading = false
		},
		convertKopecksToRub(money){
			return money ? money / 1000000: 0
		},
		async getChoicesSuppliers(){
            try {
                const responce = await General.fetchSellersChoices()
                this.choices.suppliers = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		async getChoicesStatus(){
            try {
                const responce = await General.fetchOzonAdverStatusChoices()
                this.choices.status = responce.data
            } catch (err) {
                console.log(err)
            }
        },
	},
	async mounted(){
		await this.getListAdvertisement()
		this.first_init = true
		await this.getChoicesSuppliers()
		await this.getChoicesStatus()
	}   
}
</script>

<style lang="scss" scoped>
	.red-block{
        background-color: #f1b7bd;
    }
	.theme--dark .red-block{
		background-color: #a0535b;
	}
    .green-block{
        background-color: #c0f1b4;
    }
	.theme--dark .green-block{
		background-color: #4f7844;
	}
</style>
import { HTTP } from './http'

export const Catalog = {
	async fetchProductCostPriceDetails (catalog_item_id, date) {
		return HTTP.get(`/catalog/cost_price_details/`, {
			params: {
				catalog_item_id,
				date
			}
		})
	},
	async getSellers () {
		return await HTTP.get('/products/ozon/sellers/')
	},
	async getComponentsList () {
		return await HTTP.get('/catalog/kit_components/')
	},
	async getComponent (component_id) {
		return HTTP.get(`/catalog/kit_components/${component_id}/`)
	},
	async getComponentCostPriceDays (code) {
		return HTTP.get(`/catalog/component_cost_price_days/${code}/`)
	},
	async toCard(product_id) {
		return HTTP.get(`/catalog/to_card_links/`, {
			params: {
				product_id
			}
		})
	},
	async setGroupToKitComponent(kit_id, group_id){
		return HTTP.post('/catalog/set_group_to_kit_component/', {
			"kit_id": kit_id, "group_id": group_id
		})
	},
	async setMainKitComponent(kit_id){
		return HTTP.post(`/catalog/set_main_kit_component/${kit_id}/`)
	},
	async fetchSellerTax(query=null){
		return HTTP.get('/catalog/seller_tax/', { params: query })
	},
	async updateSellerTax(query=null){
		return HTTP.post('/catalog/seller_tax/', query)
	},
	async setManager(data){
		return HTTP.post('/catalog/set_manager/', data)
	},
	async setStatus(data){
		return HTTP.post('/catalog/set_status/', data)
	},
	async fetchAllGroupRedemption(){
		return HTTP.get('/catalog/redemption_percent/')
	},
	async fetchDetailGroupRedemption(group_id){
		return HTTP.get('/catalog/redemption_percent/records/', {params: {'group_id': group_id}})
	},
	async deleteGroupRedemption(id){
		return HTTP.delete(`/catalog/redemption_percent/${id}/`)
	},
	async createGroupRedemption(data){
		return HTTP.post(`/catalog/redemption_percent/`, data)
	},
	async exportCatalog(query=null) {
		return HTTP.get('/catalog/mp/export_for_upload/', {responseType:"blob", params: query})
	},
	async checkUploadFileStatusManager (data) {
		return HTTP.post('/catalog/mp/check_upload_file/', data)
	},
	async setManagerAll (data) {
		return HTTP.post('/catalog/mp/set_manager_all/', data)
	},
	async setStatusALl (data) {
		return HTTP.post('/catalog/mp/set_status_all/', data)
	},

}
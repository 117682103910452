<template>
    <div class="product_stock_balances">
        <v-row>
            <v-col>
                <v-menu 
                    v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_from"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            clearable
                            v-model="dataRangeText"
                            label="Диапазон"
                            prepend-icon="mdi-calendar"
                            readonly="readonly"
                            v-bind="attrs"
                            hide-details="auto"
                            outlined
                            dense
                            v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="dates_range" range :first-day-of-week="1"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col>
                <v-btn
                    @click="fetchProductStockBalances()"
                    color="purple darken-2"
                    class="mb-3"
                    dark
                >Показать</v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <BeeGridTable
            class="product_table mt-5" :showFilter="false" :showPager="false"
            height="850" :columns="table_keys" :data="table_data" :loading="loading_table"
            border
        >
            <template slot-scope="{ column }" slot="column_name">
                <div
                    :class="['d-flex', {'yellow-cell': column.comment}]"
                    style="flex-direction: column; align-items: center; padding: 0px 5px;"
                >
                    <v-tooltip bottom max-width=350>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a 
                                    @click="openDialogComment(column.key)" class="dashed small-text"
                                >{{ column.title | formatDateToLocaleNoTime }}</a>
                                <v-icon
                                    small color="blue lighten-2" v-if="artificial_sales_dates.includes(column.title)"
                                >mdi-account-arrow-down</v-icon>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Комментарий: {{column.comment}}
							</div>
						</span>
                    </v-tooltip>
                </div>
            </template>
            <template slot-scope="{ column, row}" slot="head">
                <div class="d-flex" :class="['small-text', {'black-cell': emptyCell(row.label)}]">
                    {{ row[column.key] }}
					<v-tooltip bottom max-width=350 v-if="calcHelpText(row.label) != ''">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" style="cursor: pointer">
								<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
							</span>
						</template>
						<span>{{ calcHelpText(row.label) }}</span>
					</v-tooltip>
                </div>
            </template>
            <template slot-scope="{ column, row}" slot="data">
                <div class="black-cell" v-if="emptyCell(row.label)">{{row[column.key]}}</div>
                <div v-else-if="row.label == 'price_wb'">
					<div class="d-flex">
						<v-tooltip color="#2c2c2c" bottom max-width=350 v-if="row[column.key]">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a class="small-text pl-0 pr-0" @click="openPriceDialog(column.key)">
										{{ row[column.key][0] ? row[column.key][0] : 0 | toRUB }}
									</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][2]" :key="index">
									<p v-if="!item.is_commission_change && item.is_entered_spp > 0">{{ item.title }}(Снижение СПП: {{ item.is_entered_spp }}%)</p>
									<p v-else-if="!item.is_commission_change">{{ item.title }}()</p>
									<p v-else>{{ item.title }}(Минимальная комиссия: {{ item.min_commission }}% | Новая комиссия: -{{ item.is_entered_commission }}%)</p>
								</div>	
							</span>
						</v-tooltip>
						<v-tooltip color="#2c2c2c" bottom max-width=350 v-if="row[column.key] && row[column.key][1] && row[column.key][1].length > 0">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">(А)</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index" style="font-weight: bold;">
									<p v-if="!item.is_commission_change && item.is_entered_spp > 0">{{ item.title }}(Снижение СПП: {{ item.is_entered_spp }}%)</p>
									<p v-else-if="!item.is_commission_change">{{ item.title }}()</p>
									<p v-else>{{ item.title }}(Минимальная комиссия: {{ item.min_commission }}% | Новая комиссия: -{{ item.is_entered_commission }}%)</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
                </div>
                <div v-else-if="row.label=='price_api'">
                    <a class="small-text" @click="openPriceApiDialog(column.key)" v-if="row[column.key]">
                        {{ row[column.key] | toRUB }}
                    </a>
                    <span class="small-text" v-else>-</span>
                    <v-icon x-small class="ml-2" v-if="column._index == 1" @click="changeApiPriceDialog = true">
                        mdi-pencil
                    </v-icon>
                </div>
                <div class="small-text" v-else-if="row.label == 'cost_price'">
                    <a class="dashed" @click="openCostPriceDetailsDialog(column.key)" v-if="row[column.key]">
                        {{ row[column.key] | toRUB}}
                    </a>
                    <span class="small-text" v-else>-</span>
                </div>
                <div v-else-if="row.label=='orders_api_amount'">
                    <v-tooltip
                            bottom
                            max-width=350
                            v-if="row[column.key] && row[column.key][0] && row[column.key][1]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text">{{ row[column.key][0] | toRUB }}</a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Сбор.задания: {{row[column.key][1] | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                    <div v-else-if="row[column.key] && row[column.key][0]" class="small-text">
                        {{row[column.key][0] | toRUB}}
                    </div>
                    <div v-else class="small-text">-</div>
                </div>
                <div v-else-if="row.label=='orders_api_count'">
                    <v-tooltip
                            bottom
                            max-width=350
                            v-if="row[column.key] && row[column.key][0] && row[column.key][1]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text">{{ row[column.key][0] }}</a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Сбор.задания: {{row[column.key][1]}}
							</div>
						</span>
                    </v-tooltip>
                    <div v-else-if="row[column.key] && row[column.key][0]" class="small-text">
                        {{row[column.key][0]}}
                    </div>
                    <div v-else class="small-text">-</div>
                </div>
                <div v-else-if="row.label=='orders_wb_amount'">
                    <div class="small-text">
                        {{ row[column.key] | toRUB }}
                    </div>
                </div>
                <div v-else-if="row.label=='orders_wb_count'">
                    <div>
                        <a
                                @click="openWbParsingOrdersPerDayDialog(column.key)"
                                v-if="row[column.key]"
                                class="small-text"
                        >
                            {{ Math.abs(row[column.key]) }}
                        </a>
                    </div>
                </div>
                <div class="yellow-cell" style="display: block;" v-else-if="row.label=='rentab' || row.label=='markup'">
                    <div 
                        :class="['small-text', {'bold_text': row[column.key][2] > 0}]"
                    >{{ row[column.key][0] ? (row[column.key][0] * 100).toFixed(2) : 0 }}%</div>
                </div>
                <div v-else-if="row.label=='warenwert_1c'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][0] && (row[column.key][1] || row[column.key][2])">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a href="#" class="dashed small-text">
									{{ row[column.key][0] * (row[column.key][1] + row[column.key][2]) | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Склад: {{row[column.key][1] * row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Основной компонент: {{row[column.key][2] * row[column.key][0] | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="row.label=='warenwert_mp'">
                    <div class="small-text" v-if='row[column.key]'>
                        {{row[column.key].toFixed(2) | toRUB}}
                    </div>
                </div>
                <div class="yellow-cell" style="display: block;" v-else-if="row.label=='vp_plan'">
                    <v-tooltip bottom max-width=350 v-if=row[column.key]>
                        <template v-slot:activator="{ on, attrs }">
                            <div style="height: auto;" v-bind="attrs" v-on="on">
                                <a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][8] > 0}]">
                                    {{row[column.key][0] ? row[column.key][0].toFixed(2) : 0}}
                                </a>
                            </div>
                        </template>
                        <span>
                            <div class="small-text">
								(Цена - комиссия) * % выкупа: {{
                                    (row[column.key][4] - row[column.key][4] * row[column.key][1] / 100) * 
                                    row[column.key][3] / 100 | toRUB
								}}
							</div>
							<div class="small-text">
								Логистика + затраты на возвраты: {{
                                row[column.key][2] + 50 * (1 - row[column.key][3] / 100) | toRUB
								}}
							</div>
							<div class="small-text">
								Себестоимость: {{row[column.key][9] * row[column.key][3] / 100 | toRUB}}
							</div>
							<div class="small-text">
								Налог: {{
									row[column.key][4] * row[column.key][3] * row[column.key][6] / 10000 | toRUB
								}}
							</div>
                            <div class="small-text">
								Эквайринг: {{
									row[column.key][4] * row[column.key][3] * row[column.key][7] / 100 | toRUB
								}}
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div class="yellow-cell" v-else-if="row.label=='vp_orders_plan' || row.label=='vp_mp_orders_plan'">
                    <div 
                        :class="['small-text', {'bold_text': row[column.key][1] > 0}]"
                    >{{ row[column.key][0] ? row[column.key][0] : 0 | toRUB }}</div>
                </div>
                <div v-else-if="row.label=='speed_orders'">
                    <div class="small-text" v-if="row[column.key]" v-text="row[column.key].toFixed(2)"></div>
                </div>
				<div v-else-if="row.label=='speed_orders_7'">
                    <div class="small-text" v-if="row[column.key]" v-text="row[column.key].toFixed(2)"></div>
                </div>
                <div v-else-if="row.label=='stock_api'" style="flex-direction: column;">
                    <main style="white-space:nowrap">
                        <a
                                v-if="row[column.key] && (row[column.key][0] || row[column.key][0] == 0)"
                                @click="openStocksApiDialog(column.key)"
                                class="small-text"
                        >
                            fbo: {{ row[column.key][0] }}
                        </a>
                        <div v-else class="small-text">fbo: -</div>
                    </main>
                    <span style="white-space:nowrap; display: block; font-size:12px;"
                          v-if="row[column.key] && row[column.key][1]">
						fbs: {{row[column.key] && row[column.key][1] ? row[column.key][1] : '-'}} 
					</span>
                    <v-chip
                            class="mx-1 px-1 my-1 small-text"
                            color="red"
                            outlined
                            x-small
                            @click="openStocksFBSupdate()"
                            v-if="column._index == 1"
                    >
                        fbs
                    </v-chip>
                </div>
                <div v-else-if="row.label=='stock_wb'">
                    <div>
                        <a @click="openBalancesHourDialog(column.key)" v-if="row[column.key]" class="small-text">
                            {{ row[column.key][0] }}
                        </a>
                        <v-chip
                                v-if="row[column.key] && row[column.key][1]"
                                class="mx-1 px-1 small-text"
                                color="red"
                                outlined
                                x-small
                        >
                            fbs
                        </v-chip>
                    </div>

                </div>
                <div class='green-cell' style="display: block;" v-else-if="row.label=='comission_mpstats'">
                    <div>
						<a class="small-text d-flex align-center">{{ row[column.key][0] ? row[column.key][0] : 0 }}%</a>
						<v-tooltip color="#2c2c2c" bottom max-width=350 v-if="row[column.key] && row[column.key][2] && row[column.key][2].length > 0">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">(А)</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][2]" :key="index" style="font-weight: bold;">
									<p v-if="!item.is_commission_change">{{ item.title }}()</p>
									<p v-else>{{ item.title }}(Минимальная комиссия: {{ item.min_commission }}% | Новая комиссии: -{{ item.is_entered_commission }}%)</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
                </div>
				<div class='green-cell' v-else-if="row.label=='redemp_percent_mpstats'">
                    <a
                            @click="openDialogMpstats(column.key)"
                            v-if="row[column.key] || row[column.key] == 0"
                            class="small-text"
                    >
                        {{ row[column.key] }}%
                    </a>
                    <v-btn v-else elevation="1" x-small @click="openDialogMpstats(column.key)">
                        изменить
                    </v-btn>
                </div>
                <div class='green-cell' v-else-if="row.label=='warehousing_expenses'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a
                                    v-if="row[column.key] && (row[column.key][0] > 0 || row[column.key][1] > 0 || row[column.key][2] > 0 || row[column.key][3] > 0 || row[column.key][4] > 0 || row[column.key][5] > 0)"
                                >
                                    {{ (row[column.key][0] + row[column.key][1] + row[column.key][2] + row[column.key][3] + row[column.key][4] + row[column.key][5]) | toRUB}}
                                </a>
                                <a v-else>0</a>
							</div>
						</template>
						<span>
							<div class="small-text">Доставка до МП: {{row[column.key][0] | toRUB}}</div>
							<div class="small-text">Приемка: {{row[column.key][1] | toRUB}}</div>
							<div class="small-text">Хранение: {{row[column.key][2] | toRUB}}</div>
							<div class="small-text">Сборка: {{row[column.key][3] | toRUB}}</div>
							<div class="small-text">Фасовка: {{row[column.key][4] | toRUB}}</div>
							<div class="small-text">Производство МИ: {{row[column.key][5] | toRUB}}</div>
							<div class="small-text">Кол-во дней от оплаты: {{row[column.key][6]}}</div>
						</span>
					</v-tooltip>
                </div>
                <div class='green-cell' v-else-if="row.label=='logistic_mpstats'">
                    <a
                            @click="openDialogMpstats(column.key)"
                            v-if="row[column.key][0] || row[column.key][0] == 0"
                            class="small-text"
                    >
                        {{ row[column.key][0] | toRUB}}
                    </a>
                    <v-btn v-else elevation="1" x-small @click="openDialogMpstats(column.key)">
                        изменить
                    </v-btn>
                    <v-tooltip bottom max-width=350 v-if="row[column.key][0] || row[column.key][0] == 0">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" style="cursor: pointer">
								<v-icon x-small @click="openLogisticDeliveryDialog(column.key)" class="ml-1">mdi-help-circle-outline</v-icon>
							</span>
						</template>
						<span>
                            <p class="small-text">
                                С заказов: {{row[column.key][1] ? row[column.key][1] : 0 | toRUB}}
                            </p>
                            <p class="small-text">
                                С остатков: {{row[column.key][2] ? row[column.key][2] : 0 | toRUB}}
                            </p>
                        </span>
					</v-tooltip>      
                    <v-chip
                        class="mx-1 px-1" color="red" outlined x-small v-if="row[column.key][3] > 0"
                    >Р</v-chip>              
                </div>
                <div v-else-if="
					row.label=='uvz' || 
					row.label=='uvo' || 
					row.label=='redemption_sum' ||
					row.label=='distribution_sum' ||
					row.label=='distribution_cashback' ||
                    row.label=='bloggers_expenses'
				">
                    <div class="small-text" v-if="row[column.key]">{{row[column.key] | toRUB}}</div>
                </div>
				<div v-else-if="row.label=='adver_expenditure'">
                    <div  v-if="row[column.key] && (row[column.key][0] > 0)">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">{{ row[column.key][0] | toRUB }}</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index">
									<p>{{ item.product__advertisement__campaign_type }} - {{ item.advert_expenses_by_type | toRUB }}</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
				<div v-else-if="row.label=='adver_clicks'">
                    <div  v-if="row[column.key] && (row[column.key][0] > 0)">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">{{ row[column.key][0] }}</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index">
									<p>{{ item.product__advertisement__campaign_type }} - {{ item.advert_clicks_by_type }}</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
				<div v-else-if="row.label=='adver_orders'">
                    <div  v-if="row[column.key] && (row[column.key][0] > 0)">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">{{ row[column.key][0] }}</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index">
									<p>{{ item.product__advertisement__campaign_type }} - {{ item.advert_orders_by_type }}</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
                <div v-else-if="row.label=='new_redemption_percent'">
                    <div class="small-text">{{ row[column.key] ? (row[column.key] * 100).toFixed(1) : 0 }}%</div>
                </div>
				<div v-else-if="row.label=='localization_index'">
                    <div v-if="row[column.key]" :class="{'positive_ktr': row[column.key][3] > 0 && row[column.key][0] > 0 && row[column.key][3] < 1, 'negative_ktr': row[column.key][3] > 1 && row[column.key][0] > 0}">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">
										{{ row[column.key][0] ? (row[column.key][0]).toFixed(2) : 0 }} %
									</a>
								</span>
							</template>
							<span>
								<p>Индекс локализации аккаунта: {{ row[column.key][0] ? (row[column.key][0]).toFixed(2) : 0 }} %</p>	
								<p>Индекс локализации товара: {{ row[column.key][1] ? (row[column.key][1]).toFixed(2) : 0 }} %</p>
								<p>Время достаки: {{ getTextDelivery(row[column.key][2])}} </p>	
								<p>КТР аккаунта: {{ row[column.key][3]}}</p>		
								<p>КТР товара: {{ row[column.key][4]}}</p>	
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
				<div v-else-if="row.label=='delivery_time'">
                    <div  v-if="row[column.key]">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">{{ getTextDelivery(row[column.key][1]) }}</a>
								</span>
							</template>
							<span>
								<p>Индекс локализации(по кабинету): {{ row[column.key][0] ? row[column.key][0] : 0 }} %</p>
								<p>Индекс локализации: {{ row[column.key][2] ? (row[column.key][2]).toFixed(2) : 0 }} %</p>
								<p>Срок доставки: {{ getTextDelivery(row[column.key][1])}}</p>	
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
				<div v-else-if="row.label=='adver_price_click'">
                    <div  v-if="row[column.key] && (row[column.key][0] > 0)">
						<v-tooltip bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">{{ row[column.key][0] | toRUB }}</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index">
									<p>{{ item.product__advertisement__campaign_type }} - {{ item.advert_price_click_by_type | toRUB }}</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
					<div v-else>-</div>
                </div>
				<div v-else-if="
					row.label=='storage_cost'
				">
                    <a class="small-text dashed" @click="openLogisticDeliveryDialog(column.key)">
						{{row[column.key] ? row[column.key] : 0 | toRUB}}
					</a>
                </div>
                <div v-else-if="row.label=='stock_1c'" @click="openStocks1CDialog(column.key)">
                    <v-tooltip bottom max-width=350 v-if="row[column.key] && (row[column.key][0] || row[column.key][1] || row[column.key[3]])">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text">
                                    {{ row[column.key][0] || row[column.key][2] ? Math.floor(row[column.key][0] + row[column.key][2]) : '-'}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Остаток на складе: {{row[column.key] ? row[column.key][0]: 0}}
							</div>
							<div class="small-text">
								Остаток основного компонента складе: {{row[column.key] ? row[column.key][2]: 0}}
							</div>

							<div class="small-text">
								Возможные комплекты: {{row[column.key] ? row[column.key][1]: 0}}
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="
					row.label=='feedbacks_site' ||
					row.label=='spp' ||
					row.label=='redemption_count'||
					row.label=='distribution_count'
				">
                    <div class="small-text" v-text="row[column.key] ? Math.floor(row[column.key]) : '-'"></div>
                </div>
                <div v-else-if="row.label=='rating'">
                    <div class="small-text" v-text="row[column.key] ? row[column.key] : '-'"></div>
                </div>
                <div class='yellow-cell' v-else-if="row.label=='vp'">
                    <v-tooltip bottom max-width=350
                               v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
                                    {{row[column.key][2] ? row[column.key][0] : row[column.key][1] | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								C отчета: {{row[column.key] ? row[column.key][0]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Расчет.: {{row[column.key] ? row[column.key][1]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Сумма продаж: {{row[column.key] ? row[column.key][2]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Кол-во продаж: {{row[column.key] ? row[column.key][3]: 0}}
							</div>
							<div class="small-text">
								Сумма комиссий: {{row[column.key] ? row[column.key][4]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Сумма логистики: {{row[column.key] ? row[column.key][5]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Налог: 
								{{row[column.key] ? row[column.key][6]: 0 | toRUB}} 
								({{row[column.key] ? row[column.key][7]: 0}}%)
							</div>

						</span>
                    </v-tooltip>
                </div>
                <div class='yellow-cell' v-else-if="row.label=='vp_single'">
                    <v-tooltip bottom max-width=350
                               v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
                                    {{row[column.key][2] ? row[column.key][0] : row[column.key][1] | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								C отчета: {{row[column.key] ? row[column.key][0]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Расчет.: {{row[column.key] ? row[column.key][1]: 0 | toRUB}}
							</div>
							<div class="small-text"
                                 v-if='row[column.key] && row[column.key][2] && row[column.key][3] && row[column.key][0]'>
								Рентабельность: {{(row[column.key][0] / (row[column.key][2] / row[column.key][3]) * 100).toFixed(2)}}%
							</div>
							<div class="small-text"
                                 v-if='row[column.key] && row[column.key][2] && row[column.key][0] && row[column.key][4]'>
								Наценка: {{(row[column.key][0] / row[column.key][4] * 100).toFixed(2)}}%
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="row.label=='advert_expenses_from_report'" class='small-text'>
                    {{ row[column.key] ? row[column.key] : 0 | toRUB }}
                </div>
                <div v-else-if="row.label=='sales_sum'">
                    <v-tooltip bottom max-width=350
                               v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][0]}">
                                    {{ row[column.key][0] ? row[column.key][0] : row[column.key][1] | toRUB }}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								отчет: {{row[column.key] ? row[column.key][0] : 0 | toRUB}}
							</div>
							<div class="small-text">
								api: {{row[column.key] ? row[column.key][1]: 0| toRUB}}
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="row.label=='sales_count'">
                    <v-tooltip bottom max-width=350
                               v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][0]}">
                                    {{ row[column.key][0] ? row[column.key][0] : row[column.key][1] }}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								отчет: {{row[column.key] ? row[column.key][0] : 0}}
							</div>
							<div class="small-text">
								api: {{row[column.key] ? row[column.key][1]: 0}}
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="row.label=='sales_from_cost_price'">
					<span :class="[
						'small-text', 
						{'bold-text': row[column.key] && row[column.key][1]}
					]">
						{{ row[column.key] ? row[column.key][0] : 0 | toRUB }}
					</span>
                </div>
                <div v-else-if="row.label=='drr_full'" class="small-text">
					{{ row[column.key] ? row[column.key].toFixed(2) : 0 }}%
                </div>
				<div v-else-if="row.label=='drr'">
					<v-tooltip bottom max-width=350 v-if=row[column.key]>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a href="#" class="dashed small-text">
									{{ row[column.key][0] ? row[column.key][0].toFixed(2) : 0 }}%
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Расход: {{row[column.key][1] ? row[column.key][1] : 0 | toRUB }}
							</div>
							<div class="small-text">
								Клики: {{row[column.key][2] ? row[column.key][2] : 0 }}
							</div>
							<div class="small-text">
								Стоимость за клик: {{row[column.key][3] ? row[column.key][3] : 0 | toRUB}}
							</div>
							<div class="small-text">
								CTR: {{ row[column.key][2] > 0 && row[column.key][4] > 0 ? ( row[column.key][2] / row[column.key][4] * 100).toFixed(2) : 0 }}%
							</div>
						</span>
                    </v-tooltip>
                </div>
                <div v-else-if="row.label=='redemption_expenses'">
                    <v-tooltip
                            bottom max-width=350
                            v-if="row[column.key] && row[column.key][1]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <a
                                        href="#"
                                        class="dashed small-text"
                                        v-if="row[column.key][0]"
                                >
                                    {{row[column.key][0] | toRUB }}
                                </a>
                                <a
                                        href="#"
                                        class="dashed small-text"
                                        v-else
                                >
                                    {{row[column.key][1] * row[column.key][2] / 100 + row[column.key][3] | toRUB }}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Расход план: {{row[column.key][1] * row[column.key][2] / 100 + row[column.key][3] | toRUB }}
							</div>
							<div class="small-text">
								Расход факт: {{row[column.key][0] | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                    <div v-else class="small-text">-</div>
                </div>
                <div v-else-if="row.label=='feedback_db'">
                    <span class="small-text">{{ row[column.key] ? row[column.key][0] : 0 }}</span>
                    <v-chip
                            v-if="row[column.key] && row[column.key][1]"
                            class="mx-1 px-1"
                            color="success"
                            outlined
                            x-small
                    >
                        +{{ row[column.key][1] }}
                    </v-chip>
                </div>
                <div v-else-if="row.label=='avg_vp_30_days'">
                    <v-tooltip
                            bottom max-width=350
                            v-if="row[column.key] && row[column.key][5]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a href="#" class="dashed small-text">
                                    {{row[column.key][5] | toRUB }}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Сумма продаж: {{row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Количество продаж: {{row[column.key][1].toFixed(0)}}
							</div>
							<div class="small-text">
								Средняя себестоимость: {{row[column.key][2] | toRUB}}
							</div>
							<div class="small-text">
								Средняя цена: {{row[column.key][3] | toRUB}}
							</div>
							<div class="small-text">
								Сумма ВП: {{row[column.key][4] | toRUB}}
							</div>
							<div class="small-text">
								Среднее ВП: {{row[column.key][5] | toRUB}}
							</div>
							<div class="small-text">
								Средняя рентабельность: {{row[column.key][6] ? (row[column.key][6] * 100).toFixed(2) : 0}}%
							</div>
							<div class="small-text">
								Средняя наценка: {{row[column.key][7] ? (row[column.key][7] * 100).toFixed(2) : 0}}%
							</div>
						</span>
                    </v-tooltip>
                    <div v-else class="small-text">-</div>
                </div>
				<div v-else-if="row.label=='avg_vp_7_days'">
                    <v-tooltip
                            bottom max-width=350
                            v-if="row[column.key] && row[column.key][5]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a href="#" class="dashed small-text">
                                    {{row[column.key][5] | toRUB }}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text">
								Сумма продаж: {{row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Количество продаж: {{row[column.key][1].toFixed(0)}}
							</div>
							<div class="small-text">
								Средняя себестоимость: {{row[column.key][2] | toRUB}}
							</div>
							<div class="small-text">
								Средняя цена: {{row[column.key][3] | toRUB}}
							</div>
							<div class="small-text">
								Сумма ВП: {{row[column.key][4] | toRUB}}
							</div>
							<div class="small-text">
								Среднее ВП: {{row[column.key][5] | toRUB}}
							</div>
							<div class="small-text">
								Средняя рентабельность: {{row[column.key][6] ? (row[column.key][6] * 100).toFixed(2) : 0}}%
							</div>
							<div class="small-text">
								Средняя наценка: {{row[column.key][7] ? (row[column.key][7] * 100).toFixed(2) : 0}}%
							</div>
						</span>
                    </v-tooltip>
                    <div v-else class="small-text">-</div>
                </div>
				<div v-else-if="row.label=='vp_cp'">
                    <div v-if=row[column.key]>
                        <span v-if="row[column.key][2]" class="small-text bold-text">
                            {{row[column.key][0] - row[column.key][3] - row[column.key][4] - row[column.key][5] - row[column.key][6] | toRUB}}
                        </span>
                        <span v-else class="small-text">
                            {{row[column.key][1] - row[column.key][3] - row[column.key][4] - row[column.key][5] - row[column.key][6] | toRUB}}
                        </span>
                    </div>
                </div>
                <div v-else-if="row.label=='penalty'">
					<div class="small-text">{{row[column.key] ? row[column.key]: 0 | toRUB }}</div>
                </div>
				<div v-else-if="row.label=='additional_payment'">
					<div class="small-text">{{row[column.key] ? row[column.key]: 0 | toRUB }}</div>
                </div>
				<div v-else-if="row.label=='orders_lk_amount'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_seller_data_value(row[column.key], 'lk_orders_sum') | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse_name}}: {{item.lk_orders_sum ? item.lk_orders_sum: 0 | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
				<div v-else-if="row.label=='storage_cost_api'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_storage_cost_api(row[column.key]) | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse}}: {{item.n_storage_cost_api ? item.n_storage_cost_api: 0 | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
				<div v-else-if="row.label=='orders_lk_amount_without_commission'">
					<div class="small-text">{{ row[column.key] ? row[column.key] : 0 | toRUB}}</div>
				</div>
				<div v-else-if="row.label=='rentab_vp_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if=row[column.key]>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][3] > 0}]">
                                        {{ row[column.key][0] ? row[column.key][0].toFixed(2) : 0 }}%
                                    </a>
                                </div>
                            </template>
                            <span>
                                <div class="small-text">
                                    ВП_ЛС прогноз: {{ row[column.key][1] ? row[column.key][1].toFixed(2) : 0 }}%
                                </div>
                                <div class="small-text">
                                    ВП_М прогноз: {{ row[column.key][2] ? row[column.key][2].toFixed(2) : 0 }}%
                                </div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
                <div v-else-if="row.label=='vp_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if=row[column.key]>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][3] > 0}]">
                                        {{ row[column.key][0] ? row[column.key][0] : 0 | toRUB}}
                                    </a>
                                </div>
                            </template>
                            <span>
                                <div class="small-text">
                                    ВП_ЛС прогноз: {{ row[column.key][1] ? row[column.key][1] : 0 | toRUB}}
                                </div>
                                <div class="small-text">
                                    ВП_М прогноз: {{ row[column.key][2] ? row[column.key][2] : 0 | toRUB}}
                                </div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
				<div v-else-if="row.label=='warehousing_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if="row[column.key]">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    {{ row[column.key][0]| toRUB}}
                                </div>
                            </template>
                            <span>
                                <div class="small-text">Хранение МП: {{row[column.key][1] | toRUB}}</div>
                                <div class="small-text">Штрафы: {{row[column.key][2] | toRUB}}</div>
                                <div class="small-text">Транзит: {{row[column.key][3] | toRUB}}</div>
                                <div class="small-text">Другие расходы МП: {{row[column.key][4] | toRUB}}</div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
				<div v-else-if="row.label=='orders_lk_count'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_seller_data_value(row[column.key], 'lk_orders_count')}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse_name}}: {{item.lk_orders_count ? item.lk_orders_count: 0}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
				<div v-else-if="row.label=='sales_lk_amount'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_seller_data_value(row[column.key], 'lk_sales_sum') | toRUB}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse_name}}: {{item.lk_sales_sum ? item.lk_sales_sum: 0 | toRUB}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
				<div v-else-if="row.label=='sales_lk_count'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_seller_data_value(row[column.key], 'lk_sales_count')}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse_name}}: {{item.lk_sales_count ? item.lk_sales_count: 0}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
				<div v-else-if="row.label=='stocks_lk'">
                    <v-tooltip bottom max-width=350 v-if="row[column.key]">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <a class="small-text dashed">
                                    {{sum_seller_data_value(row[column.key], 'lk_stocks')}}
                                </a>
                            </div>
                        </template>
                        <span>
							<div class="small-text" v-for="(item, index) in row[column.key]" :key="index">
								{{item.warehouse_name}}: {{item.lk_stocks ? item.lk_stocks: 0}}
							</div>
						</span>
                    </v-tooltip>
                    <span class="small-text" v-else>-</span>
                </div>
                <div v-else-if="row.label == 'price_wb_test'">
                    <div class="d-flex" v-if="row[column.key]">
						<v-tooltip color="#2c2c2c" bottom max-width=350>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a class="small-text pl-0 pr-0" @click="openPriceDialog(column.key)">
										{{ row[column.key][0] ? row[column.key][0] : 0 | toRUB }}
									</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][2]" :key="index">
									<p v-if="!item.is_commission_change && item.is_entered_spp > 0">{{ item.title }}(Снижение СПП: {{ item.is_entered_spp }}%)</p>
									<p v-else-if="!item.is_commission_change">{{ item.title }}()</p>
									<p v-else>{{ item.title }}(Минимальная комиссия: {{ item.min_commission }}% | Новая комиссия: -{{ item.is_entered_commission }}%)</p>
								</div>	
							</span>
						</v-tooltip>
						<v-tooltip color="#2c2c2c" bottom max-width=350 v-if="row[column.key][1] && row[column.key][1].length > 0">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<a href="#" class="dashed small-text ml-1">(А)</a>
								</span>
							</template>
							<span>
								<div v-for="(item, index) in row[column.key][1]" :key="index" style="font-weight: bold;">
									<p v-if="!item.is_commission_change && item.is_entered_spp > 0">{{ item.title }}(Снижение СПП: {{ item.is_entered_spp }}%)</p>
									<p v-else-if="!item.is_commission_change">{{ item.title }}()</p>
									<p v-else>{{ item.title }}(Минимальная комиссия: {{ item.min_commission }}% | Новая комиссия: -{{ item.is_entered_commission }}%)</p>
								</div>		
							</span>
						</v-tooltip>
					</div>
                    <div v-else>-</div>
                </div>
				<div v-else-if="row.label=='adver_ctr'">
					<div v-if="row[column.key] && row[column.key][0] > 0 && row[column.key][1] > 0" class="small-text">
						{{ (row[column.key][0] / row[column.key][1] * 100).toFixed(2) }}%
					</div>
                    <div v-else class="small-text">0%</div>
                </div>
                <div v-else-if="row.label=='card_analytics'">
                    <div class="small-text" style="display: flex; flex-direction: column" :class="{'bold_card_analytics': row.naming=='Переходы в карточку, шт'}">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0]: 0 }}</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1] ? row[column.key][1] : 0 }})
                        </p>
                    </div>
                </div>
                <div v-else-if="row.label=='card_analytics_per'">
                    <div class="small-text" style="display: flex; flex-direction: column" :class="{'bold_card_analytics': row.naming=='CR переход - заказ'}">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0].toFixed(1): 0 }} %</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1]? row[column.key][1].toFixed(1): 0 }} %)
                        </p>
                    </div>
                </div>
                <div v-else-if="row.label=='card_analytics_rub'">
                    <div class="small-text" style="display: flex; flex-direction: column">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0]: 0 | toRUB }}</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1] ? row[column.key][1]: 0 | toRUB }})
                        </p>
                    </div>
                </div>
                <div v-else class="small-text">
                    {{ row[column.key] }}
                </div>
            </template>
        </BeeGridTable>
        <v-dialog v-model="dialog_charts" max-width="100%" v-if="dialog_charts">
            <v-card>
                <v-card-title>График</v-card-title>
                <v-card-text class="mt-5">
                    <ChartsDetailProductData :series="summaryDataPerDay"/>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog_charts=false">
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="priceDialog" max-width="600" v-if="priceDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    История за {{activePriceDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 400px;">
                    <PriceInTheMomentTable 
                        :product_id="product_id" :date="activePriceDay"
                    ></PriceInTheMomentTable>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="costPriceDetailsDialog" width="1000" v-if="costPriceDetailsDialog">
            <v-card>
                <v-card-title class="text-h5">
                    Себестоимость на {{ activeCostPriceDetailsDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 700px;">
                    <CostPriceDetails
                        :catalog_item_id="catalog_item_id" :date="activeCostPriceDetailsDay"
                    ></CostPriceDetails>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="wbParsingOrdersPerDayDialog" max-width="600"
            v-if="wbParsingOrdersPerDayDialog" scrollable
        >
            <v-card>
                <v-card-title class="text-h5">
                    Заказы на {{activeStocksDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 400px;">
                    <ProductWbParsingOrdersPerDay 
                        :product_id="product_id" :date="activeStocksDay"
                    ></ProductWbParsingOrdersPerDay>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="stocksDialog" max-width="600" v-if="stocksDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    Склады на {{activeStocksDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 400px;">
                    <ProductWarehouses 
                        :product_id="product_id" :date="activeStocksDay"
                    ></ProductWarehouses>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="balancesDialog" max-width="600" v-if="balancesDialog" scrollable
        >
            <v-card>
                <v-card-title class="text-h5">
                    История за {{activeBalanceDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 400px;">
                    <ProductStockBalancesHours
                        :product_id="product_id" :date="activeBalanceDay"
                    ></ProductStockBalancesHours>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="priceApiDialog" max-width="600" v-if="priceApiDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    История за {{activePriceApiDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div style="height: 400px;">
                    <PriceApiInTheMomentTable
                        :product_id="product_id" :date="activePriceApiDay">
                    </PriceApiInTheMomentTable>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_stocks_fbs" max-width="600" v-if="dialog_stocks_fbs" scrollable
        >
            <v-card>
                <v-card-title class="text-h5">
                    Обновление остатка fbs на сегодня
                </v-card-title>
                <div style="height: 400px;">
                    <ProductStockFBSUpdate
                        :product_id="product_id" :variations_all="variations_all"
                        :current_stock="summaryDataPerDay[0].stocks_fbs"
                        :main_variation="variations_id" @successUpdateStock="successUpdateStock"
                    ></ProductStockFBSUpdate>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="stocks1CDialog" width="700" v-if="stocks1CDialog">
            <v-card class="fill-height">
                <v-card-title class="text-h5">
                    Коэффициенты продаж по {{ catalog_item_id }}
                </v-card-title>
                <div>
                    <Stocks1CDialog
                        :code_1c="catalog_item_id" :date="activeStock1CDay"
                    ></Stocks1CDialog>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="mpstatsDialog" max-width="600" v-if="mpstatsDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    Изменить на {{activeChangeMpstatsDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div class="px-5 py-5">
                    <div class="d-flex" style="align-items:center;">
                        <v-checkbox
                                v-if="form_mpstats.flag_all || form_mpstats.flag_range_date"
                                v-model="form_mpstats.only_manual_logistic"
                                class="mr-3"
                                color="primary"
                                hide-details
                        ></v-checkbox>
                        <v-text-field
                                v-model="form_mpstats.manual_logistic"
                                label="Ручная логистика"
                                type="number"
                                outlined
                                clearable
                                tabindex="3"
                                hide-details
                                dense
                        ></v-text-field>
                        <div class="ml-2">
                            <a class="d-block">Расчетная = {{ form_mpstats.logistic ? form_mpstats.logistic : 0 }}</a>
                            <a class="d-block">Из отчета = {{ form_mpstats.recommendation.recommendation_logistic ? form_mpstats.recommendation.recommendation_logistic : 0 }}</a>
                        </div>
                    </div>
                    <div class="d-flex mt-5" style="align-items:center;">
                        <v-checkbox
                                v-if="form_mpstats.flag_all || form_mpstats.flag_range_date"
                                v-model="form_mpstats.only_redemption"
                                class="mr-3"
                                color="primary"
                                hide-details
                        ></v-checkbox>
                        <v-text-field
                                v-model="form_mpstats.redemption_percent"
                                label="%, выкупа"
                                type="number"
                                outlined
                                clearable
                                tabindex="3"
                                hide-details
                                dense
                        ></v-text-field>
                    </div>
                    <v-checkbox
                            v-model="form_mpstats.flag_all"
                            color="primary"
                            label="Применить для всех пустых значений"
                            hide-details
                    ></v-checkbox>
                    <div class="d-flex mt-5" style="align-items:center;">
                        <v-checkbox
                                v-model="form_mpstats.flag_range_date"
                                color="primary"
                                class="mt-0"
                                label="Установить период"
                                hide-details
                        ></v-checkbox>
                        <v-menu
                                v-if="form_mpstats.flag_range_date"
                                v-model="form_mpstats.date_menu"
                                :close-on-content-click="false"
                                prepend-icon="mdi-calendar"
                                transition="scale-transition"
                                offset-y="offset-y"
                                nudge-right="40"
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="dataRangeMPStatsDateText = null"
                                        label="Даты"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        hide-details
                                        v-on="on"
                                        v-model="dataRangeMPStatsDateText"
                                        class="ml-3"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="form_mpstats.dates"
                                    range
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div class="d-flex" style="justify-content: space-between;">
                        <v-btn class="mt-5" @click="saveMpstats">Сохранить</v-btn>
                    </div>

                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="changeApiPriceDialog" max-width="1200" v-if="changeApiPriceDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    Изменение цены API
                </v-card-title>
                <ChangePriceApi
                        :product_id="product_id"
                        :current_price_without_sale="summaryDataPerDay[0].price_api_avg"
                        :current_sale="summaryDataPerDay[0].discount_api_avg"
                        :comission="summaryDataPerDay[0].new_promo_commission"
                        :logistic="summaryDataPerDay[0].real_logistic"
                        :redemption_percent="summaryDataPerDay[0].redemption_percent2"
                        :cost_price="summaryDataPerDay[0].cost_price"
						:spp_percent="summaryDataPerDay[0].percent_spp_avg"
                        :tax="summaryDataPerDay[0].tax_percent"
                        :acquiring="summaryDataPerDay[0].acquiring"
                        @finishUpdatePrice="finishUpdatePrice"
                />
            </v-card>
        </v-dialog>
        <v-dialog v-model="commentDialog" max-width="600" v-if="commentDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">
                    Комментарий на {{activeChangeCommentDay | formatDateToLocaleNoTime}}
                </v-card-title>
                <div class="px-5 py-5">
                    <v-textarea
                            v-model="form_comment.text"
                            label="Комментарий"
                            outlined
                            clearable
                            auto-grow
                            rows="3"
                            col="2"
                            hide-details
                            dense
                    ></v-textarea>
                    <div class="d-flex justify-space-between mt-5">
                        <v-btn class="mt-5" @click="saveComment()">Сохранить</v-btn>
                        <v-btn class="mt-5" color="error" @click="saveComment(true)">Удалить</v-btn>
                    </div>

                </div>
            </v-card>
        </v-dialog>
		<v-dialog
            v-model="logistic_delivery_dialog" max-width="1800" v-if="logistic_delivery_dialog" scrollable
        >
            <v-card>
                <v-card-title class="text-h5">
                    Логистика и хранение на {{ logistic_delivery_day }}
                </v-card-title>
                <LogisticDeliveryCost :product_id="product_id" :date="logistic_delivery_day"/>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
	import {Products} from '@/api/products';
	import PriceInTheMomentTable from '@/components/Product/PriceInTheMomentTable';
	import PriceApiInTheMomentTable from '@/components/Product/PriceApiInTheMomentTable';
	import CostPriceDetails from '@/components/Product/CostPriceDetails';
	import ProductWbParsingOrdersPerDay from '@/components/Product/ProductWbParsingOrdersPerDay';
	import ProductWarehouses from '@/components/Product/ProductWarehouses';
	import ProductStockBalancesHours from '@/components/Product/ProductStockBalancesHours';
	import ProductStockFBSUpdate from '@/components/Product/ProductStockFBSUpdate';
	import ChartsDetailProductData from '@/components/Product/ChartsDetailProductData';
	import ChangePriceApi from '@/components/Product/ChangePriceApi';
	import Stocks1CDialog from '@/components/Product/Stocks1CDialog';
	import LogisticDeliveryCost from '@/components/Product/LogisticDeliveryCost'

	export default {
		name: 'ProductStockBalancesNew',
		components: {
			Stocks1CDialog,
			PriceInTheMomentTable,
			CostPriceDetails,
			ProductWbParsingOrdersPerDay,
			ProductWarehouses,
			ProductStockBalancesHours,
			PriceApiInTheMomentTable,
			ProductStockFBSUpdate,
			ChartsDetailProductData,
			ChangePriceApi,
			LogisticDeliveryCost
		},
        props: ['product_id', 'variations_id', 'catalog_item_id', 'artificial_sales_dates', 'variations_all', 'root_recommendation_percent'],
		watch: {
			async variations_id(val) {
				if (this.first_load)
					this.first_load = false;
				else
					await this.fetchProductStockBalances();
			},
		},
		data() {
			return {
				logistic_delivery_dialog: false,
				logistic_delivery_day: null,
				datepicker_menu_from: false,
				date_from: null,
				date_to: null,
				dates_range: [],
				changeApiPriceDialog: false,
				loading_table: false,
				summaryDataPerDay: [],
				list_date: [],
				priceDialog: false,
				stocks1CDialog: false,
				activePriceDay: null,
				activeStock1CDay: null,
				costPriceDetailsDialog: false,
				activeCostPriceDetailsDay: null,
				wbParsingOrdersPerDayDialog: false,
				activeStocksDay: null,
				dialog_stocks_fbs: false,
				stocksDialog: false,
				activeBalanceDay: null,
				balancesDialog: false,
				activePriceApiDay: null,
				priceApiDialog: false,
				activeChangeMpstatsDay: null,
				mpstatsDialog: false,
				form_mpstats: {
					logistic: 0,
                    manual_logistic: 0,
					redemption_percent: 0,
					flag_all: false,
					flag_range_date: false,
					dates: null,
					date_menu: null,
					only_manual_logistic: true,
					only_redemption: true,
                    recommendation: {}
				},
				activeChangeCommentDay: null,
				commentDialog: false,
				form_comment: {
					text: null,
				},
				dialog_charts: false,

			};
		},
		computed: {
			dataRangeText() {
				return this.dates_range.join(' ~ ');
			},
			dataRangeMPStatsDateText() {
				return this.form_mpstats.dates?.join(' ~ ');
			},
            table_data(){
                let data =  [
					{'label': 'empty', 'naming': ' '},
                    {'label': 'price_wb_test', 'naming': 'Цена WB', ...this.findField('price_wb_real', ['promotions', 'promotions_not_entered'])},
					{'label': 'spp', 'naming': 'СПП', ...this.findField('percent_spp_avg')},
					{'label': 'price_api', 'naming': 'Цена API', ...this.findField('price_api_with_sale_avg')},
					{'label': 'comission_mpstats', 'naming': 'Комиссия', ...this.findField('new_promo_commission', ['new_promo_commission', 'promotions'])},
					{'label': 'logistic_mpstats', 'naming': 'Логистика', ...this.findField('real_logistic', ['sum_logistic_stocks_by_wh', 'sum_logistic_orders_by_wh', 'manual_logistic'])},
                    {'label': 'redemp_percent_mpstats', 'naming': '% выкупа', ...this.findField('redemption_percent2')},
                    {'label': 'new_redemption_percent', 'naming': '% выкупа(из аналитики)', ...this.findField('new_redemption_percent')},
					{'label': 'cost_price', 'naming': 'Себестоимость', ...this.findField('cost_price')},
					{'label': 'vp_plan', 'naming': 'ВП_бс_н прогноз', ...this.findField('vp_plan', ['commission', 'real_logistic', 'redemption_percent2', 'actual_price', 'vp_plan_tax','tax_percent', 'acquiring', 'orders_count_test', 'cost_price'])},
					{'label': 'rentab', 'naming': 'Рентабельность план', ...this.findField('rentab', ['rentab', 'orders_count_test'])},
					{'label': 'markup', 'naming': 'Наценка план', ...this.findField('markup', ['markup', 'orders_count_test'])},
                    {'label': 'warehousing_expenses', 'naming': 'Расходы Склад/Логистика', ...this.findField('delivery_to_mp', ['acceptance', 'storage', 'assembling', 'packing', 'production_mi', 'cycles_year'])},
                    {'label': 'warehousing_mp', 'naming': 'Расходы МП', ...this.findField('expenses_mp_sum', ['test_storage_mp', 'test_penalty', 'test_transit', 'test_other_expenses_mp'])},
                    {'label': 'vp_mp', 'naming': 'ВП_МП, шт прогноз', ...this.findField('vp_mp', ['vp_ls', 'vp_m', 'orders_count_test'])},
					{'label': 'vp_mp_orders_plan', 'naming': 'ВП_МП итого прогноз', ...this.findField('vp_mp_orders_plan', ['orders_count_test'])},
                    {'label': 'rentab_vp_mp', 'naming': 'Рентабельность ВП_МП', ...this.findField('rentab_vp_mp', ['rentab_vp_ls', 'rentab_vp_m', 'orders_count_test'])},
					{'label': 'localization_index', 'naming': 'Индекс локализации', ...this.findField('localization_index_by_account', ['localization_index_by_product', 'delivery_time', 'ktr_by_account', 'ktr_by_product'])},
					{'label': 'empty', 'naming': 'Воронка продаж'},
                    {'label': 'card_analytics', 'naming': 'Переходы в карточку, шт', ...this.findField('open_card', ['open_card_delta'])},
                    {'label': 'card_analytics', 'naming': 'Положили в корзину, шт', ...this.findField('add_to_cart', ['add_to_cart_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'Конверсия в корзину', ...this.findField('cr_cart', ['cr_cart_delta'])},
                    {'label': 'card_analytics', 'naming': 'Заказали товаров, шт', ...this.findField('card_orders', ['card_orders_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'Конверсия в заказ', ...this.findField('cr_orders', ['cr_orders_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'CR переход - заказ', ...this.findField('cr_open', ['cr_open_delta'])},
                    {'label': 'card_analytics_rub', 'naming': 'Заказали на сумму, р', ...this.findField('orders_sum_rub', ['orders_sum_rub_delta'])},
                    {'label': 'empty', 'naming': 'Заказы'},
					{'label': 'orders_api_amount', 'naming': 'Заказы API руб', ...this.findField('orders_sum', ['fbs_orders_sum'])},
					{'label': 'orders_api_count', 'naming': 'Заказы API шт', ...this.findField('orders_count', ['fbs_orders_count'])},
					{'label': 'orders_wb_amount', 'naming': 'Заказы WB руб', ...this.findField('parsing_orders_sum')},
					{'label': 'orders_wb_count', 'naming': 'Заказы WB шт', ...this.findField('parsing_orders_count')},
					{'label': 'orders_lk_amount', 'naming': 'Заказы ЛК руб', ...this.findField('seller_sales_orders_reports')},
					{'label': 'orders_lk_amount_without_commission', 'naming': 'Заказы ЛК руб(с комиссией)', ...this.findField('orders_lk_without_commission')},
					{'label': 'orders_lk_count', 'naming': 'Заказы ЛК шт', ...this.findField('seller_sales_orders_reports')},
					{'label': 'speed_orders', 'naming': 'Скорость заказов, 14 дней', ...this.findField('orders_speed_14')},
					{'label': 'speed_orders_7', 'naming': 'Скорость заказов, 7 дней', ...this.findField('orders_speed_7')},
					{'label': 'vp_orders_plan', 'naming': 'ВП с заказов план', ...this.findField('vp_orders_plan', ['orders_count_test'])},
					{'label': 'drr', 'naming': 'ДРР вн', ...this.findField('drr_adverts', ['advertisement_expenditure', 'advertisement_clicks', 'advertisement_price_click', 'advertisement_views'])},
                    {'label': 'drr_full', 'naming': 'ДРР', ...this.findField('drr_full')},
					{'label': 'empty', 'naming': 'Продажи'},
					{'label': 'sales_sum', 'naming': 'Продажи руб', ...this.findField('report_sales_sum', ['sales_sum'])},
					{'label': 'sales_count', 'naming': 'Продажи шт', ...this.findField('report_sales_count', ['sales_count'])},
					{'label': 'sales_lk_amount', 'naming': 'Продажи ЛК руб', ...this.findField('seller_sales_orders_reports')},
					{'label': 'sales_lk_count', 'naming': 'Продажи ЛК шт', ...this.findField('seller_sales_orders_reports')},
					{'label': 'sales_from_cost_price', 'naming': 'Продажи по с/с руб', ...this.findField('sales_from_cost_price', ['report_sales_sum'])},
					{'label': 'vp', 'naming': 'ВП с продаж, руб', ...this.findField('our_vp', ['our_vp_new', 'report_sales_sum', 'report_sales_count', 'comission_sum', 'delivery_sum', 'tax_rub', 'tax_percent'])},
					{'label': 'vp_single', 'naming': 'ВП 1шт, руб', ...this.findField('our_vp_single',['our_vp_single_new', 'report_sales_sum', 'report_sales_count', 'cost_price']),},
					{'label': 'avg_vp_30_days', 'naming': 'Средняя ВП (месяц)', ...this.findField('sales_sum_at_30_days', ['sales_count_at_30_days','avg_cost_price_at_30_days','avg_price_at_30_days','vp_at_30_days','avg_vp_at_30_days','avg_rentab_at_30_days','avg_markup_at_30_days'])},
					{'label': 'avg_vp_7_days', 'naming': 'Средняя ВП (неделя)', ...this.findField('sales_sum_at_7_days', ['sales_count_at_7_days','avg_cost_price_at_7_days','avg_price_at_7_days','vp_at_7_days','avg_vp_at_7_days','avg_rentab_at_7_days','avg_markup_at_7_days'])},
					{'label': 'additional_payment', 'naming': 'Доплаты', ...this.findField('additional_payment')},
					{'label': 'vp_cp', 'naming': 'ВП_ЧП', ...this.findField('our_vp', ['our_vp_new', 'report_sales_sum', 'redemption_expenses', 'distribution_cashback', 'advertisement_expenditure', 'additional_payment']),},
					{'label': 'penalty', 'naming': 'Штрафы', ...this.findField('penalty')},
					{'label': 'empty', 'naming': 'Остатки'},
					{'label': 'stock_api', 'naming': 'Остатки API', ...this.findField('quantity_sum', ['stocks_fbs'])},
					{'label': 'stock_wb', 'naming': 'Остатки WB', ...this.findField('stock_balance', ['fbs'])},
					{'label': 'stock_1c', 'naming': 'Остатки 1С', ...this.findField('catalog_count',['kit_count', 'kit_main_count']),},
					{'label': 'stocks_lk', 'naming': 'Остатки ЛК', ...this.findField('seller_sales_orders_reports')},
					{'label': 'warenwert_mp', 'naming': 'Стоимость товара на МП', ...this.findField('warenwert_mp')},
					{'label': 'warenwert_1c', 'naming': 'Стоимость товара 1С', ...this.findField('cost_price',['catalog_count', 'kit_main_count'])},
					{'label': 'uvz', 'naming': 'УВз', ...this.findField('lost_proceed_due_purchase')},
					{'label': 'uvo', 'naming': 'УВо', ...this.findField('lost_proceed')},
					{'label': 'storage_cost', 'naming': 'Стоимость хранения за день', ...this.findField('sum_storage_cost_by_wh')},
					{'label': 'storage_cost_api', 'naming': 'Стоимость хранения API', ...this.findField('n_storage_cost_api')},
					{'label': 'empty', 'naming': 'Реклама'},
                    {'label': 'advert_expenses_from_report', 'naming': 'Расходы из отчета', ...this.findField('advert_expenses_from_report'),},
					{'label': 'adver_expenditure', 'naming': 'Расходы, руб', ...this.findField('advertisement_expenditure', ['advert_stats_by_type']),},
					{'label': 'adver_clicks', 'naming': 'Клики', ...this.findField('advertisement_clicks', ['advert_stats_by_type'])},
					{'label': 'adver_orders', 'naming': 'Заказы, шт', ...this.findField('advertisement_orders', ['advert_stats_by_type'])},
					{'label': 'adver_price_click', 'naming': 'Цена за клик, руб', ...this.findField('advertisement_price_click', ['advert_stats_by_type'])},
					{'label': 'adver_ctr', 'naming': 'CTR', ...this.findField('advertisement_clicks', ['advertisement_views'])},
					{'label': 'empty', 'naming': 'Выкупы'},
					{'label': 'redemption_sum', 'naming': 'Сумма выкупов', ...this.findField('redemption_sum')},
					{'label': 'redemption_count', 'naming': 'Кол-во выкупов', ...this.findField('redemption_count')},
					{'label': 'redemption_expenses', 'naming': 'Расходы выкупы', ...this.findField('redemption_expenses', ['redemption_sum', 'commission', 'real_logistic']),},
					{'label': 'distribution_sum', 'naming': 'Сумма раздач', ...this.findField('distribution_sum')},
					{'label': 'distribution_count', 'naming': 'Кол-во раздач', ...this.findField('distribution_count')},
					{'label': 'distribution_cashback', 'naming': 'Расходы раздач', ...this.findField('distribution_cashback')},
                    {'label': 'bloggers_expenses', 'naming': 'Расходы блогеры', ...this.findField('bloggers_expenses')},
					{'label': 'empty', 'naming': 'Отзывы'},
					{'label': 'feedback_db', 'naming': 'Отзывы БД', ...this.findField('feedbacks_db_count', ['feedbacks_new'])},
					{'label': 'feedbacks_site', 'naming': 'Отзывы сайт', ...this.findField('snapshot_feedbacks_count')},
					{'label': 'rating', 'naming': 'Рейтинг', ...this.findField('rating')},
				];
                return data
            },
            table_keys(){
                let key = [{title: ' ', key: 'naming', fixed: 'left', width: 170, slot: 'head'}];
                if(this.list_date && this.list_date.length > 0){
                    this.list_date.forEach(element => {
                        key.push({
                            title: element, key: element, width: 90, resizable: true, align: 'center',
                            slot: 'data', headSlot: 'column_name', comment: this.getCommentDate(element),
                        })
                    });
                }
				return key
            }
		},
		methods: {
			sum_seller_data_value(array, field){
				let result = 0
                if (!array || array.length <= 0){
                    return result
                }
				array.forEach(item => {
					try{
						result += item[field] ? item[field] : 0
					} catch(err){
						console.log(err)
					}
				})
				return result
			},
			sum_storage_cost_api(array){
				if (!array || array.length == 0)
					return 0
				return array.reduce((accum, item) => accum + item.n_storage_cost_api, 0);
			},
			async finishUpdatePrice() {
				this.changeApiPriceDialog = false;
				await this.fetchProductStockBalances();
			},
			getCommentDate(date) {
				let filter_data = this.summaryDataPerDay.filter(item => item.date == date);
				if (filter_data && filter_data.length > 0) {
					return filter_data[0]?.comment;
				}
				return '';
			},
			async successUpdateStock() {
				this.dialog_stocks_fbs = false;
				await this.fetchProductStockBalances();
			},
			openDialogComment(date) {
				this.activeChangeCommentDay = date;
				this.form_comment.text = this.getCommentDate(date);
				this.commentDialog = true;
			},
			async openDialogMpstats(date) {
				this.activeChangeMpstatsDay = date;
				let filter_data = this.summaryDataPerDay.filter(item => item.date == date);
				if (filter_data && filter_data.length > 0) {
					this.form_mpstats.logistic = filter_data[0]?.logistic;
                    this.form_mpstats.manual_logistic = filter_data[0]?.manual_logistic
					this.form_mpstats.redemption_percent = filter_data[0]?.redemption_percent2;
				} else {
					this.form_mpstats.logistic = 0;
					this.form_mpstats.redemption_percent = 0;
                    this.form_mpstats.manual_logistic = 0
				}
				this.mpstatsDialog = true;
                await this.fetchRecommendationCommission();
			},
			async saveComment(del = false) {
				let text = !del ? this.form_comment.text : '';
				const data = {
					'product': this.product_id,
					'date': this.activeChangeCommentDay,
					'text': text,
				};
				try {
					const response = await Products.saveComment(data);
					this.fetchProductStockBalances();

				} catch (err) {
					console.log(err);
				}
				this.commentDialog = false;
				this.activeChangeCommentDay = null;
			},
			async saveMpstats() {
				const data = {
					'product': this.product_id,
					'date': this.activeChangeMpstatsDay,
					'flag_all': this.form_mpstats.flag_all,
				};
				if (this.form_mpstats.flag_range_date || this.form_mpstats.flag_all) {
					if (this.form_mpstats.only_redemption)
						data['redemption_percent'] = this.form_mpstats.redemption_percent;
                    if (this.form_mpstats.only_manual_logistic)
                        data['manual_logistic'] = this.form_mpstats.manual_logistic
				} else {
					data['redemption_percent'] = this.form_mpstats.redemption_percent;
                    data['manual_logistic'] = this.form_mpstats.manual_logistic
				}
				if (this.form_mpstats.flag_range_date) {
					data['date_from'] = this.form_mpstats.dates[0];
					data['date_to'] = this.form_mpstats.dates[1];
				}
				try {
					await Products.saveMpstats(data);
					this.fetchProductStockBalances();

				} catch (err) {
					console.log(err);
				}
				this.mpstatsDialog = false;
				this.activeChangeMpstatsDay = null;
			},
			openBalancesHourDialog(date) {
				this.activeBalanceDay = date;
				this.balancesDialog = true;
			},
			openStocksApiDialog(date) {
				this.activeStocksDay = date;
				this.stocksDialog = true;
			},
			openStocksFBSupdate() {
				this.dialog_stocks_fbs = true;
			},
			openWbParsingOrdersPerDayDialog(date) {
				this.activeStocksDay = date;
				this.wbParsingOrdersPerDayDialog = true;
			},
			getTextDelivery(item){
				if (!item || item.length < 3)
					return `0д 0ч 0м`
				return `${item[0]}д ${item[1]}ч ${item[2]}м`
			},
			openCostPriceDetailsDialog(date) {
				this.activeCostPriceDetailsDay = date;
				this.costPriceDetailsDialog = true;
			},
			openPriceDialog(date) {
				this.activePriceDay = date;
				this.priceDialog = true;
			},
			openStocks1CDialog(date) {
				this.activeStock1CDay = date;
				this.stocks1CDialog = true;
			},
			openLogisticDeliveryDialog(date){
				this.logistic_delivery_day = date
				this.logistic_delivery_dialog = true
			},
			openPriceApiDialog(date) {
				this.activePriceApiDay = date;
				this.priceApiDialog = true;
			},
			emptyCell(label) {
				if (label == 'empty')
					return true;
				return false;
			},
			findField(field, kwargs = []) {
				let result = {};
                if (!this.list_date || this.list_date.length <= 0){
                    return {}
                }
				this.list_date.forEach(el => {
					let filter_data = this.summaryDataPerDay.filter(item => item.date == el);
					try {
						if (kwargs.length == 0)
							result[el] = filter_data[0][field];
						else {
							result[el] = [filter_data[0][field]];
							kwargs.forEach(el_kw => {
								result[el].push(filter_data[0][el_kw]);
							});
						}
					} catch {
						if (kwargs.length == 0)
							result[el] = null
						else {
							result[el] = [null];
							kwargs.forEach(el_kw => {
								result[el].push(null);
							});
						}
					}
				});
				return result;
			},
			async fetchProductStockBalances() {
				this.loading_table = true;
				try {
					const query = {
						'variations_id': this.variations_id != 0 ? this.variations_id : '',
                        'dates_range': this.dates_range ? this.dates_range.join(',') : null
                    }
					const res = await Products.fetchProductBalances(this.product_id, query,
					);
					this.summaryDataPerDay = res.data.data;
					this.list_date = res.data.list_date;
				} catch (err) {
					console.log(err);
				}
				this.loading_table = false;
			},
			calcHelpText(label){
				if (label == 'vp_ls')
					return 'ВП план - Расходы склада/логистика'
                if (label == 'vp_m')
                    return 'Плановые рекламные расходы'
                if (label == 'vp_mp')
                    return 'Плановые расходы МП'
				return ''
			},
            async fetchRecommendationCommission(date = null) {
				this.loading_recom = true;
				try {
					let query = {};
					if (date)
						query['date'] = date;
					const response = await Products.fetchRecommendationCommission(this.product_id, query);
					this.form_mpstats.recommendation = response.data;
				} catch (err) {
					console.log(err);
				}
				this.loading_recom = false;
			},
		},
		async mounted() {
			await this.fetchProductStockBalances();
		},
	};
</script>

<style lang="scss">
    .product_table .bee-table td {
        height: 30px !important;
    }

    .product_stock_balances .bee-table-cell {
        padding: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .black-cell {
        background-color: #c6c6c6 !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
        color: black;
    }

    .bold-text {
        font-weight: bold;
    }

    .green-cell {
        background-color: #d9ead3 !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
    }

    .yellow-cell {
        background-color: #fff2cc !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
    }

    .small-text {
        font-size: 12px;
    }
	.positive_ktr{
		background-color: #def1dd;
		width: 100%;
		height: 100%;
		padding: 0 5px;
	}
	.negative_ktr{
		background-color: #fee0e0;
		width: 100%;
		height: 100%;
		padding: 0 5px;
	}
    .green_delta{
        color: #067b00;
    }
    .red_delta{
        color: #f96666;
    }
    .bold_card_analytics{
        font-weight: bold;
    }
</style>
<template>
	<div>
		<v-progress-linear v-if="loading" indeterminate color="deep-purple-accent-4"></v-progress-linear>
		<v-data-table
			:headers="headers" :items="data" class="elevation-0 mt-1 vertical-border small-font"
			:items-per-page="30" :footer-props="{'items-per-page-options': [7, 14, 30, 60]}"
		>
		</v-data-table>
	</div>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
	name: "AdvertisementDetailHistoricalComponent",
	props: ['campaign_id'],
	data(){
		return {
			data: [],
			headers: [
				{ text: "Дата", value: "date", sortable: true, width: "1%" },
				{ text: "Пользователь",  value: "user", sortable: false, width: "1%" },
				{ text: 'Тип операции', value: 'type_action_display', sortable: false, width: '1%' },
				{ text: 'Старое значение', value: 'old_value', sortable: false, width: '1%' },
				{ text: "Новое значение", value: "new_value", sortable: false, width: "1%" },
				{ text: "Описание", value: "description", sortable: false, width: "3%" }
			],
			loading: false
		}
	},
	methods: {
		async get_advertisement_detail_historical_data(){
			this.loading = true
			try{
				const response = await Advertisement.fetchAdvertisementDetailHistoricalData(this.campaign_id)
				this.data = response.data
			} catch (err){
				console.log(err)
			}
			this.loading = false
		}
	},
	async mounted(){
		await this.get_advertisement_detail_historical_data()
	}
}
</script>

<style lang="scss" scoped>
	.green_stats{
		height: 100%;
		width: calc(100% + 32px);
		margin-left: -16px;
		background-color: #def3d9;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.red_stats{
		height: 100%;
		background-color: #f5d4d7;
		width: calc(100% + 32px);
		margin-left: -16px;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
</style>
import { render, staticRenderFns } from "./AdvertisementListTableComponent.vue?vue&type=template&id=7a96ce1c&scoped=true&"
import script from "./AdvertisementListTableComponent.vue?vue&type=script&lang=js&"
export * from "./AdvertisementListTableComponent.vue?vue&type=script&lang=js&"
import style0 from "./AdvertisementListTableComponent.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AdvertisementListTableComponent.vue?vue&type=style&index=1&id=7a96ce1c&lang=scss&scoped=true&"
import style2 from "./AdvertisementListTableComponent.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a96ce1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VChip,VDataTable,VDialog,VDivider,VIcon,VImg,VSwitch})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.marketplace == 'Ozon')?_c('router-link',{attrs:{"to":{ name: 'ozon_supply_planning_create'}}},[_c('v-btn',{staticClass:"mt-5",attrs:{"block":"","depressed":"","color":"purple darken-2","dark":""}},[_vm._v("Создать новый план")])],1):_c('router-link',{attrs:{"to":{ name: 'supply_planning_create'}}},[_c('v-btn',{staticClass:"mt-5",attrs:{"block":"","depressed":"","color":"purple darken-2","dark":""}},[_vm._v("Создать новый план")])],1),_c('v-data-table',{staticClass:"elevation-0 mt-5 vertical-border",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"items-per-page":50,"footer-props":{'items-per-page-options': [5, 15, 25, 50, 100]},"loading":_vm.table.loading,"options":_vm.table.options,"server-items-length":_vm.table.pagination.totalItems},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.status == 'SUCCESSFULL' && _vm.marketplace == 'Ozon')?_c('router-link',{attrs:{"to":{ name: 'ozon_supply_planning_detail', params: {id: item.id}}}},[_vm._v("Посмотреть расчет")]):(item.status == 'SUCCESSFULL')?_c('router-link',{attrs:{"to":{ name: 'supply_planning_detail', params: {id: item.id}}}},[_vm._v("Посмотреть расчет")]):_c('div')]}},{key:"item.limit_turnover",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.marketplace != 'Ozon' ? item.limit_turnover : 60)+" ")]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["+_vm._s(item.date_from)+" ; "+_vm._s(item.date_to)+"] ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.status_display)+" "),(item.status == 'ERROR')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error_text))])]):_vm._e()],1),(item.status == 'IN_PROGRESS')?_c('div',[_c('v-progress-linear',{attrs:{"height":"20"},model:{value:(item.progress),callback:function ($$v) {_vm.$set(item, "progress", $$v)},expression:"item.progress"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(item.progress))+"%")])])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.delete_supply_planning(item.id)}}},[_vm._v("mdi-delete")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<v-dialog 
			v-model="dialog_change_product_bid" 
			max-width="500px"
			v-if="dialog_change_product_bid"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Изменение общей ставки {{edit_product_bid.sku}}</span>
				</v-card-title>
				<v-card-text>
					<div style="display: flex">
						<v-text-field
							v-model="new_product_bid"
							label="Ставка"
							outlined
							type="number"
							dense
							clearable
						></v-text-field>
						<v-btn
							@click="changeProductBid"
							depressed
							color="purple darken-2"
							dark
							class="ml-5"
							:loading="loading_change_product_bid"
						>Изменить</v-btn>
					</div>
					<div>
						<div class="title">Рекомендация: 
							<span :class="{
								'green_stats': recommendation_product_bid <= new_product_bid,
								'red_stats': recommendation_product_bid > new_product_bid
							}">
								{{recommendation_product_bid ? recommendation_product_bid : "..."}}
							</span>
							<div v-if="recommendation_product_bid && new_product_bid">
								<p 
									v-if="recommendation_product_bid <= new_product_bid"
									style="font-size: 12px"
								>
									Ваша ставка соответствует текущему уровню конкуренции
								</p>
								<p 
									v-else-if="recommendation_product_bid > new_product_bid"
									style="font-size: 12px"
								>
									Ваша ставка ниже текущего уровня конкуренции
								</p>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-dialog 
			v-model="dialog_change_keyword_bid" 
			max-width="1500px"
			v-if="dialog_change_keyword_bid"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Ставки по ключевым словам</span>
				</v-card-title>
				<v-card-text>
					<AdvertisementKeywordDialog 
						:advertisement_id="edit_keyword_bid.adver"
						:product_id="edit_keyword_bid.id"
						
					/>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-card class="mx-auto" flat>
			<v-card-text class="pb-0">
				<div class="d-flex">
					<div class="title">
						{{ data ? data.campaign_name : "-" }}
					</div>
				</div>
			</v-card-text>
			<v-sheet class="d-flex">
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Аккаунт</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data && data.account_name ? data.account_name : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Вид рекламы</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data && data.type_display ? data.type_display : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Статус</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data && data.status_display ? data.status_display : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Создана</v-list-item-title>
							<v-list-item-subtitle>{{
								data.date_created ? showDate(data.date_created) : '-'
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Завершена</v-list-item-title>
							<v-list-item-subtitle>{{
								data.date_finish ? showDate(data.date_finish) : '-'
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content  style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">Последнее обновление</v-list-item-title>
							<v-list-item-subtitle style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.date_our_update ? showDateWithTime(data.date_our_update): '-'
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Товаров со ставкой</v-list-item-title>
							<v-list-item-subtitle>{{
								data && data.count_product_with_bid ? data.count_product_with_bid : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Заказы, шт</v-list-item-title>
							<v-list-item-subtitle>{{
								data && data.orders_count ? data.orders_count : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Выручка</v-list-item-title>
							<v-list-item-subtitle>{{
								data && data.orders_sum ? data.orders_sum : 0 | toRUB
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Расход</v-list-item-title>
							<v-list-item-subtitle>{{
								data && data.expenses ? data.expenses : 0 | toRUB
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>ДРР</v-list-item-title>
							<v-list-item-subtitle>{{
								data && data.orders_sum && data.expenses ? 
								data.expenses / data.orders_sum * 100 : 0 | toRUB
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-sheet>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Товары из рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="product_table.headers"
					:items="product_table.data"
					:loading="product_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto"
					disable-pagination
				>
					<template v-slot:item.img="{ item }">
						<v-img
							:src="item.img"
							class="my-2 mx-0"
							contain
							max-height="60px"
							max-width="60px"
						/>
					</template>
					<template v-slot:item.bid="{ item }">
						<div v-if="item.bid">
							{{ convertKopecksToRub(item.bid) | toRUB }}
						</div>
						<div v-else>-</div>
					</template>
					<template v-slot:item.sku="{ item }">
						<router-link 
							target="_blank" 
							class="link" 
							:to="{ name: 'ozon_product_card', params: { id: item.product }}"
						>ozon id: {{ item.product }}</router-link>
						<a 
							:href="`https://www.ozon.ru/context/detail/id/${item.sku}/`"
							target="_blank"
							style="display: block;"
						><span class="grey--text">sku: </span>{{ item.sku }}</a>
					</template>
					<template v-slot:item.price="{ item }">
						<div v-if="item.price">
							{{ item.price | toRUB }}
						</div>
						<div v-else>-</div>
					</template>
					<template v-slot:item.visibility_index="{ item }">
						<div style="font-size: 12px;">{{ item.visibility_index }}</div>
						<div 
							v-if="item.prev_visibility_index && item.visibility_index && item.visibility_index - item.prev_visibility_index != 0"
							style="font-size: 12px;"
							:class="{
								'red_stats': item.visibility_index - item.prev_visibility_index > 0,
								'green_stats': item.visibility_index - item.prev_visibility_index < 0
							}"
						>
							{{ item.visibility_index - item.prev_visibility_index }}
						</div>
					</template>
					<template v-slot:item.views="{ item }">
						<div style="font-size: 12px;">{{ item.views }}</div>
						<div 
							v-if="calculateDelta(item.prev_views, item.views)"
							style="font-size: 12px;"
							:class="{
								'red_stats': calculateDelta(item.prev_views, item.views) < 0,
								'green_stats': calculateDelta(item.prev_views, item.views) > 0
							}"
						>
							{{ calculateDelta(item.prev_views, item.views) }}%
						</div>
					</template>
					<template v-slot:item.bid_percent="{ item }">
						<a v-if="item.bid_percent" @click="openDialogChangeProductBid(item)">
							{{item.bid_percent}}% ({{item.bid_price | toRUB}})
						</a>
						<a v-else @click="openDialogChangeProductBid(item)">
							Установить ставку
						</a>
					</template>
					<template v-slot:item.count_special_bid="{ item }">
						<a @click="openDialogChangeKeywordBid(item)">
							{{item.count_special_bid ? item.count_special_bid : "Установить"}}
						</a>
					</template>
					<template v-slot:item.rentab_plan="{ item }">
						<div :class="{'green_purpose': item.rentab_plan >= 15, 'red_purpose': item.rentab_plan < 15}">
							{{ item.rentab_plan ? item.rentab_plan : 0 }}% ({{ item.vp_plan ? item.vp_plan : 0 | toRUB }})
						</div>
					</template>
					<template v-slot:item.rentab_report="{ item }">
						<div :class="{'green_purpose': item.rentab_sales >= 15, 'red_purpose': item.rentab_sales < 15}">
							{{ item.rentab_sales ? item.rentab_sales : 0 }}% ({{ item.vp_sales ? item.vp_sales : 0 | toRUB }})
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Статистика по рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="adver_stats_table.headers"
					:items="adver_stats_table.data"
					:loading="adver_stats_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto"
					disable-pagination
				>
					<template v-slot:item.bid="{ item }">
						{{ convertKopecksToRub(item.bid) | toRUB }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Статистика по товарам рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="adver_product_stats_table.headers"
					:items="adver_product_stats_table.data"
					:loading="adver_product_stats_table.loading"
					fixed-header
					style="width: 100%;"
					class="elevation-1 vertical-border small-font"
				>
					<template v-slot:item.expenses="{ item }">
						<div v-if="item.expenses">
							{{item.expenses | toRUB}}
						</div>
						<div v-else></div>
					</template>
					<template v-slot:item.sale_price="{ item }">
						<div v-if="item.sale_price">
							{{item.sale_price | toRUB}}
						</div>
						<div v-else></div>
					</template>
					<template v-slot:item.bid="{ item }">
						<div v-if="item.bid">
							{{item.bid | toRUB}}
						</div>
						<div v-else></div>
					</template>
					<template v-slot:item.calc_drr="{ item }">
						<div v-if="item.calc_drr">
							{{(item.calc_drr * 100).toFixed(2)}}%
						</div>
						<div v-else></div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'
import AdvertisementKeywordDialog from '@/components/OzonAdvertisement/AdvertisementKeywordDialog.vue'

export default {
	name: "OzonAdvertisementDetail",
	components: {AdvertisementKeywordDialog},
	data() {
		return {
			recommendation_product_bid: null,
			edit_keyword_bid: null,
			dialog_change_keyword_bid: false,
			edit_product_bid: null,
			loading_change_product_bid: false,
			new_product_bid: null,
			dialog_change_product_bid: false,
			adver_data: null,
			product_table: {
				loading: false,
				headers: [
					{ text: 'sku', value: 'sku', sortable: false, width: '4%' },
					{ text: 'img', value: 'img', sortable: false, width: '1%', align: 'center' },
					{ text: 'Название', value: 'title', sortable: false, width: '10%' },
					{ text: 'Индекс видимости', value: 'visibility_index', sortable: false, width: '1%' },
					{ text: 'Показы', value: 'views', sortable: false, width: '4%' },
					{ text: 'Текущая цена', value: 'price', sortable: false, width: '4%' },
					{ text: 'Общая ставка', value: 'bid_percent', sortable: false, width: '4%' },
					{ text: 'Специальных ставок', value: 'count_special_bid', sortable: false, width: '4%' },
					{ text: "Маржинальность план", value: "rentab_plan", sortable: false, width: "1%" },
					{ text: "Маржинальность факт", value: "rentab_report", sortable: false, width: "1%" },
				],
				data: []
			},
			adver_stats_table: {
				loading: false,
				headers: [
					{ text: 'id', value: 'id', sortable: false, width: '1%' },
					{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
					{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'CTR(%)', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expense', sortable: false, width: '1%' },
					{ text: 'Заказы шт', value: 'orders_count', sortable: false, width: '1%' },
					{ text: 'Заказы руб', value: 'orders_sum', sortable: false, width: '1%' },
					{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
				],
				data: []
			},
			adver_product_stats_table: {
				loading: false,
				headers: [
					{ text: 'sku', value: 'sku', sortable: false, width: '1%' },
					{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
					{ text: 'Кол-во', value: 'search_quantity', sortable: false, width: '1%' },
					{ text: 'CPO', value: 'cpo', sortable: false, width: '1%' },
					{ text: 'Цена продажи', value: 'search_avg_sale_price', sortable: false, width: '1%' },
					{ text: 'Стоимость', value: 'search_avg_cost', sortable: false, width: '1%'},
					{ text: 'Ставка %', value: 'search_avg_bid_percent', sortable: false, width: '1%' },
					{ text: 'Ставка руб', value: 'search_avg_bid', sortable: false, width: '1%' },
					{ text: 'Себестоимость', value: 'calc_cost_price', sortable: false, width: '1%'},
					{ text: 'ВП', value: 'calc_vp', sortable: false, width: '1%'},
					{ text: 'Выручка', value: 'calc_revenue', sortable: false, width: '1%'},
					{ text: 'ДРР', value: 'calc_drr', sortable: false, width: '1%'},
					{ text: 'Комиссия', value: 'calc_commission', sortable: false, width: '1%'},
					{ text: 'Логистика', value: 'calc_logistic', sortable: false, width: '1%'},
					{ text: 'Налог', value: 'calc_seller_tax_rub', sortable: false, width: '1%'},
					{ text: 'Доходность', value: 'calc_profit', sortable: false, width: '1%'},
				],
				data: []
			}
			
		}
	},
	computed: {
		advertisement_id() {
			return this.$route.params.campaign_id;
		},
	},
	props: ["data"],
	methods: {
		async getAdverProducts() {
			this.product_table.loading = true
			try {
				let response = await OzonAdvertisement.getDetailOzonCampaign(
					this.advertisement_id
				);
				this.product_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.product_table.loading = false
		},
		async getAdverStats() {
			this.adver_stats_table.loading = true
			try {
				let response = await OzonAdvertisement.getOzonAdvertisementStats(
					this.advertisement_id
				);
				this.adver_stats_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.adver_stats_table.loading = false
		},
		async getAdverStatsByProduct() {
			this.adver_product_stats_table.loading = true
			try {
				let response = await OzonAdvertisement.getOzonAdvertisementProductStats(
					{ "id": this.advertisement_id }
				);
				this.adver_product_stats_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.adver_product_stats_table.loading = false
		},
		convertKopecksToRub(money){
			return money ? money / 1000000: 0
		},
		showDate(date){
            return date  ? this.$options.filters.formatDateToLocale(date ) : "-"
        },
		showDateWithTime(date) {
			return date ? this.$options.filters.formatDateToLocale(date) : "-";
		},
		calculateDelta(v1, v2){
			if (!v1 || !v2 || v1==0 || v2==0)
				return null
			return ((v2 - v1) / v1 * 10000 / 100).toFixed(2)
		},
		async openDialogChangeProductBid(item){
			this.recommendation_product_bid = null
			this.dialog_change_product_bid = true
			this.edit_product_bid = item
			this.new_product_bid = item.bid_percent
			await this.getRecommendationProductBid()

		},
		async getRecommendationProductBid() {
			try {
				let response = await OzonAdvertisement.getRecommendationProductBid(
					{ "product_id": this.edit_product_bid.id }
				);
				this.recommendation_product_bid = response.data.recommendation;
			} catch (err) {
				console.error(err.response);
			}
		},
		openDialogChangeKeywordBid(item){
			this.dialog_change_keyword_bid = true
			this.edit_keyword_bid = item
		},
		async changeProductBid(){
			this.loading_change_product_bid = true
			try {
				let response = await OzonAdvertisement.changeGeneralBid(
					this.edit_product_bid.id, this.new_product_bid
				);
				this.$toasted.show(`Ставка обновлена`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
				this.dialog_change_product_bid = false
				this.edit_product_bid = null
				await this.getAdverProducts()
			} catch (err) {
				console.error(err.response);
			}
			this.loading_change_product_bid = false
		},
	},
	async mounted() {
		document.title = `Реклама Ozon | ${this.advertisement_id} | mp.lomex-group.ru`;
		this.$store.commit("setPageTitle", `Ozon Рекламная кампания ${this.advertisement_id}`);
		await this.getAdverProducts()
		await this.getAdverStats()
		await this.getAdverStatsByProduct()

	}
}
</script>

<style lang="scss" scoped>
	.red_stats{
		color: #ea6a69;
	}
	.green_stats{
		color: #89cc41;
	}
	.green_purpose{
		background-color: #afedaf;
	}
	.red_purpose{
		background-color: #f5d4d7;
	}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-3 elevation-2 flat",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Рекламные кампании")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 mt-0 vertical-border small-font",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"single-expand":"","expanded":_vm.table.expanded,"show-expand":"","hide-default-footer":"","disable-pagination":"","item-key":"campaign_id"},on:{"update:expanded":function($event){return _vm.$set(_vm.table, "expanded", $event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item)?_c('td',{staticStyle:{"padding":"0 0 0 0"},attrs:{"colspan":_vm.table.headers.length}},[_c('AdvertisementOnProductCardStatsComponent',{key:item.campaign_id,attrs:{"campaign_id":item.campaign_id,"nmid":_vm.product_id}})],1):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.date_create)+" "),_c('br'),_vm._v(" "+_vm._s(item.date_change)+" ")])]}},{key:"item.campaign_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":{ 
						name: 'advertisement_detail', params: { campaign_id: item.campaign_id }
					}}},[_vm._v(_vm._s(item.campaign_name))])]}},{key:"item.cpc",fn:function(ref){
					var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.clicks > 0 && item.expenses > 0 ? (item.expenses / item.clicks).toFixed(2): 0)+" ")])]}},{key:"item.ctr",fn:function(ref){
					var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.clicks > 0 && item.views > 0 ? (item.clicks / item.views * 100).toFixed(2): 0)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div>
		<v-dialog 
			v-model="dialog" 
			max-width="500px"
			v-if="dialog"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Изменение ставки {{edit_item.query_id}}</span>
				</v-card-title>
				<v-card-text>
					<div style="display: flex">
						<v-text-field
							v-model="new_keyword_bid"
							label="Ставка"
							outlined
							type="number"
							dense
							clearable
						></v-text-field>
						<v-btn
							@click="changeKeywordBid"
							depressed
							color="purple darken-2"
							dark
							class="ml-5"
							:loading="btn_loading_keyword"
						>Изменить</v-btn>
					</div>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-data-table
			:headers="table.headers"
			:items="table.data"
			:loading="table.loading"
			hide-default-footer
			fixed-header
			style="width: 100%;  max-height: 500px; overflow: auto;"
			class="elevation-1 vertical-border small-font overflow-y-auto mt-5"
			disable-pagination
		>
			<template v-slot:item.position="{ item }">
				{{item.position ? item.position.toFixed(2) : "-"}}
			</template>
			<template v-slot:item.edit="{ item }">
				<v-icon
					small
					class="mr-2"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'

export default {
	name: "AdvertisementKeywordDialog",
	props: ["advertisement_id", "product_id"],
	data() {
		return {
			btn_loading_keyword: false,
			new_keyword_bid: null,
			dialog: false,
			edit_item: null,
			table: {
				loading: false,
				headers: [
					{ text: 'id запроса', value: 'query_id', sortable: false, width: '1%' },
					{ text: 'Запрос', value: 'query', sortable: false, width: '1%' },
					{ text: 'Ставка', value: 'bid', sortable: false, width: '1%' },
					{ text: 'Средняя позиция', value: 'position', sortable: false, width: '1%' },
					{ text: 'Просмотров', value: 'views', sortable: false, width: '1%' },
					{ text: 'Изменить', value: 'edit', sortable: false, width: '1%' },
				],
				data: []
			},
		}
	},
	methods: {
		editItem(item){
			this.dialog = true
			this.edit_item = item
			this.new_keyword_bid = item.bid
		},
		async getAdverKeywords() {
			this.table.loading = true
			try {
				let response = await OzonAdvertisement.getKeywordsData(
					this.advertisement_id, this.product_id
				);
				this.table.data = response.data.data;
				if(response.data){
					this.general_bid = response.data.general_bid
				}
			} catch (err) {
				console.error(err.response);
			}
			this.table.loading = false
		},
		async changeKeywordBid(){
			this.btn_loading_keyword = true
			try {
				let response = await OzonAdvertisement.changeKeywordBud(
					this.product_id, this.new_keyword_bid, this.edit_item.id 
				);
				this.$toasted.show(`Ставка обновлена`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
				this.dialog = false
				await this.getAdverKeywords()
			} catch (err) {
				console.error(err.response);
			}
			this.btn_loading_keyword = false
		}
	},
	async mounted() {
		await this.getAdverKeywords()
	}
}
</script>

<style lang="scss" scoped>
.general_bid{
	font-size: 24px;
}
</style>
<template>
    <div>
        <v-sheet>
            <v-container fluid>
                <v-subheader>По дате продажи</v-subheader>
                <v-row class="">
                    <v-col>
                        <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_from">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateFromFormatted"
                                        label="Начало периода"
                                        prepend-icon="mdi-calendar"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_after"
                                    :first-day-of-week="1"
                                    @input="datepicker_menu_from = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y="offset-y" min-width="290px"
                                ref="datepicker_menu_to">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateToFormatted"
                                        label="Конец периода"
                                        prepend-icon="mdi-calendar"
                                        readonly="readonly"
                                        hide-details="auto"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="filters.date_before"
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <div class="text-right" style="font-size: .8rem;">
                            <a @click="setYesterdayToDateFilter()">Вчера</a>
                        </div>
                    </v-col>
                    <v-col class="ml-5">
                        <v-radio-group
                                v-model="filters.is_bundle"
                                hide-details
                                column
                                class="mt-0"
                        >
                            <v-radio
                                    label="Все"
                                    :value=0
                            ></v-radio>
                            <v-radio
                                    label="Связано с выкупом"
                                    :value=1
                            ></v-radio>
                            <v-radio
                                    label="Не связано с выкупом"
                                    :value=-1
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="filters.supplier_oper_name"
                                :items="supplier_oper_name_choices"
                                item-text="name"
                                item-value="value"
                                label="Обосн. для оплаты"
                                outlined
                                multiple
                                hide-details
                                dense
                                clearable
                        ></v-select>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col>
                        <v-text-field
                                v-model="filters.rid"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchDataWithFilters()"
                                append-icon="mdi-magnify"
                                label="id продажи"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="fetchDataWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">

                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-data-table
                v-if="data"
                dense
                :headers="headers"
                :pageCount="pagination.numberOfPages"
                :items="data"
                item-key="id"
                @update:page="fetchData"
                @update:sort-by="fetchData"
                @update:sort-desc="fetchData"
                :loading="tableLoading"
                :options.sync="options"
                :items-per-page="50"
                :item-class="row_classes"
                :server-items-length="pagination.totalItems"
                class="elevation-0 vertical-border small-font"
        >
            <template v-slot:item.rr_dt="{ item }">
                <div>{{ item.rr_dt | formatDateToLocaleNoTime }}</div>
            </template>
            <template v-slot:item.order_dt="{ item }">
                <div>{{ item.order_dt | formatDateToLocaleNoTime }}</div>
            </template>
            <template v-slot:item.sale_dt="{ item }">
                <div>{{ item.sale_dt | formatDateToLocaleNoTime }}</div>
            </template>
            <template v-slot:item.supplier_oper_name="{ item }">
                <div>
                    <div>
                        {{ item.supplier_oper_name }}
                    </div>
                    <div v-if="item.other_data.penalty">
                        {{ item.other_data.penalty | toRUB }}
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import {Products} from '@/api/products';
	import {General} from '@/api/general';

	export default {
		name: 'ProductReportSales',
		props: {
			product_id: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				loading: false,
				tableLoading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0,
				},
				data: null,
				headers: [
					{text: 'realizationreport_id', sortable: false, value: 'realizationreport_id'},
					{text: 'Договор', sortable: false, value: 'suppliercontract_code'},
					{text: 'rid', sortable: false, value: 'rid'},
					{text: 'Дата операции', sortable: false, value: 'rr_dt'},
					{text: 'Дата заказа', sortable: false, value: 'order_dt'},
					{text: 'Дата продажи', sortable: false, value: 'sale_dt'},
					{text: 'Номер поставки', sortable: false, value: 'other_data.gi_id'},
					{text: 'Размер', sortable: false, value: 'other_data.ts_name'},
					{text: 'barcode', sortable: false, value: 'other_data.barcode'},
					{text: 'Тип док-та', sortable: true, value: 'other_data.doc_type_name'},
					{text: 'quantity', sortable: false, value: 'other_data.quantity'},
					{text: 'Id выкупа', sortable: false, value: 'redemption_id'},
					{text: 'retail_price', sortable: false, value: 'other_data.retail_price'},
					{text: 'retail_amount', sortable: false, value: 'retail_amount'},
					{text: 'sale_percent', sortable: false, value: 'other_data.sale_percent'},
					{text: 'commission_percent', sortable: false, value: 'other_data.commission_percent'},
					{text: 'Склад', sortable: false, value: 'other_data.office_name'},
					{text: 'Обосн. для оплаты', sortable: false, value: 'supplier_oper_name'},
					{text: 'Штрафы', sortable: false, value: 'penalty'},
					{text: 'Доплаты', sortable: false, value: 'additional_payment'},
					{text: 'ШК', sortable: false, value: 'other_data.shk_id'},
					{text: 'retail_price_withdisc_rub', sortable: false, value: 'retail_price_withdisc_rub'},
					{text: 'delivery_amount', sortable: false, value: 'other_data.delivery_amount'},
					{text: 'return_amount', sortable: false, value: 'other_data.return_amount'},
					{text: 'delivery_rub', sortable: false, value: 'delivery_rub'},
					{text: 'Тип короба', sortable: false, value: 'other_data.gi_box_type_name'},
					{text: 'Согл. дисконт', sortable: false, value: 'other_data.product_discount_for_report'},
					{text: 'Промокод', sortable: false, value: 'other_data.supplier_promo'},
					{text: 'СПП', sortable: false, value: 'other_data.ppvz_spp_prc'},
					{text: 'kvw % base', sortable: false, value: 'other_data.ppvz_kvw_prc_base'},
					{text: 'kvw %', sortable: false, value: 'other_data.ppvz_kvw_prc'},
					{text: 'ppvz sales commission', sortable: false, value: 'ppvz_sales_commission'},
					{text: 'ppvz for pay', sortable: false, value: 'other_data.ppvz_for_pay'},
					{text: 'ppvz reward', sortable: false, value: 'ppvz_reward'},
					{text: 'ppvz vw', sortable: false, value: 'ppvz_vw'},
					{text: 'ppvz vw nds', sortable: false, value: 'ppvz_vw_nds'},
					{text: 'office id', sortable: false, value: 'other_data.ppvz_office_id'},
					{text: 'Офис доставки', sortable: false, value: 'other_data.ppvz_office_name'},
					{text: 'id партнера', sortable: false, value: 'other_data.ppvz_supplier_id'},
					{text: 'Партнер', sortable: false, value: 'other_data.ppvz_supplier_name'},
					{text: 'ИНН партнера', sortable: false, value: 'other_data.ppvz_inn'},
				],
				filters: {
					date_before: null,
					date_after: null,
					odid: null,
					rid: null,
					supplier_oper_name: null,
					is_bundle: 0,
				},
				supplier_oper_name_choices: [],
				datepicker_menu_from: null,
				datepicker_menu_to: null,
			};
		},
		computed: {
			transfromFiltersForQuery() {
				let query = {};
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',');
					} else {
						query[f] = this.filters[f];
					}
					if (this.filters[f] == false) {
						query[f] = null;
					}
				}
				return query;
			},
			dateFromFormatted() {
				return this.filters.date_after ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) :
					null;
			},
			dateToFormatted() {
				return this.filters.date_before ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) :
					null;
			},
		},
		async mounted() {
			await this.fetchData();
			await this.fetchReportSupplier();
		},
		methods: {
			row_classes(item) {
				if (item.is_bundle) {
					return 'bundle-report';
				}
			},
			setYesterdayToDateFilter() {
				const d = new Date();
				d.setDate(d.getDate() - 1);
				const d_str = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
				this.filters.date_after = d_str;
				this.filters.date_before = d_str;
			},
			async fetchDataWithFilters() {
				this.options.page = 1;
				await this.fetchData();
			},
			async fetchData() {
				this.loading = true;
				const {page, itemsPerPage, sortBy, sortDesc} = this.options;
				let pageNumber = page - 1;
				console.log(page, itemsPerPage, pageNumber);
				try {
					const res = await Products.fetchProductReports(
						this.product_id,
						{
							...this.transfromFiltersForQuery,
							...{
								'offset': itemsPerPage * pageNumber,
								'limit': itemsPerPage,
								// 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
							},
						},
					);
					this.data = res.data.results;
					this.pagination.totalItems = res.data.count;
					this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage);
				} catch (err) {
					console.log(err);
				}
				this.loading = false;
			},
			async fetchReportSupplier() {
				try {
					const response = await General.fetchReportSupplier();
					this.supplier_oper_name_choices = response.data;
				} catch (err) {
					console.log(err.data);
				}
			},
		},
	};
</script>

<style lang="scss">
    .bundle-report {
        background-color: #e5e5e5;
    }
</style>
<template>
    <div>
        <v-row class="py-10">
            <v-col sm="12" lg="12" class="px-10">
                <div class="title">Фильтры</div>
                <v-row class="mt-3">
                    <v-col>
                        <div class="d-flex flex-column">
                            <v-menu 
                                v-model="datePicker.datepicker_date_after" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datePicker.datepicker_date_after"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_after = null"
                                        v-model="dateAfter"
                                        label="Дата, от"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_after" 
                                    @input="datePicker.datepicker_date_after = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu 
                                v-model="datePicker.datepicker_date_before" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y="offset-y"
                                nudge-right="40" 
                                min-width="290px" 
                                ref="datePicker.datepicker_date_before"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable
                                        @click:clear="filters.date_before = null"
                                        v-model="dateBefore"
                                        label="Дата, до"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        hide-details
                                        outlined
                                        dense
                                        v-on="on"
                                        class="mt-5"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_before" 
                                    @input="datePicker.datepicker_date_before = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.type"
                            :items="choices.advertisement_type"
                            item-text="name"
                            item-value="name"
                            label="Вид рекламы"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.group"
                            :items="choices.advertisement_group"
                            item-text="name"
                            item-value="value"
                            label="Группа товаров"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid"
                            clearable
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            label="Артикул"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col> 
                    <v-col>
                        <v-select
                            v-model="filters.purpose"
                            :items="choices.advertisement_purpose"
                            item-text="name"
                            item-value="value"
                            label="Цель РК"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-select>
                    </v-col>
					<v-col>
                        <v-text-field
                            v-model="filters.advertisement"
                            clearable
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            label="id кампании"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col> 
					<v-col>
						<v-select
							v-model="filters.suppliers"
							:items="suppliers"
							item-text="name"
							item-value="id"
							label="Аккаунт"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
					</v-col>
                    <v-col>
                        <v-btn
                                @click="applyFilter()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="12" lg="12">
                <v-data-table
                    :headers="headers"
                    :items="stats_data"
                    class="elevation-0 mt-0 small-font w-100"
                    :loading="table_loading"
                    @update:page="pageChanged"
                    :options.sync="options"
                    :items-per-page="10"
                    :server-items-length="pagination.totalItems"
                >
                    <template v-slot:item.current_date="{ item }">
                        <p class="mb-0">{{showDate(item.current_date)}}</p>
                    </template>
					<template v-slot:item.cpm="{ item }">
                        {{item.cpm ? item.cpm.toFixed(2): 0}}
                    </template>
					<template v-slot:item.avg_placement="{ item }">
                        {{item.avg_placement ? item.avg_placement.toFixed(3): 0}}
                    </template>
                    <template v-slot:top>
						<div class="d-flex">
							<div class="d-flex w-100 px-7 pt-5">
								<v-btn
									color="primary"
									dark
									class="mb-2"
									@click="exportToExcel"
								>
									Экспорт в excel
								</v-btn>
							</div>
							<div class="d-flex w-100 px-7 pt-5">
								<v-btn
									color="primary"
									dark
									class="mb-2"
									@click="exportDeltaToExcel"
								>
									Экспорт дельты в excel
								</v-btn>
							</div>
							<div class="d-flex w-100 px-7 pt-5">
								<v-btn
									color="primary"
									dark
									class="mb-2"
									:loading="loading_base_stats"
									@click="exportBaseStatsToExcel"
								>
									Экспорт основных показателей в excel
								</v-btn>
							</div>
							
						</div>
                        
                    </template>
                    <template v-slot:item.vp_unit="{ item }">
                        {{item.vp * item.stats.orders}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import {General} from '@/api/general'

export default {
	name: 'AdvertisementNewStats',
	data() {
		return {
			suppliers: [],
            filters: {
                type: null,
                group: null,
                nmid:null,
                date_after: null,
                date_before: null,
                purpose: null,
				advertisement: null,
				suppliers: null
            },
            choices: {
                advertisement_group: [],
                advertisement_type: [],
                advertisement_purpose: []
            },
            datePicker: {
                datepicker_date_before: null,
                datepicker_date_after: null
            },
            headers: [
                { text: 'День', value: 'current_date', sortable: false, width: '1%' },
                { text: 'Id кампании', value: 'campaign_id', sortable: false, width: '1%' },
                { text: 'sku', value: 'nmid', sortable: false, width: '1%' },
                { text: 'Вид рекламы', value: 'adver_type', sortable: false, width: '1%' },
                { text: 'Цель РК', value: 'purpose', sortable: false, width: '1%' },
                { text: 'Просмотры', value: 'stats.views', sortable: false, width: '1%' },
                { text: 'Частота', value: 'stats.frq', sortable: false, width: '1%' },
                { text: 'Клики', value: 'stats.clicks', sortable: false, width: '1%' },
                { text: 'CPC', value: 'stats.cpc', sortable: false, width: '1%' },
				{ text: 'CPM', value: 'cpm', sortable: false, width: '1%' },
                { text: 'CTR(%)', value: 'stats.ctr', sortable: false, width: '1%' },
                { text: 'CR', value: 'stats.cr', sortable: false, width: '1%' },
                { text: 'Расход(руб, с НДС)', value: 'stats.sum', sortable: false, width: '1%' },
                { text: 'Добавление в корзину', value: 'stats.atbs', sortable: false, width: '1%' },
                { text: 'Заказы', value: 'stats.orders', sortable: false, width: '1%' },
                { text: "Себестоимость", value: "cur_cost_price", sortable: false, width: "1%" },
				{ text: "Комиссия %", value: "cur_commision", sortable: false, width: "1%" },
				{ text: "Новая комиссия %", value: "new_commission", sortable: false, width: "1%" },
				{ text: "Логистика", value: "cur_logistic", sortable: false, width: "1%" },
				{ text: "Новая логистика", value: "new_logistic", sortable: false, width: "1%" },
				{ text: "Цена продажи", value: "cur_last_price", sortable: false, width: "1%" },
				{ text: "ВП, шт", value: "vp", sortable: false, width: "1%" },
                { text: "ВП", value: "vp_unit", sortable: false, width: "1%"},
				{ text: "Доходность", value: "profit", sortable: false, width: "1%" },
                { text: "ДРР", value: "drr", sortable: false, width: "1%" },
				{ text: "Топовый запрос", value: "top_keyword", sortable: false, width: "1%" },
				{ text: "Ср. позиция за день", value: "avg_placement", sortable: false, width: "1%" },
				{ text: 'CR delta', value: 'conversion', sortable: false, width: '1%' },
				{ text: 'Заказы дельта', value: 'predict_orders', sortable: false, width: '1%' },
				{ text: 'Заказы с парсера', value: 'orders_from_parsing', sortable: false, width: '1%' },
				{ text: 'Доходность дельта', value: 'predict_profit', sortable: false, width: '1%' },
				{ text: 'ДРР дельта', value: 'predict_drr', sortable: false, width: '1%' },
				{ text: 'Выручка дельта', value: 'predict_revenue', sortable: false, width: '1%' },
				{ text: 'ВП дельта', value: 'predict_vp_all', sortable: false, width: '1%' },
				{ text: 'ВП с заказов', value: 'vp_from_orders_unit', sortable: false, width: '1%' },
				{ text: 'Доходность с заказов', value: 'profit_from_orders', sortable: false, width: '1%' },
				{ text: 'Выкупов', value: 'count_redemption', sortable: false, width: '1%' },
				{ text: '% выкупа', value: 'redemption_percent', sortable: false, width: '1%' },
				{ text: 'Налог ЛК, руб', value: 'seller_tax_rub', sortable: false, width: '1%' },
            ],
            stats_data: [],
            table_loading: false,
			loading_base_stats: false,
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
		}
	},    
    computed: {
        dateAfter () {
            return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
        },
        dateBefore () {
            return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
        },
        transfromFiltersForQuery(){
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
            }
            return query
        },
    },
	methods: {
		async getSuppliers(){
			try {
				const response = await General.getWbAccounts()
				this.suppliers = response.data
			} catch (err) {
				console.log(err)
			}
		},
        async pageChanged () {
            await this.getAdvertisementStats()
        },
        async getAdvertisementTypeAndAndGroup(){
            try {
                const response = await General.fetchAdvertisementType()
                this.choices.advertisement_type = response.data
            } catch (err) {
                 console.log(err.data)
            }
            try {
                const response3 = await General.fetchProductGroup()
                this.choices.advertisement_group = response3.data
            } catch (err) {
                 console.log(err.data)
            }
        },
        async getAdvertisementStats(){
            this.table_loading = true
            const { page, itemsPerPage} = this.options
            let pageNumber = page - 1
            try {
                let response = await AdvertisementNew.getAdvertisementCampaignsStats(
                    {
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                        },
                        ...this.transfromFiltersForQuery,
                    }
                )
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.stats_data = response.data.results
            } catch (err) {
                console.error(err.response)
            }
            this.table_loading = false
        },
        showDate(date) {
            return date ? this.$options.filters.formatDateToLocaleNoTime(date) : "-";
        },
        async applyFilter(){
            this.options.page = 1
            await this.getAdvertisementStats()
        },
        async exportToExcel(){
            try {
                const response = await AdvertisementNew.exportAdvertisementStats({
                    ...this.transfromFiltersForQuery
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
		async exportBaseStatsToExcel(){
			this.loading_base_stats = true
            try {
                const response = await AdvertisementNew.exportAdvertisementBaseStats({
                    ...this.transfromFiltersForQuery
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_base_stats = false
        },
		async exportDeltaToExcel(){
			try {
                const response = await AdvertisementNew.exportAdvertisementDeltaStats({
                    ...this.transfromFiltersForQuery
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'adver_delta_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
		},
        async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices.advertisement_purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
	},
    async mounted () {
		document.title = `Статистика рекламы | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Статистика рекламных кампаний`)
		await this.getSuppliers()
        await this.getAdvertisementStats()
        await this.getAdvertisementTypeAndAndGroup()
        await this.getAdvertisementPurpose()
	}
}
</script>
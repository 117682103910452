<template>
    <v-card>
        <v-card-title class="text-h5">
            Документ продажи
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-menu 
                            v-model="datePicker.datepicker_sale_dt_after" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_sale_dt_after"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateFormattedAfter"
                                    @click:clear="clear_date_after"
                                    label="Дата покупки (начало)"
                                    readonly="readonly"
                                    tabindex="2"
                                    v-bind="attrs"
                                    outlined
                                    hide-details
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="filterData.sale_dt_after" 
                                @change="test"
                                @input="datePicker.datepicker_sale_dt_after = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-menu 
                            v-model="datePicker.datepicker_sale_dt_before" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_sale_dt_before"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateFormattedBefore"
                                    @click:clear="clear_date_before"
                                    label="Дата покупки (конец)"
                                    readonly="readonly"
                                    tabindex="2"
                                    hide-details
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="filterData.sale_dt_before" 
                                @change="test"
                                @input="datePicker.datepicker_sale_dt_before = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="filterData.sum_order"
                            label="Сумма"
                            outlined
                            type="number"
                            tabindex="4"
                            @change="test"
                            hide-details
                            dense
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row style="padding: 12px 12px 24px 12px;">
                    <p>Дата заказа: {{showDate(items.date_order)}}</p>
                    <p class="ml-5">Дата покупки: {{showDate(items.date_buy)}}</p>
                    <p class="ml-5">Кол-во: {{items.quantity_product}}</p>
                    <p class="ml-5">Цена, шт: {{items.sum_order / items.quantity_product}}</p>
                </v-row>
                <v-data-table
                    :headers="data.headers"
                    :items="data.list_report"
                    :loading="tableLoading"
                    class="elevation-1"
                    v-model="data.current_reports"
                    :pageCount="pagination.numberOfPages"
                    @update:page="pageChanged"
                    :options.sync="options"
                    show-select
                    :items-per-page="20"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 20, 50]
                    }"
                    :server-items-length="pagination.totalItems"
                >
                    <template v-slot:item.sale_dt="{ item }">
                        {{ showDate(item.sale_dt)  }}
                    </template>
                </v-data-table>

            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="close()"
            >
                Закрыть
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="close(1)"
            >
                Добавить
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

import { Redemption } from '@/api/redemption'

export default {
    name: "RedemptionFormReport",    
    props: ['items'],
    watch: {
        async options (){
            await this.getReport()
        },
        'data.current_reports': function(val, oldVal) {
            if (val.length > this.items.quantity_product) {
                this.$nextTick(() => {
                    this.data.current_reports = oldVal  
                })
            }
        }
    },
    data(){
        return{
            datePicker: {
                datepicker_sale_dt_after: "",
                datepicker_sale_dt_before: ""
            },
            tableLoading: false,
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
            filterData: {
                sum_order: "",
                sale_dt_after: "",
                sale_dt_before: ""
            },
            data: {
                current_reports: [],
                list_report: [],
                sale_dt_after: null,
                sale_dt_before: null,
                headers: [
                    {text: 'ID', align: 'start', value: 'id', width: '15%', sortable: false,},
                    {text: 'Документ продажи', align: 'start', value: 'realizationreport_id', width: '15%', sortable: false,},
                    {text: 'Дата', align: 'start', value: 'sale_dt', width: '15%'},
                    {text: 'Сумма', align: 'start', value: 'sum_order', width: '15%'},
                    {text: 'Стоимость логистики', align: 'start', value: 'logistic', width: '15%', sortable: false,},
                    {text: 'Коммисия из продажи', align: 'start', value: 'commission', width: '15%', sortable: false,},
                    {text: 'Размер', align: 'start', value: 'size', width: '15%', sortable: false,},
                ]
            }
        }
    },
    computed: {
        dateFormattedAfter () {
            return this.filterData.sale_dt_after ? this.$options.filters.formatDateToLocaleNoTime(this.filterData.sale_dt_after) : null
        },
        dateFormattedBefore () {
            return this.filterData.sale_dt_before ? this.$options.filters.formatDateToLocaleNoTime(this.filterData.sale_dt_before) : null
        },
    },
    methods: {
        transformDate(date){
            let tmp = new Date(date)
            return tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate()
        },
        showDate(date){
            return date  ? this.$options.filters.formatDateToLocaleNoTime(date) : null
        },
        close(el=null){
            let result = el
            if (el)
                result = this.data.current_reports
            this.$emit('close', result)
        },
        clear_date_before(){
            this.filterData.sale_dt_before = ''
            this.options.page=1
            this.getReport()
        },
        clear_date_after(){
            this.filterData.sale_dt_after = ''
            this.options.page=1
            this.getReport()
        },
        test(){
            this.options.page=1
            this.getReport()
        },
        async getReport(){
            this.tableLoading = true
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            try {
                const response = await Redemption.fetchReportRedemption(
                    {
                        'page': page,
                        'page_size': itemsPerPage,
                        'product': this.items.product_id,
                        'variations': this.items.variations ? this.items.variations.id : "",
                        'sale_dt_after': this.filterData.sale_dt_after,
                        'sale_dt_before': this.filterData.sale_dt_before,
                        'sum_order': this.filterData.sum_order,
                        'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                    }
                )
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.data.list_report = response.data.results
                this.tableLoading = false
            } catch (err) {
                console.log(err.data)
            }
        },
        async pageChanged () {
            await this.getReport()
        },
    },
    mounted(){
        this.filterData.sale_dt_after = this.items.date_buy ? this.transformDate(this.items.date_buy) : ""
        this.filterData.sale_dt_before = this.items.date_buy ? this.transformDate(this.items.date_buy) : ""
        this.filterData.sum_order = this.items.sum_order / this.items.quantity_product
        this.getReport()
    },
}
</script>

<template>
	<v-data-table
		:headers="table.headers"
		:items="table.data"
		class="elevation-0 mt-1 vertical-border"
		:loading="table.loading"
		:items-per-page="14" 
		:footer-props="{'items-per-page-options': [7, 14, 30, 60, -1]}"
	>
		<template v-slot:item.summary_score="{ item }">
			<div v-if="item.is_empty_product"></div>
			<div v-else-if="item.is_traforetto" style="text-align: center; color: #005bff;">Трафарет</div>
			<div v-else style="text-align: center;">
				<p class="mb-0">{{ item.final_result.toFixed(3) }}</p>
				<v-chip small v-if="item.search_promotion_enabled" class="deep-purple--text">
					Поднятие на {{ (item.search_promotion_boost * 100).toFixed(3) }}%
				</v-chip>
			</div>
		</template>
		<template v-slot:item.query_fit_score="{ item }">
			{{ item.query_fit_score ? item.query_fit_score : 0 }} балла
		</template>
		<template v-slot:item.rating="{ item }">
			<p class="mb-0" style="white-space: nowrap; text-align: center;" v-if="!item.is_empty_product">
				{{ item.rating.toFixed(1) }} ({{ item.rates_count }} шт.)
			</p>
		</template>
		<template v-slot:item.price="{ item }">
			<div v-if="!item.is_empty_product">
				<p class="mb-0">{{ item.price_score }} б.</p>
				<p class="mb-0 grey--text">{{ item.price_rub | toRUB }}</p>
			</div>				
		</template>
		<template v-slot:item.promotion="{ item }">
			<p class="mb-0 grey--text" style="text-align: center;" v-if="!item.is_empty_product">
				{{ item.is_in_promo ? 'Учавствует' : 'Нет' }}
			</p>			
		</template>
		<template v-slot:item.delivery_time="{ item }">
			<div style="text-align: center; white-space: nowrap;" v-if="!item.is_empty_product">
				от {{ item.delivery_speed_boost_slot_from }} до {{ item.delivery_speed_boost_slot_to }} дней	
			</div>		
		</template>
		<template v-slot:item.delivery_speed="{ item }">
			<div style="text-align: center" v-if="!item.is_empty_product">
				{{ convert_delivery_speed(item.delivery) }}
			</div>		
		</template>
		<template v-slot:item.price_index="{ item }">
			<div style="text-align: center;" v-if="!item.is_empty_product">
				{{ item.price_defect_rate_boost > 0 ? (item.price_defect_rate_boost * 100).toFixed(1) + '%' : 'Нет'}}
			</div>
		</template>
	</v-data-table>
</template>

<script>
import { KeywordMonitoring } from '@/api/keyword_monitoring'
  export default {
    name: "OzonCompetitorsComponent",
	props: ['sku', 'keyword'],
    data(){
        return {
			table: {
				data: [],
				loading: false,
				headers: [
					{ text: "Дата", value: "date", sortable: true, width: "1%", align: 'center' },
					{ text: "Позиция", value: "position", sortable: true, width: "1%", align: 'center' },
					{ text: "Сводная оценка", value: "summary_score", sortable: false, width: "1%" },
					{ text: "Соответствие запросу", value: "query_fit_score", sortable: false, width: "1%", align: 'center' },
					{ text: "Отзывы", value: "rating", sortable: false, width: "1%" },
					{ text: "Цена", value: "price", sortable: false, width: "1%" },
					{ text: "Популярность по запросу", value: "popularity_score", sortable: false, width: "1%", align: 'center' },
					{ text: "Продажи товара", value: "sales_score", sortable: false, width: "1%", align: 'center' },
					{ text: "Популярность общая", value: "popularity_total_score", sortable: false, width: "1%", align: 'center' },
					{ text: "Акции", value: "promotion", sortable: false, width: "1%" },
					{ text: "Быстрая доставка", value: "delivery_speed", sortable: false, width: "1%" },
					{ text: "Срок доставки", value: "delivery_time", sortable: false, width: "1%" },
					{ text: "Индекс цен", value: "price_index", sortable: false, width: "1%" },
				],
			},
        }
    },
    methods:{
        async fetchDetailCompetitors(){
			this.table.loading = true
			try{
				const response =  await KeywordMonitoring.fetchDetailCompetitors(this.sku, {"keyword": this.keyword})
				this.table.data = response.data
			} catch (err){
				console.log(err)
			}      
			this.table.loading = false   
        },
		convert_delivery_speed(value){
			let dict = {
				"LOCAL_STORE_TYPE": "Домашний склад",
				"NONE_TYPE": "Нет",
				"EXPRESS_TYPE": "EXPRESS_TYPE"
			}
			try{
				return dict[value]
			} catch{
				return value
			}
		}
    },
    async mounted(){
        await this.fetchDetailCompetitors()
	},
  }
</script>
<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="loading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <CoefficientChart :values="values" :labels="labels"></CoefficientChart>
        </div>
    </v-sheet>
</template>

<script>
	import {Analytics} from '@/api/analytics';
	import CoefficientChart from './CoefficientChart';

	export default {
		name: 'Stocks1CDialog',
		components: {CoefficientChart},
		props: {
			code_1c: {
				type: String,
			},
			date: {
				type: String,
			},
		},
		data() {
			return {
				data: null,
				loading: true,
			};
		},
		methods: {
			async fetchCoefficientsByCode1C() {
				this.loading = true;
				try {
					const res = await Analytics.fetchCoefficientsByCode1C(
						this.code_1c,
						this.date.split('-').join(''),
					);
					this.data = res.data;
				} catch (err) {
					console.log(err);
				}
				this.loading = false;
			},
		},
		computed: {
			values() {
				return this.data.map(item => {
					return item.coefficient
				});
			},
			labels() {
				return this.data.map(item => {
					return `${item.code_mp} (${item.marketplace_type})`
				});
			},
		},
		async mounted() {
			await this.fetchCoefficientsByCode1C();
		},
	};
</script>

<style scoped>

</style>

<template>
    <v-container fluid>
        <v-dialog v-model="create.dialog" max-width="600" v-if="create.dialog">
            <v-card class="p-5">
                <v-card-title>Добавление записи социальной сети</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="create.form_data.manager" :items="create.manager_choices" class="mt-3"
                        label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                    ></v-autocomplete>
                    <v-select
						v-model="create.form_data.resource" :items="create.resource_choices" class="mt-3"
						item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
					></v-select>
                    <v-text-field
                        v-model="create.form_data.link" label="Ссылка на запись" 
                        outlined clearable dense class="mt-3" hide-details
                    ></v-text-field>
                    <v-autocomplete
                        outlined clearable label="Артикул WB" v-model="create.form_data.products"
                        :loading="create.loading_product_autocomplete" :items="create.products_choices"
                        item-text="nmid" item-value="id" :search-input.sync="create.search_products"
                        cache-items hide-details dense multiple class="mt-3"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="create.form_data.name" label="Название ролика" 
                        outlined clearable dense class="mt-3" hide-details
                    ></v-text-field>
                    <v-menu 
                        v-model="datepicker_menu_for_create" :close-on-content-click="false" 
                        :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                        min-width="290px" ref="datepicker_menu_for_create"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                clearable @click:clear="create.form_data.date = null" dense v-on="on"
                                v-model="dateForCreatedFormatted" label="Дата создания ролика" 
                                readonly="readonly" v-bind="attrs" 
                                hide-details="auto" outlined class="mt-3"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="create.form_data.date" :first-day-of-week="1"
                            @input="datepicker_menu_for_create = false"
                        ></v-date-picker>
                    </v-menu>
                    <div>
                        <v-btn 
                            color="primary" class="mt-5" :loading="create.loading" @click="create_record"
                        >Создать запись</v-btn>
                        <div class="d-flex mt-3">
                            <v-file-input
                                label="Файл с данными" outlined dense id="file_social_items" hide-details
                            ></v-file-input>
                            <v-btn 
                                color="primary" :loading="create.loading_file" @click="import_social_items" class="ml-5"
                            >Загрузить записи из файла</v-btn>
                        </div>
                        <p style="cursor:pointer" class="text-download mt-3" @click="template_import_social_item">
                            Скачать шаблон
                        </p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleted.dialog" max-width="600" v-if="deleted.dialog">
            <v-card class="p-5">
                <v-card-title>Вы уверены, что хотите удалить запись {{ deleted.editable_item.link }}</v-card-title>
                <v-card-text class="mt-5">
                    <v-btn color="error" @click="confirm_delete_item">
                        Удалить запись
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card>
            <v-card-title>
                Статистика социальных сетей
                <v-btn class="ml-5" color="primary" @click="open_dialog_create">
                    Создать запись
                </v-btn>
                <v-btn class="ml-5" color="primary" @click="export_base" :loading="export_base_loading">
                    Экспорт социальных сетей
                </v-btn>
                <v-btn class="ml-5" color="primary" @click="export_stats" :loading="export_stats_loading">
                    Экспорт статистики социальных сетей
                </v-btn>
            </v-card-title>

            <v-card-text ref="social_items_filters">
                <div class="d-flex mt-5">
                    <div style="display: flex; flex-direction: column;">
                        <v-menu 
                            v-model="datepicker_menu_from" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_from"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable @click:clear="list.filters.date_created_after = null" dense v-on="on"
                                    v-model="dateFromFormatted" label="Статистика начало периода" prepend-icon="mdi-calendar"
                                    readonly="readonly" v-bind="attrs" hide-details="auto" outlined
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="list.filters.date_created_after" :first-day-of-week="1"
                                @input="datepicker_menu_from = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu 
                            v-model="datepicker_menu_to" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_to"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable @click:clear="list.filters.date_created_before = null" dense v-on="on"
                                    v-model="dateToFormatted" label="Статистка конец периода" prepend-icon="mdi-calendar"
                                    readonly="readonly" v-bind="attrs" hide-details="auto" outlined class="mt-3"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="list.filters.date_created_before" :first-day-of-week="1"
                                @input="datepicker_menu_to = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-menu 
                            v-model="datepicker_menu_date_from" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_date_from"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable @click:clear="list.filters.date_after = null" dense v-on="on"
                                    v-model="dateRealFromFormatted" label="Дата создания(начало)" prepend-icon="mdi-calendar"
                                    readonly="readonly" v-bind="attrs" hide-details="auto" outlined
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="list.filters.date_after" :first-day-of-week="1"
                                @input="datepicker_menu_date_from = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu 
                            v-model="datepicker_menu_date_to" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_date_to"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable @click:clear="list.filters.date_before = null" dense v-on="on"
                                    v-model="dateRealToFormatted" label="Дата создания(конец)" prepend-icon="mdi-calendar"
                                    readonly="readonly" v-bind="attrs" hide-details="auto" outlined class="mt-3"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="list.filters.date_before" :first-day-of-week="1"
                                @input="datepicker_menu_date_to = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <v-autocomplete
                        v-model="list.filters.manager" :items="create.manager_choices" class="ml-3" multiple
                        label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                    ></v-autocomplete>
                    <v-select
						v-model="list.filters.resource" :items="create.resource_choices" class="ml-3" multiple
						item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
					></v-select>
                    <v-text-field
                        v-model="list.filters.products" label="Артикул" 
                        outlined clearable dense class="ml-3" hide-details
                    ></v-text-field>
                    <v-text-field
                        v-model="list.filters.name" label="Название" 
                        outlined clearable dense class="ml-3" hide-details
                    ></v-text-field>
                    <v-btn 
                        color="primary" class="ml-5" :loading="list.loading" @click="list_records"
                    >Применить фильтры</v-btn>

                </div>
                <v-data-table
                    :headers="list.headers" :pageCount="list.pagination.numberOfPages" :items="list.data"
                    item-key="id" :loading="list.loading" :options.sync="list.options"
                    :server-items-length="list.pagination.totalItems" class="elevation-1 mt-5"
                    :footer-props="{'items-per-page-options': [10, 25]}"
                >
                    <template v-slot:item.products="{ item }">
                        <div style="display: flex; flex-direction: column;">
                            <router-link 
                                v-for="sku in item.wb_products"
                                :key="sku"
                                target="_blank" 
                                :to="{ name: 'product_card', params: { id: sku }}"
                            >{{ sku }}</router-link>
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn 
                            @click="open_delete_dialog(item)" outlined x-small color="error"
                            v-if="isSuperManager"
                        >Удалить запись</v-btn>
                        <v-btn 
                            @click="set_disable_record(item)" outlined x-small
                            :color="!item.disable ? 'blue-grey' : 'success'" class="mt-3"
                        >{{ !item.disable ? 'остановить': 'запустить' }}</v-btn>
                        <v-btn 
                            @click="check_stats(item)" outlined x-small 
                            color="green" class="mt-3"
                        >Проверить статистику</v-btn>
                        
                    </template>
                    <template v-slot:item.link="{ item }">
                        <a :href="item.link" target="_blank">{{ item.link }}</a>
                    </template>
                    <template v-slot:item.total_likes="{ item }">
                        <div style="text-align: center;">{{ item.total_likes }}</div>
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.likes_array" :name="'Лайки'"/>
                        </div>
                        
                    </template>
                    <template v-slot:item.total_views="{ item }">
                        <div style="text-align: center;">{{ item.total_views }}</div>
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.views_array" :name="'Просмотры'"/>
                        </div>
                    </template>
                    <template v-slot:item.total_comments="{ item }">
                        <div style="text-align: center;">{{ item.total_comments }}</div>
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.comments_array" :name="'Комментарии'"/>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';
    import { Products } from '@/api/products'
    import { Redemption } from '@/api/redemption'
    import _ from 'lodash'
    import SocialParserCharts from '@/views/SocialParser/SocialParserCharts.vue';

	export default {
		name: 'SocialParserBasePage',
        components: {
            SocialParserCharts
        },
		data() {
			return {
                deleted: {
                    dialog: false,
                    editable_item: null
                },
                create: {
                    dialog: false,
                    loading: false,
                    loading_file: false,
                    form_data: {
                        link: null,
                        products: null,
                        manager: null,
                        resource: null,
                        name: null,
                        date: null
                    },
                    loading_search_products: false,
                    manager_choices: [],
                    resource_choices: [
                        {'id': 'TIKTOK', 'name': 'Tiktok'},
                        {'id': 'YOUTUBE', 'name': 'Youtube'},
                        {'id': 'INSTAGRAM', 'name': 'Instagram'},
                        {'id': 'LIKEE', 'name': 'Likee'},
                        {'id': 'VK', 'name': 'Vk'},
                    ],
                    products_choices: [],
                    search_products: null
                },
                list: {
                    loading: false,
                    headers: [
						{ text: 'Дата добавления в сервис', value: 'date_created', width: '1%', sortable: true},
                        { text: 'Дата создания', value: 'date', width: '1%', sortable: true},
						{ text: 'Менеджер', value: 'manager', width: '1%', sortable: false },
						{ text: 'Площадка', value: 'resource', width: '1%', sortable: true },
						{ text: 'SKU', value: 'products', width: '3%', sortable: false },
                        { text: 'Название', value: 'name', width: '3%', sortable: true },
						{ text: 'Ссылка на ролик', value: 'link', width: '3%', sortable: false },
						{ text: 'Просмотры', value: 'total_views', width: '1%', sortable: true },
						{ text: 'Лайки', value: 'total_likes', width: '1%', sortable: true },
						{ text: 'Комментарии', value: 'total_comments', width: '1%', sortable: true },
						{ text: 'Действия', value: 'action', width: '1%', sortable: false },
					],
                    data: [],
                    options: {
                        sortBy: ['date_created'],
                        sortDesc: [true],
                        itemsPerPage: 25
                    },
                    pagination: {
                        next: null,
                        totalItems: 0,
                        numberOfPages: 0,
                        currentPage: 0
                    },
                    filters: {
                        date_created_after: null,
                        date_created_before: null,
                        manager: null,
                        resource: null,
                        products: null,
                        name: null,
                        date_after: null,
                        date_before: null
                    }
                },
                datepicker_menu_from: false,
                datepicker_menu_to: false,
                datepicker_menu_for_create: false,
                datepicker_menu_date_from: false,
                datepicker_menu_date_to: false,
                export_base_loading: false,
                export_stats_loading: false
            }
		},
        watch: {
            'create.search_products': function(val) {
                if (val && val.length > 1){
                    this.create.loading_product_autocomplete = true
                    this.debouncedGetFilteredProduct(val)
                }
            },
            'list.options': async function (newVal, oldVal) {
				if (oldVal) {
					await this.list_records()
				}
			},
        },
        computed: {
            transfromFiltersForQuery(){
                let query = {}
                for (const f in this.list.filters) {
                    if (Array.isArray(this.list.filters[f])) {
                        query[f] = this.list.filters[f].join(',')
                    } else {
                        query[f] = this.list.filters[f]
                    }
                }
                return query
            },
            dateFromFormatted () {
                return this.list.filters.date_created_after ? this.$options.filters.formatDateToLocaleNoTime(this.list.filters.date_created_after) : null
            },
            dateToFormatted () {
                return this.list.filters.date_created_before ? this.$options.filters.formatDateToLocaleNoTime(this.list.filters.date_created_before) : null
            },
            dateForCreatedFormatted () {
                return this.create.form_data.date ? this.$options.filters.formatDateToLocaleNoTime(this.create.form_data.date) : null
            },
            dateRealFromFormatted () {
                return this.list.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.list.filters.date_after) : null
            },
            dateRealToFormatted () {
                return this.list.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.list.filters.date_before) : null
            },
            isSuperManager() {
				return this.$store.getters.isSuperManager || false;
			},
        },
		methods: {
            async open_dialog_create(){
                this.create.dialog = true
            },
            async fetch_manager_choices() {
                try {
                    const response = await SocialParser.ListManagers()
                    this.create.manager_choices = response.data
                    let current_user = Array.from(this.create.manager_choices)
                                            .find(obj => obj.id == this.$store.getters.userPK)
                    if (current_user)
                        this.create.form_data.manager = current_user.id
                } catch (err) {
                    console.log(err.response)
                }
            },
            async get_filters_products(val){
                try {
                    const response = await Redemption.fetchRedemptionFilteredProduct({'nmid': val})
                    this.create.products_choices = response.data
                } catch (err) {
                    console.log(err.data)
                }
                this.create.loading_product_autocomplete = false
            },
            async create_record(){
                this.create.loading = true
                try{
                    await SocialParser.CreateSocialItem(this.create.form_data)
                    this.$toasted.show(`Запись создана`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.create.dialog = false
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка(${err.response.data.error})`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.create.loading = false
            },
            async list_records(){
                this.list.loading = true
				this.$vuetify.goTo(this.$refs.social_items_filters, {
                    duration: 500, offset: 150, easing: 'linear',
                })
				const { page, itemsPerPage, sortBy, sortDesc } = this.list.options
				let pageNumber = page - 1
				try {
					let query_data = {
						...this.transfromFiltersForQuery,
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
                    const response = await SocialParser.ListSocialItem(query_data)
					this.list.data = response.data.results
					this.list.pagination.totalItems = response.data.count
					this.list.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.list.loading = false
            },
            async set_disable_record(item){
                let status = item.disable ? 'активировано' : 'приостановлено'
                try{
                    await SocialParser.SetDisableSocialItem(item.id)
                    this.$toasted.show(`Получение статистики записи ${status}`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
            },
            async import_social_items(){
                this.create.loading_file = true
				let fileToUpload = document.getElementById('file_social_items').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				try {
					await SocialParser.ImportSocialItem(formData)
                    this.create.dialog = false
                    await this.list_records()
				}
				catch (err){
					console.log(err)
				}
				this.create.loading_file = false
			},
            async template_import_social_item(){
				try {
					const response = await SocialParser.TemplateImportSocialItem()
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'template_social_items.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
			},
            async export_base(){
                this.export_base_loading = true
				try {
					const response = await SocialParser.ExportBaseSocialItem(this.transfromFiltersForQuery)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_base.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
                this.export_base_loading = false
			},
            async export_stats(){
                if (!this.list.filters.date_created_after || !this.list.filters.date_created_before){
                    this.$toasted.show(`Заполните начальную и конечную дату статистики(первый фильтр)`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    return
                }
                this.export_stats_loading = true
				try {
					const response = await SocialParser.ExportStatsSocialItem(this.transfromFiltersForQuery)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_stats.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
                    console.log(err)
				}
                this.export_stats_loading = false
			},
            async check_stats(item){
                try{
                    const response = await SocialParser.CheckStatsSocialItem(item.id)
                    let data = response.data
                    this.$toasted.show(`Запрос выполнен успешно(Лайки = ${data.likes} / Просмотры = ${data.views} / Комментарии = ${data.comments})`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                } catch (err){
                    this.$toasted.show(`Запрос выполнен с ошибкой ${err.response.data.notes}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
            },
            open_delete_dialog(item){
                this.deleted.dialog = true
                this.deleted.editable_item = item
            },
            async confirm_delete_item(){
                try{
                    await SocialParser.DeleteStatsSocialItem(this.deleted.editable_item.id)
                    this.$toasted.show(`Запись удалена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.deleted.dialog = false
            },

		},
		async mounted() {
			document.title = 'Парсер социальных сетей';
			this.$store.commit('setPageTitle', `Парсер социальных сетей`)
            await this.list_records()
            await this.fetch_manager_choices()
		},
        created: function () {
            this.debouncedGetFilteredProduct = _.debounce(this.get_filters_products, 1000)
        }, 
	};
</script>

<style lang="scss">
</style>
<template>
	<div class="px-5 py-5">
		<v-text-field
			v-model="old_price" label="Цена до скидок" type="number" outlined clearable dense
		></v-text-field>
		<v-text-field
			v-model="price" label="Цена с учетом скидок" type="number" outlined clearable dense @change="calculateIndicators"
		></v-text-field>
		<v-text-field
			v-model="min_price" label="Минимальная цена" type="number" outlined clearable dense @change="calculateIndicators"
		></v-text-field>
		<div class="d-flex">
			<p>Комиссия: {{ comission }}%</p>
			<p class="ml-2">Логистика: {{ logistic | toRUB }}</p>
			<p class="ml-2">с/с: {{ cost_price | toRUB }}</p>
			<p class="ml-2">Налог: {{ tax }}%</p>
			<p class="ml-2">Эквайринг: {{ acquiring * 100 }}</p>
			<p class="ml-2">Базовая логистика: {{ base_logistic }}</p>
			<p class="ml-2">Последняя миля: {{ last_mile }}</p>
			<p class="ml-2">% выкупа: {{ redemption_percent * 100 }}</p>
		</div>
		<v-row>
			<v-col>
				<v-text-field
					v-model="indicators.vp_plan_min_price" label="ВП_бс_н план от мин. цены" type="number"
					outlined readonly dense hide-details
				></v-text-field>
			</v-col>
			<v-col>
				<v-text-field
					v-model="indicators.vp_plan_price" label="ВП_бс_н план от цены" type="number"
					outlined readonly dense hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-text-field
					v-model="indicators.rentab_min_price" label="марж-ть от мин. цены" type="number"
					outlined readonly dense hide-details
				></v-text-field>
			</v-col>
			<v-col>
				<v-text-field
					v-model="indicators.rentab_price" label="марж-ть от цены" type="number"
					outlined readonly dense hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-btn color="primary" class="mt-5" :disabled="!canChangePrice" @click="savePriceAPI" :loading="loading">Изменить цену</v-btn>
	</div>
</template>

<script>
	import {Products} from '@/api/products';
	export default {
		name: 'OzonChangePrice',
		data(){
			return {
				old_price: 0,
				min_price: 0,
				price: 0,
				loading: false,
				indicators: {
					vp_plan_min_price: null,
					vp_plan_price: null,
					rentab_min_price: null,
					rentab_price: null
				}
			}
			
		},
		computed: {
			canChangePrice(){
				if ((this.old_price < this.cost_price) || (this.min_price > 0 && this.min_price < this.cost_price) || (this.price < this.cost_price)){
					return false
				}
				if ((this.old_price > this.price) && (this.price > this.min_price)){
					return true
				}
				return false
			}
		},
		props: {
			product_id: { type: Number },
			current_min_price: { type: Number, require: false, default: 0},
			current_price: { type: Number, require: false, default: 0},
			current_old_price: { type: Number, required: false, default: 0 },
			comission: { type: Number, required: false, default: 0 },
			logistic: { type: Number, required: false, default: 0 },
			last_mile: { type: Number, required: false, default: 0 },
			cost_price: { type: Number, required: false, default: 0 },
			tax: { type: Number, required: false, default: 0 },
			acquiring: { type: Number, required: false, default: 0},
			base_logistic: { type: Number, required: false, default: 0 },
			redemption_percent: { type: Number, required: false, default: 0 }
		},
		methods: {
			async savePriceAPI(){
				if (!this.canChangePrice){
					this.$toasted.show(`Не выполнены условия изменения цены`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-right',
						duration: 3000,
					});
					return
				}
				this.loading = true
				let json_data = {}
				if (this.min_price != this.current_min_price){
					json_data['min_price'] = this.min_price
				}
				if (this.old_price != this.current_old_price){
					json_data['old_price'] = this.old_price
				}
				if (this.price != this.current_price){
					json_data['price'] = this.price
				}
				try{
					const response = await Products.saveOzonPrice(this.product_id, json_data)
					if (response.status == 200) {
						this.$toasted.show(`Цены изменены`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
					}
				} catch (err) {
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-right',
						duration: 3000,
					});
				}
				this.loading = false
				this.$emit('finishUpdatePrice')
			},
			calc_vp(price){
				return (
					(price - price * this.comission / 100) * this.redemption_percent -
					this.cost_price * this.redemption_percent -
					this.logistic - this.last_mile - this.base_logistic * (1 - this.redemption_percent) -
					price * this.tax * this.redemption_percent / 100 -
					price * this.acquiring
				)
			},
			calculateIndicators(){
				this.indicators.vp_plan_min_price =  this.calc_vp(this.min_price).toFixed(2)
				this.indicators.vp_plan_price = this.calc_vp(this.price).toFixed(2)
				this.indicators.rentab_min_price = this.min_price != 0 ? (this.indicators.vp_plan_min_price / this.min_price * 100).toFixed(2): 0
				this.indicators.rentab_price = this.price != 0 ? (this.indicators.vp_plan_price / this.price * 100).toFixed(2): 0
			}
		},
		async mounted() {
			this.price = this.current_price
			this.old_price = this.current_old_price
			this.min_price = this.current_min_price
			this.calculateIndicators()
		},
	};
</script>

<style scoped>

</style>

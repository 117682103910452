import { HTTP } from './http'

export const AdvertisementNew = {
	async sendCode (phone) {
		try {
			const res = await HTTP.post('/advertisement_new/actions/send_code/', {phone} )
			if (res.status === 200) {
				return res.data
			}
		} catch (err) {
			throw new Error('Error. Try later')
		}
	},
	async confirmCode (confirmCode, sendCodeToken) {
		const res = await HTTP.post('/advertisement_new/actions/confirm_code/', {
			'confirm_code': confirmCode, 'send_code_token': sendCodeToken
		})
		if (res.status === 200) {
			return res.data
		}
	},
	async setNewTokenCmp (token) {
		try {
			const res = await HTTP.post('/advertisement_new/actions/set_new_token_cmp/', {token} )
			if (res.status === 200) {
				return res.data
			}
		} catch (err) {
			throw new Error('Error. Try later')
		}
	},
	async getSuppliers () {
		const res = await HTTP.get('/advertisement_new/actions/get_suppliers/')
		if (res.status === 200) {
			return res.data
		}
	},  
	async getAdvertisementCampaigns (query) {
		return HTTP.get('/advertisement_new/get_advertisement_campaigns/', {
			params: query
		})
	},
	async updateAdvertisement (id, data=null) {
		return HTTP.put(`/advertisement_new/update_advertisement_data/${id}/`, data)
	},
	async updateOneAdvertisementData (advert_id) {
		return HTTP.post(
			'/advertisement_new/fill_one_advertisement_campaign/', 
			{'adver_id': advert_id}
		)
	},
	async getDetailAdvertisementCampaign (id) {
		return HTTP.get(`/advertisement_new/get_advertisement_campaigns/${id}/`)
	},
	async getAdvertisementCampaignsStats (query) {
		return HTTP.get('/advertisement_new/get_advertisement_campaigns/stats/', {
			params: query
		})
	},
	async getAdvertisementCampaignsStatsV2 (query) {
		return HTTP.get('/advertisement_new/get_advertisement_campaigns/stats_v2/', {
			params: query
		})
	},
	async exportAdvertisementStats(query) {
		return HTTP.get('/advertisement_new/export_advertisement_stats/', {
			responseType:"blob", 
			params: query
		})
	},
	async exportAdvertisementBaseStats(query) {
		return HTTP.get('/advertisement_new/export_adverts_base_stats/', {
			responseType:"blob", 
			params: query
		})
	},
	async exportAdvertisementDeltaStats(query=null){
		return HTTP.get('/advertisement_new/export_advertisement_delta_stats/', {
			responseType:"blob", 
			params: query
		})
	},
	async exportNewAdvertisementStats(query) {
		return HTTP.get('/advertisement_new/export_advertisement_stats_v2/', {
			responseType:"blob", 
			params: query
		})
	},
	async exportNewAdvertisementDeltaStats(query=null){
		return HTTP.get('/advertisement_new/export_advertisement_delta_stats_v2/', {
			responseType:"blob", 
			params: query
		})
	},
	async getStatsKeyword (advert_id) {
		return HTTP.get('/advertisement_new/get_advertisement_campaign_stats_by_keyword/', {
			params: {
				"advert_id": advert_id
			}
		})
	},
	async changeExcludedKeyword (advert_id, keywords_list, type) {
		return HTTP.post(
			'/advertisement_new/change_excluded_keyword/', 
			{'adver_id': advert_id, 'keywords_list': keywords_list, 'type': type}
		)
	},
	async exportKeywordsStatsFromWB(query=null) {
		return HTTP.get('/advertisement_new/export_keywords_stats_from_wb/', {
			responseType:"blob", 
			params: query
		})
	},
	async changeStatusAdvertisement (advert_id, status) {
		return HTTP.post(
			'/advertisement_new/change_status_advertisement/', 
			{'adver_id': advert_id, 'status': status}
		)
	},
	async getGroupForCreate(supplier_id, url_type) {
		return HTTP.get('/advertisement_new/get_group_products_for_create/', {
			params: {'supplier_id': supplier_id, 'url_type': url_type}
		})
	},
	async getProductsByGroup(supplier_id, url_type, subjects) {
		return HTTP.get('/advertisement_new/get_products_by_group_for_create/', {
			params: {
				'supplier_id': supplier_id, 
				'url_type': url_type,
				'subjects': subjects
			}
		})
	},
	async createAdvertisementCampaign (query) {
		return HTTP.post('/advertisement_new/create_advertisement_campaign/', query)
	},
	async getStatsDataForChart (query) {
		return HTTP.get('/advertisement_new/get_stats_for_chart/', {params: query})
	},
	async changeRobotAutoPayment(id){
		return HTTP.post(`/advertisement_new/change_robot_autopayment/${id}/`)
	},
	async getGeneralRobotKeywords(id){
		return HTTP.get(`/advertisement_new/get_general_robot_keyword/${id}/`)
	},
	async getListProductsForCreateAdverSearch(query){
		return HTTP.get('/advertisement_new/list_products_for_create_advertisement/', {params: query})
	},
	async getSearchPhrasesFromMpstats(query){
		return HTTP.get('/advertisement_new/list_search_phrases_from_mpstats/', {params: query})
	},
	async PrepareProducts(data){
		return HTTP.post('/advertisement_new/prepare_products_for_upload_wb_adver/', data)
	},
	async CreatePluralSearchAdvertisement(json_data){
		return HTTP.post('/advertisement_new/create_plural_search_advertisement_campaign/', json_data)
	},
	async checkStatusToken(){
		return HTTP.post('/advertisement_new/check_advertisement_wb_token/')
	},
	async getOtherAdvertsByTopKeyword(adver_id){
		return HTTP.get(`/advertisement_new/other_adverts_by_top_keyword/${adver_id}/`)
	},
	async toggleFixedMode(adver_id){
		return HTTP.post(`/advertisement_new/toggle_fixed_mode/${adver_id}/`)
	},
	async getAvgPos(adver_id){
		return HTTP.get(`/advertisement_new/get_advertisement_placement_historical/${adver_id}/`)
	},
	async exportAdvertCpmPlacement(query=null){
		return HTTP.get('/advertisement_new/export_adverts_cpm_placement_data/', {
			responseType:"blob", params: query
		})
	},
	async changeAdvertisementName(adver_id, json){
		return HTTP.post(`/advertisement_new/change_advert_name/${adver_id}/`, json)
	},
	async changeParentAdvertisement(adver_id, json){
		return HTTP.post(`/advertisement_new/change_parent_advertisement/${adver_id}/`, json)
	},
	async checkBetsBySearch(query){
		return HTTP.get(`/advertisement_new/check_cpm_advert_search/`, {params: query})
	},
	getSubjectByAccount(account){
		return HTTP.get('/advertisement_new/get_subject_id_by_account/', {params: {"supplier": account}})
	},
	createAutoCampaign(json_data){
		return HTTP.post('/advertisement_new/create_auto_advertisement_campaign/', json_data)
	},
}

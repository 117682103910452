<template>
    <v-card flat class="my-3 elevation-2 flat">
        <v-card-title> 
            Ключевые фразы 
            <v-icon 
                v-if="data.url_type_name=='search'"
                @click="openDialogKeyword"
                class="ml-3"
            > mdi-poll</v-icon>
			<div class="ml-5 d-flex" style="font-size:14px">
					Фиксированные фразы:
					<v-switch
						:loading="loading_change_fixed_mode"
						color="primary"
						class="mt-0 ml-3"
						hide-details
						v-model="data.is_fixed_keyword"
						@change="changeFixedMode(data.is_fixed_keyword)"
					></v-switch>
				</div>
        </v-card-title>
        <v-card-text >
			<div style="display: flex; justify-content: space-between;">
				<div class="current_keyword">
					<h3>Ключевые фразы</h3>
					<div 
						class="list_keyword" 
						v-if="!current_search.show_form" 
						@click="current_search.show_form = true"
					>
						<div 
							v-for="(item, index) in data.all_keyword" 
							:key="index"
							:class="{
								'green_stats': convert_props[item.keyword] > 2, 
								'red_stats': convert_props[item.keyword] == 0, 
								'yellow_stats': convert_props[item.keyword] > 0 && convert_props[item.keyword] <= 2
							}"
						>
							{{item.keyword}}
							{{ isRobotPhraseChange(item.keyword) ? '(М)' : '' }} 
							({{item.count}}) - {{convert_props[item.keyword]}}
						</div>
					</div>
					<div class="current_keyword_form" v-else>
						<v-autocomplete
							v-model="current_search.selected_keyword"
							:items="data.all_keyword"
							item-text="keyword"
							item-value="keyword"
							outlined
							dense
							label="Фразы"
							multiple
							clearable
						>
							<template v-slot:selection="data_main">
								<div v-if='data.all_keyword.length < 20'>{{data_main.item.keyword}}</div>
								<div v-else></div>
							</template>   
						</v-autocomplete>
						<div class="select_all_group">
							<div @click="selectAllInSearch">Выбрать все</div>
							<div 
								@click="current_search.selected_keyword=[]"
							>Снять выделение</div>
						</div>
						<div class="button-group">
							<v-btn 
								depressed 
								style="background-color: #f5763b; color: white;"
								@click="changeExludedKeyword(current_search.selected_keyword, 1)"
								:loading="current_search.preloader"
								:disabled="current_search.selected_keyword != null && current_search.selected_keyword.length == 0"
							>
								Перенести в минус фразы
							</v-btn>
							<v-btn 
								depressed 
								style="background-color: #f5763b; color: white;"
								@click="changeExludedKeyword(current_search.selected_keyword, 12)"
								:loading="current_search.preloader"
								:disabled="current_search.selected_keyword != null && current_search.selected_keyword.length == 0"
							>
								Перенести в фиксированные фразы
							</v-btn>

							<v-btn depressed @click="current_search.show_form = false">
								Отменить
							</v-btn>
						</div>
					</div>
				</div>
				<div class="excluded_keyword">
					<h3>Минус фразы</h3>
					<v-tabs
						background-color="transparent"
						color="basil"
						grow
						class="mt-3"
					>
						<v-tabs-slider color="blue"></v-tabs-slider>

						<v-tab>Из поиска</v-tab>
						<v-tab>Точное соответствие</v-tab>
						<v-tab>Фразовое соответствие</v-tab>
						<v-tab>Фиксированные фразы</v-tab>

						<v-tab-item>
							<div 
								:class="['excluded_keyword_list', {'our_minus': data.is_fixed_keyword}]" 
								v-if="!excluded_keyword_in_search.show_form" 
								@click="excluded_keyword_in_search.show_form = data.is_fixed_keyword ? false: true"
							>
								<div 
									v-for="(item, index) in data.excluded_in_search_keyword" 
									:key="index"
									:class="{
										'green_stats': convert_props[item] > 2, 
										'red_stats': convert_props[item] == 0, 
										'yellow_stats': convert_props[item] > 0 && convert_props[item] <= 2
									}"
								>
									{{item}}{{ isRobotPhraseChange(item) ? '(A)' : '' }} - {{convert_props[item]}}
								</div>
							</div>
							<div class="excluded_keyword_form" v-else>
								<v-autocomplete
									v-model="excluded_keyword_in_search.selected_keyword"
									:items="data.excluded_in_search_keyword"
									item-text="keyword"
									item-value="keyword"
									outlined
									dense
									label="Фразы"
									multiple
									clearable
								>
									<template v-slot:selection="data_main">
										<div v-if='data.excluded_in_search_keyword.length < 20'>{{data_main.item.keyword}}</div>
										<div v-else></div>
									</template>   
								</v-autocomplete>
								<div class="select_all_group">
									<div @click="selectAllExcluded">Выбрать все</div>
									<div 
										@click="excluded_keyword_in_search.selected_keyword=[]"
									>Снять выделение</div>
								</div>
								<div class="button-group">
									<v-btn 
										depressed 
										style="background-color: #f5763b; color: white;"
										@click="changeExludedKeyword(current_search.selected_keyword, 2)"
										:loading="excluded_keyword_in_search.preloader"
										:disabled="excluded_keyword_in_search.selected_keyword != null && excluded_keyword_in_search.selected_keyword.length == 0"
									>
										Вернуть в ключевые фразы
									</v-btn>

									<v-btn depressed @click="excluded_keyword_in_search.show_form = false">
										Отменить
									</v-btn>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div 
								:class="['excluded_keyword_list', {'our_minus': data.is_fixed_keyword}]" 
								v-if="!excluded_keyword_strong.show_form" 
								@click="excluded_keyword_strong.show_form = data.is_fixed_keyword ? false: true"
							>
								<div v-for="(item, index) in data.excluded_strong_keyword" :key="index">
									{{item}}
								</div>
							</div>
							<div class="excluded_keyword_form" v-else>
								<v-textarea
									class="elevation-0"
									outlined
									clearable
									auto-grow
									rows="3"
									col="2"
									v-model="excluded_keyword_strong.selected_keyword"
									label="Введите фразы через запятую без пробелов"
									hide-details
								></v-textarea>
								<div class="button-group">
									<v-btn 
										depressed 
										style="background-color: #f5763b; color: white;"
										@click="changeExludedKeyword(excluded_keyword_strong.selected_keyword, 3)"
										:loading="excluded_keyword_strong.preloader"
									>
										Сохранить
									</v-btn>

									<v-btn depressed @click="excluded_keyword_strong.show_form = false">
										Отменить
									</v-btn>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div 
								:class="['excluded_keyword_list', {'our_minus': data.is_fixed_keyword}]" 
								v-if="!excluded_keyword_phrase.show_form" 
								@click="excluded_keyword_phrase.show_form = data.is_fixed_keyword ? false: true"
							>
								<div v-for="(item, index) in data.excluded_phrase_keyword" :key="index">
									{{item}}
								</div>
							</div>
							<div class="excluded_keyword_form" v-else>
								<v-textarea
									class="elevation-0"
									outlined
									clearable
									auto-grow
									rows="3"
									col="2"
									v-model="excluded_keyword_phrase.selected_keyword"
									label="Введите фразы через запятую c пробелами"
									hide-details
								></v-textarea>
								<div class="button-group">
									<v-btn 
										depressed 
										style="background-color: #f5763b; color: white;"
										@click="changeExludedKeyword(excluded_keyword_phrase.selected_keyword, 4)"
										:loading="excluded_keyword_phrase.preloader"
									>
										Сохранить
									</v-btn>

									<v-btn depressed @click="excluded_keyword_phrase.show_form = false">
										Отменить
									</v-btn>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div 
								:class="['excluded_keyword_list', {'our_minus': !data.is_fixed_keyword}]" 
								v-if="!fixed_phrase.show_form" 
								@click="fixed_phrase.show_form = !data.is_fixed_keyword ? false: true"
							>
								<div 
									v-for="(item, index) in data.fixed_keyword" 
									:key="index"
								>{{item}}</div>
							</div>
							<div class="excluded_keyword_form" v-else>
								<v-autocomplete
									v-model="fixed_phrase.selected_keyword"
									:items="data.fixed_keyword"
									item-text="keyword"
									item-value="keyword"
									outlined
									dense
									label="Фразы"
									multiple
									clearable
								>
									<template v-slot:selection="data_main">
										<div v-if='data.fixed_keyword.length < 20'>{{data_main.item}}</div>
										<div v-else></div>
									</template>   
								</v-autocomplete>
								<div class="select_all_group">
									<div @click="selectAllFixed">Выбрать все</div>
									<div 
										@click="fixed_phrase.selected_keyword=[]"
									>Снять выделение</div>
								</div>
								<div class="button-group">
									<v-btn 
										depressed 
										style="background-color: #f5763b; color: white;"
										@click="changeExludedKeyword(fixed_phrase.selected_keyword, 11)"
										:loading="fixed_phrase.preloader"
										:disabled="fixed_phrase.selected_keyword != null && fixed_phrase.selected_keyword.length == 0"
									>
										Вернуть в ключевые фразы
									</v-btn>

									<v-btn depressed @click="fixed_phrase.show_form = false">
										Отменить
									</v-btn>
								</div>
							</div>
						</v-tab-item>
					</v-tabs>
				</div>
			</div>
			<div style="display: flex; justify-content: space-between;">
				<div class="plus_phrase mt-10">
					<h3 class="d-flex" style="align-items:center;">
						Плюс фразы
						<div class="ml-5 d-flex" style="font-size:14px; align-items:center;">
							Широкое совпадение:
							<v-switch
								color="primary"
								class="mt-0 ml-3"
								hide-details
								v-model="data.plus_phrase_mode"
								@change="changeMode(data.plus_phrase_mode)"
							></v-switch> 
							<v-progress-circular
								v-if="show_preloader_mode"
								:size="15"
								:width="2"
								color="primary"
								indeterminate
							></v-progress-circular>
						</div>
					</h3>
					<div 
						class="plus_phrase_list" 
						v-if="!plus_phrase.show_form" 
						@click="plus_phrase.show_form = true"
					>
						<div v-for="(item, index) in data.plus_phrase" :key="index">
							{{item}}
						</div>
					</div>
					<div class="plus_phrase_form" v-else>
						<v-textarea
							class="elevation-0"
							outlined
							clearable
							auto-grow
							rows="3"
							col="2"
							v-model="plus_phrase.selected_keyword"
							label="Введите фразы через запятую c пробелами"
							hide-details
						></v-textarea>
						<div class="button-group">
							<v-btn 
								depressed 
								style="background-color: #f5763b; color: white;"
								:loading="plus_phrase.preloader"
								@click="changeExludedKeyword(plus_phrase.selected_keyword, 5)"
							>
								Сохранить
							</v-btn>

							<v-btn depressed @click="plus_phrase.show_form = false">
								Отменить
							</v-btn>
						</div>
					</div>
				</div>
				<div :class="[
					'plus_phrase', 
					'mt-10',
					'minus_phrase_container',
					{'our_minus': !data.plus_phrase_mode}
				]">
					<div class="minus_phrase_container_item">
						<h3>Наши минус фразы {{!data.plus_phrase_mode ? "(Недоступно)" : ''}}</h3>
						<div 
							class="plus_phrase_list" 
							v-if="!minus_phrase.show_form" 
							@click="openOurMinusPhrase"
						>
							<div v-for="(item, index) in data.minus_phrase" :key="index">
								{{item}}
							</div>
						</div>
						<div class="plus_phrase_form" v-else>
							<v-textarea
								class="elevation-0"
								outlined
								clearable
								auto-grow
								rows="3"
								col="2"
								v-model="minus_phrase.selected_keyword"
								label="Введите фразы через запятую c пробелами"
								hide-details
							></v-textarea>
							<div class="button-group">
								<v-btn 
									depressed 
									style="background-color: #f5763b; color: white;"
									:loading="minus_phrase.preloader"
									@click="changeExludedKeyword(minus_phrase.selected_keyword, 6)"
								>
									Сохранить
								</v-btn>

								<v-btn depressed @click="minus_phrase.show_form = false">
									Отменить
								</v-btn>
							</div>
						</div>
					</div>
					<div class="minus_phrase_container_item">
						<h3>Наши точные минус фразы {{!data.plus_phrase_mode ? "(Недоступно)" : ''}}</h3>
						<div 
							class="plus_phrase_list" 
							v-if="!minus_phrase_accurate.show_form" 
							@click="openOurMinusPhraseAccurate"
						>
							<div v-for="(item, index) in data.accurate_minus_phrase" :key="index">
								{{item}}
								{{ isRobotPhraseChange(item) ? '(A)' : '' }}
								{{ isRobotPhraseTopFive(item) ? '(A5)': '' }}
							</div>
						</div>
						<div class="plus_phrase_form" v-else>
							<v-textarea
								class="elevation-0"
								outlined
								clearable
								auto-grow
								rows="3"
								col="2"
								v-model="minus_phrase_accurate.selected_keyword"
								label="Введите фразы через запятую c пробелами"
								hide-details
							></v-textarea>
							<div class="button-group">
								<v-btn 
									depressed 
									style="background-color: #f5763b; color: white;"
									:loading="minus_phrase_accurate.preloader"
									@click="changeExludedKeyword(minus_phrase_accurate.selected_keyword, 10)"
								>
									Сохранить
								</v-btn>

								<v-btn depressed @click="minus_phrase_accurate.show_form = false">
									Отменить
								</v-btn>
							</div>
						</div>
					</div>
				</div>
			</div>
        </v-card-text>
    </v-card>
</template>

<script>
import { AdvertisementNew } from "@/api/advertisement_new";

export default {
    name: 'AdvertisementFormChangeKeyword',
    props: ['data', 'data_stats'],
    data(){
        return {
			loading_change_fixed_mode: false,
			general_keywords: [],
			top_five_phrase: [],
			dict_ctr: {},
			show_preloader_mode: false,
            current_search: {
                show_form: false,
                selected_keyword: [],
                preloader: false
            },
            excluded_keyword_in_search: {
                show_form: false,
                selected_keyword: [],
                preloader: false
            },
            excluded_keyword_strong: {
                show_form: false,
                selected_keyword: [],
                preloader: false
            },
            excluded_keyword_phrase: {
                show_form: false,
                selected_keyword: [],
                preloader: false
            },
			plus_phrase: {
				show_form: false,
				selected_keyword: [],
				preloader: false
			},
			minus_phrase: {
				show_form: false,
				selected_keyword: [],
				preloader: false
			},
			minus_phrase_accurate: {
				show_form: false,
				selected_keyword: [],
				preloader: false
			},
			fixed_phrase: {
				show_form: false,
				selected_keyword: [],
				preloader: false
			}
        }
    },
	computed: {
		convert_props(){
			let result = {}
			if (this.data_stats){	
				this.data_stats.forEach(item=>{
					result[item.keyword] = item.ctr
				})
			}
			return result
		},
	},
    methods:{
		isRobotPhraseChange(keyword){
			if (this.general_keywords.indexOf(keyword) != -1) {
				return true
			}
			return false
		},
		isRobotPhraseTopFive(keyword){
			if (this.top_five_phrase.indexOf(keyword) != -1) {
				return true
			}
			return false
		},
		openOurMinusPhrase(){
			if (this.data.plus_phrase_mode)
				this.minus_phrase.show_form = true
		},
		openOurMinusPhraseAccurate(){
			if (this.data.plus_phrase_mode)
				this.minus_phrase_accurate.show_form = true
		},
		async getGeneralRobotKeywords(){
			try{
				const resp = await AdvertisementNew.getGeneralRobotKeywords(this.data.id)
				this.general_keywords = resp.data.manual
				this.top_five_phrase = resp.data.top_five
			} catch (err){
				console.log(err)
			}
		},
        async changeExludedKeyword(keyword_list, type){
            let keywords = []
            if (type == 3){
                this.excluded_keyword_strong.preloader = true
                keywords = keyword_list
            }
            if (type == 4){
                this.excluded_keyword_phrase.preloader = true
                keywords = keyword_list
            }
            if (type == 1 && this.current_search.selected_keyword){
                this.current_search.preloader = true
                keywords = this.data.excluded_in_search_keyword ? this.data.excluded_in_search_keyword : []
                keywords.push(...this.current_search.selected_keyword)
                keywords = keywords.join(',') 
            }
            if (type == 2 && this.excluded_keyword_in_search.selected_keyword){
                this.excluded_keyword_in_search.preloader = true
                keywords = this.data.excluded_in_search_keyword.filter(el => !this.excluded_keyword_in_search.selected_keyword.includes(el))
                keywords = keywords.join(',')
            }
			if (type == 5 && this.plus_phrase.selected_keyword){
				this.plus_phrase.preloader = true
				keywords = keyword_list
			}
			if (type == 6 && this.minus_phrase.selected_keyword){
				this.minus_phrase.preloader = true
				keywords = keyword_list
			}
			if (type == 10 && this.minus_phrase_accurate.selected_keyword){
				this.minus_phrase_accurate.preloader = true
				keywords = keyword_list
			}
			if (type == 11 && this.fixed_phrase.selected_keyword){
				this.fixed_phrase.preloader = true
                keywords = this.data.fixed_keyword.filter(el => !this.fixed_phrase.selected_keyword.includes(el))
                keywords = keywords.join(',')
			}
			if (type == 12 && this.current_search.selected_keyword){
                this.current_search.preloader = true
                keywords = this.data.fixed_keyword ? this.data.fixed_keyword : []
                keywords.push(...this.current_search.selected_keyword)
                keywords = keywords.join(',') 
            }
            try {
                const response = await AdvertisementNew.changeExcludedKeyword(this.data.id, keywords, type)
                if (response.status == 200)
                    this.$emit('succesfullExcludedKeyword')
            } catch (err) {
                console.log(err.data)
            }
            this.excluded_keyword_strong.preloader = false
            this.excluded_keyword_phrase.preloader = false
            this.excluded_keyword_in_search.preloader = false
            this.current_search.preloader = false
			this.plus_phrase.preloader = false
			this.minus_phrase.preloader = false
			this.minus_phrase_accurate.preloader = false
            this.excluded_keyword_in_search.selected_keyword = []
            this.current_search.selected_keyword = []
            this.excluded_keyword_strong.show_form = false
            this.excluded_keyword_phrase.show_form = false
            this.excluded_keyword_in_search.show_form = false
            this.current_search.show_form = false
			this.plus_phrase.show_form = false
			this.minus_phrase.show_form = false
			this.minus_phrase_accurate.show_form = false
			this.fixed_phrase.preloader = false
			this.fixed_phrase.selected_keyword = []
			this.fixed_phrase.show_form = false
        },
        selectAllInSearch(){
            this.current_search.selected_keyword=[]
            this.data.all_keyword.forEach(item => this.current_search.selected_keyword.push(item.keyword))
        },
        selectAllExcluded(){
            this.excluded_keyword_in_search.selected_keyword=[]
            this.data.excluded_in_search_keyword.forEach(item => this.excluded_keyword_in_search.selected_keyword.push(item))
        },
		selectAllFixed(){
			this.fixed_phrase.selected_keyword = []
			this.data.fixed_keyword.forEach(item => this.fixed_phrase.selected_keyword.push(item))
		},
        openDialogKeyword(){
            this.$emit('openDialog')
        },
		async changeMode(mode){
			this.show_preloader_mode = true
			let send_dict = {'plus_phrase_mode': mode}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					this.data.id, 
					send_dict
				)
			} catch (err) {
				console.log(err.response)
			}
			this.show_preloader_mode = false
		},
		async changeFixedMode(fixed){
			this.loading_change_fixed_mode = true
			try{
				await AdvertisementNew.toggleFixedMode(this.data.id)
				this.$toasted.show(`Фиксированные фразы изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
				this.$emit('succesfullExcludedKeyword')
			} catch(err){
				this.$toasted.show(`Ошибка изменения фиксированных фраз`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
			this.loading_change_fixed_mode = false
		}
    },
    async mounted(){
        this.excluded_keyword_strong.selected_keyword = this.data.excluded_strong_keyword ? this.data.excluded_strong_keyword.join(', ') : []
        this.excluded_keyword_phrase.selected_keyword = this.data.excluded_phrase_keyword ? this.data.excluded_phrase_keyword.join(', ') : []
		this.plus_phrase.selected_keyword = this.data.plus_phrase ? this.data.plus_phrase.join(', ') : []
		this.minus_phrase.selected_keyword = this.data.minus_phrase ? this.data.minus_phrase.join(', ') : []
		this.minus_phrase_accurate.selected_keyword = this.data.accurate_minus_phrase ? this.data.accurate_minus_phrase.join(', ') : []
		await this.getGeneralRobotKeywords()
	}
}
</script>

<style lang="scss" scoped>
	.our_minus{
		cursor: not-allowed !important;
		background: rgba(213,213,213, .5);
		color: grey;
		h3{
			cursor: not-allowed !important;
			color: grey;
		}
		
		.plus_phrase_list{
			cursor: not-allowed !important;
			background: rgba(213,213,213, .5);
			color: grey;
		}
	}
    h3{
        color: black;
    }
    .current_keyword, .excluded_keyword, .plus_phrase{
        width: 45%;
    }
    .list_keyword, .excluded_keyword_list, .plus_phrase_list{
        border: 1px solid black;
        height: 300px;  
        padding: 0 10px;
        cursor: pointer;
        margin-top: 60px;
        overflow-y: scroll;
        color: black;
    }
	.plus_phrase_list{
		margin-top: 25px;
	}
    .excluded_keyword_list{
        margin-top: 0;
    }
    .current_keyword_form, .excluded_keyword_form, .plus_phrase_form{
        height: 300px; 
        margin-top: 25px;
        color: black;
    }
    .excluded_keyword_form{
        height: 275px;
		overflow-y: scroll !important;
    }
    .button-group{
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
    }
    .select_all_group{
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
        div{
            cursor: pointer;
        }
        div:hover{
            text-decoration: underline;
        }
    }
	.green_stats{
        background-color: #def3d9;
    }
    .red_stats{
        background-color: #f5d4d7;
    }
    .yellow_stats{
        background-color: #f3ebb9;
    }
	.minus_phrase_container{
		display: flex;
		justify-content: space-between;
		.minus_phrase_container_item{
			width: calc(50% - 10px);
		}
	}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ozon_keywords_component"},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteKeyword()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('BeeGridTable',{staticClass:"mt-5 ozon_keyword_monitoring",attrs:{"loading":_vm.loading,"border":"","showFilter":false,"showPager":false,"height":"380","columns":_vm.keys,"data":_vm.data},scopedSlots:_vm._u([{key:"head",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('div',[_vm._v(_vm._s(row[column.key]))])]}},{key:"delete",fn:function(ref){
var row = ref.row;
return [(!_vm.is_short_version && !_vm.is_competitors && !row.is_general_fields)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDialogDelete(row.text)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"favorite",fn:function(ref){
var row = ref.row;
return [(!_vm.is_short_version && !_vm.is_competitors && !row.is_general_fields)?_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":""},on:{"change":function($event){return _vm.changeFavoriteKeyword(row)}},model:{value:(row.is_favorite),callback:function ($$v) {_vm.$set(row, "is_favorite", $$v)},expression:"row.is_favorite"}}):_vm._e()]}},{key:"text_keyword",fn:function(ref){
var row = ref.row;
return [(!row.is_general_fields)?_c('a',{class:{'error_keyword': _vm.calculate_not_data(row)},attrs:{"href":_vm.urlOzonMonitoring(row.text),"target":"_blank"}},[_vm._v(_vm._s(row.text))]):_c('div',[_vm._v(" "+_vm._s(row.text)+" ")])]}},{key:"position",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(row && row.keyword_monitoring_historical)?_c('div',[(row.is_general_fields || row.is_calculate_color)?_c('div',[_c('span',{class:{'fill_cell_keyword': !row.is_general_fields, 'fill_index': row.is_calculate_color}},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].position_from_mpstats ? row.keyword_monitoring_historical[column.key].position_from_mpstats : '-')+" ")]),(row.keyword_monitoring_historical[column.key].index_day_from_mpstats < 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"red","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].index_day_from_mpstats)+" ")]):(row.keyword_monitoring_historical[column.key].index_day_from_mpstats > 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"success","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].index_day_from_mpstats)+" ")]):_vm._e()],1):_c('div',[(!row.is_general_fields)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!row.keyword_monitoring_historical[column.key])?_c('div',[_vm._v("-")]):_c('div',[_c('span',{class:['fill_cell_keyword', {'fill_index': row.is_calculate_color}]},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].position_from_mpstats ? row.keyword_monitoring_historical[column.key].position_from_mpstats : '-')+" ")]),(row.keyword_monitoring_historical[column.key].is_traforetto)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("T")]):_vm._e(),(row.keyword_monitoring_historical[column.key].index_day_from_mpstats < 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"red","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].index_day_from_mpstats)+" ")]):(row.keyword_monitoring_historical[column.key].index_day_from_mpstats > 0)?_c('v-chip',{staticClass:"mx-1 px-1 font-small",attrs:{"color":"success","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(row.keyword_monitoring_historical[column.key].index_day_from_mpstats)+" ")]):_vm._e()],1)])]}}],null,true)},[_c('div',{staticStyle:{"background":"#fff","text-align":"center"}},[_c('div',{staticStyle:{"color":"black","white-space":"nowrap"}},[_vm._v(" Позиция(с ЛК): "+_vm._s(row.keyword_monitoring_historical[column.key].position_lk)+" ")]),_c('div',{staticStyle:{"color":"black","white-space":"nowrap"}},[_vm._v(" Популярность запроса: "+_vm._s(row.keyword_monitoring_historical[column.key].populatity)+" ")]),(row.keyword_monitoring_historical[column.key].is_traforetto)?_c('div',{staticStyle:{"color":"black","white-space":"nowrap"}},[_vm._v(" Трафарет ")]):_vm._e()])]):_vm._e()],1)]):_c('div',[_vm._v("-")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
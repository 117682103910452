<template>
    <apexchart 
		type="line" 
		height="350" 
		:options="chartOptions"
        :series="seriesSales"
	></apexchart>
</template>

<script>
	import VueApexCharts from 'vue-apexcharts';

	export default {
		name: 'StatsChart',
		components: {
			apexchart: VueApexCharts,
		},
		props: [
			'series',
		],
		data() {
			return {
				chartOptions: {
					chart: {
						height: 350,
						type: 'line',
						zoom: {
							enabled: true,
						},
					},
					animations: {
						enabled: true,
					},
					colors: ['#cd5b45', '#27a327', '#000099', '#db8b00'],
					dataLabels: {
						enabled: false,
					},
					markers: {
						size: [4, 4, 4, 4],
						strokeColors: '#fff',
						strokeWidth: 1,
						strokeOpacity: 0.9,
						strokeDashArray: 1,
					},
					stroke: {
						curve: 'straight',
						width: [3, 3],
					},
					grid: {
						row: {
							colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
							opacity: 0.5,
						},
					},
					xaxis: {
						type: 'datetime',
					},
					yaxis: [
						{
							seriesName: 'Кол-во',
							title: {
								text: 'Кол-во',
								style: {
									color: '#000',
								},
							},
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#000',
							},
							labels: {
								style: {
									colors: '#000',
								},
								formatter: function(val) {
									return val.toFixed(0);
								},
							},
						},
					],
					tooltip: {
						y: {
							formatter: function (val) {
							return val
							}
						}
					}
				},
			};
		},
		computed: {
			seriesSales() {
				return [
					{
						name: 'Просмотры',
						type: 'line',
						data: this.seriesViews,
					},
					{
						name: 'Клики',
						type: 'line',
						data: this.seriesClicks,
					},
					{
						name: 'Добавление в корзину',
						type: 'line',
						data: this.seriesAddBasket,
					},
					{
						name: 'Заказы',
						type: 'line',
						data: this.seriesOrders,
					},
				];
			},
			seriesViews() {
				let result = {}
				let map_result = []
				this.series.forEach(element => {
					if(element.date in result)
						result[element.date] = result[element.date] + element.views
					else{
						result[element.date] = element.views
					}	
				})
				for (const [key, value] of Object.entries(result)){
					map_result.push({x: key, y: value})
				}
				
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesClicks() {
				let result = {}
				let map_result = []
				this.series.forEach(element => {
					if(element.date in result)
						result[element.date] = result[element.date] + element.clicks
					else{
						result[element.date] = element.clicks
					}	
				})

				for (const [key, value] of Object.entries(result)){
					map_result.push({x: key, y: value})
				}
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesAddBasket() {
				let result = {}
				let map_result = []
				this.series.forEach(element => {
					if(element.date in result)
						result[element.date] = result[element.date] + element.add_to_basket
					else{
						result[element.date] = element.add_to_basket
					}	
				})

				for (const [key, value] of Object.entries(result)){
					map_result.push({x: key, y: value})
				}
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesOrders() {
				let result = {}
				let map_result = []
				this.series.forEach(element => {
					if(element.date in result)
						result[element.date] = result[element.date] + element.orders
					else{
						result[element.date] = element.orders
					}	
				})

				for (const [key, value] of Object.entries(result)){
					map_result.push({x: key, y: value})
				}
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
		},
	};
</script>

<style scoped>

</style>
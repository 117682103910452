<template>
  <OzonTransactionsReportsList/>
</template>

<script>
import OzonTransactionsReportsList from '@/components/OzonTransactionsReports/OzonTransactionsReportsList'

export default {
  name: 'OzonTransactionsReports',
  components:{
    OzonTransactionsReportsList,
  },
  mounted () {
    document.title = `Финансовые отчеты OZON | mp.lomex-group.ru`;
    this.$store.commit('setPageTitle', `Финансовые отчеты OZON`)
  }
}
</script>
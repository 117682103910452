<template>
    <v-sheet>
		<v-dialog v-model="dialog_charts" max-width="100%">
			<v-card>
				<v-card-title>График</v-card-title>
				<v-card-text class="mt-5">
					<ChartsDetailProductData 
						:series="summaryDataPerDay"
					/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog_charts=false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-skeleton-loader
                v-if="balancesLoading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <v-card>
                <v-data-table
                        dense
                        :headers="headers"
                        :items="summaryDataPerDay"
                        :items-per-page="20"
                        class="elevation-0 vertical-border small-font"
                >
                    <template v-slot:item.date="{ item }">
                        <div class="d-flex"><span>{{ item.date | formatDateToLocaleNoTime }} </span><v-icon class="mx-2" small color="blue lighten-2" v-if="artificial_sales_dates.includes(item.date)">mdi-account-arrow-down</v-icon></div>
                    </template>
                    <template v-slot:item.stock_balance="{ item }">
                        <div>
                            <div>
                                <a @click="openBalancesHourDialog(item)">{{ item.stock_balance }}</a>
                                <v-chip
                                        v-if="item.fbs"
                                        class="mx-1 px-1"
                                        color="red"
                                        outlined
                                        x-small
                                >
                                    FBS
                                </v-chip>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.quantity_sum="{ item, index }">
						<div style="white-space:nowrap">
							<a 
								v-if="'quantity_sum' in item"
								@click="openStocksApiDialog(item)"
							>
								FBO: {{ item.quantity_sum }}
							</a>
							<div v-else>FBO: -</div>
						</div>
						<div style="white-space:nowrap" v-if='item.stocks_fbs'>
							FBS: {{item.stocks_fbs ? item.stocks_fbs : '-'}} 
						</div>
						<v-chip
							class="mx-1 px-1 my-1"
							color="red"
							outlined
							x-small
							@click="openStocksFBSupdate()"
							v-if="index == 0"
						>
							FBS
						</v-chip>
                    </template>
                    <template v-slot:item.sell="{ item }">
                        <div>{{ Math.abs(item.sell) }}</div>
                    </template>
                    <template v-slot:item.sell_amount="{ item }">
                        <div>{{ Math.abs(item.sell) * item.price }}</div>
                    </template>
                    <template v-slot:item.catalog_count="{ item }">
                        <div v-text="item.catalog_count ? Math.floor(item.catalog_count) : '-'"></div>
                    </template>
                    <template v-slot:item.price_wb_real="{ item }">
                        <div v-if="item.price_wb_real">
                            <a @click="openPriceDialog(item)">
                                {{ item.price_wb_real | toRUB }}
                            </a>
                        </div>
                    </template>
                    <template v-slot:item.parsing_orders_count="{ item }">
                        <div v-if="item.parsing_orders_count"><a @click="openWbParsingOrdersPerDayDialog(item)">{{
                            Math.abs(item.parsing_orders_count) }}</a></div>
                    </template>
                    <template v-slot:item.orders_wb_sum="{ item }">
                        <div v-if="item.parsing_orders_count">{{ item.parsing_orders_sum | toRUB }}</div>
                        <div v-else>-</div>
                    </template>
                    <template v-slot:item.cost_price="{ item }">
                        <div><a class="dashed" @click="openCostPriceDetailsDialog(item)">{{ item.cost_price | toRUB
                            }}</a></div>
                    </template>
                    <template v-slot:item.orders_sum="{ item }">
                        <div v-if="item.orders_sum || item.fbs_orders_sum">
                            <v-tooltip bottom max-width=350>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        <a href="#" class="dashed">{{ calcOrdersSum(item) | toRUB }}</a>
                                    </div>
                                </template>
                                <span>
                                    <div>
                                        FBO: {{item.orders_sum | toRUB}}
                                    </div>
                                    <div>
                                        FBS: {{item.fbs_orders_sum | toRUB}}
                                    </div>
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.orders_count="{ item }">
                        <div v-if="item.orders_count || item.fbs_orders_count">
                            <v-tooltip bottom max-width=350>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        <a href="#" class="dashed">{{ calcOrdersCount(item) }}</a>
                                    </div>
                                </template>
                                <span>
                                    <div>
                                        FBO: {{item.orders_count}}
                                    </div>
                                    <div>
                                        FBS: {{item.fbs_orders_count}}
                                    </div>
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.redemption_sum="{ item }">
                        <div v-if="item.redemption_sum">{{ item.redemption_sum | toRUB }}</div>
                    </template>
                    <template v-slot:item.sales_sum="{ item }">
                        <div v-if="item.sales_sum">{{ item.sales_sum | toRUB }}</div>
                    </template>
                    <template v-slot:item.report_sales_sum="{ item }">
                        <div v-if="item.report_sales_sum">{{ item.report_sales_sum | toRUB }}</div>
                    </template>
                    <template v-slot:item.vp="{ item }">
                        <div v-text="calcGrossProfit(item)"></div>
                    </template>
                    <template v-slot:item.vp_single="{ item }">
                        <div v-text="calcGrossProfitSingle(item)"></div>
                    </template>
                    <template v-slot:item.feedbacks_db_count="{ item }">
                        <div>
                            <span>{{ item.feedbacks_db_count }}</span>
                            <v-chip
                                    v-if="item.feedbacks_new"
                                    class="mx-1 px-1"
                                    color="success"
                                    outlined
                                    x-small
                            >
                                +{{ item.feedbacks_new }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.orders_speed_14="{ item }">
                        <div v-if="item.orders_speed_14">
                            <div v-text="item.orders_speed_14.toFixed(2)"></div>
                        </div>
                    </template>
                    <template v-slot:item.lost_proceed_due_purchase="{ item }">
                        <div v-if="item.lost_proceed_due_purchase">{{ item.lost_proceed_due_purchase | toRUB }}</div>
                    </template>
                    <template v-slot:item.lost_proceed="{ item }">
                        <div v-if="item.lost_proceed">{{ item.lost_proceed | toRUB }}</div>
                    </template>
					<template v-slot:item.advertisement_expenditure="{ item }">
                        <div v-if="item.advertisement_expenditure">{{ item.advertisement_expenditure | toRUB }}</div>
                    </template>
                </v-data-table>
            </v-card>
            <v-dialog
                    v-model="priceDialog"
                    max-width="600"
                    v-if="priceDialog"
                    scrollable
            >
                <v-card>
                    <v-card-title class="text-h5">
                        История за {{activePriceDay.date | formatDateToLocaleNoTime}}
                    </v-card-title>
                    <div style="height: 400px;">
                        <PriceInTheMomentTable 
                            :product_id="product_id"
                            :date="activePriceDay.date">
                        </PriceInTheMomentTable>
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                    v-model="costPriceDetailsDialog"
                    width="1000"
                    v-if="costPriceDetailsDialog"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Себестоимость на {{ activeCostPriceDetailsDay.date | formatDateToLocaleNoTime}}
                    </v-card-title>
                    <div style="height: 700px;">
                        <CostPriceDetails :catalog_item_id="catalog_item_id"
                                          :date="activeCostPriceDetailsDay.date"></CostPriceDetails>
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                    v-model="balancesDialog"
                    max-width="600"
                    v-if="balancesDialog"
                    scrollable
            >
                <v-card>
                    <v-card-title class="text-h5">
                        История за {{activeBalanceDay.date | formatDateToLocaleNoTime}}
                    </v-card-title>
                    <div style="height: 400px;">
                        <ProductStockBalancesHours :product_id="product_id"
                                                   :date="activeBalanceDay.date"></ProductStockBalancesHours>
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                    v-model="stocksDialog"
                    max-width="600"
                    v-if="stocksDialog"
                    scrollable
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Склады на {{activeStocksDay.date | formatDateToLocaleNoTime}}
                    </v-card-title>
                    <div style="height: 400px;">
                        <ProductWarehouses :product_id="product_id" :date="activeStocksDay.date"></ProductWarehouses>
                    </div>
                </v-card>
            </v-dialog>
			<v-dialog
				v-model="dialog_stocks_fbs"
				max-width="600"
				v-if="dialog_stocks_fbs"
				scrollable
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Обновление остатка fbs на сегодня
                    </v-card-title>
                    <div style="height: 400px;">
                        <ProductStockFBSUpdate 
							:product_id="product_id" 
							:variations_all="variations_all"
							:current_stock="summaryDataPerDay[0].stocks_fbs"
							:main_variation="variations_id"
							@successUpdateStock="successUpdateStock"
						></ProductStockFBSUpdate>
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                    v-model="wbParsingOrdersPerDayDialog"
                    max-width="600"
                    v-if="wbParsingOrdersPerDayDialog"
                    scrollable
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Заказы на {{activeStocksDay.date | formatDateToLocaleNoTime}}
                    </v-card-title>
                    <div style="height: 400px;">
                        <ProductWbParsingOrdersPerDay :product_id="product_id"
                                                      :date="activeStocksDay.date"></ProductWbParsingOrdersPerDay>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
	import {Products} from '@/api/products';
	import ProductStockBalancesHours from '@/components/Product/ProductStockBalancesHours';
	import ProductWarehouses from '@/components/Product/ProductWarehouses';
	import ProductWbParsingOrdersPerDay from '@/components/Product/ProductWbParsingOrdersPerDay';
	import CostPriceDetails from './CostPriceDetails';
    import PriceInTheMomentTable from './PriceInTheMomentTable';
	import ChartsDetailProductData from '@/components/Product/ChartsDetailProductData'
	import ProductStockFBSUpdate from '@/components/Product/ProductStockFBSUpdate'
	export default {
		name: 'ProductStockBalances',
		props: {
			product_id: {
				type: String,
			},
			variations_id: {
				type: Number,
			},
			catalog_item_id: {
				type: String,
			},
			artificial_sales_dates: {
				type: Array,
                required: false
            },
			variations_all: {
				type: Array,
			}
		},
		components: {
			CostPriceDetails,
			ProductStockBalancesHours,
			ProductWarehouses,
			ProductWbParsingOrdersPerDay,
            PriceInTheMomentTable,
			ChartsDetailProductData,
			ProductStockFBSUpdate
		},
		watch: {
			async variations_id(val) {
				if (this.first_load)
					this.first_load = false;
				else
					await this.fetchProductStockBalances();
			},
		},
		data() {
			return {
				dateFrom: null,
				dateTo: null,
				dialog_charts: false,
				first_load: true,
				activeBalanceDay: null,
				balancesDialog: false,
				wbParsingOrdersPerDayDialog: false,
				stocksDialog: false,
				dialog_stocks_fbs: false,
                priceDialog: false,
                activePriceDay: null,
				costPriceDetailsDialog: false,
				activeCostPriceDetailsDay: null,
				summaryDataPerDay: null,
				balancesLoading: true,
				variation_choice: null,
				warehousesChoice: ['склад wb', 'склад продавца'],
				table_filters: {
					dates: {
						from: null,
						to: null,
					},
					search: '',
					warehouse: '',
				},
				headers: [
					{
						text: 'Дата',
						align: 'start',
						sortable: true,
						value: 'date',
					},
					//{text: 'Видимость', value: 'product_monitoring_visibility'},
					{text: 'Остаток WB', value: 'stock_balance'},
					{text: 'Остаток API', value: 'quantity_sum'},
					{text: 'Остаток 1С', value: 'catalog_count'},
					{text: 'Цена продажи', value: 'price_wb_real'},
					{text: 'Себестоимость', value: 'cost_price'},
					{text: 'Заказы WB', value: 'parsing_orders_count'},
					{text: 'Сумма', value: 'orders_wb_sum'},
					{text: 'Заказы API', value: 'orders_count'},
					{text: 'Скор. заказов', value: 'orders_speed_14'},
					{text: 'Сумма', value: 'orders_sum'},
					{text: 'Сумма выкупов', value: 'redemption_sum'},
					{text: 'Расходы реклама', value: 'advertisement_expenditure'},
					{text: 'Клики реклама', value: 'advertisement_clicks'},
					{text: 'Продажи API', value: 'sales_count'},
					{text: 'Сумма', value: 'sales_sum'},
					{text: 'Продажи отчет', value: 'report_sales_count'},
					{text: 'Сумма отчет', value: 'report_sales_sum'},
					{text: 'ВП общ.', value: 'vp'},
					{text: 'ВП шт.', value: 'vp_single'},
					{text: 'Отзывы БД', value: 'feedbacks_db_count'},
					{text: 'Отзывы сайт', value: 'snapshot_feedbacks_count'},
					{text: 'Рейтинг', value: 'rating'},
					{text: 'УВз', value: 'lost_proceed_due_purchase'},
					{text: 'УВо', value: 'lost_proceed'},
				],
			};
		},
		computed: {
			isSingleVariation() {
				return this.summaryDataPerDay.length === 1;
			},
		},
		methods: {
			async successUpdateStock(){
				this.dialog_stocks_fbs = false
				await this.fetchProductStockBalances()
			},
			openStocksFBSupdate(){
				this.dialog_stocks_fbs = true;
			},
			openDialogCharts(){
				this.dialog_charts = true
			},
			calcOrdersSum(item) {
				return (item.orders_sum || 0) + (item.fbs_orders_sum || 0)
            },
			calcOrdersCount(item) {
				return (item.orders_count || 0) + (item.fbs_orders_count || 0)
			},
			calcGrossProfit(item) {
				return item.report_sales_sum ? this.$options.filters.toRUB(item.report_sales_sum - (item.cost_price * item.report_sales_count) - item.delivery_sum -item.comission_sum) : '-';
			},
			calcGrossProfitSingle(item) {
				return item.report_sales_sum ? this.$options.filters.toRUB((item.report_sales_sum - (item.cost_price * item.report_sales_count) - item.delivery_sum -item.comission_sum) / item.report_sales_count) : '-';
			},
			openBalancesHourDialog(item) {
				this.activeBalanceDay = item;
				this.balancesDialog = true;
			},
            openPriceDialog(item) {
				this.activePriceDay = item;
				this.priceDialog = true;
			},
			openStocksApiDialog(item) {
				this.activeStocksDay = item;
				this.stocksDialog = true;
			},
			openCostPriceDetailsDialog(item) {
				this.activeCostPriceDetailsDay = item;
				this.costPriceDetailsDialog = true;
			},
			openWbParsingOrdersPerDayDialog(item) {
				this.activeStocksDay = item;
				this.wbParsingOrdersPerDayDialog = true;
			},
			async fetchProductStockBalances() {
				this.balancesLoading = true;
				try {
					const res = await Products.fetchProductBalances(this.product_id, {
							'variations_id': this.variations_id != 0 ? this.variations_id : '',
						},
					);
					this.summaryDataPerDay = res.data.data;
				} catch (err) {
					console.log(err);
				}
				this.balancesLoading = false;
			},
		},
		async mounted() {
			await this.fetchProductStockBalances();
		},
	};
</script>

<style scoped>

</style>

<template>
    <OzonRedemptionMain/>
</template>

<script>
import OzonRedemptionMain from '@/components/RedemptionOzon/OzonRedemptionMain.vue';

export default {
	name: 'RedemptionOzon',
	components: {
		OzonRedemptionMain,
	},
	mounted() {
		document.title = 'Выкупы Ozon | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Выкуп товаров ozon`);
	},
};
</script>

<style scoped>

</style>
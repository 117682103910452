<template>
	<v-data-table
		:headers="headers" :items="data" :loading="loading" class="elevation-1"
	>
		<template v-slot:item.price="{ item }">
			{{ item.price | toRUB}}
		</template>
		<template v-slot:item.price_with_base_sale="{ item }">
			{{ item.price_with_base_sale | toRUB}}
		</template>
		<template v-slot:item.price_with_spp="{ item }">
			{{ item.price_with_spp | toRUB}}
		</template>
		<template v-slot:item.base_sale="{ item }">
			{{ item.base_sale ? item.base_sale : 0 }}%
		</template>
		<template v-slot:item.spp="{ item }">
			{{ item.spp ? item.spp : 0 }}%
		</template>
		<template v-slot:item.orders_sum="{ item }">
			{{ item.orders_sum | toRUB}}
		</template>
		<template v-slot:item.brand="{ item }">
			<div>{{ item.seller }}</div>
			<hr>
			<dir>{{ item.brand }}</dir>
		</template>
		<template v-slot:item.categories="{ item }">
			<v-tooltip bottom max-width=350 v-if="item.categories_count">
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on">
						<a href="#" class="dashed">{{item.categories_count}}</a>
					</div>
				</template>
				<span>
					<div class="small-text" v-for="(category, i) in item.categories" :key="i">
						{{ category }}
					</div>
				</span>
			</v-tooltip>
			<div v-else>0</div>
		</template>
		<template v-slot:item.stocks="{ item }">
			<v-tooltip bottom max-width=350 v-if="item.stocks">
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on">
						<a href="#" class="dashed">{{item.stocks}}</a>
					</div>
				</template>
				<span>
					<div class="small-text" v-for="(stock, i) in item.stocks_by_warehouse" :key="i">
						{{ stock.warehouse_name }} - {{ stock.stocks }}
					</div>
				</span>
			</v-tooltip>
			<div v-else>0</div>
			<v-chip 
				v-if="item.is_fbs" class="mx-1 px-1 small-text" color="red" outlined x-small
			>fbs</v-chip>
		</template>
		<template v-slot:item.promotion="{ item }">
			<v-tooltip bottom max-width=350 v-if="item.promotion_parser">
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on" style="cursor: pointer;">
						<v-icon small color="green">mdi-checkbox-marked-circle</v-icon>
					</div>
				</template>
				<span>
					{{ item.promotion_parser }}
				</span>
			</v-tooltip>
			<div v-else>Нет акции</div>
		</template>
	</v-data-table>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "CompetitorsDetailDialog",
    props: {nmid: {type: String}},
    data () {
		return {
			loading: false,
			data: [],
			headers: [
				{text: 'Дата', sortable: true, value: 'date'},
				{text: 'Продавец/бренд', sortable: false, value: 'brand'},
				{text: 'Цена', sortable: true, value: 'price'},
				{text: 'Базовая скидка', sortable: true, value: 'base_sale'},
				{text: 'Цена с базовой скидкой', sortable: true, value: 'price_with_base_sale'},
				{text: 'СПП', sortable: true, value: 'spp'},
				{text: 'Цена с СПП', sortable: true, value: 'price_with_spp'},
				{text: 'Сумма продаж', sortable: true, value: 'orders_sum'},
				{text: 'Кол-во продаж', sortable: true, value: 'orders_count'},
				{text: 'Отзывов', sortable: true, value: 'feedbacks'},
				{text: 'Рейтинг', sortable: true, value: 'rating'},
				{text: 'Видимость', sortable: true, value: 'visibility'},
				{text: 'Средняя позиция', sortable: true, value: 'avg_pos'},
				{text: 'Категории', sortable: true, value: 'categories'},
				{text: 'Остатки', sortable: true, value: 'stocks'},
				{text: 'Акция', sortable: false, value: 'promotion'}
			]
		}
    },
    methods: {
		async fetchDetailCompetitorsData(){
			this.loading = true
			try{
				const response = await Products.fetchDetailCompetitorsData(this.nmid)
				this.data = response.data
			} catch(err){
				console.log(err)
			}
			this.loading = false
		}
    },
    async mounted () {
		await this.fetchDetailCompetitorsData()
    }
  }
</script>
<template>
	<ManagementProductListComponent/>
</template>

<script>
	import ManagementProductListComponent from '@/components/ManagementWbProduct/ManagementProductListComponent'

	export default {
		name: 'ManagementWbProductList',
		components:{
			ManagementProductListComponent
		},
		mounted () {
			document.title = `Управление ценой (Создание шаблона)`;
			this.$store.commit('setPageTitle', `Управление ценой (Создание шаблона)`)
		}
	}
</script>

<style scoped>

</style>
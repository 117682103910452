<template>
    <v-data-table 
        :headers="headers_historical"
        :items="historical_data"
        :loading="loading"
        class="elevation-0 mt-0 small-font vertical-border"
    ></v-data-table>
</template>

<script>
	import {Products} from '@/api/products';

	export default {
		name: 'CatalogMpHistory',
        props: ['nmid', 'mid'],
		data() {
			return {
				loading: [],
				headers_historical: [
					{ text: "Дата", value: "date", sortable: true, width: "1%" },
					{ text: "Источник", value: "source", sortable: true, width: "1%" },
					{ text: "Тип", value: "type_action", sortable: true, width: "1%" },
					{ text: "Старое значение", value: "old_value", sortable: true, width: "1%" },
					{ text: "Новое значение", value: "new_value", sortable: true, width: "1%" },
				],
				historical_data: []
			};
		},
		methods: {
			async fetch_data() {
				this.loading = true;
                let json_data = {}
                if (this.nmid){
                    json_data['nmid'] = this.nmid
                } else {
                    json_data['mid'] = this.mid
                }
                try{
                    const response = await Products.fetchCatalogMPHistory(json_data);
                    this.historical_data = response.data;
                } catch(err){
                    console.log(err)
                }
				this.loading = false;
			},
		},
		async mounted() {
            await this.fetch_data()
		},
	};
</script>

<template>
    <div>
        <FeedbacksList/>
    </div>
</template>

<script>
  import FeedbacksList from '@/components/FeedbacksList'

  export default {
    name: 'Feedbacks',
    components: { FeedbacksList },
    mounted () {
      this.$store.commit('setPageTitle', `Список отзывов`)
    }
  }
</script>

<style scoped lang="scss">

</style>

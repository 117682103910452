<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<v-dialog 
			v-model="dialog" 
			max-width="500px"
			v-if="dialog"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Изменение ставки {{edit_item.query_id}}</span>
				</v-card-title>
				<v-card-text>
					<div style="display: flex">
						<v-text-field
							v-model="new_keyword_bid"
							label="Ставка"
							outlined
							type="number"
							dense
							clearable
						></v-text-field>
						<v-btn
							@click="changeKeywordBid"
							depressed
							color="purple darken-2"
							dark
							class="ml-5"
							:loading="btn_loading_keyword"
						>Изменить</v-btn>
					</div>
				</v-card-text>
			</v-card>    
		</v-dialog>
		<v-card class="mx-auto" flat>
			<v-card-text>
				<div class="d-flex">
					<div class="title">
						({{product_id}}) {{advertisement_id}}
					</div>
				</div>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Поисковые фразы</v-card-title>
			<v-card-text class="pb-0">
				<div style="display: flex; max-width: 500px">
					<v-text-field
						v-model="general_bid"
						label="Общая ставка"
						outlined
						type="number"
						dense
						clearable
					></v-text-field>
					<v-btn
						@click="changeGeneralBid"
						depressed
                        color="purple darken-2"
                        dark
						class="ml-5"
						:loading="btn_loading"
					>Изменить</v-btn>
				</div>
				<v-data-table
					:headers="table.headers"
					:items="table.data"
					:loading="table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto mt-5"
					disable-pagination
				>
					<template v-slot:item.edit="{ item }">
						<v-icon
							small
							class="mr-2"
							@click="editItem(item)"
						>
							mdi-pencil
						</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'

export default {
	name: "OzonAdvertisementDetail",
	data() {
		return {
			btn_loading_keyword: false,
			new_keyword_bid: null,
			dialog: false,
			edit_item: null,
			general_bid: null,
			btn_loading: false,
			table: {
				loading: false,
				headers: [
					{ text: 'id запроса', value: 'query_id', sortable: false, width: '1%' },
					{ text: 'Запрос', value: 'query', sortable: false, width: '1%' },
					{ text: 'Ставка', value: 'bid', sortable: false, width: '1%' },
					{ text: 'Изменить', value: 'edit', sortable: false, width: '1%' },
				],
				data: []
			},
		}
	},
	computed: {
		advertisement_id() {
			return this.$route.params.campaign_id
		},
		product_id(){
			return this.$route.params.product_id
		}
	},
	props: ["data"],
	methods: {
		editItem(item){
			this.dialog = true
			this.edit_item = item
			this.new_keyword_bid = item.bid
		},
		async getAdverKeywords() {
			this.table.loading = true
			try {
				let response = await OzonAdvertisement.getKeywordsData(
					this.advertisement_id, this.product_id
				);
				this.table.data = response.data.data;
				if(response.data){
					this.general_bid = response.data.general_bid
				}
			} catch (err) {
				console.error(err.response);
			}
			this.table.loading = false
		},
		async changeGeneralBid(){
			this.btn_loading = true
			try {
				let response = await OzonAdvertisement.changeGeneralBid(
					this.product_id, this.general_bid
				);
				this.$toasted.show(`Ставка обновлена`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
				await this.getAdverKeywords()
			} catch (err) {
				console.error(err.response);
			}
			this.btn_loading = false
		},
		async changeKeywordBid(){
			this.btn_loading_keyword = true
			try {
				let response = await OzonAdvertisement.changeKeywordBud(
					this.product_id, this.new_keyword_bid, this.edit_item.id 
				);
				this.$toasted.show(`Ставка обновлена`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
				this.dialog = false
				await this.getAdverKeywords()
			} catch (err) {
				console.error(err.response);
			}
			this.btn_loading_keyword = false
		}
	},
	async mounted() {
		document.title = `Ставки по поисковым фразам| ${this.advertisement_id} | mp.lomex-group.ru`;
		this.$store.commit("setPageTitle", `Ozon Рекламная кампания ${this.advertisement_id}`);
		await this.getAdverKeywords()
	}
}
</script>

<style lang="scss" scoped>
.general_bid{
	font-size: 24px;
}
</style>
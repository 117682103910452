var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"ml-3",attrs:{"target":"_blank","to":{name: 'ozon_management_product_create'}}},[_c('v-btn',{staticClass:"mt-5 ml-5",attrs:{"color":"primary","dark":""}},[_vm._v("Создать новый шаблон")])],1),_c('v-data-table',{staticClass:"elevation-0 mt-3 vertical-border",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"pageCount":_vm.table.pagination.numberOfPages,"options":_vm.table.options,"items-per-page":50,"server-items-length":_vm.table.pagination.totalItems,"footer-props":{'items-per-page-options': [5, 10, 25, 50, 100]}},on:{"update:page":_vm.fetch_list_templates,"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":{ name: 'ozon_management_product_detail', params: { template_id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateToLocale")(item.date_created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.delete_template(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"item.auto_update",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","dense":""},on:{"change":function($event){return _vm.change_auto_update(item)}},model:{value:(item.is_auto_update),callback:function ($$v) {_vm.$set(item, "is_auto_update", $$v)},expression:"item.is_auto_update"}})]}},{key:"item.is_open",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","dense":""},on:{"change":function($event){return _vm.change_is_open(item)}},model:{value:(item.is_open),callback:function ($$v) {_vm.$set(item, "is_open", $$v)},expression:"item.is_open"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
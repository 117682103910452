<template>
    <v-container fluid>
		<v-card max-width="1200" style="margin: 25px auto 0 auto;">
            <v-card-title>Подбор аккаунта для выкупа</v-card-title>	
			<v-card-text>
				<div>
					<div class="title_block text-center">Выбор товара</div>
					<v-row class="mt-2">
						<v-col cols="12" md="3">
							<v-autocomplete
								v-model="form.supplier" :items="choices_data.wb_accounts"
								item-text="name" item-value="id" label="Выберите личный кабинет"
								outlined hide-details dense clearable
							></v-autocomplete>
						</v-col>
						<v-col cols="12" md="2">
							<v-text-field
								clearable @click:clear="search.name = null" v-model="search.name"
								label="Введите название" hide-details @change="getProducts"
								outlined dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="2">
							<v-text-field
								clearable @click:clear="search.nmid = null"
								v-model="search.nmid" label="Введите артикул" hide-details
								outlined @change="getProducts" dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-autocomplete
								v-model="form.main_product" :items="choices_data.list_products"
								item-text="text" item-value="id" :loading="loading_products"
								label="Выберите товар" outlined hide-details dense clearable
							></v-autocomplete>
						</v-col>
						<v-col cols="12" md="1">
							<v-btn 
								depressed color="purple darken-2" dark @click="getProducts"
							>Загрузить</v-btn>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<div class="mt-5" v-if="form.main_product">
					<div class="title_block text-center">Дополнительные фильтры</div>
					<v-row class="mt-2 align-center d-flex">
						<v-col cols="12" md="5">
							<v-text-field
								clearable @click:clear="form.address_pvz = null" outlined dense
								v-model="form.address_pvz" label="Введите адресс ПВЗ" hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12" md="5">
							<v-autocomplete
								v-model="form.manager" :items="choices_data.managers"
								item-text="username" item-value="username" label="Менеджер"
								outlined hide-details dense clearable @click:clear="form.manager = null"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" md="2">
							<v-checkbox
								hide-details v-model="form.is_seedling"
								label="Рассада?" class="mt-0"
							></v-checkbox>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<div class="mt-5" v-if="form.main_product">
					<v-btn 
						depressed color="purple darken-2" dark 
						@click="selectionOfAccount" :loading="table.loading"
					>Получить список аккаунтов</v-btn>
				</div>
				<div>
					<v-data-table
						:headers="table.headers"
						:items="table.data"
						:loading="table.loading"
						class="elevation-1 mt-0 vertical-border small-font"
						hide-default-footer
						disable-pagination
						multi-sort
					>
					<template v-slot:item.last_buy="{ item }">
						{{ item.last_buy | formatDateToLocaleNoTime }}
					</template>
					<template v-slot:item.is_virtual_sim_card="{ item }">
						{{ item.is_virtual_sim_card ? 'да' : 'нет' }}
					</template>
					<template v-slot:item.action="{ item }">
						<v-btn icon @click="changeLastDateSelection(item.phone)">
							<v-icon>mdi-plus-circle-outline</v-icon>
						</v-btn>
					</template>
					</v-data-table>
				</div>
			</v-card-text>			
        </v-card>
	</v-container>
</template>

<script>
import { AdvertisementNew } from '@/api/advertisement_new'
import { General } from '@/api/general'
import { Redemption } from '@/api/redemption'

export default {
    name: 'RedemptionSelectionAccount',
	data(){
		return {
			table:{
				headers: [
					{ text: "Телефон", value: "phone", sortable: false, width: "2%" },
					{ text: "ФИО профиля", value: "last_account_buyer", sortable: false, width: "2%" },
					{ text: "Кол-во выкупов", value: "count_redemption", sortable: true, width: "1%" },
					{ text: "Последний выкуп", value: "last_buy", sortable: true, width: "1%" },
					{ text: "Последний ПВЗ", value: "last_pvz", sortable: false, width: "1%" },
					{ text: "Перерыв между выкупами", value: "delta_days", sortable: true, width: "1%" },
					{ text: "Последний бренд", value: "last_brand", sortable: false, width: "1%" },
					{ text: "Последний ЛК", value: "last_lk", sortable: false, width: "1%" },
					{ text: "Виртуальная сим карта", value: "is_virtual_sim_card", sortable: true, width: "1%" },
					{ text: 'Действия', value: 'action', sortable: false, width: "1%"}

				],
				data: [],
				loading: false
			},
			loading_products: false,
			search: {
				nmid: null,
				name: null,
			},
			form: {
				supplier: null,
				main_product: null,
				address_pvz: null,
				manager: null,
				is_seedling: false
			},
			choices_data: {
				wb_accounts: [],
				list_products: [],
				managers: []
			}
		}
	},
	methods: {
		async changeLastDateSelection(phone){
			try{
				await Redemption.changeLastDateSelection(phone)
				await this.selectionOfAccount()
			} catch(err){
				console.log(err)
			}
		},
		phoneMask(phone){
            if(phone)
                return "+7(" + phone.substring(1, 4) + ") " + phone.substring(4, 7) + "-" + phone.substring(7,9) + "-" + phone.substring(9,11) 
            return 
		},
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.choices_data.wb_accounts = response.data
        },
		async getProducts (){
			try{
				this.loading_products = true
				let query = {}
				if (this.form.supplier)
					query["wbaccount"] = this.form.supplier
				if(this.search.nmid)
					query['nmid'] = this.search.nmid
				if (this.search.name)
					query['name'] = this.search.name
				const resp = await AdvertisementNew.getListProductsForCreateAdverSearch(query)
				this.choices_data.list_products = resp.data
			} catch(err){
				console.log(err)
			}
			this.loading_products = false
		},
		async selectionOfAccount(){
			this.table.loading = true
			let json_data = {"product_nmid": this.form.main_product}
			if (this.form.address_pvz)
				json_data["address_pvz"] = this.form.address_pvz
			if (this.form.manager)
				json_data["manager"] = this.form.manager
			if (this.form.is_seedling)
				json_data["is_seedling"] = this.form.is_seedling
			
			try{
				const resp = await Redemption.selectionOfAccount(json_data)
				this.table.data = resp.data.accounts
			} catch (err){
				console.log(err)
			}
			this.table.loading = false
		},
		async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.choices_data.managers = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
	},
	async mounted(){
		await this.fetchWbAccounts()
		await this.getManagers()
	}
}
</script>

<style lang="scss" scoped>
	.title_block{
		color: #000;
		font-weight: bold;
	}
	hr{
		height: 1px;
		background-color: #000;
	}
	.check{
		border: 1px dashed black;
		margin-top: 25px;
	}
</style>
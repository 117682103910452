<template>
    <v-container fluid class="pa-10" style="height: 100%">
      <v-dialog
          v-model="addModalOpened"
          max-width="400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mb-2 primary"
              small
              v-bind="attrs"
              v-on="on"
          >Добавить позицию</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Добавление продукта</span>
          </v-card-title>
          <v-card-text>
                  <v-text-field
                      label="Артикул WB *"
                      outlined
                      v-model="addProductData.nmid"
                      type="number"
                      dense
                      crealable
                      small
                      required
                  ></v-text-field>
                  <v-text-field
                      label="Группа"
                      outlined
                      v-model="addProductData.group"
                      crealable
                      dense
                      small
                  ></v-text-field>
              <v-alert
                  type="error"
                  v-if="addProductErrorText"
              >
                {{addProductErrorText}}
              </v-alert>
                  <v-btn color="green" :loading="addLoading" dark @click="addProduct">Добавить</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-btn
          class="mb-2 primary ml-1"
          small
          :loading="checkLoading"
          @click="runCheck"
      >Запустить проверку</v-btn>
        <price-monitoring-products-data-table ref="productsDataTable"/>
    </v-container>
</template>

<script>
  import PriceMonitoringProductsDataTable from '@/components/PriceMonitoring/PriceMonitoringProductsDataTable.vue';
  import {PriceMonitoring} from '@/api/price_monitoring'

	export default {
		name: 'PriceMonitoringPage',
    components: {PriceMonitoringProductsDataTable},
		data() {
			return {
        addModalOpened: false,
        addProductData: {
          nmid: null,
          group: null,
        },
        addProductErrors: [],
        addLoading: false,
        checkLoading: false
      };
		},
    computed: {
      addProductErrorText () {
        let text = ''
        if (this.addProductErrors) {
          for (const [k, v] of Object.entries(this.addProductErrors)) {
            text = text.concat(v, '\n')
          }
        }
        return text
      },
      addProductQuery () {
        let filteredAddProductData = {}
        for (const [k, v] of Object.entries(this.addProductData)) {
          if (v) {
            filteredAddProductData[k] = v
          }
        }
        return filteredAddProductData
      }
    },
		mounted() {
			document.title = "[WB] Мониторинг цен | mp.lomex-group.ru";
			this.$store.commit('setPageTitle', `[WB] Мониторинг цен`);
		},
		methods: {
      async addProduct() {
        this.addProductErrors = []
        try {
          this.addLoading = true
          await PriceMonitoring.add(this.addProductQuery)
          this.addModalOpened = false
          await this.$refs.productsDataTable.fetchData()
          this.addProductData.nmid = ''
        } catch (e) {
          this.addProductErrors = e.response.data
        } finally {
          this.addLoading = false
        }
      },
      async runCheck() {
        this.checkLoading = true
        try {
          await PriceMonitoring.runCheck()
          await this.$refs.productsDataTable.fetchData()
        } catch (err) {
          console.log(err)
        }
        this.checkLoading = false
      }
		},
	};
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 mt-1 vertical-border",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.table.loading,"items-per-page":14,"footer-props":{'items-per-page-options': [7, 14, 30, 60, -1]}},scopedSlots:_vm._u([{key:"item.summary_score",fn:function(ref){
var item = ref.item;
return [(item.is_empty_product)?_c('div'):(item.is_traforetto)?_c('div',{staticStyle:{"text-align":"center","color":"#005bff"}},[_vm._v("Трафарет")]):_c('div',{staticStyle:{"text-align":"center"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.final_result.toFixed(3)))]),(item.search_promotion_enabled)?_c('v-chip',{staticClass:"deep-purple--text",attrs:{"small":""}},[_vm._v(" Поднятие на "+_vm._s((item.search_promotion_boost * 100).toFixed(3))+"% ")]):_vm._e()],1)]}},{key:"item.query_fit_score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.query_fit_score ? item.query_fit_score : 0)+" балла ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('p',{staticClass:"mb-0",staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" "+_vm._s(item.rating.toFixed(1))+" ("+_vm._s(item.rates_count)+" шт.) ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.price_score)+" б.")]),_c('p',{staticClass:"mb-0 grey--text"},[_vm._v(_vm._s(_vm._f("toRUB")(item.price_rub)))])]):_vm._e()]}},{key:"item.promotion",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('p',{staticClass:"mb-0 grey--text",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.is_in_promo ? 'Учавствует' : 'Нет')+" ")]):_vm._e()]}},{key:"item.delivery_time",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center","white-space":"nowrap"}},[_vm._v(" от "+_vm._s(item.delivery_speed_boost_slot_from)+" до "+_vm._s(item.delivery_speed_boost_slot_to)+" дней ")]):_vm._e()]}},{key:"item.delivery_speed",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.convert_delivery_speed(item.delivery))+" ")]):_vm._e()]}},{key:"item.price_index",fn:function(ref){
var item = ref.item;
return [(!item.is_empty_product)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.price_defect_rate_boost > 0 ? (item.price_defect_rate_boost * 100).toFixed(1) + '%' : 'Нет')+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
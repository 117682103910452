<template>
    <div class="py-10">
        <v-container>
            <div v-if="step===1">
                <!--<v-card max-width="400" :loading="loading.sendCode">
                    <template slot="progress">
                        <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                        ></v-progress-linear>
                    </template>

                    <v-card-title>Вход</v-card-title>
                    <v-card-text>
                        <v-text-field
                                label="Номер телефона"
                                outlined
                                v-model="phone"
                                hint="Формат 79999999999"
                        ></v-text-field>
                        <v-btn @click="sendCodeToServer" :loading="loading.sendCode" :disabled="loading.sendCode"
                               color="primary">Далее
                        </v-btn>
                    </v-card-text>
                </v-card>
                -->
                <v-alert type="error">Ошибка получения данных о продавцах. Перезагрузите страницу или сообщите об
                    ошибке
                </v-alert>
            </div>
            <div v-else-if="step===2">
                <v-card max-width="400" :loading="loading.confirmCode">
                    <template slot="progress">
                        <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                        ></v-progress-linear>
                    </template>

                    <v-card-title>Введите код</v-card-title>
                    <v-card-text>
                        <p>Введите код из СМС или приложения Wildberries</p>
                        <v-text-field
                                label="Код"
                                outlined
                                v-model="confirmCode"
                                hide-details
                        ></v-text-field>
                        <div v-if="codeError" class="red--text">{{ codeError }}</div>
                        <v-btn @click="confirmCodeToServer" class="mt-3" :loading="loading.confirmCode"
                               :disabled="loading.confirmCode" color="primary">Далее
                        </v-btn>
                    </v-card-text>
                </v-card>
            </div>
            <div v-else-if="step===3">
                <v-row>
                    <v-col sm="6" lg="4">
                        <v-card :loading="loading.getSuppliers">
                            <template slot="progress">
                                <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>

                            <v-card-title>Список доступных продавцов</v-card-title>
                            <v-card-text>
                                <v-list dense two-line>
                                    <v-list-item-group
                                            v-model="selectedSupplier"
                                            color="indigo"
                                    >
                                        <v-list-item v-for="supplier in suppliers" :key="supplier.id"
                                                     @click="selectSupplier(supplier)">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="supplier.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="supplier.fullName"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col sm="6" lg="4">
                        <v-card :disabled="loading.getTotalSellerData" :loading="loading.getTotalSellerData">
                            <template slot="progress">
                                <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>
                            <v-card-title>Ближайшие запланированные поставки</v-card-title>
                            <v-card-text>
                                <v-list dense two-line>
                                    <v-list-item
                                            v-for="income in $store.state.suppl.sellerData.incomes"
                                            :key="income.id"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-chip
                                                        class="mr-2"
                                                        small
                                                >
                                                    {{ income.supplyDateTime | formatDateToLocaleNoTime }}
                                                </v-chip>
                                                {{ income.warehouse }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>{{ income.planQuantity }} шт., {{
                                                income.statusName
                                                }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col sm="6" lg="4">
                        <v-card :disabled="loading.getTotalSellerData" :loading="loading.getTotalSellerData">
                            <template slot="progress">
                                <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>
                            <v-card-title>Планирование поставки</v-card-title>
                            <!--                            <v-card-subtitle>Выберите нужный</v-card-subtitle>-->
                            <v-card-text>
                                <div class="mt-2 text-subtitle-1">
                                    Выберите нужный заказ
                                </div>
                                <v-list dense two-line>
                                    <v-list-item-group
                                            color="indigo"
                                    >
                                        <v-list-item
                                                v-for="preorder in $store.state.suppl.sellerData.preorders"
                                                :key="preorder.id"
                                                @click="selectPreOrder(preorder)"
                                                :disabled="preorder.statusCode !== 7"
                                                :class="preorder.statusCode !== 7 ? (!$vuetify.theme.dark ? 'red lighten-5': 'red darken-4') : ''"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="preorder.warehouseName"></v-list-item-title>
                                                <v-list-item-subtitle>{{ preorder.quantity }}, {{
                                                    preorder.statusName
                                                    }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <v-divider></v-divider>
                                <div class="pt-2">
                                    <v-dialog
                                            ref="dialog"
                                            v-model="supplyDateDialog"
                                            :return-value.sync="supplyDate"
                                            width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="supplyDateRangeText"
                                                    label="Планируемые даты поставки"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="supplyDate"
                                                range
                                                scrollable
                                                :min="minDate"
                                                :first-day-of-week="1"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    text
                                                    color="primary"
                                                    @click="supplyDateDialog = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog.save(supplyDate)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </div>
                                <div class="text-right">
                                    <v-col>
                                        <v-text-field
                                                v-model="maxCoef"
                                                label="Макс. коэф."
                                                type="number"
                                                value="0"
                                                prefix="x"
                                        ></v-text-field>
                                    </v-col>
                                    <v-row justify="center" align-content="center">
                                        <v-col v-if="selectedPreOrder && $store.state.suppl.sellerData.shop_requisites">
                                            <v-autocomplete
                                                    v-model="active_shop_requisite"
                                                    :items="$store.state.suppl.sellerData.shop_requisites"
                                                    item-text="name" item-value="id"
                                                    label="Юр лицо" outlined hide-details dense clearable
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col v-if="selectedPreOrder && selectedPreOrder.isMono">
                                            <v-text-field
                                                    label="Кол-во монопаллет"
                                                    placeholder="Целое число"
                                                    v-model="monoCount"
                                                    dense
                                                    full-width="80"
                                                    outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-btn
                                                    color="purple darken-3"
                                                    class="white--text"
                                                    @click="createSupply"
                                                    :loading="loading.createSupply"
                                                    :disabled="loading.createSupply || !selectedPreOrder || (selectedPreOrder.isMono && !monoCount)"
                                            >Запланировать
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="supplyCreationData.text">
                                    <v-alert
                                            dense
                                            text
                                            :type="supplyCreationData.error ? 'error' : 'success'"
                                            class="mt-2"
                                    >
                                        {{ supplyCreationData.text }}
                                    </v-alert>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :loading="loading.fetchSupplyTasks">
                            <v-card-title>Задачи</v-card-title>
                            <div style="position: relative;">
                                <v-data-table
                                        dense
                                        :loading="loading.fetchSupplyTasks"
                                        :headers="supplyTasksHeaders"
                                        :items="$store.state.suppl.supplyTasks"
                                        item-key="order_id"
                                >
                                    <template v-slot:item.order="{ item }">
                                        <div>
                                            <div><strong>{{ item.order_id }}</strong></div>
                                            <div>{{ item.warehouse }}, {{ item.amount }} шт.</div>
                                        </div>
                                    </template>
                                    <template v-slot:item.dates="{ item }">
                                        <div>{{ item.dates[0] | formatDateToLocaleNoTime }} - {{
                                            item.dates[1] |
                                                formatDateToLocaleNoTime
                                            }}
                                        </div>
                                    </template>
                                    <template v-slot:item.task.start_time="{ item }">
                                        <div>{{ item.task.start_time | formatDateToLocale }}</div>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <div v-if="!item.task.enabled && item.closed_date">Завершена успешно {{
                                            item.closed_date | formatDateToLocale
                                            }}
                                        </div>
                                        <div v-else-if="!item.task.enabled && !item.is_closed_date">Остановлена</div>
                                        <div v-else-if="item.task.enabled && !item.is_closed_date">В работе</div>
                                        <div v-else>Непредвиденная ошибка</div>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <div>
                                            <v-btn
                                                    icon
                                                    color="grey darken-2"
                                                    v-if="!item.task.enabled && !item.closed_date"
                                                    @click="runTask(item.id)"
                                            >
                                                <v-icon>mdi-play</v-icon>
                                            </v-btn>
                                            <v-btn
                                                    icon
                                                    color="grey darken-2"
                                                    v-else-if="item.task.enabled && !item.closed_date"
                                                    @click="stopTask(item.id)"
                                            >
                                                <v-icon>mdi-pause</v-icon>
                                            </v-btn>
                                            <v-btn
                                                    icon
                                                    color="grey darken-2"
                                                    @click="deleteTaskAction(item)"
                                            >
                                                <v-icon>mdi-delete-forever</v-icon>
                                            </v-btn>
                                            <v-dialog
                                                    v-model="deleteTaskDialog"
                                                    max-width="500"
                                            >

                                                <v-card>
                                                    <v-card-title class="text-h5">
                                                        Удаление задачи
                                                    </v-card-title>

                                                    <v-card-text v-if="taskForDeleted">
                                                        Вы действительно хотите удалить задачу с поставкой {{
                                                        taskForDeleted.order_id || ''
                                                        }}?
                                                    </v-card-text>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>

                                                        <v-btn
                                                                color="green darken-1"
                                                                text
                                                                @click="deleteTaskDialog = false"
                                                        >
                                                            Нет
                                                        </v-btn>

                                                        <v-btn
                                                                color="green darken-1"
                                                                text
                                                                @click="deleteTaskDialog = false; deleteTask()"
                                                        >
                                                            Да
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import {Suppl} from '@/api/suppl';

export default {
	name: 'Supples',
	components: {},
	data() {
		return {
			loading: {
				sendCode: false,
				confirmCode: false,
				getSuppliers: false,
				getTotalSellerData: false,
				createSupply: false,
				fetchSupplyTasks: false,
			},
			deleteTaskDialog: false,
			taskForDeleted: null,
			supplyTasksHeaders: [
				{
					text: 'Поставка',
					value: 'order',
				},
                {
                    text: 'Макс. коэф.',
                    value: 'max_coef'
                },
				{
					text: 'Продавец',
					value: 'supplier_name',
				},
				{
					text: 'Даты',
					value: 'dates',
				},
				{
					text: 'Старт задачи',
					value: 'task.start_time',
				},
				{
					text: 'Статус',
					value: 'status',
				},
				{
					text: 'Попытки',
					value: 'attempt',
				},
				{
					text: 'Действия',
					value: 'actions',
				},
			],
			sendCodeToken: null,
			step: 0,
			phone: '',
			confirmCode: '',
			codeError: '',
			selectedSupplier: null,
			selectedPreOrder: null,
			suppliers: [],
			supplyDate: [
				new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
				new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)],
			minDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString(),
			supplyDateDialog: false,
			monoCount: null,
			maxCoef: 0,
			active_shop_requisite: null,
			supplyCreationData: {
				text: '',
				error: false,
			},
		};
	},
	computed: {
		supplyDateRangeText() {
			return this.supplyDate.join(' ~ ');
		},
	},
	methods: {
		changeStep(n) {
			this.step = n;
		},
		deleteTaskAction(task) {
			this.taskForDeleted = task;
			this.deleteTaskDialog = true;
		},
		async deleteTask() {
			this.loading.fetchSupplyTasks = true;
			await Suppl.handleSuppleTask(this.taskForDeleted.id, 'delete');
			this.$toasted.show(`Задача ${this.taskForDeleted.order_id} удалена`, {
				theme: 'bubble',
				type: 'success',
				position: 'top-right',
				duration: 3000,
			});
			this.taskForDeleted = {};
			await this.$store.dispatch('fetchSupplyTasks');
			this.loading.fetchSupplyTasks = false;
		},
		async runTask(id) {
			this.loading.fetchSupplyTasks = true;
			const task = await Suppl.handleSuppleTask(id, 'run');
			this.$toasted.show(`Задача ${task.order_id} запущена`, {
				theme: 'bubble',
				type: 'success',
				position: 'top-right',
				duration: 3000,
			});
			await this.$store.dispatch('fetchSupplyTasks');
			this.loading.fetchSupplyTasks = false;
		},
		async stopTask(id) {
			this.loading.fetchSupplyTasks = true;
			const task = await Suppl.handleSuppleTask(id, 'stop');
			this.$toasted.show(`Задача ${task.order_id} остановлена`, {
				theme: 'bubble',
				type: 'success',
				position: 'top-right',
				duration: 3000,
			});
			await this.$store.dispatch('fetchSupplyTasks');
			this.loading.fetchSupplyTasks = false;
		},
		async createSupply() {
			this.supplyCreationData.text = '';
			this.loading.createSupply = true;
			let payload = {
				supplyData: this.selectedPreOrder,
				dates: this.supplyDate,
			};
			if (this.selectedPreOrder.isMono) {
				payload.monoCount = this.monoCount;
			}
			if (this.active_shop_requisite) {
				payload.shop_requisites_id = this.active_shop_requisite;
			}
      payload.maxCoef = this.maxCoef
			try {
				const response = await this.$store.dispatch('createSupplyOnServer', payload);
				this.supplyCreationData.text = `Поставка успешно поставлена на ${response.supply_date}`;
				this.supplyCreationData.error = false;
				await this.selectSupplier(this.$store.state.suppl.supplier);
			} catch (err) {
				console.log(err.response);
				this.supplyCreationData.text = err.response.data;
				this.supplyCreationData.error = true;
				this.fetchSupplyTasks();
			}
			this.loading.createSupply = false;
		},
		async fetchSupplyTasks() {
			this.loading.fetchSupplyTasks = true;
			await this.$store.dispatch('fetchSupplyTasks');
			this.loading.fetchSupplyTasks = false;
		},
		async selectSupplier(supplier) {
			this.selectedPreOrder = null;
			this.loading.getTotalSellerData = true;
			try {
				await this.$store.dispatch('changeSupplier', supplier);
			} catch (err) {
				this.step = 1;
			}
			this.loading.getTotalSellerData = false;
		},
		async selectPreOrder(preorder) {
			this.selectedPreOrder = preorder;
		},
		async sendCodeToServer() {
			this.loading.sendCode = true;
			const res = await Suppl.sendCode(this.phone);
			this.sendCodeToken = res['token'];
			this.loading.sendCode = false;
			this.step = 2;
		},
		async confirmCodeToServer() {
			this.loading.confirmCode = true;
			try {
				const res = await Suppl.confirmCode(this.confirmCode, this.sendCodeToken);
				this.step = 3;
			} catch (err) {
				console.log(err.response.status);
				if (err.response.status) {
					this.codeError = err.response.data;
				}
			}
			this.loading.confirmCode = false;
			this.getSuppliersFromServerAPI();
		},
		async getSuppliersFromServerAPI() {
			try {
				this.suppliers = await Suppl.getSuppliers();
				this.step = 3;
			} catch (err) {
				this.$toasted.show(`Ошибка загрузки данных продавцов`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		},
	},
	async mounted() {
		document.title = 'Поставки WB | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Поставки`);
		await this.getSuppliersFromServerAPI();
		await this.fetchSupplyTasks();
	},
};
</script>

<style scoped>

</style>

<template>
	<v-card flat class="my-3 elevation-2 flat">
		<v-card-title>Блок с графиками</v-card-title>
		<v-card-text>
			<v-container fluid>
                <v-row>
                    <v-col cols="3">
                        <v-menu
                                v-model="dataRangeDateMenu"
                                :close-on-content-click="false"
                                prepend-icon="mdi-calendar"
                                transition="scale-transition"
                                offset-y="offset-y"
                                nudge-right="40"
                                min-width="290px"
                                ref="dataRangeDateMenu"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="clearDate"
                                        v-model="dataRangeDateText"
                                        label="Даты"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        hide-details
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dataRangeDates"
									@click:clear="clearDate"
                                    range
                                    @change="changeDates"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                                @click="UpdateDateToCharts()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            <v-icon dark>
                                mdi-send
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
			<v-container fluid style="display: flex; justify-content:space-between;">
				<div style="width: 49.5%">
					<h3 style="text-align:center;">Статистика</h3>
					<StatsChart :series="serializeData"/>
				</div>
				<div style="width: 49.5%">
					<h3 style="text-align:center;">Продажи</h3>
					<SalesChart :series="serializeData"/>
				</div>
			</v-container>
		</v-card-text>    
	</v-card>
</template>

<script>
import StatsChart from "@/components/AdvertisementNew/StatsChart.vue"
import SalesChart from "@/components/AdvertisementNew/SalesChart.vue"

export default {
    name: 'AdvertisementCharts',
	data(){
		return{
			dataRangeDateMenu: false,
			dataRangeDates: null,
			dateRangeForCharts: {
				"before": null,
				"after": null
			}
		}
	},
	props: ['data'],
	components: {
		StatsChart,
		SalesChart
	},
	computed: {
		dataRangeDateText() {
			return this.dataRangeDates?.join(' ~ ');
		},
		serializeData(){
			let result_array = []
			if (this.data)
				this.data.forEach(element => {
					result_array = result_array.concat(element.statics.slice(0, -1))
				})
			if(this.dateRangeForCharts){
				if (this.dateRangeForCharts['after'] && this.dateRangeForCharts['before'])
					result_array = result_array.filter(item => {
						let current_date = this.$options.filters.formatDateToLocaleNoTime(item.date).split('.').reverse().join('-')
						return (
							current_date >= this.dateRangeForCharts['after'] && 
							current_date <= this.dateRangeForCharts['before']
						)
						
					})
				else if (this.dateRangeForCharts['after'])
					result_array = result_array.filter(
						item => item.date >= this.dateRangeForCharts['after']
					)
			}
			return result_array
		}
	},
	methods: {
		clearDate(){
			this.dataRangeDates = null
			this.dataRangeDateText = null
			this.dateRangeForCharts = {
				"before": null,
				"after": null
			}

		},
		changeDates(dates) {
			let date1 = new Date(dates[0]);
			let date2 = new Date(dates[1]);
			if (date1 > date2) {
				dates = dates.reverse();
			}
			this.dataRangeDates = dates
		},
		UpdateDateToCharts(){
			if(this.dataRangeDates){
				if (this.dataRangeDates.length == 1)
					this.dateRangeForCharts =  {
						"before": null,
						"after": this.dataRangeDates[0]
					}
				else if (this.dataRangeDates.length == 2){
					this.dateRangeForCharts =  {
						"before": this.dataRangeDates[1],
						"after": this.dataRangeDates[0]
					}
				}
			}
			else{
				this.dateRangeForCharts =  {
					"before": null,
					"after": null
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
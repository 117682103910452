<template>
    <v-container fluid>
		<v-expansion-panels v-model="panels.panels_general_info">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="expand_title">Общая информация</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<AdvertisementDetailInfoComponent 
						:data="data" :week_stats="week_stats" @get_data="get_advertisement_data"
						@change_tab="change_tab"
					/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<v-tabs-items v-model="tab" class="mt-5 elevation-2">
			<v-tab-item>
				<v-expansion-panels v-model="panels.panels_management">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="expand_title">Управление РК</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<AdvertisementDetailManagementComponent 
								:data="data" :loading="loading" v-if=data
								@get_data="get_advertisement_data"
							/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<v-expansion-panels v-model="panels.panels_keywords">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="expand_title">Ключевые фразы</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<AdvertisementDetailKeywordsComponent :campaign_id="campaign_id"/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<v-expansion-panels v-model="panels.panels_stats">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="expand_title">Статистика</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<AdvertisementDetailStatsComponent :campaign_id="campaign_id"/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<v-expansion-panels v-model="panels.panels_charts">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="expand_title">Графики</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<AdvertisementDetailChartsComponent :campaign_id="campaign_id"/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<v-expansion-panels v-model="panels.panels_history">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="expand_title">История рекламной кампании</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<AdvertisementDetailHistoricalComponent :campaign_id="campaign_id"/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
import AdvertisementDetailInfoComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailInfoComponent'
import AdvertisementDetailKeywordsComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailKeywordsComponent'
import AdvertisementDetailStatsComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailStatsComponent'
import AdvertisementDetailManagementComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailManagementComponent'
import AdvertisementDetailHistoricalComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailHistoricalComponent'
import AdvertisementDetailChartsComponent from '@/components/Advertisement/AdvertisementDetail/AdvertisementDetailChartsComponent'
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementDetailView',
	components: {
		AdvertisementDetailInfoComponent,  AdvertisementDetailKeywordsComponent, 
		AdvertisementDetailStatsComponent, AdvertisementDetailManagementComponent, 
		AdvertisementDetailHistoricalComponent, AdvertisementDetailChartsComponent
	},
	data() {
		return {
			loading: false,
			data: null,
			tab: 0,
			panels: {
				panels_general_info: 0,
				panels_management: 0,
				panels_keywords: 0,
				panels_stats: 0,
				panels_history: 0,
				panels_charts: 0
			},
			week_stats: {
				drr: 0,
				profit: 0,
				ctr: 0,
				cpc: 0,
				cpm: 0,
				cr: 0
			}
		}
	},
	computed: {
		campaign_id() {
			return this.$route.params.campaign_id;
		},
	},
	methods: {
		change_tab(data){
			this.tab = data
		},
		async get_advertisement_data() {
			this.loading = true
			try {
				let response = await Advertisement.fetchAdvertisementDetail(this.campaign_id);
				this.data = response.data;
			} catch (err) {
				this.$toasted.show(`Ошибка загрузки данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading = false
		},
		async fetch_week_stats(){
			try{
				const response = await Advertisement.fetchWeekStatsAdvertisementCampaign(this.campaign_id)
				this.week_stats.drr = response.data.drr
				this.week_stats.profit = response.data.profit
				this.week_stats.ctr = response.data.ctr
				this.week_stats.cpc = response.data.cpc
				this.week_stats.cpm = response.data.cpm
				this.week_stats.cr = response.data.cr
			} catch(err){
				console.log(err)
			}
		},
	},
	async mounted(){
		document.title = `Рекламная кампания ${this.campaign_id} | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Рекламная кампания ${this.campaign_id}`)
		await this.get_advertisement_data()
		await this.fetch_week_stats()
	}
}
</script>

<style lang="scss" scoped>
	.expand_title{
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: 0.0125em;
		line-height: 2rem;
		word-break: break-all;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
</style>
<template>
	<v-card class="mt-5">
		<v-card-title>Проверка коэффициентов приемки Wildberries</v-card-title>
		<v-card-text>
			<v-row class="mt-5">
				<v-col>
                    <v-autocomplete
						v-model="period" item-text="name" item-value="value" label="Период проверки"
						outlined hide-details dense clearable :items="[
                            {'value': 3, 'name': '3 дня'}, 
							{'value': 7, 'name': '7 дней'}, 
							{'value': 14, 'name': '14 дней'}, 
						]"
					></v-autocomplete>
				</v-col>
				<v-col>
					<v-btn 
						depressed color="purple darken-2" dark @click="fetch_data" :loading="table.loading"
					>Выполнить проверку</v-btn>	
				</v-col>
			</v-row>
            <v-row v-if="table.type_acceptance.length > 0">
                <h3 class="mt-5">Настройка уведомлений</h3>
            </v-row>
            <v-row class="mt-10" v-if="table.type_acceptance.length > 0">
                <v-text-field
                    v-model="settings.coefficient" label="Коэффициент приемки" outlined type="number" dense 
                    clearable hide-details @change="update_alert_settings({'coefficient': settings.coefficient}, 'coefficient')"
                ></v-text-field>
                <v-select
                    v-model="settings.list_type_acceptance" :items="table.type_acceptance" 
                    label="Типы поставок" outlined multiple hide-details dense clearable
                    class="ml-5" @change="update_alert_settings({'list_type_acceptance': settings.list_type_acceptance}, 'list_type_acceptance')"
                ></v-select>
                <v-select
                    v-model="settings.list_warehouse" :items="table.list_warehouse" 
                    label="Список складов" outlined multiple hide-details dense clearable
                    class="ml-5" @change="update_alert_settings({'list_warehouse': settings.list_warehouse}, 'list_warehouse')"
                ></v-select>
            </v-row>
            <v-row v-if="table.type_acceptance.length > 0" class="mt-10">
                <v-tabs grow v-model="current_tab" @change=prepared_headers_and_data>
                    <v-tab v-for="(item, index) in table.type_acceptance" :key="index">{{ item }}</v-tab>
                </v-tabs>
            </v-row>
            <v-row v-if="prepared_headers.length > 0">
                <v-data-table
                    :headers="prepared_headers" :items="prepared_data" :loading="prepare_loading"
                    class="elevation-0 small-font mt-3 vertical-border" style="width: 100%;"
                    hide-default-footer fixed-header height="650px" disable-pagination dense
                >
                    <template v-for="prop in table.date_list" v-slot:[`item.${prop}`]="{ item }">
                        <div v-if="item[prop].coefficient == -1" class="grey_rentab" :key="prop">
                           Нет поставок
                        </div>
                        <div :key="prop + 1" v-else :class="{
                            'green_rentab': item[prop].coefficient == 0, 
                            'red_rentab': item[prop].coefficient > 0, 
                            'grey_rentab': item[prop].coefficient == -1
                        }">{{ item[prop].coefficient }} ({{ item[prop].logistic_storage_coefficient }})</div>
                    </template>
                </v-data-table>
            </v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import {Suppl} from '@/api/suppl';

export default {
    name: 'SupplCheckFreeCoef',
	data() {
		return {
            period: 7,
            current_tab: 0,
            prepared_headers: [],
            prepared_data: [],
            table: {
                loading: false,
                data: {},
                headers: [],
                date_list: [],
                type_acceptance: [],
                list_warehouse: []
            },
            prepare_loading: false,
            settings: {}
		}
	},
	methods: {
        calc_headers(){
            let data = [{ text: 'Склады', value: 'warehouse_name', width: '3%', sortable: false }]
            this.table.date_list.forEach(item => {
                data.push({ text: item, value: item, width: '1%', sortable: false })
            })
            this.prepared_headers = data
        },
        calc_data(){
            let current_type = this.table.type_acceptance[this.current_tab]
            let data = this.table.data[current_type]
            let result = []
            for (const [key, value] of Object.entries(data)) {
                let tmp = {'warehouse_name': key}
                for (const [date, date_value] of Object.entries(value)) {
                    tmp[date] = date_value
                }
                result.push(tmp)
            }
            this.prepared_data = result
        },
        prepared_headers_and_data(){
            this.prepare_loading = true
            this.prepared_data = []
            this.calc_headers()
            this.calc_data()
            this.prepare_loading = false
        },
        async fetch_data(){
            this.table.loading = true
            try{
                const response = await Suppl.checkFreeLimits(this.period)
                this.table.data = response.data.data
                this.table.date_list = response.data.date_list
                this.table.type_acceptance = response.data.type_acceptance
                this.table.list_warehouse = response.data.list_warehouse
                this.prepared_headers_and_data()
            } catch(err){
                console.log(err)
            }
            this.table.loading = false
        },
        async fetch_settings(){
            try{
                const response = await Suppl.getAlertConfig()
                this.settings = response.data
            } catch(err){
                console.log(err)
            }
        },
        async update_alert_settings(data, key){
			try{
				await Suppl.updateAlertConfig(this.settings.id, data)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				if (key)
					this.settings[key] = data[key]
			} catch(err){
				this.$toasted.show(`Ошибка изменения данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		},
	},
	async mounted(){
		document.title = 'Проверка коэффициентов поставок | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Проверка коэффициентов поставок | mp.lomex-group.ru'`)
        await this.fetch_settings()
	}
}
</script>

<style scoped lang="scss">
	.green_rentab{
        margin: -16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
		background-color: #80c56e;
	}
	.red_rentab{
        margin: -16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
		background-color: #e37d87;
	}
    .grey_rentab{
        margin: -16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
		background-color: #8c8c8c;
	}
</style>


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialog_delete)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1):_vm._e(),_c('router-link',{staticClass:"ml-3",attrs:{"target":"_blank","to":{name: 'management_product_list'}}},[_c('v-btn',{staticClass:"mt-5 ml-5",attrs:{"color":"primary","dark":""}},[_vm._v("Создать новый шаблон")])],1),_c('router-link',{staticClass:"ml-3",attrs:{"target":"_blank","to":{name: 'management_product_delayed_task'}}},[_c('v-btn',{staticClass:"mt-5 ml-5",attrs:{"color":"primary","dark":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"title":"Отложенные задачи"}},[_vm._v("mdi-timetable")]),_vm._v(" Список отложенных задач ")],1)],1),_c('v-data-table',{ref:"block_list_templates",staticClass:"elevation-0 mt-3 vertical-border",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.table_loading,"pageCount":_vm.pagination.numberOfPages,"options":_vm.options,"items-per-page":50,"server-items-length":_vm.pagination.totalItems,"footer-props":{
				'items-per-page-options': [5, 10, 25, 50, 100]
			}},on:{"update:page":_vm.getTempalteResponse,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
			var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":{ name: 'management_product_main', params: { template_id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date_created",fn:function(ref){
			var item = ref.item;
return [(item.date_created)?_c('div',[_vm._v(_vm._s(_vm._f("formatDateToLocale")(item.date_created)))]):_c('div',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
			var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteTemplate(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.auto_updating",fn:function(ref){
			var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","dense":""},on:{"change":function($event){return _vm.change_auto_update(item)}},model:{value:(item.is_auto_update),callback:function ($$v) {_vm.$set(item, "is_auto_update", $$v)},expression:"item.is_auto_update"}})]}},{key:"item.is_open",fn:function(ref){
			var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","dense":""},on:{"change":function($event){return _vm.change_is_open(item)}},model:{value:(item.is_open),callback:function ($$v) {_vm.$set(item, "is_open", $$v)},expression:"item.is_open"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
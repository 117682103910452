<template>
	<v-container fluid>
		<v-dialog v-model="dialog_detail" v-if="dialog_detail">
			<v-card>
				<v-card-title>
					Исторические данные по {{ dialog_detail_item.sku }} 
					({{dialog_detail_item.keyword}})
				</v-card-title>
				<div>
					<OzonCompetitorsDetailDialog 
						:sku="dialog_detail_item.sku" :keyword="dialog_detail_item.keyword"
					/>
				</div>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="table.headers"
			:items="table.data"
			class="elevation-0 mt-1 vertical-border"
			:loading="table.loading"
			group-by="keyword"
			show-group-by
			ref="test"
			hide-default-footer
			disable-pagination
			:item-class="calculate_color_row"
		>
			<template v-slot:group.header="item">
				<td :colspan="table.headers.length">
					<v-btn icon @click="item.toggle">
						<v-icon x-small>{{ item.isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
					</v-btn>
					Поисковой запрос: <span style="font-weight: bold;">{{ item.group }}</span>
				</td>
			</template>
			<template v-slot:item.position="{ item }">
				<div style="text-align: center;">
					<v-icon
						style="padding: 0px;" class="mr-2" small @click="open_dialog_detail(item)"
					>mdi-history </v-icon>
					{{ item.position ? item.position : 0 }}
				</div>
			</template>
			<template v-slot:item.product="{ item }">
				<div class="d-flex py-1">
					<div style="border-radius: 10px; overflow: hidden; width: 48px; height: 48px;">
						<v-img :src="item.image" contain height="48" width="48" v-if="!item.is_empty_product"/>
					</div>
					<div class="ml-2" style="width: 250px;">
						<p style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" class="mb-0">
							{{ !item.is_empty_product ? item.name : '-' }}
						</p>
						<p style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" class="mt-1 mb-0 grey--text">
							{{ item.sku }} | {{ !item.is_empty_product ? item.seller_name : '-' }}
						</p>
					</div>
				</div>
			</template>
			<template v-slot:item.summary_score="{ item }">
				<div v-if="item.is_empty_product"></div>
				<div v-else-if="item.is_traforetto" style="text-align: center; color: #005bff;">Трафарет</div>
				<div v-else style="text-align: center;">
					<p class="mb-0">{{ item.final_result.toFixed(3) }}</p>
					<v-chip small v-if="item.search_promotion_enabled" class="deep-purple--text">
						Поднятие на {{ (item.search_promotion_boost * 100).toFixed(3) }}%
					</v-chip>
				</div>
			</template>
			<template v-slot:item.query_fit_score="{ item }">
				{{ item.query_fit_score ? item.query_fit_score : 0 }} балла
			</template>
			<template v-slot:item.rating="{ item }">
				<p class="mb-0" style="white-space: nowrap; text-align: center;" v-if="!item.is_empty_product">
					{{ item.rating.toFixed(1) }} ({{ item.rates_count }} шт.)
				</p>
			</template>
			<template v-slot:item.price="{ item }">
				<div v-if="!item.is_empty_product">
					<p class="mb-0">{{ item.price_score }} б.</p>
					<p class="mb-0 grey--text">{{ item.price_rub | toRUB }}</p>
				</div>				
			</template>
			<template v-slot:item.promotion="{ item }">
				<p class="mb-0 grey--text" style="text-align: center;" v-if="!item.is_empty_product">
					{{ item.is_in_promo ? 'Учавствует' : 'Нет' }}
				</p>			
			</template>
			<template v-slot:item.delivery_time="{ item }">
				<div style="text-align: center; white-space: nowrap;" v-if="!item.is_empty_product">
					от {{ item.delivery_speed_boost_slot_from }} до {{ item.delivery_speed_boost_slot_to }} дней	
				</div>		
			</template>
			<template v-slot:item.delivery_speed="{ item }">
				<div style="text-align: center" v-if="!item.is_empty_product">
					{{ convert_delivery_speed(item.delivery) }}
				</div>		
			</template>
			<template v-slot:item.price_index="{ item }">
				<div style="text-align: center;" v-if="!item.is_empty_product">
					{{ item.price_defect_rate_boost > 0 ? (item.price_defect_rate_boost * 100).toFixed(1) + '%' : 'Нет'}}
				</div>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import { KeywordMonitoring } from '@/api/keyword_monitoring'
import OzonCompetitorsDetailDialog from '@/components/Products/OzonCompetitorsDetailDialog'

export default {
    name: "OzonCompetitorsComponent",
	props: ['fbo_sku'],
	components: { OzonCompetitorsDetailDialog },
    data(){
        return {
			table: {
				data: [],
				loading: false,
				headers: [
					{ text: "Поисковая фраза", value: "keyword", sortable: false, width: "1%" },
					{ text: "Позиция", value: "position", sortable: true, width: "1%", groupable: false, align: 'center' },
					{ text: "Товар", value: "product", sortable: false, width: "2%", groupable: false },
					{ text: "Сводная оценка", value: "summary_score", sortable: false, width: "1%", groupable: false },
					{ text: "Соответствие запросу", value: "query_fit_score", sortable: false, width: "1%", groupable: false, align: 'center' },
					{ text: "Отзывы", value: "rating", sortable: false, width: "1%", groupable: false },
					{ text: "Цена", value: "price", sortable: false, width: "1%", groupable: false },
					{ text: "Популярность по запросу", value: "popularity_score", sortable: false, width: "1%", groupable: false, align: 'center' },
					{ text: "Продажи товара", value: "sales_score", sortable: false, width: "1%", groupable: false, align: 'center' },
					{ text: "Популярность общая", value: "popularity_total_score", sortable: false, width: "1%", groupable: false, align: 'center' },
					{ text: "Акции", value: "promotion", sortable: false, width: "1%", groupable: false },
					{ text: "Быстрая доставка", value: "delivery_speed", sortable: false, width: "1%", groupable: false,},
					{ text: "Срок доставки", value: "delivery_time", sortable: false, width: "1%", groupable: false,},
					{ text: "Индекс цен", value: "price_index", sortable: false, width: "1%", groupable: false,},
				],
			},
			dialog_detail: false,
			dialog_detail_item: null
        }
    },
    methods:{
        async fetchCompetitors(){
			this.table.loading = true
			try{
				const response =  await KeywordMonitoring.fetchCompetitors(this.fbo_sku)
				this.table.data = response.data
			} catch (err){
				console.log(err)
			}      
			this.table.loading = false   
        },
		convert_delivery_speed(value){
			let dict = {
				"LOCAL_STORE_TYPE": "Домашний склад",
				"NONE_TYPE": "Нет",
				"EXPRESS_TYPE": "EXPRESS_TYPE"
			}
			try{
				return dict[value]
			} catch{
				return value
			}
		},
		close_all_groups(){
			if(this.$refs.test && this.$refs.test.openCache){
				for (const key of Object.keys(this.$refs.test.openCache)) {
					this.$refs.test.openCache[key] = false
				}
			}
		},
		calculate_color_row(item){
			if (item.is_our_product)
				return 'green-row'
			return 'orange-row'
		},
		open_dialog_detail(item){
			this.dialog_detail = true
			this.dialog_detail_item = item
		}
    },
    async mounted(){
        await this.fetchCompetitors()
		this.close_all_groups()
	},
  }
</script>
<style>
	.green-row{
        background-color: #e2f5dd;
    }
	.orange-row{
		background-color: #f1f1d2;
	}
</style>
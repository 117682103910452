var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"pa-4",attrs:{"type":"table-heading, table-row@5"}}):_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Склад")]),_c('th',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"small-text dashed"},[_vm._v("free_to_sell_amount"),_c('v-icon',{staticClass:"mb-1",attrs:{"x-small":""}},[_vm._v("mdi-help")])],1)])]}}])},[_c('span',[_vm._v("Количество товара, доступное к продаже на Ozon.")])])],1),_c('th',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"small-text dashed"},[_vm._v("reserved_amount"),_c('v-icon',{staticClass:"mb-1",attrs:{"x-small":""}},[_vm._v("mdi-help")])],1)])]}}])},[_c('span',[_vm._v("Количество товара, зарезервированное для покупки, возврата и перевозки между складами.")])])],1),_c('th',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"small-text dashed"},[_vm._v("promised_amount"),_c('v-icon',{staticClass:"mb-1",attrs:{"x-small":""}},[_vm._v("mdi-help")])],1)])]}}])},[_c('span',[_vm._v("Количество товара, указанное в подтверждённых будущих поставках.")])])],1)])]),_c('tbody',_vm._l((_vm.data),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_vm._v(_vm._s(item.warehouse_name))]),_c('td',[_vm._v(_vm._s(item.free_to_sell_amount))]),_c('td',[_vm._v(_vm._s(item.reserved_amount))]),_c('td',[_vm._v(_vm._s(item.promised_amount))])])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }